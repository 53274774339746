import {uuid4} from "@/Utility/Helpers";
import AuthResult from "@/Services/ThridPartyAuth/AuthResult";
import {Provider} from "@/Services/ThridPartyAuth/Provider";

export default class ThirdPartyAuthService {

    private readonly authOrigin = 'https://oauth.3spin-learning.com';

    async requestAuth(provider: Provider, withoutUserInteraction = false): Promise<AuthResult> {

        const cachedAuthResult = this.readFromCache(provider);

        if (cachedAuthResult !== null && !cachedAuthResult.isExpired) {
            return cachedAuthResult;
        } else if (withoutUserInteraction) {
            throw new Error('Could not authenticate without user interaction.');
        }

        const state = uuid4();
        const authWindow = window.open(`${this.authOrigin}/?provider=${provider}&origin=${window.origin}&state=${state}`);

        return new Promise((resolve, reject) => {
            if (authWindow === null) {
                reject('Could not open login window.');
                return;
            }

            const interval = setInterval(() => {
                if (authWindow.closed) {
                    // window has been closed - cancel watcher and mark promise as failed
                    clearInterval(interval);
                    reject('Auth window has been closed.');
                }
            }, 500);

            const messageListener = (event: MessageEvent) => {
                try {
                    const authResult = this.validateAuthMessage(event, state, authWindow);
                    this.writeToCache(provider, authResult);
                    resolve(authResult);
                } catch (e) {
                    reject(e);
                } finally {
                    clearInterval(interval);
                    authWindow.close();
                }
            };

            window.addEventListener('message', messageListener, {once: true});
        });
    }

    private validateAuthMessage(event: MessageEvent, state: String, win: Window): AuthResult {
        if (event.origin !== this.authOrigin) {
            throw new Error(`Message origin (${event.origin}) does not match expected origin (${this.authOrigin}).`);
        }

        if (event.source !== win) {
            throw new Error('Message originates from unexpected window instance.');
        }

        const authResult = new AuthResult(event.data);

        if (authResult.state !== state) {
            throw new Error('Auth states do not match.');
        }

        return authResult;
    }

    private writeToCache(provider: Provider, authResult: AuthResult) {
        const key = this.getCacheKey(provider);
        localStorage.setItem(key, authResult.toJson());
    }

    private readFromCache(provider: Provider): AuthResult | null {
        const key = this.getCacheKey(provider);
        const cached = localStorage.getItem(key);

        if (cached === null) {
            return null;
        }

        return AuthResult.fromJson(cached);
    }

    private getCacheKey(provider: Provider) {
        return 'third_party_oauth_' + provider;
    }
}
