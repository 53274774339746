import AxiosRequest from "@/Services/AxiosRequest";
import ServiceIsBusyError from "@/Errors/ServiceIsBusyError";
import {route, trans} from "@/Utility/Helpers";
import SpeechSdkAuthToken from "@/Services/CognitiveServices/SpeechSdkAuthToken";
import {
    AudioConfig,
    PushAudioOutputStream,
    SpeechConfig,
    SpeechSynthesisOutputFormat,
    SpeechSynthesisResult,
    SpeechSynthesizer
} from "microsoft-cognitiveservices-speech-sdk";
import TextToSpeechResult from "@/Services/CognitiveServices/TextToSpeechResult";

export default class TextToSpeechService {

    get DefaultOutputFormat() {
        return SpeechSynthesisOutputFormat.Audio24Khz96KBitRateMonoMp3;
    }

    constructor() {
        this.isSynthesizing = false;
        this.request = null;

        /**
         * @type {null|SpeechSdkAuthToken}
         */
        this.authToken = null;

        /**
         * @type {null|TextToSpeechResult}
         */
        this.lastSuccessfullSynthesisResult = null;
    }

    /**
     * Cancel any ongoing requests.
     */
    async cancelRequests() {
        // @NOTE: Only working with a single request at the moment!
        if (this.request !== null) {
            await this.request.cancel();
        }
    }

    /**
     * Synthesizes the given text and returns a populated result object.
     * When called multiple times with the same parameters, a cached result will be returned.
     *
     * @param {string} text
     * @param {TextToSpeechVoiceConfig} voiceConfig
     * @return {Promise<TextToSpeechResult>}
     */
    async synthesize(text, voiceConfig) {

        // Return cached result if text did not change
        if (this.lastSuccessfullSynthesisResult?.plainTextInput === text &&
            this.lastSuccessfullSynthesisResult?.voiceConfig === voiceConfig) {
            return this.lastSuccessfullSynthesisResult;
        }

        this.isSynthesizing = true;

        try {

            const token = await this._ensureValidToken();

            const speechConfig = SpeechConfig.fromAuthorizationToken(token.authToken, token.region);
            speechConfig.speechSynthesisVoiceName = voiceConfig.voiceName;
            speechConfig.speechSynthesisLanguage = voiceConfig.languageCode;
            speechConfig.speechSynthesisOutputFormat = this.DefaultOutputFormat;

            const pushStreamCallback = {
                write: () => {
                },
                close: () => {
                }
            };

            const outputStream = PushAudioOutputStream.create(pushStreamCallback);
            const audioConfig = AudioConfig.fromStreamOutput(outputStream);

            const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

            const ssmlText = voiceConfig.getSsmlText(text);
            const result = await this._executeSpeechSynthesis(synthesizer, ssmlText);
            synthesizer.close();

            if (typeof result.errorDetails === 'string') {
                switch (result.properties.getProperty('CancellationErrorCode')) {
                    case "ConnectionFailure":
                        throw trans('assets.create.modals.tts_errors.connectionfailure.description');
                    default:
                        throw result.errorDetails;
                }
            }

            this.lastSuccessfullSynthesisResult = new TextToSpeechResult(
                result,
                text,
                voiceConfig,
                speechConfig.speechSynthesisOutputFormat
            );

        } finally {
            this.isSynthesizing = false;
        }

        return this.lastSuccessfullSynthesisResult;
    }

    async _ensureValidToken() {
        if (this.authToken?.isValid) {
            return this.authToken;
        }

        return await this._fetchToken();
    }

    /**
     * @return {Promise<SpeechSdkAuthToken>}
     * @private
     */
    async _fetchToken() {
        if (this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.request = new AxiosRequest();

        try {
            const response = await this.request.post(route('api.services.cognitive_services.token'));
            this.authToken = new SpeechSdkAuthToken(response.data);
            return this.authToken;
        } finally {
            this.request = null;
        }
    }

    /**
     * @param {SpeechSynthesizer} synthesizer
     * @param {string} escapedText
     * @return {Promise<SpeechSynthesisResult>}
     * @private
     */
    async _executeSpeechSynthesis(synthesizer, escapedText) {
        return new Promise((resolve, reject) => {
            synthesizer.speakSsmlAsync(
                escapedText,
                result => resolve(result),
                error => reject(error),
            );
        });
    }
}
