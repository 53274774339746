<template>
    <svg v-if="name" class="icon">
        <use :xlink:href="'#' + name"></use>
    </svg>
</template>

<script>
    export default {
        name: 'Icon',
        props: {
            name: {             // SVG icon identifier name (e.g. "icon_search, icon_doublearrow_right")
                type: String,
                default: null
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
