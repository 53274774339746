import Emitter from 'tiny-emitter/instance';
window.Emitter = Emitter;

import Popper from "popper.js";
window.Popper = Popper;

import moment from 'moment';
window.moment = moment;

import Papa from 'papaparse';
window.Papa = Papa;

// Initialize Gate and register it in Vue
import Gate from '@/Models/User/Gate';
window.gate = new Gate('currentUser');

// Register policies used for checking permissions with the global Gate instance:
import UserPolicy from "@/Policies/UserPolicy";
import AssetPolicy from "@/Policies/AssetPolicy";
import CoursePolicy from '@/Policies/CoursePolicy';
import UnitPolicy from '@/Policies/UnitPolicy';
import TenantPolicy from "@/Policies/TenantPolicy";
import UnitLinkPolicy from "@/Policies/UnitLinkPolicy";
import ManagedSessionPolicy from '@/Policies/ManagedSessionPolicy';

window.gate.policy('User', new UserPolicy);
window.gate.policy('Asset', new AssetPolicy());
window.gate.policy('Course', new CoursePolicy());
window.gate.policy('Unit', new UnitPolicy());
window.gate.policy('Tenant', new TenantPolicy());
window.gate.policy('UnitLink', new UnitLinkPolicy());
window.gate.policy('ManagedSession', new ManagedSessionPolicy());

// Toast Notifications
import {ToastInterface, useToast} from "vue-toastification";
import {ToastContent, ToastID, ToastOptions} from "vue-toastification/dist/types/types";
import {TYPE} from "vue-toastification/src/ts/constants";
import ToastNotification from "@/Vue/Common/ToastNotification.vue";
window.toast = useToast();

// Always use our custom toast component unless an actual component is passed as parameter
// @NOTE: This is only needed because of our SVG icons not being supported by the plugin
const initializeToastPlugin = (): ToastInterface => {

    const injectToastComponent = (content: ToastContent, options: ToastOptions = {}, icon: any = false): ToastContent => {
        options = options || {};
        options.icon = options.icon || icon;
        return (typeof content === 'string') ? {
            component: ToastNotification,
            props: {
                text: content,
                options: options,
            }
        } : content;
    };

    // Override methods to inject component and custom icons:
    return Object.assign(
        (content: ToastContent, options?: ToastOptions): ToastID => window.toast(injectToastComponent(content, options), options),
        {
            ...window.toast,
            error: (content: ToastContent, options?: ToastOptions & { type?: TYPE.ERROR }): ToastID => window.toast.error(injectToastComponent(content, options, options?.icon || 'icon_error'), options),
            info: (content: ToastContent, options?: ToastOptions & { type?: TYPE.INFO }): ToastID => window.toast.info(injectToastComponent(content, options, options?.icon || 'icon_info'), options),
            success: (content: ToastContent, options?: ToastOptions & { type?: TYPE.SUCCESS }): ToastID => window.toast.success(injectToastComponent(content, options, options?.icon || 'icon_check'), options),
            warning: (content: ToastContent, options?: ToastOptions & { type?: TYPE.WARNING }): ToastID => window.toast.warning(injectToastComponent(content, options, options?.icon || 'icon_hotspot-warning'), options),
        }
    );
}

export function registerGlobals(app: any) {
    app.config.globalProperties.$gate = window.gate;

    // Add a global event bus, so we can better handle events between components:
    // @see: https://alligator.io/vuejs/global-event-bus/
    app.config.globalProperties.$globalEvents = window.Emitter;

    app.config.globalProperties.moment = window.moment;
    app.config.globalProperties.Papa = window.Papa;

    // Inject custom toast notification component:
    app.config.globalProperties.$toast = initializeToastPlugin();
}
