<script>

    // Import classes:
    import {h, mergeProps, readonly} from 'vue';
    import Draggable from '@/Utility/Draggable';
    import {shortId} from '@/Utility/Helpers';

    export default {
        name: 'Draggable',
        inheritAttrs: false,
        props: {
            disabled: {                         // Disabled state
                type: Boolean,
                default: false
            },
            groups: {                           // Group identifiers (can be just one or a list of strings)
                type: [String, Array],
                default: null
            },
            value: {                            // The value to be dragged along with the DOM element and passed to the event callbacks
                type: [String, Number, Object, Array, Boolean],
                default: null
            },
            tag: {                              // The HTML tag to be rendered
                type: String,
                default: 'div'
            },
            draggableSelector: {                // Optional selector to specify the dragging handle (e.g. 'header[draggable]')
                type: String,
                default: null
            },
            dropOnly: {                         // Whether this component should only act as a drop target
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                uid: shortId('draggable'),      // Unique identifier for all draggables
                isFirefox: Boolean(navigator.userAgent.toLowerCase().indexOf('firefox') >= 0),   // @TODO: Remove this once the bugs are fixed in FireFox
                callbacks: {}   // Callback methods passed from initial attributes
            }
        },
        render() {

            // Copy initial attributes and merge CSS classes
            const attrs = mergeProps(this.$attrs, {
                class: this.cssClasses
            });

            // Move drag'n'drop callbacks to temporary variable so we can trigger them conditionally with our injected data
            Object.keys(attrs).filter(k => k.match(/^on(drag|drop)/i)).forEach(attributeName => {
                this.callbacks[attributeName] = attrs[attributeName];
                delete attrs[attributeName];
            });

            // Enable drag functionality
            if (this.canDrag) {
                attrs.onDragstart = this.onDragStart;
                attrs.onDragend = this.onDragEnd;

                // Add [draggable] attribute to root element only if no specific selector is set
                // @NOTE: Needed to fix selections with mouse not working in input field children
                if (this.draggableSelector === null) {
                    attrs.draggable = true;
                }

                // @TODO: Remove once the bugs are fixed in FireFox
                if (this.isFirefox) {
                    // Fix for double click
                    attrs.onDblclick = attrs.onDblclick ? mergeProps(attrs.onDblclick, this.onDoubleClick) : this.onDoubleClick;
                }
            } else {
                delete attrs.draggable;
                delete attrs.onDragstart;
                delete attrs.onDragend;
            }

            // Enable drop functionality if the component has a drop function defined
            if (this.canDrop) {
                attrs.onDrop = this.onDrop;
                attrs.onDragover = this.onDragOver;
                attrs.onDragenter = this.onDragEnter;
                attrs.onDragleave = this.onDragLeave;
            } else {
                delete attrs.onDrop;
                delete attrs.onDragover;
                delete attrs.onDragenter;
                delete attrs.onDragleave;
            }

            return h(this.tag, readonly(attrs), this.$slots.default instanceof Function ? this.$slots.default() : null);
        },
        mounted() {
            if (!this.hasValue && !this.dropOnly) {
                console.warn('Draggable component will not be draggable unless you specify a :value="" property.');
            }
            if (this.dropOnly && !this.hasDropHandler) {
                throw new Error('Draggable component requires a drop function to be specified as a @drop="" listener.');
            }
        },
        computed: {

            /**
             * @return {boolean}
             */
            canDrag() {
                return !this.disabled && this.hasValue && !this.dropOnly;
            },

            /**
             * @return {boolean}
             */
            canDrop() {
                return !this.disabled && this.hasDropHandler;
            },

            /**
             * @return {boolean}
             */
            hasDropHandler() {
                return this.$attrs.onDrop instanceof Function || this.$attrs.onDrop instanceof Array;
            },

            /**
             * @return {boolean}
             */
            hasValue() {
                return this.value !== null;
            },

            /**
             * Get CSS classes
             *
             * @return {String}
             */
            cssClasses() {
                const classes = [];
                if (this.disabled) {
                    classes.push('disabled');
                }
                if (this.canDrag) {
                    classes.push('draggable');
                }
                if (!this.disabled && this.hasDropHandler) {
                    classes.push('droptarget');
                }
                return classes.join(' ');
            }
        },
        methods: {

            /**
             * Inject drag'n'drop data into an event
             *
             * @param {MouseEvent} e
             */
            injectDataIntoEvent(e) {
                // Cloning mode by holding Shift key
                if (e.type === 'dragstart') {
                    Draggable.cloningMode = e.shiftKey;
                } else if (e.type === 'dragend') {
                    Draggable.cloningMode = false;
                }

                e.dataDraggable = Draggable;
                e.dataDropTarget = {
                    component: this,
                    element: e.currentTarget,
                    elementId: this.uid,
                    groups: this.groups,
                    value: this.value
                };

                return this;
            },

            /**
             * Drag start handler
             *
             * @param {MouseEvent} e
             */
            onDragStart(e) {

                // Disable bubbling since we only want to drag the child element if draggables are nested
                e.stopImmediatePropagation();

                // Cancel dragging if the handle element doesn't match
                if (this.draggableSelector !== null && !e.target.matches(this.draggableSelector)) {
                    e.preventDefault();
                    return this;
                }

                // Set event data
                e.dataTransfer.setData('text/plain', JSON.stringify(this.groups));
                Draggable.component = this;
                Draggable.element = e.currentTarget;
                Draggable.elementId = this.uid;
                Draggable.groups = this.groups;
                Draggable.value = this.value;
                e.currentTarget.classList.add('dragging');
                window.document.body.classList.add('dragging');
                window.document.body.getClientRects();  // Force reflow

                // Inject drag'n'drop data into the event
                this.injectDataIntoEvent(e);
                //console.log('Draggable->onDragStart()', e, this.value);

                // Callbacks
                if (this.callbacks.onDragstart instanceof Function) {
                    this.callbacks.onDragstart(e);
                } else if (this.callbacks.onDragstart instanceof Array) {
                    this.callbacks.onDragstart.forEach(c => c(e));
                }

                return this;
            },

            /**
             * Drag end handler
             *
             * @param {MouseEvent} e
             */
            onDragEnd(e) {
                e.preventDefault();
                //e.stopImmediatePropagation(); // @NOTE: Disabled to allow bubbling to parent elements
                e.currentTarget.classList.remove('dragging', 'can-drop', 'drop-before', 'drop-after', 'drag-over');
                window.document.body.classList.remove('dragging');

                // Inject drag'n'drop data into the event
                this.injectDataIntoEvent(e);
                //console.log('Draggable->onDragEnd()', e);

                // Callbacks
                if (this.callbacks.onDragend instanceof Function) {
                    this.callbacks.onDragend(e);
                } else if (this.callbacks.onDragend instanceof Array) {
                    this.callbacks.onDragend.forEach(c => c(e));
                }

                // Reset the data:
                Draggable.component = null;
                Draggable.element = null;
                Draggable.elementId = null;
                Draggable.groups = null;
                Draggable.value = null;

                return this;
            },

            /**
             * Drop handler
             *
             * @param {MouseEvent} e
             */
            onDrop(e) {
                e.preventDefault();
                //e.stopImmediatePropagation();     // @NOTE: Disabled to allow bubbling to parent elements
                e.currentTarget.classList.remove('can-drop', 'drop-before', 'drop-after', 'drag-over');
                window.document.body.classList.remove('dragging');

                // Prevent dragging onto same element or elements not from the same groups
                if (this.uid === Draggable.elementId || !Draggable.hasGroups(this.groups)) {
                    return this;
                }

                // Inject drag'n'drop data into the event
                this.injectDataIntoEvent(e);
                //console.log('Draggable->onDrop()', e);
                //console.log(e.dataTransfer.getData('text/plain'));

                // Callbacks
                if (this.callbacks.onDrop instanceof Function) {
                    this.callbacks.onDrop(e);
                } else if (this.callbacks.onDrop instanceof Array) {
                    this.callbacks.onDrop.forEach(c => c(e));
                }

                return this;
            },

            /**
             * Drag over handler
             *
             * @param {MouseEvent} e
             */
            onDragOver(e) {
                // Prevent dragging onto same element or elements not from the same groups
                if (this.uid === Draggable.elementId || !Draggable.hasGroups(this.groups)) {
                    return this;
                }

                e.preventDefault(); // @NOTE: Drop event will not be fired without this
                //e.stopImmediatePropagation(); // @NOTE: Disabled to allow bubbling to parent elements

                // Inject drag'n'drop data into the event
                this.injectDataIntoEvent(e);
                //console.log('Draggable->onDragOver()', e);

                // Callbacks
                if (this.callbacks.onDragover instanceof Function) {
                    this.callbacks.onDragover(e);
                } else if (this.callbacks.onDragover instanceof Array) {
                    this.callbacks.onDragover.forEach(c => c(e));
                }

                return this;
            },

            /**
             * Drag enter handler
             *
             * @param {MouseEvent} e
             */
            onDragEnter(e) {
                // Prevent dragging onto same element or elements not from the same groups
                if (this.uid === Draggable.elementId || !Draggable.hasGroups(this.groups)) {
                    return this;
                }

                // Show the drop target:
                e.preventDefault(); // @NOTE: Drop event will not be fired without this
                //e.stopImmediatePropagation(); // @NOTE: Disabled to allow bubbling to parent elements
                e.currentTarget.classList.add(
                    'can-drop',
                    // @see https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
                    Draggable.element.compareDocumentPosition(e.currentTarget) & Node.DOCUMENT_POSITION_FOLLOWING ? 'drop-after' : 'drop-before'
                );

                // Inject drag'n'drop data into the event
                this.injectDataIntoEvent(e);
                //console.log('Draggable->onDragEnter()', e);

                // Callbacks
                if (this.callbacks.onDragenter instanceof Function) {
                    this.callbacks.onDragenter(e);
                } else if (this.callbacks.onDragenter instanceof Array) {
                    this.callbacks.onDragenter.forEach(c => c(e));
                }

                return this;
            },

            /**
             * Drag leave handler
             *
             * @param {MouseEvent} e
             */
            onDragLeave(e) {
                // Hide the drop target:
                e.currentTarget.classList.remove('can-drop', 'drop-before', 'drop-after', 'drag-over');

                // Prevent dragging onto same element or elements not from the same groups
                if (this.uid === Draggable.elementId || !Draggable.hasGroups(this.groups)) {
                    return this;
                }

                //e.stopImmediatePropagation();  // @NOTE: Disabled to allow bubbling to parent elements

                // Inject drag'n'drop data into the event
                this.injectDataIntoEvent(e);
                //console.log('Draggable->onDragLeave()', e);

                // Callbacks
                if (this.callbacks.onDragleave instanceof Function) {
                    this.callbacks.onDragleave(e);
                } else if (this.callbacks.onDragleave instanceof Array) {
                    this.callbacks.onDragleave.forEach(c => c(e));
                }

                return this;
            },

            /**
             * Double click handler for FireFox
             *
             * @param {MouseEvent} e
             */
            onDoubleClick(e) {
                // Fix for FireFox:
                // @TODO: Remove this once the bugs are fixed in FireFox
                // @see: https://bugzilla.mozilla.org/show_bug.cgi?id=800050
                // @see: https://bugzilla.mozilla.org/show_bug.cgi?id=1189486
                if (e.target.tagName.toLowerCase() === 'input' && ['text', 'url', 'email', 'number', 'search', 'tel'].indexOf(e.target.getAttribute('type').toLowerCase()) >= 0) {
                    // Select text input on double click:
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    e.target.select();
                }
                return this;
            }
        }
    }
</script>

<style lang="scss">
    // Fix additional dragenter and dragleave events being fired on child elements while dragging
    body.dragging .droptarget:not(.dragging) *:not(.dragging, .droptarget, .children) {
        pointer-events: none !important;
    }
    body.dragging .droptarget,
    body.dragging .droptarget.dragging *:not(.dragging, .droptarget, .children) {
        pointer-events: auto !important;
    }
</style>
