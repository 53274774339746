import type Course from "@/Models/Course/Course";
import type PagingMetadata from "@/Models/PagingMetadata";

export default class CoursePage {

    constructor(
        public readonly courseList: Course[],
        public readonly pagingMetadata: PagingMetadata
    ) {
    }

    replaceCourse(course: Course): void {
        const index = this.courseList.findIndex(courseInPage => courseInPage.uid === course.uid);

        if (index >= 0) {
            this.courseList[index] = course;
        }
    }
}
