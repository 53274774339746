import {AbstractModel3DAsset} from '@/Models/Asset/Assets/AbstractModel3DAsset';
import {CharacterGender} from '@/Models/Asset/CharacterGender';

export class CharacterModel3DAsset extends AbstractModel3DAsset {

    public readonly character_gender: CharacterGender | null;

    constructor(attributes: any) {
        super(attributes);

        this.character_gender = attributes.character_gender || null;
    }

    get isMale(): boolean {
        return this.character_gender === CharacterGender.Masculine;
    }
}
