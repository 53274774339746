import { uuid4 } from '@/Utility/Helpers';
import Position from '@/Models/Unity/Position';

export default class ConnectionPoint
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.name = attributes.name || 'ConnectionPoint_' + uuid4();        // Name
        this.position = new Position(attributes.position);                  // Position
        this.startendpoint = (typeof attributes.startendpoint === 'boolean') ? attributes.startendpoint : true;  // Whether the connection point is a start and end point
    }

    /**
     * Duplicate
     *
     * @returns {ConnectionPoint}
     */
    duplicate()
    {
        const duplicated = new ConnectionPoint(this);
        duplicated.position = new Position(this.position);
        return duplicated;
    }
}
