import { sortArrayByDate, trans } from '@/Utility/Helpers';
import FilterCategory from '@/Filters/FilterCategory';

/**
 * Filter definitions
 */
const FilterDefinitions = {

    All: {
        title: trans('labels.all'),
        callback(elements, options = null) {
            return elements;
        },
        paramName: 'all',
    },

    Draft: {
        title: trans('labels.draft'),
        callback(elements, options = null) {
            return elements.filter(e => e.isDraft);
        },
        paramName: 'draft',
    },

    Released: {
        title: trans('labels.released'),
        callback(elements, options = null) {
            return elements.filter(e => e.isReleased);
        },
        paramName: 'released',
    },

    Updated: {
        title: trans('labels.updated'),
        callback(elements, options = null) {
            return sortArrayByDate(elements, 'updated_at', true);
        },
        paramName: 'updated_at',
    }
};

export default class UnitRevisionFilters
{
    static get All()                { return new FilterCategory(FilterDefinitions.All); }

    static get Draft()              { return new FilterCategory(FilterDefinitions.Draft); }

    static get Released()           { return new FilterCategory(FilterDefinitions.Released); }

    static get Updated()            { return new FilterCategory(FilterDefinitions.Updated); }

    constructor()
    {
        if (this.constructor === UnitRevisionFilters)
        {
            throw new TypeError('Static class "UnitRevisionFilters" cannot be instantiated directly.');
        }
    }
}
