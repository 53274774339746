import { trans } from '@/Utility/Helpers';

export default class ExecutionRepeatType
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || ExecutionRepeatType.isValidType(attributes.type) === false)
        {
            console.warn('ExecutionRepeatType->constructor(): Invalid data.', attributes);
            throw new TypeError('ExecutionRepeatType->constructor(): Property "type" has to be set on ExecutionRepeatType. Must be a valid type from ExecutionRepeatType class.');
        }

        // Populate the model:
        this.type = attributes.type;                                        // Type identifier
        this.title = attributes.title;                                      // Translated title
        this.description = attributes.description || null;                  // Translated description
    }

    /**
     * ExecutionRepeatType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {ExecutionRepeatType}
     */
    static get Last()                   { return StaticExecutionRepeatTypes.Last; }
    static get Loop()                   { return StaticExecutionRepeatTypes.Loop; }
    static get None()                   { return StaticExecutionRepeatTypes.None; }

    /**
     * Get all execution types as an array
     *
     * @returns {ExecutionRepeatType[]}
     */
    static get all()
    {
        return Object.values(StaticExecutionRepeatTypes);
    }

    /**
     * Get ExecutionRepeatType by a given type name
     *
     * @param {String} type
     * @returns {ExecutionRepeatType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in ExecutionRepeatTypes)
        {
            if (ExecutionRepeatTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }
}

/**
 * Type definitions
 */
const StaticExecutionRepeatTypes = {};
const ExecutionRepeatTypes = {

    Last: {
        type: 'last',
        title: trans('executiontypes.repeat.last'),
        description: trans('executiontypes.repeat.last_description'),
    },

    Loop: {
        type: 'loop',
        title: trans('executiontypes.repeat.loop'),
        description: trans('executiontypes.repeat.loop_description'),
    },

    None: {
        type: 'none',
        title: trans('executiontypes.repeat.none'),
        description: trans('executiontypes.repeat.none_description'),
    },
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in ExecutionRepeatTypes)
{
     StaticExecutionRepeatTypes[i] = new ExecutionRepeatType(ExecutionRepeatTypes[i]);
}
