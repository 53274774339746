import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import {route, trans} from '@/Utility/Helpers';
import Instruction from '@/Models/Sessions/Instruction';
import type InstructionPayload from '@/Models/Sessions/InstructionPayload';
import Device from '@/Models/Devices/Device';
import type {AxiosRequestConfig} from 'axios';
import axios from 'axios';
import ManagedSession from '@/Models/Sessions/ManagedSession';
import PagingPage from '@/Models/PagingPage';
import PagingMetadata from '@/Models/PagingMetadata';

export type UpdateSessionParameters = {
    title?: string,
    description?: string | null,
    unit_uid?: string,
}

export default class SessionService {

    public isLoading: boolean = false;
    public isSaving: boolean = false;

    private abortController = new AbortController();

    /**
     * Cancel any ongoing requests
     */
    async cancelRequests(): Promise<any> {
        this.abortController.abort();
        this.abortController = new AbortController();

        return Promise.resolve('Requests canceled');
    }

    async fetchSessions(page: number = 1): Promise<PagingPage<ManagedSession>> {
        if (this.isLoading) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;

        const params = {
            page: page
        };

        return axios
            .get(
                route('api.sessions.index'),
                {
                    params: params,
                    signal: this.abortController.signal,
                } as AxiosRequestConfig
            )
            .then(({ data }) => {
                const pagingMetadata = new PagingMetadata(data.meta);
                const sessions = data.data
                    .map((sessionData: any) => {
                        try {
                            return this.parseSession(sessionData);
                        } catch (_ex) {
                            return null;
                        }
                    })
                    .filter((session: ManagedSession | null) => session !== null) as ManagedSession[];
                return new PagingPage(sessions, pagingMetadata);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async createSession(title: string, description: string | null = null): Promise<ManagedSession> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        const params = {
            title: title,
            description: description,
        };

        return axios
            .post(
                route('api.sessions.create'),
                params,
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return this.parseSession(data.data);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async updateSession(sessionUid: string, updateParameters: UpdateSessionParameters): Promise<ManagedSession> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .patch(
                route('api.sessions.update', { managed_session: sessionUid }),
                updateParameters,
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return this.parseSession(data.data);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async deleteSession(sessionUid: string): Promise<any> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .delete(
                route('api.sessions.delete', { managed_session: sessionUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then()
            .finally(() => {
                this.isSaving = false;
            });
    }

    async fetchSessionDevices(sessionUid: string): Promise<Device[]> {
        return axios
            .get(
                route('api.sessions.devices.index', { managed_session: sessionUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data.data
                    .map((deviceData: any) => {
                        try {
                            return this.parseDevice(deviceData);
                        } catch (_ex) {
                            return null;
                        }
                    })
                    .filter((device: Device | null) => device !== null) as Device[];
            });
    }

    async updateSessionDevices(
        sessionUid: string,
        devicesToRemove: string[] = [],
        devicesToAdd: string[] = []
    ): Promise<Device[]> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .post(
                route('api.sessions.devices.update', { managed_session: sessionUid }),
                {
                    'devices_to_remove': devicesToRemove,
                    'devices_to_add': devicesToAdd,
                },
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data.data
                    .map((deviceData: any) => {
                        try {
                            return this.parseDevice(deviceData);
                        } catch (_ex) {
                            return null;
                        }
                    })
                    .filter((device: Device | null) => device !== null) as Device[];
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async fetchSessionInstructions(sessionUid: string): Promise<Instruction[]> {
        if (this.isLoading) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;

        return axios
            .get(
                route('api.sessions.instructions.index', { managed_session: sessionUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data.data
                    .map((instructionData: any) => {
                        try {
                            return this.parseInstruction(instructionData);
                        } catch (_ex) {
                            return null;
                        }
                    })
                    .filter((instruction: Instruction | null) => instruction !== null);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async createSessionInstructions(
        sessionUid: string,
        payload: InstructionPayload,
        deviceUid: string | undefined = undefined,
    ): Promise<Instruction> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;

        const data: any = { payload: payload };
        if (deviceUid) {
            data.device_uids = [deviceUid];
        }

        return axios
            .post(
                route('api.sessions.instructions.create', { managed_session: sessionUid }),
                data,
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data.data
                    .map((instructionData: any) => {
                        try {
                            return this.parseInstruction(instructionData);
                        } catch (_ex) {
                            return null;
                        }
                    })
                    .filter((instruction: Instruction | null) => instruction !== null);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    /**
     * Tries to parse the given instruction data into a valid instruction.
     * Will print and throw usable errors when this fails.
     */
    private parseInstruction(instructionData: any): Instruction {
        try {
            return new Instruction(instructionData);
        } catch (ex) {
            console.error(
                'InstructionService->parseInstruction(): API returned invalid or incompatible instruction data.',
                instructionData,
                ex
            );
            throw new Error(trans('errors.instruction.invalid_data'));
        }
    }

    /**
     * Tries to parse the given session data into a valid managed session.
     * Will print and throw usable errors when this fails.
     */
    private parseSession(sessionData: any): ManagedSession {
        try {
            return new ManagedSession(sessionData);
        } catch (ex) {
            console.error(
                'SessionService->parseSession(): API returned invalid or incompatible device data.',
                sessionData,
                ex
            );
            throw new Error(trans('errors.managed_session.invalid_data'));
        }
    }

    /**
     * Tries to parse the given device data into a valid device.
     * Will print and throw usable errors when this fails.
     */
    private parseDevice(deviceData: any): Device {
        try {
            return new Device(deviceData);
        } catch (ex) {
            console.error(
                'SessionService->parseDevice(): API returned invalid or incompatible device data.',
                deviceData,
                ex
            );
            throw new Error(trans('errors.device.invalid_data'));
        }
    }
}
