<template>
    <span class="confirm-remove" v-focusable v-shortcuts.stop @blur="onBlur" @click.stop>
        <span class="confirm-dialog">
            <span class="confirm-question">{{ trans('labels.remove_question') }}</span>
            <Icon name="icon_close" class="icon-cancel confirm-no round" @click.stop="onClickCancel" />
            <Icon name="icon_check" class="icon-confirm confirm-yes round" @click.stop="onClickConfirm" />
        </span>
    </span>
</template>

<script>
    export default {
        name: 'OverlayConfirmRemove',
        emits: [
            'cancel',
            'confirm',
        ],
        data() {
            return {
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([
                    ['Enter', this.onClickConfirm],
                    ['Escape', this.onClickCancel],
                ])
            }
        },
        mounted() {
            this.previousFocusElement = document.activeElement;
            this.$el.focus();
        },
        methods: {

            /**
             * Blur handler
             * 
             * @param {FocusEvent} e
             */
            onBlur(e) {
                if (e.relatedTarget !== this.$el && this.$el.contains(e.relatedTarget) === false)
                {
                    this.onClickCancel();
                }
                return this;
            },

            /**
             * Click handler for cancel button
             */
            onClickCancel() {
                this.$emit('cancel');
                if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                {
                    this.previousFocusElement.focus();
                }
                this.previousFocusElement = null;
                return this;
            },

            /**
             * Click handler for confirm button
             */
            onClickConfirm() {
                this.$emit('confirm');
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
