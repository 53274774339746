<template>
    <li class="tenant-list-item">
        <a
            href="#"
            @click="onTenantSelected"
            @keyup.enter="onTenantSelected"
        >
            <img
                :class="{ empty: !tenant.logo_thumbnail }"
                :src="thumbnail"
                alt=""
                class="logo"
                loading="lazy"
            >

            <div class="tenant-info">
                <h3 class="tenant-info-name">
                    <Icon v-if="tenant.isAnyDefaultTenant" name="icon_admin" />
                    {{ tenant.name }}
                    <span v-if="isCurrentTenant">({{ trans('labels.current_tenant') }})</span>
                </h3>
                <span v-if="shouldShowTenantUid" class="tenant-info-uid">
                    {{ tenant.uid }}
                </span>
            </div>
        </a>
    </li>
</template>

<script lang="ts">
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import {trans} from '@/Utility/Helpers/trans';
import type Tenant from '@/Models/Tenant/Tenant';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({
    components: {
        Icon
    },

    props: {
        tenant: {
            type: Object as PropType<Tenant>,
            required: true,
        },
        shouldShowTenantUid: {
            type: Boolean,
            default: false,
        }
    },

    emits: {
        'select': (_: Tenant) => true,
    },

    data() {
        const currentTenant = window.currentUser!.tenant!;

        return {
            currentTenant: currentTenant,
        };
    },

    computed: {
        isCurrentTenant() {
            return this.tenant.uid === this.currentTenant.uid;
        },

        thumbnail() {
            return this.tenant.logo_thumbnail ??
                'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"/>';
        }
    },

    methods: {
        trans,

        onTenantSelected() {
            this.$emit('select', this.tenant);
        },
    },
});
</script>

<style lang="scss" scoped>

.tenant-list-item {

    a {
        padding: 8px 32px;
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid var(--color-anthracite20);
        color: var(--font-color-dark) !important;

        &:hover,
        &:focus-visible,
        &:active {
            background-color: var(--background-color-light);

            .tenant-info-name {
                color: var(--color-primary);
            }
        }

        .logo {
            width: 100%;
            max-width: 75px;
            max-height: 50px;
            object-fit: contain;
            flex-shrink: 0;
            border-radius: 5px;
            background-color: var(--background-color-white);

            &.empty {
                aspect-ratio: 16 / 9;
                border: 1px solid var(--color-anthracite20);
            }
        }

        .tenant-info-name {
            flex-grow: 3;
            margin: 0;
            font-size: var(--font-size-default);
            line-height: var(--line-height-default);

            .icon {
                height: 16px;
                width: 16px;
                transform: translate(0, -2px);
            }
        }

        .tenant-info-uid {
            color: var(--color-anthracite80);
            font-family: var(--font-family-mono-regular);
            font-size: var(--font-size-small);
        }
    }
}

</style>
