<template>
    <div class="property property-unit-links">
        <header>Public Link</header>
        <div
            v-if="unitLinks.length !== 0 && !showLoadingIndicator"
            class="unit-link-item"
            v-for="unitLink in unitLinks"
        >
            <Icon :name="'icon_public-link'" />
            <TextInput
                class="no-wrap"
                :model="unitLink"
                property="url"
                :read-only="true"
            />
            <ButtonCircular
                v-tooltip="'labels.copy'"
                class="small"
                icon="icon_copy"
                @trigger="onUnitLinkCopy(unitLink, $event)"
            />
            <ButtonCircular
                v-tooltip="'labels.remove'"
                class="small"
                icon="icon_delete"
                @trigger="onUnitLinkRemove(unitLink, $event)"
                :disabled="!canDeleteUnitLink(unitLink)"
            />
            <!-- Delete confirm overlay -->
            <OverlayConfirmRemove v-if="isRemoveConfirmDialogVisible" @confirm="onConfirmUnitLinkRemove(unitLink)" @cancel="onCancelUnitLinkRemove(unitLink)" />
        </div>
        <div
            v-if="showLoadingIndicator"
            class="unit-link-item-loading-indicator"
        >
            <LoadingIndicator class="small"/>
        </div>
        <div
            v-else-if="canCreateUnitLink"
             class="unit-link-buttons"
        >
            <ButtonSecondary
                caption="labels.create"
                icon="icon_add"
                @trigger="createTenantUnitLink"
                target="_blank"
            />
        </div>

    </div>
</template>

<script lang="ts">

import {defineComponent, inject} from "vue";
import {trans} from "@/Utility/Helpers/trans";
import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";
import Icon from "@/Vue/Common/Icon.vue";
import TextInput from "@/Vue/Common/TextInput.vue";
import ButtonSecondary from "@/Vue/Common/ButtonSecondary.vue";
import UnitLink from "@/Models/Unit/UnitLink";
import {unitLinkServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import UnitLinkService from "@/Services/UnitLinkService";
import Unit from "@/Models/Unit/Unit";
import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";
import {Permission} from "@/Models/User/Permission";
import OverlayConfirmRemove from "@/Vue/Inspector/OverlayConfirmRemove.vue";
import Clipboard from "@/Utility/Clipboard";

export default defineComponent({
    name: "PanelUnitLinks",

    components: {
        OverlayConfirmRemove,
        LoadingIndicator,
        ButtonSecondary,
        TextInput,
        Icon,
        ButtonCircular,
    },

    props: {
        unit: {
            type: Unit,
            required: true,
        },
    },

    data() {
        return {
            unitLinkService: inject(unitLinkServiceKey) as UnitLinkService,
            isRemoveConfirmDialogVisible: false,
        }
    },

    computed: {
        unitLinks() {
            return this.unit.unit_links ?? [];
        },
        showLoadingIndicator() {
            return this.unitLinkService.isLoading || this.unitLinkService.isSaving || this.unitLinkService.isDeleting;
        },
        canCreateUnitLink() {
            return (
                this.unitLinks.length === 0
                && this.$gate.allows(Permission.ability(Permission.UnitLinksCreate()), UnitLink.constructorName, this.unit)
            );
        },
    },

    methods: {
        trans,

        createTenantUnitLink() {
            let unitLinkData = {
                tenant_uid: window.currentUser.tenant.uid,
                unit_uid: this.unit.uid,
            };

            this.unitLinkService
                .createUnitLink(
                    unitLinkData
                )
                .then(unitLink => {
                    this.unit.unit_links.push(unitLink);
                })
                .catch(this.onErrorApi);
        },

        onUnitLinkRemove(unitLink: UnitLink, e: Event) {
            e.target?.blur();
            this.isRemoveConfirmDialogVisible = true;
        },

        onConfirmUnitLinkRemove(unitLink: UnitLink) {
            this.isRemoveConfirmDialogVisible = false;
            this.unitLinkService
                ?.deleteUnitLink(unitLink)
                .then(unitLink => {
                    this.removeUnitLink(unitLink);
                })
                .catch(this.onErrorApi);
        },

        onCancelUnitLinkRemove(unitLink: UnitLink) {
            this.isRemoveConfirmDialogVisible = false;
        },

        /**
         * Remove a specific unit from the list
         */
        removeUnitLink(unitLink: UnitLink) {
            const index = this.unit.unit_links.findIndex(t => t.uid === unitLink.uid);
            // Remove the unit from the cached list
            if (index >= 0) {
                this.unit.unit_links.splice(index, 1);
            }

            return this;
        },

        canDeleteUnitLink(unitLink: UnitLink) {
            return this.$gate.allows(Permission.ability(Permission.UnitLinksDelete()), unitLink);
        },

        onUnitLinkCopy(unitLink: UnitLink, e: Event) {
            e.target?.blur();

            if (unitLink.url === null) {
                return;
            }

            Clipboard.setClipboardStringAsync(unitLink.url);
        },

        /**
         * Error handler for API errors
         *
         * @param {String} error
         */
        onErrorApi(error: string) {
            this.$root.showErrorDialog(error);
            return this;
        },
    },

    watch: {
        unit: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (oldValue !== undefined && newValue.uid !== oldValue.uid) {
                    this.isRemoveConfirmDialogVisible = false;
                }
            }
        },
    },
});
</script>

<style scoped lang="scss">

    #layout-inspector {
        .property-unit-links {
            padding-bottom: 10px;
            margin-bottom: 6px;
        }
    }

    .unit-link-buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 15px;

        .btn {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
        }
    }

    .unit-link-item {
        display: flex;
        align-items: center;
        height: 35px;

        & + .unit-link-item {
            margin-top: 5px;
        }

        & > .icon {
            width: 24px;
            height: 24px;
            flex-basis: 24px;
            margin-right: 5px;
        }

        .textinput {
            flex-grow: 1;
            margin-bottom: 0;
            margin-right: 5px;
        }
    }

    .unit-link-item-loading-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
    }
</style>
