<template>
    <StatisticsCard class="statistics-card-started-completed-bars" :is-loading="isLoading">
        <StatisticsCardHeader
            :title="trans('learning_records.statistics.labels.unit_performance')"
            :subtitle="trans('learning_records.statistics.labels.unit_performance_subtitle')"
        />

        <figure class="chart-wrapper" ref="chartWrapper">
            <svg ref="chart" class="chart">
                <g class="root">
                    <g class="x-scale"/>
                    <g class="y-scale"/>
                    <g class="bars"/>
                </g>
            </svg>

            <figcaption>
                <ul class="legend">
                    <li>
                        <span class="color-indicator" :style="'background-color: ' + this.colors[0] + ';'"></span>
                        <span class="label">{{ trans('learning_records.statistics.labels.view_count') }}</span>
                    </li>
                    <li>
                        <span class="color-indicator" :style="'background-color: ' + this.colors[1] + ';'"></span>
                        <span class="label">{{ trans('learning_records.statistics.labels.completion_count') }}</span>
                    </li>
                    <li v-if="this.completed.count === 0" class="legend-completed-action-hint">
                        <span class="color-indicator" style="background-color: transparent;"></span>
                        <span class="info" v-html="trans('learning_records.statistics.labels.completed_action_hint')"></span>
                    </li>
                </ul>
            </figcaption>
        </figure>
    </StatisticsCard>
</template>

<script lang="ts">

    import {defineComponent, PropType, ref, toRaw} from "vue";
    import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
    import {trans} from "@/Utility/Helpers";
    import {select} from "d3-selection";
    import {axisBottom, axisLeft, max, scaleBand, scaleLinear, scaleOrdinal} from "d3";
    import StatisticsCardHeader from "@/Vue/Statistics/General/Cards/StatisticsCardHeader.vue";
    import {useResize} from "@/Vue/Utility/useResize";
    import {UnitCount} from "@/Models/Statistics/LearningRecordsStatistics";

    export default defineComponent({
        name: "LearningRecordsCardStartedCompletedBars",

        components: {
            StatisticsCardHeader,
            StatisticsCard
        },

        props: {
            started: {
                type: Object as PropType<UnitCount>,
                required: true,
            },
            completed: {
                type: Object as PropType<UnitCount>,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
            chartHeight: {
                type: Number,
                default: 200,
            }
        },

        setup() {
            const chartWrapper = ref<HTMLElement>();
            const {width: chartWidth} = useResize(chartWrapper);

            return {
                chartWidth,
                chartWrapper,
            };
        },

        computed: {

            assetCount() {
                return this.assets.reduce((prev, data) => prev + data.count, 0);
            },

            assets() {

                let started = {
                    count: this.started.count,
                    title: 'Started',
                    uid: this.started.uid,
                } as UnitCount;

                let completed = {
                    count: this.completed.count,
                    title: 'Completed',
                    uid: this.completed.uid,
                } as UnitCount;

                return [started, completed];
            },

            colors() {
                return ['#0098A5', '#8D9414'];
            },

        },

        mounted() {
            this.redraw();
        },

        methods: {
            trans,

            redraw() {
                const chartElement = this.$refs.chart as HTMLElement;

                if (!chartElement) {
                    return;
                }

                const width = this.chartWidth;
                const height = this.chartHeight;
                const padding = 8;
                const yAxisWidth = 30;
                const xAxisHeight = 30;
                const chartWidth = width - yAxisWidth - 2 * padding;
                const chartHeight = height - xAxisHeight - 2 * padding;
                const barCornerRadius = 8;
                const barPaddingPercent = 0.15;

                const colorTransform = scaleOrdinal<UnitCount, string>()
                    .domain(this.assets)
                    .range(this.colors)

                const xScale = scaleBand()
                    .range([0, chartWidth])
                    .domain(this.assets.map(asset => asset.title));

                const maxAssetCount = max(this.assets, asset => asset.count) || 0;
                const yScale = scaleLinear()
                    .range([0, chartHeight])
                    .domain([maxAssetCount, 0]);

                const chart = select(chartElement)
                    .attr('width', width)
                    .attr('height', height);
                chart.select('.root .x-scale').selectAll('*').remove();
                chart.select('.root .y-scale').selectAll('*').remove();
                chart.select('.root .bars').selectAll('*').remove();

                const group = chart
                    .select('.root')
                    .attr('transform', `translate(${yAxisWidth + padding}, ${padding})`);

                const xAxis = axisBottom(xScale)
                    .tickSize(-chartHeight);
                group
                    .select<SVGGElement>('.x-scale')
                    .attr('transform', `translate(0, ${chartHeight})`)
                    .call(xAxis);

                const yAxis = axisLeft(yScale)
                    .ticks(Math.min(5, maxAssetCount), 'd')
                    .tickSize(-chartWidth);
                group
                    .select<SVGGElement>('.y-scale')
                    .call(yAxis);

                let finalBarWidth = xScale.bandwidth() * (1 - (barPaddingPercent * 2));
                const getBarX = (asset: UnitCount) => (xScale(asset.title) || 0) + (xScale.bandwidth() * barPaddingPercent);

                group.select('.bars')
                    .selectAll('.bar')
                    .data(toRaw(this.assets))
                    .join(
                        enter => enter
                            .append('rect')
                            .attr('fill', d => colorTransform(d))
                            .attr('class', 'bar')
                            .attr('x', getBarX)
                            .attr('width', finalBarWidth)
                            .attr('y', asset => yScale(asset.count))
                            .attr('height', asset => chartHeight - yScale(asset.count)),

                        update => update
                            .attr('x', getBarX)
                            .attr('width', finalBarWidth)
                    );
            },
        },

        watch: {
            chartWidth() {
                this.redraw();
            },
            assets() {
                // delay to make sure the dom is ready
                this.$nextTick(() => {
                    this.redraw();
                });
            },
        },
    });

</script>

<style lang="scss">

    .statistics-card-started-completed-bars {
        overflow: hidden; // Fix resize issue when changing media breakpoints

        .chart-wrapper {
            padding: 24px 30px 16px 20px;

            figcaption {
                font-size: var(--font-size-small);
                font-family: var(--font-family-condensed);
                white-space: nowrap;

                .legend {
                    list-style-type: none;

                    li {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: start;
                        gap: 16px;
                        padding-left: 20px;

                        .label {
                            flex-grow: 1;
                        }

                        .info {
                            flex-basis: 100%;
                            flex-shrink: 0;
                            flex-grow: 1;
                            white-space: break-spaces;
                        }

                        .color-indicator {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            flex-basis: 8px;
                            flex-grow: 0;
                            flex-shrink: 0;
                        }

                        &.legend-completed-action-hint {
                            .info {
                                font-size: 10px;
                            }
                        }
                    }


                }
            }
        }

        .chart {

            text {
                font-size: 10px;
                font-family: var(--font-family-condensed);
                color: #4F4F4F;
            }

            .domain,
            .tick line {
                stroke: #ECECEC;
            }

            .x-scale text {
                transform: translateY(4px);
            }

            .x-scale .tick line {
                visibility: hidden;
            }

            .y-scale text {
                transform: translateX(-8px);
            }
        }
    }

</style>
