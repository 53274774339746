<template>
    <span :class="cssClasses" v-shortcuts>

        <!-- Label -->
        <label v-if="label">{{ label }}</label>

        <!-- Buttons -->
        <span
            v-for="(buttonConfig, index) in buttons"
            :key="'radiobtn'+id+'_'+index"
            :class="cssClassesBtn(buttonConfig)"
            @click="onClick(buttonConfig, $event)"
            v-focusable-if="!buttonConfig.disabled"
            :data-configindex="index"
            :title="buttonConfig.helpText"
        >
            <span class="radiobtn-icon"></span>
            <span class="caption">{{ buttonConfig.caption }}</span>
        </span>

        <!-- Error messages -->
        <span v-if="errorMsg" v-html="errorMsg" class="error-msg"></span>

    </span>
</template>

<script>
    // Import classes:
    import { shortId }              from '@/Utility/Helpers';

    export default {
        name: 'RadioButtons',
        emits: [
            'change',
        ],
        props: {
            buttons: {              // List of RadioButtonConfigs
                type: Array,
                default() {
                    return [];
                }
            },
            initialValue: {         // Initial value (either use this or model+property!)
                type: String,
                default: ''
            },
            model: {                // Associated model reference
                type: Object,
                default: null
            },
            property: {             // Property name from the associated model that should be modified
                type: String,
                default: null
            },
            disabled: {             // Disabled state
                type: Boolean,
                default: false
            },
            required: {             // Required state
                type: Boolean,
                default: false
            },
            label: {                // Optional label text
                type: String,
                default: null
            },
            errorMsg: {             // Error message text
                type: String,
                default: null
            },
            // @TODO validation rules?
        },
        data() {
            return {
                id: null,                                   // Unique ID for each group
                selectedValue: null,                        // The selected value
                errors: {},                                 // Validation errors
                shortcuts: new Map([
                    ['Space.prevent.stop', this.onShortcut]
                ])
            }
        },
        computed: {

            /**
             * CSS classes for the checkbox
             *
             * @returns {String}
             */
            cssClasses() {
                const classes = ['radiobutton-group'];

                // Enabled/disabled state:
                classes.push((this.disabled === true) ? 'disabled' : 'enabled');

                // Required state:
                if (this.required === true)
                {
                    classes.push('required');
                }

                // Empty state:
                if (this.selectedValue === null)
                {
                    classes.push('is-empty');
                }

                // Error state:
                if (this.hasErrors === true)
                {
                    classes.push('error');
                }

                return classes.join(' ');
            },

            /**
             * Validation
             *
             * @returns {Boolean}
             */
            hasErrors() {
                // Required?
                if (this.required === true && (this.selectedValue === null || this.buttons.map(b => b.value).indexOf(this.selectedValue) === -1))
                {
                    this.errors.required = true;
                }
                else
                {
                    delete this.errors.required;
                }
                return (Object.keys(this.errors).length > 0);
            }
        },
        mounted() {
            // Check properties
            if (this.model !== null && this.property === null) {
                console.warn('RadioButtons->mounted(): Property :model="" is set but no property="" name is given.', this);
            }
            if (this.model !== null && this.initialValue !== '') {
                console.warn('RadioButtons->mounted(): Both :model="" and :initial-value="" are set. You should use just one of them.', this);
            }

            // Create a unique ID for each group:
            this.id = shortId('radiobtngroup');

            // Set initial value:
            this.resetValue();
        },
        methods: {

            /**
             * Get CSS classes for each button
             *
             * @param {RadioButtonConfig} buttonConfig
             * @returns {String}
             */
            cssClassesBtn(buttonConfig) {
                const classes = ['radiobutton'];

                // Enabled/disabled state:
                classes.push((buttonConfig.disabled === true || this.disabled === true) ? 'disabled' : 'enabled');

                // Checked state:
                if (buttonConfig.value === this.selectedValue)
                {
                    classes.push('checked');
                }

                return classes.join(' ');
            },

            /**
             * Reset to initial value
             */
            resetValue() {
                // Reset to initial value:
                if (this.model !== null && this.property !== null && typeof this.model[this.property] !== 'undefined')
                {
                    this.selectedValue = this.model[this.property];
                }
                else
                {
                    this.selectedValue = this.initialValue;
                }
                return this;
            },

            /**
             * Click handler
             *
             * @param {RadioButtonConfig} buttonConfig
             * @param {CustomEvent|MouseEvent} e
             */
            onClick(buttonConfig, e) {
                if (!buttonConfig.disabled && !this.disabled && this.selectedValue !== buttonConfig.value)
                {
                    e.target.focus();
                    this.selectedValue = buttonConfig.value;
                    if (this.model !== null && this.property !== null) {
                        this.model[this.property] = this.selectedValue;
                    }
                    this.$emit('change', this.selectedValue, e);
                }
                return this;
            },

            /**
             * Shortcut handler
             *
             * @param {CustomEvent} e
             */
            onShortcut(e) {
                if (e.target && e.target.dataset.configindex && this.$el.contains(e.target))
                {
                    // Select on spacebar key:
                    e.preventDefault();
                    e.detail.keyboardEvent.preventDefault();
                    e.stopImmediatePropagation();
                    e.detail.keyboardEvent.stopImmediatePropagation();
                    const buttonConfig = this.buttons[parseInt(e.target.dataset.configindex, 10)];
                    this.onClick(buttonConfig, e);
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
