<template>
    <Modal
        id="dialog-import-template-unit"
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.import_template_unit.title')"
        :user-closable="true"
        event-type="MODAL_IMPORT_TEMPLATE_UNIT"
        @show="onShow"
    >
        <!-- eslint-disable vue/no-v-html -->
        <p
            class="description"
            v-html="trans('modals.import_template_unit.description', {title: unit?.latestRevision?.title || ''})"
        />
        <!-- eslint-enable vue/no-v-html -->
        <TextInput
            ref="titleInput"
            :blur-on-enter-key="false"
            :error-msg="trans('errors.unit.title')"
            :maxlength="50"
            :model="$data"
            :placeholder="trans('units.create.unit_title_placeholder')"
            :required="true"
            property="newTitle"
            @key-enter="onClickApply"
        />
        <template #buttons>
            <ButtonSecondary
                caption="modals.import_template_unit.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="!isValid"
                caption="modals.import_template_unit.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import type Unit from '@/Models/Unit/Unit';
import {defineComponent} from 'vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import {trans} from '@/Utility/Helpers';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        TextInput,
        Modal
    },

    data() {
        return {
            /**
             * Reference to the Unit for which this dialog is being shown
             */
            unit: null as Unit | null,

            /**
             * Title of the duplicated unit
             */
            newTitle: '' as string,
        };
    },

    computed: {

        isValid(): boolean {
            return this.newTitle.length > 0 && this.newTitle.length <= 50;
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },
    },

    methods: {
        trans,

        onShow(unit: Unit) {
            this.unit = unit;
            this.newTitle = this.unit.latestRevision?.title || '';
        },

        onHide() {
            this.unit = null;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            if (!this.isValid) {
                return;
            }

            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_IMPORT_TEMPLATE_UNIT_APPLY, this.unit, this.newTitle);
        },
    }
});
</script>

<style lang="scss" scoped>

</style>
