<template>

    <main id="layout-main">

        <PageHeader
            :page-title="trans('tenants.update.headline')"
            :show-back-button="true"
        />

        <TenantUpdateForm
            :tenant="tenant"
        />

        <ManageLocalFeaturesForm
            :tenantUid="this.tenant.uid"
            :enable-dialogs="false"
        />

        <ModalProgress/>
        <ModalNotification/>
    </main>

</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {trans} from "@/Utility/Helpers/trans";
import PageHeader from "@/Vue/Common/PageHeader.vue";
import ManageLocalFeaturesForm from "@/Vue/Features/Local/ManageLocalFeaturesForm.vue";
import ModalNotification from "@/Vue/Modals/ModalNotification.vue";
import TenantUpdateForm from "@/Vue/Tenants/TenantUpdateForm.vue";
import Tenant from "@/Models/Tenant/Tenant";
import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

export default defineComponent({
    name: "PageTenantUpdate",

    components: {
        ModalProgress,
        TenantUpdateForm,
        ModalNotification,
        ManageLocalFeaturesForm,
        PageHeader,
    },

    props: {
        tenantJson: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            tenant: JSON.parse(this.tenantJson) as Tenant,
        }
    },

    methods: {
        trans,
    }
});
</script>

<style scoped lang="scss">

</style>
