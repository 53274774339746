<template>
    <span class="loading-indicator ring"/>
</template>

<script>
    export default {
        name: 'LoadingIndicator',
    }
</script>

<style lang="scss" scoped>

    .loading-indicator.ring {
        width: 24px;
        height: 24px;
        margin: 8px;
        border: 3px solid var(--background-color-dark);
        border-bottom-color: var(--color-primary);
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;

        &.small {
            width: 18px;
            height: 18px;
            border: 2px solid var(--background-color-dark);
            border-bottom-color: var(--color-primary);
            border-radius: 50%;
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

</style>
