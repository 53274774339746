import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import type User from '@/Models/User/User';
import {UserRole} from '@/Models/User/UserRole';

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.UsersUpdate() = 'users:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('User', new UserPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.UsersUpdate()), user);
 *
 */
export default class UserPolicy {

    /**
     * Determine whether the user can update the given other user.
     */
    update(user: User, userToUpdate: User): boolean {
        // a user is *never ever* allowed to update another user with a higher user role
        if (!this.manipulationIsAllowedByUserRoleHierarchy(user, userToUpdate)) {
            return false;
        }

        // check for the "UpdateOwnUser"-Permission if the user wants to update himself
        if (user.uid === userToUpdate.uid && permission(Permission.UsersUpdateOwn())) {
            return true;
        }

        return permission(Permission.UsersUpdate()) || permission(Permission.UsersUpdateAny());
    }

    delete(user: User, userToDelete: User): boolean {
        // WebApp users cannot be deleted because they are system users
        if (userToDelete.role === UserRole.WebApp) {
            return false;
        }

        // SuperAdmin users cannot be deleted
        if (userToDelete.role === UserRole.SuperAdmin) {
            return false;
        }

        // a user is *never ever* allowed to delete another user with a higher user role
        if (!this.manipulationIsAllowedByUserRoleHierarchy(user, userToDelete)) {
            return false;
        }

        // Delete own user
        if (
            user.uid === userToDelete.uid
            && permission(Permission.UsersDeleteOwn())
        ) {
            return true;
        }

        if (
            permission(Permission.UsersDeleteAny())
            && !userToDelete.belongsToMultipleTenants()
        ) {
            return true;
        }

        return false;
    }

    delete_instance_wide(user: User, userToDelete: User): boolean {
        // WebApp users cannot be deleted because they are system users
        if (userToDelete.role === UserRole.WebApp) {
            return false;
        }

        // SuperAdmin users cannot be deleted
        if (userToDelete.role === UserRole.SuperAdmin) {
            return false;
        }

        // a user is *never ever* allowed to delete another user with a higher user role
        if (!this.manipulationIsAllowedByUserRoleHierarchy(user, userToDelete)) {
            return false;
        }

        return permission(Permission.UsersDeleteInstanceWide());
    }

    private manipulationIsAllowedByUserRoleHierarchy(user: User, userToUpdate: User): boolean {
        switch (user.role) {
            case UserRole.SuperAdmin:
                return [
                    UserRole.SuperAdmin,
                    UserRole.Admin,
                    UserRole.User,
                    UserRole.Lms,
                    UserRole.WebApp,
                ].includes(userToUpdate.role);
            case UserRole.Admin:
                return [
                    UserRole.Admin,
                    UserRole.User,
                    UserRole.Lms,
                    UserRole.WebApp,
                ].includes(userToUpdate.role);
            case UserRole.User:
                return [
                    UserRole.User
                ].includes(userToUpdate.role);
            default:
                return false;
        }
    }
}
