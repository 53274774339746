<template>
    <table class="table table-bordered">
        <thead>
        <tr class="table-active">
            <th>{{ trans('labels.name') }}</th>
            <th>{{ trans('labels.title') }}</th>
            <th>{{ trans('labels.description') }}</th>
            <th colspan="4">{{ trans('instance_management.features.instance_enabled') }}</th>
        </tr>
        <tr class="table-active">
            <th></th>
            <th></th>
            <th></th>
            <th>{{ trans('instance_management.features.sources.config') }}</th>
            <th>{{ trans('instance_management.features.sources.local') }}</th>
            <th>{{ trans('instance_management.features.sources.younium') }}</th>
            <th>{{ trans('instance_management.features.sources.other') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="feature in features">
            <td>{{ feature.name }}</td>
            <td>{{ feature.title }}</td>
            <td>{{ feature.description }}</td>
            <td class="text-center">
                <Icon v-if="feature.instance_enabled_config" name="icon_check" class="icon-check-circle round"/>
            </td>
            <td class="text-center">
                <Icon v-if="feature.instance_enabled_local" name="icon_check" class="icon-check-circle round"/>
            </td>
            <td class="text-center">
                <Icon v-if="feature.instance_enabled_younium" name="icon_check" class="icon-check-circle round"/>
            </td>
            <td class="text-center">
                <Icon v-if="feature.instance_enabled_other" name="icon_check" class="icon-check-circle round"/>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
    import Icon from "@/Vue/Common/Icon.vue";
    import {trans} from "@/Utility/Helpers";
    import {defineComponent, PropType} from "vue";

    type Feature = {
        name: string,
        title: string,
        description: string,
        instance_enabled: boolean,
        instance_enabled_config: boolean,
        instance_enabled_younium: boolean,
        instance_enabled_local: boolean,
        instance_enabled_other: boolean,
    }

    export default defineComponent({
        name: "FeaturesTable",
        components: {
            Icon,
        },
        props: {
            'features': {
                type: Array as PropType<Feature[]>,
            }
        },
        methods: {
            trans,
        },
    });
</script>

<style lang="scss" scoped>
    .table-active, .table-active > th, .table-active > td {
        & > th, & > td {
            background-color: var(--color-anthracite);
            color: white;
            font-family: var(--font-family-default);
            font-weight: 400;
        }
    }

    .table-bordered thead th, .table-bordered thead td {
        border-bottom-width: 1px;
    }

    svg.icon {
        &.icon-check-circle {
            height: 22px;
            width: 22px;
            background-color: var(--color-primary);
            color: white;
            padding: 4px;
        }
    }

    tbody {
        background-color: white;
    }
</style>
