import Category from '@/Filters/Category';

export default class FilterCategory extends Category
{
    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     */
    constructor(attributes = {})
    {
        super(attributes);

        // Check for mandatory properties:
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('FilterCategory->constructor(): Invalid data.', attributes);
            throw new TypeError('FilterCategory->constructor(): Property "title" has to be set on FilterCategory.');
        }
        if (typeof attributes.callback !== 'function')
        {
            console.warn('FilterCategory->constructor(): Invalid data.', attributes);
            throw new TypeError('FilterCategory->constructor(): Property "callback" has to be set on FilterCategory. The callback function should apply a filter to a given list of elements.');
        }

        // Populate the model:
        this.title = attributes.title;                                                  // Translated title name for this filter category
        this.callback = attributes.callback;                                            // Callback method that filters a given list of elements
        this.options = attributes.options || null;                                      // Additional options that can be passed to the filter
        this.descending = (typeof attributes.descending === 'boolean') ? attributes.descending : null;  // Whether the default sorting is ascending or descending
        this.paramName = attributes.paramName || null;                                  // API parameter name
    }
}
