<template>
    <header class="launch-page-webapp-header">
        <span class="logo">
            <svg><use xlink:href="#logo_3spin" /></svg>
        </span>
        <h2 v-if="title">
            {{ title }}
        </h2>
        <ButtonSecondary
            :caption="trans('lms_xapi.launch_page.teaser.button_headset')"
            class="hide-on-mobile"
            icon="icon_passthrough-off"
            @trigger="triggerOpenInHeadset"
        />
        <ButtonCircular
            v-tooltip="trans('lms_xapi.launch_page.teaser.button_headset')"
            class="bordered"
            icon="icon_passthrough-off"
            @trigger="triggerOpenInHeadset"
        />
        <ButtonSecondary
            caption="labels.controls"
            @trigger="toggleControls"
        />
        <ButtonPrimary
            caption="labels.fullscreen"
            class="hide-on-mobile"
            icon="icon_fullscreen"
            @trigger="triggerFullscreen"
        />
        <ButtonCircular
            v-tooltip="'labels.fullscreen'"
            class="bordered"
            icon="icon_fullscreen"
            @trigger="triggerFullscreen"
        />
    </header>
</template>

<script>
import {trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';

export default defineComponent({
    components: {
        ButtonCircular,
        ButtonSecondary,
        ButtonPrimary
    },

    props: {
        title: {
            type: String,
            default: null,
        },
    },

    emits: [
        'onTriggerFullscreen',
        'onTriggerOpenInHeadset',
        'onToggleControls',
    ],

    methods: {
        trans,

        triggerOpenInHeadset() {
            this.$emit('onTriggerOpenInHeadset');
        },

        triggerFullscreen() {
            this.$emit('onTriggerFullscreen');
        },

        toggleControls() {
            this.$emit('onToggleControls');
        },
    },
});
</script>

<style lang="scss" scoped>
header {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 24px;
    align-items: center;
    gap: 12px;

    h2 {
        line-height: var(--line-height-largest);
        flex-grow: 1;
        flex-shrink: 1;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }

    .logo {
        display: block;

        &, svg {
            height: 32px;
            width: 64px;
        }
    }

    .btn {
        flex-shrink: 0;
        text-wrap: nowrap;
    }
}

@media only screen and (max-width: 768px) {
    header {
        .hide-on-mobile {
            display: none;
        }
    }
}

@media only screen and (min-width: 769px) {
    header {
        .btn-circular {
            display: none;
        }
    }
}

</style>
