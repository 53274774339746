// Include helpers that should be globally available in all Vue <template> tags:
import { VueTemplateMethods } from '@/Utility/Helpers';

export function registerTemplateMethods(app) {
    for (const [name, method] of Object.entries(VueTemplateMethods))
    {
        app.config.globalProperties[name] = method;
    }
}

