import { trans } from '@/Utility/Helpers';
import AbstractError from '@/Errors/AbstractError';

export default class ImageMaxDimensionsError extends AbstractError
{
    /**
     * Constructor
     *
     * @NOTE: Allows multiple different argument signatures
     */
    constructor()
    {
        super(...arguments);
        this.name = 'ImageDimensionsMaxError';
        this.message = this.message || trans('validation.custom.dimensions.max');
        Object.setPrototypeOf(this, ImageMaxDimensionsError.prototype);
    }
}
