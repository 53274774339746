<template>
    <div
        ref="modal-preview-wrapper"
        class="modal-asset-preview-canvas-wrapper"
        @contextmenu.prevent
    >
        <video
            ref="environment-video-player"
            :src="src"
            controls=""
            controlsList="nodownload"
            disablePictureInPicture
            @contextmenu.prevent
            autoplay
        />

    </div>
</template>

<script type="module">
import {
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    PerspectiveCamera,
    Scene,
    SphereGeometry,
    VideoTexture,
    WebGLRenderer
} from 'three';

export default {
    name: 'EnvironmentVideoRenderer',
    props: {
        src: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            camera: null,
            scene: null,
            renderer: null,

            shouldAnimate: false,

            isUserInteracting: false,
            onPointerDownMouseX: 0,
            onPointerDownMouseY: 0,
            lon: 0,
            onPointerDownLon: 0,
            lat: 0,
            onPointerDownLat: 0,
            phi: 0,
            theta: 0,
        }
    },

    mounted() {
        this.initThree();
        this.animate();

        this.container.addEventListener('pointerdown', this.onPointerDown);
        document.addEventListener('wheel', this.onDocumentMouseWheel);
        window.addEventListener('resize', this.onWindowResize);
    },

    beforeUnmount() {
        this.container.removeEventListener('pointerdown', this.onPointerDown)
        document.removeEventListener('wheel', this.onDocumentMouseWheel);
        window.removeEventListener('resize', this.onWindowResize);

        // Conditionally registered listeners
        document.removeEventListener('pointermove', this.onPointerMove);
        document.removeEventListener('pointerup', this.onPointerUp);
    },

    computed: {
        container() {
            return this.$refs['modal-preview-wrapper'];
        }
    },

    methods: {
        rendererSize() {
            // The used values are based on the css of the modal:
            // e.g. size, margins, paddings
            return {
                width: (window.innerWidth * 0.8 - 2 * 42),
                height: (window.innerHeight * 0.8 - 138),
            };
        },

        rendererAspect() {
            return this.rendererSize().width / this.rendererSize().height;
        },

        initThree() {
            if (
                this.src === undefined
                || this.src === null
                || this.src === 'undefined'
            ) {
                return this;
            }

            this.camera = new PerspectiveCamera(75, this.rendererAspect(), 1, 1100);
            this.scene = new Scene();
            const geometry = new SphereGeometry(500, 60, 40);
            // invert the geometry on the x-axis so that all the faces point inward
            geometry.scale(-1, 1, 1);

            const video = this.$refs['environment-video-player'];
            video.play();

            const texture = new VideoTexture(video);
            const material = new MeshBasicMaterial({map: texture});

            const mesh = new Mesh(geometry, material);

            this.scene.add(mesh);

            this.renderer = new WebGLRenderer();
            this.renderer.setPixelRatio(window.devicePixelRatio);

            this.renderer.setSize(this.rendererSize().width, this.rendererSize().height);
            this.container.appendChild(this.renderer.domElement);
            this.container.style.touchAction = 'none';
        },

        onWindowResize() {
            this.camera.aspect = this.rendererAspect();
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.rendererSize().width, this.rendererSize().height);
        },

        onPointerDown(event) {
            if (event.isPrimary === false) {
                return;
            }

            this.isUserInteracting = true;
            this.onPointerDownMouseX = event.clientX;
            this.onPointerDownMouseY = event.clientY;
            this.onPointerDownLon = this.lon;
            this.onPointerDownLat = this.lat;

            document.addEventListener('pointermove', this.onPointerMove);
            document.addEventListener('pointerup', this.onPointerUp);
        },

        onPointerMove(event) {
            if (event.isPrimary === false) {
                return;
            }

            this.lon = (this.onPointerDownMouseX - event.clientX) * 0.1 + this.onPointerDownLon;
            this.lat = (event.clientY - this.onPointerDownMouseY) * 0.1 + this.onPointerDownLat;
        },

        onPointerUp(event) {
            if (event.isPrimary === false) {
                return;
            }

            this.isUserInteracting = false;

            document.removeEventListener('pointermove', this.onPointerMove);
            document.removeEventListener('pointerup', this.onPointerUp);
        },

        onDocumentMouseWheel(event) {
            const fov = this.camera.fov + event.deltaY * 0.05;
            this.camera.fov = MathUtils.clamp(fov, 10, 75);
            this.camera.updateProjectionMatrix();
        },

        animate() {
            requestAnimationFrame(this.animate);
            this.update();
        },

        update() {
            if (
                this.isUserInteracting === false
                && this.shouldAnimate === true
            ) {
                this.lon += 0.1;
            }

            this.lat = Math.max(-85, Math.min(85, this.lat));
            this.phi = MathUtils.degToRad(90 - this.lat);
            this.theta = MathUtils.degToRad(this.lon);

            const x = 500 * Math.sin(this.phi) * Math.cos(this.theta);
            const y = 500 * Math.cos(this.phi);
            const z = 500 * Math.sin(this.phi) * Math.sin(this.theta);

            this.camera.lookAt(x, y, z);

            this.renderer.render(this.scene, this.camera);
        },
    },
    watch: {
        src() {
            this.initThree();
            this.animate();
            return this;
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-asset-preview-canvas-wrapper {
    width: 100%;
    max-width: 80vw;
    overflow: hidden;
}

video {
    display: none;
}
</style>
