<template>
    <StatisticsCard class="statistics-card-activities" :is-loading="isLoading">
        <StatisticsCardHeader
            :title="trans('statistics.index.activity_chart.title')"
            :subtitle="trans('statistics.index.activity_chart.subtitle')"
        />

        <figure class="chart-wrapper">
            <svg ref="chart" class="chart">
                <g class="root">
                    <g class="x-scale"/>
                    <g class="bars"/>
                </g>
            </svg>

            <figcaption>
                <h4>
                    <Icon name="icon_time"/>
                    {{ trans('statistics.index.activity_chart.last_changes') }}
                </h4>
                <ul v-if="this.activities.length > 0" class="activity-list"></ul>
                <p v-else>{{ trans('statistics.index.activity_chart.no_activities') }}</p>
            </figcaption>
        </figure>
    </StatisticsCard>
</template>

<script lang="ts">

import {defineComponent, PropType, ref} from "vue";
import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
import {Activity} from "@/Models/Statistics/Statistics";
import {trans} from "@/Utility/Helpers";
import {select} from "d3-selection";
import {axisBottom, NumberValue, scaleOrdinal, scaleTime} from "d3";
import StatisticsCardHeader from "@/Vue/Statistics/General/Cards/StatisticsCardHeader.vue";
import Icon from "@/Vue/Common/Icon.vue";
import {useResize} from "@/Vue/Utility/useResize";

export default defineComponent({
    name: "StatisticsCardActivities",

    components: {
        Icon,
        StatisticsCardHeader,
        StatisticsCard
    },

    props: {
        activities: {
            type: Object as PropType<Activity[]>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        chartHeight: {
            type: Number,
            default: 225,
        }
    },

    setup() {
        const chart = ref<HTMLElement>();
        const {width: chartWidth} = useResize(chart);

        return {
            chartWidth,
            chart,
        };
    },

    methods: {
        trans,

        redraw() {
            const width = this.chartWidth;
            const height = this.chartHeight;
            const padding = 20;
            const xAxisHeight = 30;
            const chartWidth = width - 2 * padding;
            const chartHeight = height - xAxisHeight - 2 * padding;
            const barWidth = width / 100;
            const barOpacity = 0.4;
            const numberOfLastChanges = 9;

            const yesterday = Date.now() - 24 * 60 * 60 * 1000;
            const now = Date.now() + 60 * 1000;
            const xScale = scaleTime()
                .range([0, chartWidth])
                .domain([yesterday, now]);

            const chart = select('.statistics-card-activities .chart')
                .attr('height', height);

            const group = chart
                .select('.root')
                .attr('transform', `translate(${padding}, ${padding})`);

            const getColorByObjectType = scaleOrdinal<string, string>()
                .domain(['asset', 'course'])
                .range(['#0098A5', '#29C9D7', '#FAB200', '#A8B1B2'])

            const getLegendEntry = (activity: Activity) => {
                const time = this.toTimeString(activity.time);
                const color = getColorByObjectType(activity.objectType);
                return `<span class="time">${time}</span> – User ${activity.operation}
                        <span class="object" style="color:${color}">${activity.objectType}</span>
                        “${activity.objectName}“`;
            };

            group
                .select<SVGGElement>('.x-scale')
                .attr('transform', `translate(0, ${chartHeight})`)
                .call(axisBottom(xScale)
                    .tickSize(-chartHeight)
                    .tickFormat(this.toTimeString)
                );

            group.select('.bars')
                .selectAll('rect')
                .data(this.activities)
                .join('rect')
                .attr('x', activity => xScale(activity.time))
                .attr('width', barWidth)
                .attr('y', 0)
                .attr('fill', activity => getColorByObjectType(activity.objectType))
                .attr('opacity', barOpacity)
                .attr('height', chartHeight);

            select('.statistics-card-activities .activity-list')
                .selectAll('li')
                .data(this.activities.slice(0, numberOfLastChanges))
                .join('li')
                .html(getLegendEntry);
        },

        toTimeString(time: Date | NumberValue): string {
            const date = time instanceof Date ? time : new Date(time.valueOf());

            return date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    },

    watch: {
        chartWidth() {
            this.redraw();
        }
    },
});

</script>

<style lang="scss">

.statistics-card-activities {
    overflow: hidden; // Fix resize issue when changing media breakpoints

    .chart-wrapper {
        padding: 32px 38px 24px 28px;
        display: flex;
        gap: 34px;

        figcaption {
            min-width: 150px;
            font-size: var(--font-size-small);
            font-family: var(--font-family-condensed);

            .icon {
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }

            ul {
                list-style-type: none;

                .object {
                    font-family: var(--font-family-condensed-demibold);
                }
            }
        }
    }

    .chart {

        flex-grow: 1;

        text {
            font-size: 10px;
            font-family: var(--font-family-condensed);
            color: #4F4F4F;
        }

        .domain,
        .tick line {
            stroke: #ECECEC;
        }

        .x-scale text {
            transform: translateY(4px);
        }
    }
}

</style>
