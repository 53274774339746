import { uuid4 } from '@/Utility/Helpers';
import Component from '@/Models/Unity/Components/Component';
import Transform from '@/Models/Unity/Transform';

export default class TransformComponent extends Component
{
    /**
     * Type for this component
     *
     * @var {String}
     */
    static get type() { return 'transform'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Set type for this component:
        super({...attributes, ...{type: attributes.type || TransformComponent.type}});

        // Populate the model:
        this.transform = new Transform(attributes.transform);   // Unity Transform
    }

    /**
     * Duplicate
     *
     * @returns {TransformComponent}
     */
    duplicate()
    {
        const duplicated = new TransformComponent(this);
        duplicated.uid = uuid4();
        duplicated.transform = this.transform.duplicate();

        return duplicated;
    }
}
