export default class FilterSection {
    /**
     * Constructor
     *
     * @param {string} title                 // Title of this section
     * @param {FilterCategory|Category} category      // FilterCategory used in this section
     */
    constructor(title = '', category = null) {

        this.title = title.length >= 0 ? title : null;
        this.category = category;
    }
}
