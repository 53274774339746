import {parseDate} from "@/Utility/Helpers";
import CoursePermissionPolicy, {CoursePermissionPolicyStandard} from "@/Models/Course/CoursePermissionPolicy";

export default class CourseWithoutRelations {

    public readonly uid: string;

    public readonly created_at: Date | null;
    public readonly updated_at: Date | null;

    public preview: string;
    public preview_thumbnail: string;

    public policy: string;
    public title: string;
    public description: string | null;
    public auto_enrollment: boolean;
    public readonly owned_by: string | null;
    public readonly owned_by_tenant: string | null;

    static get constructorName() {
        return 'CourseWithoutRelations';
    }

    constructor(attributes: any = {}) {
        this.uid = attributes.uid;

        this.created_at = parseDate(attributes.created_at || null);
        this.updated_at = parseDate(attributes.updated_at || null);

        this.policy = attributes.policy || CoursePermissionPolicyStandard.type;
        this.title = attributes.title;
        this.description = attributes.description;
        this.auto_enrollment = attributes.auto_enrollment;
        this.preview = attributes.preview;
        this.preview_thumbnail = attributes.preview_thumbnail;
        this.owned_by = attributes.owned_by || null;
        this.owned_by_tenant = attributes.owned_by_tenant || null;
    }

    /**
     * Get the constructor name from the instance's class
     */
    get constructorName(): string {
        return CourseWithoutRelations.constructorName;
    }

    get parsedPolicy(): CoursePermissionPolicy | undefined {
        return CoursePermissionPolicy.getPolicyForType(this.policy);
    }
}
