import { uuid4 } from '@/Utility/Helpers';
import Position from '@/Models/Unity/Position';

export default class Waypoint
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Populate the model:
        this.uid = attributes.uid || uuid4();                               // Unique ID
        this.originalUid = this.uid;                                        // Original unique ID from which the object was duplicated (hidden)
        this.title = attributes.title || null;                              // Title
        this.position = new Position(attributes.position);                  // Position
    }

    /**
     * Duplicate
     *
     * @returns {Waypoint}
     */
    duplicate()
    {
        const duplicated = new Waypoint(this);
        duplicated.uid = uuid4();
        duplicated.position = new Position(this.position);
        return duplicated;
    }
}
