<template>
    <section
        v-if="revisionToDisplay"
        v-focusable-if="!unitIsLaunchable"
        :class="classNames"
        @click="$emit('click', unit)"
        @dblclick="$emit('dblclick', unit)"
        @focusin="$emit('focus', unit)"
    >
        <a
            v-if="unitIsLaunchable"
            v-tooltip="'buttons.courses.view'"
            :href="route('courses.units.launch', {'course': course.uid,'unit': unit.uid})"
            class="unit-preview"
            @click.stop.prevent="$emit('click-launch', unit)"
        >
            <Icon name="icon_play" />
            <img :src="revisionToDisplay.preview_thumbnail" alt="">
        </a>
        <img
            v-else
            class="unit-preview"
            :src="revisionToDisplay.preview_thumbnail"
            alt=""
        >

        <div class="unit-content">
            <h2 class="unit-title">
                {{ revisionToDisplay.title }}
                <time :datetime="moment(revisionToDisplay.released_at).format()" class="unit-author">
                    {{ releasedDate }}
                </time>
            </h2>
            <p class="unit-description">
                {{ revisionToDisplay.description }}
            </p>
        </div>

        <footer>
            <div class="unit-labels">
                <StatusLabel
                    v-for="labelConfig in labels"
                    :key="'statuslabel'+unit.uid+(labelConfig.type || labelConfig.caption)"
                    :config="labelConfig"
                />
            </div>
            <template v-if="unitHasActions">
                <div class="unit-actions">
                    <!-- Btn: Remove -->
                    <ButtonCircular
                        v-if="unitIsRemovable"
                        v-tooltip="'buttons.units.remove_from_course'"
                        icon="icon_delete"
                        @trigger="$emit('click-remove', unit)"
                    />
                </div>
            </template>
        </footer>

    </section>
</template>

<script lang="ts">
import moment from 'moment';
import Unit from '@/Models/Unit/Unit';
import {Permission} from '@/Models/User/Permission';
import {UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';
import {route, trans} from '@/Utility/Helpers';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';
import Course from '@/Models/Course/Course';
import {defineComponent, inject} from 'vue';
import {featureRepositoryKey} from '@/Vue/Bootstrap/InjectionKeys';
import {Feature} from '@/Models/Features/Feature';
import type UnitRevision from '@/Models/Unit/UnitRevision';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({

    components: {
        Icon,
        StatusLabel,
        ButtonCircular
    },

    props: {
        unit: {
            type: Unit,
            required: true,
        },
        currentUnitRevisionUid: {
            type: String,
            required: true,
        },
        unitIsRemovable: {
            type: Boolean,
            default: false,
        },
        course: {
            type: Course,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        showPolicyLabel: {
            type: Boolean,
            default: false,
        },
        showReleaseLabel: {
            type: Boolean,
            default: false,
        }
    },

    emits: {
        'click': (_: Unit) => true,
        'dblclick': (_: Unit) => true,
        'click-launch': (_: Unit) => true,
        'click-remove': (_: Unit) => true,
        'focus': (_: Unit) => true,
    },

    data() {
        return {
            featureRepository: inject(featureRepositoryKey)!,
        };
    },

    computed: {

        classNames() {
            return [
                'unit-list-item',
                this.selected ? 'selected' : '',
                this.unit.is_new ? 'is-new' : '',
            ];
        },

        labels() {
            const labels: StatusLabelConfig[] = [];

            // Policy (if non-standard)
            if (this.showPolicyLabel && this.unit.policy !== UnitPermissionPolicyStandard.type) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'policy',
                        caption: this.unit.policy
                    })
                );
            }

            // Released or draft
            if (this.showReleaseLabel && this.unit.isReleased) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'status',
                        caption: 'released'
                    })
                );
            } else if (this.showReleaseLabel && this.unit.isDraft) {
                labels.push(
                    new StatusLabelConfig({
                        caption: 'draft'
                    })
                );
            }

            // Unreleased changes
            if (this.showReleaseLabel && this.unit.hasUnreleasedChanges) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'notification',
                        caption: 'unreleased_changes'
                    })
                );
            }

            // Incompatible
            if (!this.unit.latestRevision?.isCompatible) {
                labels.push(
                    new StatusLabelConfig({
                        type: 'warning',
                        caption: 'incompatible_unit_open',
                        tooltip: 'incompatible_unit_open_tooltip'
                    })
                );
            }

            return labels;
        },

        revisionToDisplay(): null | UnitRevision {
            return this.currentUnitRevision;
        },

        currentUnitRevision(): null | UnitRevision {
            return this.unit.revisions.find(unitRevision => unitRevision.uid === this.currentUnitRevisionUid) || null;
        },

        releasedDate(): string {
            const revision = this.revisionToDisplay;
            if (!revision || revision.isDraft) {
                return '';
            }
            return `${trans('labels.released_on')} ${moment(revision.released_at)
                .format(trans('courses.unit_list.item_date_format'))}`;
        },

        unitHasActions() {
            return this.unitIsRemovable || this.unitIsLaunchable;
        },

        unitIsEditable() {
            return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.unit);
        },

        unitIsLaunchable() {
            return this.$gate.allows(Permission.ability(Permission.CoursesLaunchUnit()), this.course, this.unit)
                && this.featureRepository.active(Feature.EntitlementDevicetypeWebapp);
        },
    },

    methods: {
        route
    }
});
</script>

<style lang="scss" scoped>

</style>
