import ValidatedValue from '@/Models/User/Import/ValidatedValue';
import {TenantRole} from '@/Models/Tenant/TenantRole';
import {UserRole} from '@/Models/User/UserRole';
import type {UserToImport} from '@/Services/UserService';

export default class UserImportEntry {

    public static fromCsvRow(csvRowData: Array<string>): UserImportEntry {
        const lastName = csvRowData[0];
        const firstName = csvRowData[1];
        const email = csvRowData[2];
        const tenantRole = csvRowData[3];
        const role = csvRowData[4];

        return new UserImportEntry(
            lastName,
            firstName,
            email,
            tenantRole,
            role
        );
    }

    public readonly firstName: ValidatedValue<string>;
    public readonly lastName: ValidatedValue<string>;
    public readonly email: ValidatedValue<string>;
    public readonly tenantRole: ValidatedValue<string>;
    public readonly role: ValidatedValue<string>;

    constructor(lastName: string, firstName: string, email: string, tenantRole: string, role: string) {
        lastName = lastName?.trim();
        this.lastName = new ValidatedValue(lastName, !!lastName && lastName?.length <= 255);

        firstName = firstName?.trim();
        this.firstName = new ValidatedValue(firstName, !!firstName && firstName.length <= 255);

        email = email?.trim();
        this.email = new ValidatedValue(email, !!email && email.includes('@'));

        tenantRole = tenantRole?.trim();
        this.tenantRole = new ValidatedValue(tenantRole, Object.values<string>(TenantRole.Name).includes(tenantRole));

        role = role?.trim();
        this.role = new ValidatedValue(role, Object.values<string>(UserRole).includes(role));
    }

    get isValid() {
        return this.firstName.isValid &&
            this.lastName.isValid &&
            this.email.isValid &&
            this.tenantRole.isValid &&
            this.role.isValid;
    }

    toUserToImport(): UserToImport {
        return {
            firstname: this.firstName.value,
            lastname: this.lastName.value,
            email: this.email.value,
            tenant_role_name: this.tenantRole.value as TenantRole.Name,
            user_role_name: this.role.value as UserRole,
        }
    }
}
