<template>
    <Modal
        ref="modal"
        :animated="true"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :user-closable="true"
        event-type="MODAL_CREATE_ASSET"
        @show="onShow"
    >
        <CreateAssetForm
            v-if="isVisible"
            :asset-file-downloader="assetFileDownloader"
            :asset-provider="assetProvider"
            :asset-types="assetTypes"
            :enable-dialogs="false"
            :initial-asset-attribution="initialAssetAttribution"
            :initial-asset-type="initialAssetType"
            :initial-description="initialAssetDescription"
            :initial-preview-image-url="initialPreviewImageUrl"
            :initial-title="initialAssetTitle"
            :lock-asset-attribution="lockAssetAttribution"
            :policies="policies"
            :provider-asset-id="providerAssetId"
            @cancel="onCancelAssetCreate"
            @success="onSuccessAssetCreate"
            @change-type="onChangeAssetType"
        />
    </Modal>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import {AssetPolicyStandard} from '@/Models/Asset/AssetPolicy';
import CreateAssetForm from '@/Vue/Assets/CreateAssetForm.vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import type ModalCreateAssetShowEventParameters from '@/Models/Asset/ModalCreateAssetShowEventParameters';
import type Asset from '@/Models/Asset/Asset';
import type {AssetProvider} from '@/Models/Asset/AssetProvider';

export default defineComponent({

    components: {
        Modal,
        CreateAssetForm
    },

    props: {
        policies: {
            type: Array,
            default() {
                return [AssetPolicyStandard.type];
            }
        },
    },

    emits: {
        'success': (_: Asset) => true
    },

    data() {
        return {
            isVisible: false,
            initialAssetTitle: null as string | null,
            initialAssetDescription: null as string | null,
            initialAssetAttribution: null as string | null,
            initialAssetType: null as string | null,
            initialPreviewImageUrl: null as string | null,
            lockAssetAttribution: false,
            assetProvider: null as AssetProvider | null,
            providerAssetId: null as string | null,
            assetFileDownloader: null as (() => Promise<Blob>) | null,
            callback: null as ((_: Asset) => void) | null,

            /**
             * List of available AssetTypes for selection in the create asset form.
             */
            assetTypes: {} as { [key: string]: string[] },
        };
    },

    methods: {

        onShow(options: ModalCreateAssetShowEventParameters = {}) {
            const availableAssetTypes = {};

            // Convert list of AssetType objects into an object that the create asset form uses
            if (options.assetTypes) {
                options.assetTypes.forEach(assetType => availableAssetTypes[assetType.type] = assetType.supportedFileExtensions);
            } else {
                console.error('ModalCreateAsset->show(): No valid asset types given.');
            }

            this.initialAssetTitle = options.initialAssetTitle || null;
            this.initialAssetDescription = options.initialAssetDescription || null;
            this.initialAssetAttribution = options.initialAssetAttribution || null;
            this.initialPreviewImageUrl = options.initialPreviewImageUrl || null;
            this.lockAssetAttribution = options.lockAssetAttribution || false;
            this.assetFileDownloader = options.assetFileDownloader || null;
            this.callback = options.callback || null;
            this.assetTypes = availableAssetTypes;
            this.assetProvider = options.assetProvider || null;
            this.providerAssetId = options.providerAssetId || null;

            this.isVisible = true;
        },

        onHide() {
            this.isVisible = false;
        },

        /**
         * Success handler for promise from asset create form.
         */
        onSuccessAssetCreate(asset: Asset) {
            this.$refs.modal.hide();

            if (this.callback) {
                this.callback(asset);
            }

            this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_SUCCESS, asset);
            this.$emit('success', asset);

            // Remember selected type:
            this.initialAssetType = asset.type;
        },

        /**
         * Cancel handler for promise from asset create form.
         */
        onCancelAssetCreate() {
            this.$refs.modal.hide();
        },

        onChangeAssetType(type: string) {
            // Remember selected type:
            this.initialAssetType = type;
        }
    }
});
</script>

<style lang="scss" scoped>
dialog {
    width: 660px;
    max-width: 660px;

    :deep(.container) {
        .textinput textarea {
            min-height: 120px;
        }

        .textinput textarea[name='attribution'] {
            min-height: 60px;
        }

        // Bootstrap fixes
        // @TODO: Remove once Bootstrap is removed
        .card,
        .card-header,
        .card-body {
            border: 0 none;
            background: none;
        }

        .card-header {
            font-size: var(--font-size-large);
        }

        .col-md-4 {
            text-align: left;
            flex: 0 0 120px;
            max-width: 120px;

            &.text-md-right {
                text-align: left !important;
            }
        }

        .offset-md-4 {
            margin-left: 120px;
        }

        .col-md-6 {
            flex: 1 0;
            max-width: 75%;
        }

        .ml-2 {
            margin-left: .5rem !important;
        }

        .ml-4 {
            margin-left: 1.5rem !important;
        }
    }
}
</style>
