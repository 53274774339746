import type InstructionPayload from '@/Models/Sessions/InstructionPayload';
import InstructionPayloadFactory from '@/Models/Sessions/InstructionPayloadFactory';
import {parseDate, trans} from '@/Utility/Helpers';
import type {InstructionType} from '@/Models/Sessions/InstructionType';
import type Device from '@/Models/Devices/Device';

export default class Instruction {

    static getButtonLabelForAllDevices(type: string) {
        return trans(`sessions.instructions.types.${type}.button_all_devices`);
    }

    static getDescriptionForAllDevices(type: InstructionType) {
        return trans(`sessions.instructions.types.${type}.description_all_devices`);
    }

    static getDescriptionForSingleDevice(type: InstructionType) {
        return trans(`sessions.instructions.types.${type}.description_single_device`);
    }

    static getToastForSingleDevice(type: InstructionType, device: Device, unitTitle: string) {
        return trans(`sessions.instructions.types.${type}.toast_single_device`, {
            device: device.name,
            unit: unitTitle,
        });
    }

    static getToastForAllDevices(type: InstructionType, unitTitle: string) {
        return trans(`sessions.instructions.types.${type}.toast_all_devices`, {
            unit: unitTitle,
        });
    }

    readonly uid: string;
    readonly device_uid: string;

    readonly payload: InstructionPayload;

    readonly created_at: Date;
    readonly updated_at: Date;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.device_uid = attributes.device_uid;

        this.payload = InstructionPayloadFactory.createFromAttributes(attributes.payload);

        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }
}


