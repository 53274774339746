<template>
    <main
        id="layout-main"
        :data-loading="isLoading"
        :data-saving="isSaving"
    >

        <PageHeader
            :page-title="trans('sessions.index.headline')"
        />

        <div id="layout-content">
            <div id="content">

                <ButtonPrimary
                    v-tooltip="'buttons.managed_sessions.create'"
                    :href="route('sessions.create')"
                    caption="sessions.index.btn_create"
                    icon="icon_add"
                />

                <div class="session-list">
                    <SessionListItem
                        v-for="session in sessionPage.items"
                        :key="session.uid"
                        v-focusable
                        :session="session"
                        @delete-click="onDeleteClick"
                    />

                    <NoItemsAvailable v-if="!isLoading && sessionPage.items.length === 0" />
                </div>

                <Pagination
                    v-if="sessionPage.items.length > 0"
                    :current-page="sessionPage.metadata.current_page"
                    :number-of-pages="sessionPage.metadata.last_page"
                    @click="onClickChangePage"
                />

            </div>

            <!-- Modals go here -->
            <ModalDeleteSession />
            <ModalNotification />
            <ModalProgress />

        </div>
    </main>
</template>

<script lang="ts">

import {route, trans} from '@/Utility/Helpers';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import EventType from '@/Utility/EventType';
import {defineComponent, inject} from 'vue';
import {sessionServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import SessionListItem from '@/Vue/Sessions/SessionListItem.vue';
import PagingPage from '@/Models/PagingPage';
import type ManagedSession from '@/Models/Sessions/ManagedSession';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import Pagination from '@/Vue/Common/Pagination.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ModalDeleteSession from '@/Vue/Modals/ModalDeleteSession.vue';

export default defineComponent({
    components: {
        ModalDeleteSession,
        ButtonPrimary,
        Pagination,
        NoItemsAvailable,
        SessionListItem,
        ModalProgress,
        ModalNotification,
        PageHeader,
    },

    data() {
        return {
            sessionService: inject(sessionServiceKey)!,

            events: new Map([
                [EventType.MODAL_DELETE_MANAGED_SESSION_APPLY, this.onDeleteSessionConfirmed],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
            ]),

            sessionPage: new PagingPage<ManagedSession>(),
        };
    },

    computed: {
        isLoading(): boolean {
            if (this.sessionService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isSaving(): boolean {
            if (this.sessionService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });

        this.fetchSessions();
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        route,
        trans,

        async fetchSessions(page: number = 1) {
            return this.sessionService
                .fetchSessions(page)
                .then(sessionPage => {
                    this.sessionPage = sessionPage;
                })
                .catch(this.onErrorApi);
        },

        onDeleteClick(session: ManagedSession) {
            this.$globalEvents.emit(EventType.MODAL_DELETE_MANAGED_SESSION_SHOW, session);
        },

        onDeleteSessionConfirmed(session: ManagedSession) {
            this.sessionService
                .deleteSession(session.uid)
                .then(() => {
                    if (this.sessionPage.items.length > 1) {
                        // Reload current page
                        return this.fetchSessions(this.sessionPage.metadata.current_page);
                    } else {
                        // We deleted the last item - load previous page.
                        // 0 is fine here and will simply return the first page available.
                        return this.fetchSessions(this.sessionPage.metadata.current_page - 1);
                    }
                })
                .catch(this.onErrorApi);
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }
            this.$root?.showErrorDialog(error);
        },

        /**
         * Called when the user triggered a page change.
         */
        onClickChangePage(page: number) {
            this.fetchSessions(page);
        },

        onBeforeUnload() {
            this.sessionService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },
    }
});
</script>

<style lang="scss" scoped>

#content {
    .session-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 12px 0 30px 0;

        max-width: var(--container-max-width);
        min-width: 670px;
    }
}

</style>
