<template>
    <component
        ref="collapsible"
        :is="dragGroups ? 'Draggable' : tag"
        :tag="tag"
        :id="uid"
        :class="cssClasses"
        :data-collapsible-group="group"
        :groups="dragGroups"
        :value="dragValue"
        v-focusable-if="!disabled"
        v-shortcuts
        @focus="onFocusHeader"
        @blur="onBlurHeader"
    >
        <!-- Header -->
        <header
            v-if="$slots.header"
            class="collapsible-header"
            @click="onClickToggleCollapse"
            :draggable="Boolean(dragGroups)"
        >
            <slot name="header" />
        </header>

        <!-- Collapsible Body -->
        <component v-if="$slots.body" v-show="!collapsed" :is="tag" class="collapsible-body">
            <slot name="body" />
        </component>

    </component>
</template>

<script>

    // Import classes:
    import { shortId }              from '@/Utility/Helpers';

    export default {
        name: 'Collapsible',
        emits: [
            'expand',
            'collapse',
            'focus',
            'blur',
        ],
        props: {
            disabled: {                         // Disabled state
                type: Boolean,
                default: false
            },
            group: {                            // Group identifier
                type: String,
                default: null
            },
            tag: {                              // The HTML tag to be rendered
                type: String,
                default: 'div'
            },
            initialCollapsed: {                 // Initial collapsed state
                type: Boolean,
                default: false
            },

            // Draggable properties:
            dragGroups: {                       // Drag'n'drop group identifiers (enables dragging if any group is set)
                type: [String, Array],
                default: null
            },
            dragValue: {                        // The value to be dragged along with the DOM element
                type: [String, Number, Object, Array, Boolean],
                default: null
            }
        },
        data() {
            return {
                uid: shortId('collapsible'),    // A unique identifer for HTML id="" attribute
                collapsed: false,               // Collapsed state
                scrollableAncestor: null,       // First scrollable ancestor
                eventGroupCollapse: 'collapsible-group-collapse',   // Event name for group collapses
                shortcuts: new Map([
                    ['LeftArrow', this.onShortcutCollapse],
                    ['RightArrow', this.onShortcutExpand],
                    ['Space.prevent', this.onClickToggleCollapse],
                    ['Enter', this.onClickToggleCollapse]
                ])
            }
        },
        computed: {

            /**
             * Container CSS classes
             *
             * @returns {String}
             */
            cssClasses() {
                const classes = [ 'collapsible' ];
                classes[classes.length] = (this.disabled === true) ? 'is-not-collapsible' : 'is-collapsible';
                classes[classes.length] = (this.collapsed === true) ? 'collapsed' : 'expanded';
                if (this.group !== null)
                {
                    classes[classes.length] = 'group-' + this.group.replace(/[^-_\da-z]/gi, '');
                }
                return classes.join(' ');
            }
        },
        mounted() {
            // Use the initial state:
            this.collapsed = this.initialCollapsed;

            // Find the first parent ancestor that is scrollable:
            this.scrollableAncestor = (() => {
                let parent = (this.$refs.collapsible.$el || this.$refs.collapsible).parentNode;
                while (parent !== null)
                {
                    if (parent.scrollHeight > parent.clientHeight)
                    {
                        return parent;
                    }
                    parent = parent.parentNode;
                }
                return null;
            })();

            // Add a global event for group items:
            this.$globalEvents.on(this.eventGroupCollapse, this.onCollapseGroup);
        },
        beforeUnmount() {
            // Remove global event for group items:
            this.$globalEvents.off(this.eventGroupCollapse, this.onCollapseGroup);
        },
        methods: {

            /**
             * Scroll first scrollable ancestor to beginning of the element
             */
            scrollIntoView() {
                const collapsible = this.$refs.collapsible.$el || this.$refs.collapsible;
                let totalOffset = collapsible.offsetTop || 0;
                let parent = collapsible.parentNode;
                while (parent !== null && (this.scrollableAncestor === null || parent.isSameNode(this.scrollableAncestor) === false))
                {
                    totalOffset += parent.offsetTop;
                    parent = parent.parentNode;
                }
                // Only scroll if outside of the visible area:
                if (this.scrollableAncestor !== null && (this.scrollableAncestor.scrollTop > totalOffset || this.scrollableAncestor.scrollTop < totalOffset - this.scrollableAncestor.clientHeight))
                {
                    this.scrollableAncestor.scrollTo(0, totalOffset);
                }
                return this;
            },

            /**
             * Expand
             *
             * @param {CustomEvent|MouseEvent} e
             */
            expand(e = null) {
                this.collapsed = false;

                // Scroll to beginning of the element:
                this.scrollIntoView();

                // Trigger event for the parent:
                this.$emit('expand', e, this);

                // Collapse other group items:
                if (this.group !== null)
                {
                    this.$globalEvents.emit(this.eventGroupCollapse, this.group, this.uid);
                }
                return this;
            },

            /**
             * Collapse
             *
             * @param {CustomEvent|MouseEvent} e
             */
            collapse(e = null) {
                this.collapsed = true;

                // Scroll to beginning of the element:
                this.scrollIntoView();

                // Trigger event for the parent:
                this.$emit('collapse', e, this);
                return this;
            },

            /**
             * Focus handler for the header
             *
             * @param {FocusEvent} e
             */
            onFocusHeader(e) {
                this.$emit('focus', e);
                return this;
            },

            /**
             * Blur handler for the header
             *
             * @param {FocusEvent} e
             */
            onBlurHeader(e) {
                this.$emit('blur', e);
                return this;
            },

            /**
             * Shortcut handler: Collapse
             *
             * @param {CustomEvent} e
             */
            onShortcutCollapse(e) {
                if (this.disabled === false && this.collapsed === false && document.activeElement === this.$el)
                {
                    this.collapse(e);
                }
                return this;
            },

            /**
             * Shortcut handler: Expand
             *
             * @param {CustomEvent} e
             */
            onShortcutExpand(e) {
                if (this.disabled === false && this.collapsed === true && document.activeElement === this.$el)
                {
                    this.expand(e);
                }
                return this;
            },

            /**
             * Click handler for the collapse toggle
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickToggleCollapse(e)
            {
                if (this.disabled === false && ['input', 'textarea'].indexOf(e.target.tagName.toLowerCase()) === -1 && document.activeElement === this.$el)
                {
                    e.stopPropagation();
                    if (this.collapsed === false)
                    {
                        this.collapse(e);
                    }
                    else
                    {
                        this.expand(e);
                    }
                }
                return this;
            },

            /**
             * Collapse handler for group items
             *
             * @param {String} group
             * @param {String} uid
             */
            onCollapseGroup(group, uid)
            {
                // Collapse if it's from the same group as the initiator:
                if (this.group !== null && this.collapsed === false && group === this.group && uid !== this.uid)
                {
                    this.collapsed = true;

                    // Trigger event for the parent:
                    this.$emit('collapse');
                }
                return this;
            }
        },
        watch: {
            initialCollapsed(collapsed)
            {
                this.collapsed = collapsed;

                // Trigger event for the parent:
                this.$emit((this.collapsed === true) ? 'collapse' : 'expand', null, this);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
