import {trans} from "@/Utility/Helpers";

class LearningRecordVerb {

    constructor(
        public readonly id,
        public readonly caption,
        public readonly display
    ) {

    }

}

export const LearningRecordVerbs = {
    Answered: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/answered',
        trans('learning_records.verbs.answered'),
        { "en-US": "answered" }
    ),

    Asked: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/asked',
        trans('learning_records.verbs.asked'),
        { "en-US": "asked" }
    ),

    Attempted: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/attempted',
        trans('learning_records.verbs.attempted'),
        { "en-US": "attempted" }
    ),

    Completed: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/completed',
        trans('learning_records.verbs.completed'),
        { "en-US": "completed" }
    ),

    Experienced: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/experienced',
        trans('learning_records.verbs.experienced'),
        { "en-US": "experienced" }
    ),

    Failed: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/failed',
        trans('learning_records.verbs.failed'),
        { "en-US": "failed" }
    ),

    Interacted: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/interacted',
        trans('learning_records.verbs.interacted'),
        { "en-US": "interacted" }
    ),

    // Mastered: new LearningRecordVerb(
    //     'http://adlnet.gov/expapi/verbs/mastered',
    //     trans('learning_records.verbs.mastered'),
    //     { "en-US": "mastered" }
    // ),

    Passed: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/passed',
        trans('learning_records.verbs.passed'),
        { "en-US": "passed" }
    ),

    // Progressed: new LearningRecordVerb(
    //     'http://adlnet.gov/expapi/verbs/progressed',
    //     trans('learning_records.verbs.progressed'),
    //     { "en-US": "progressed" }
    // ),

    Responded: new LearningRecordVerb(
        'http://adlnet.gov/expapi/verbs/responded',
        trans('learning_records.verbs.responded'),
        { "en-US": "responded" }
    ),

    Viewed: new LearningRecordVerb(
        'http://id.tincanapi.com/verb/viewed',
        trans('learning_records.verbs.viewed'),
        { "en-US": "viewed" }
    ),
}
