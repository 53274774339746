import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route, trans} from '@/Utility/Helpers';
import Course from '@/Models/Course/Course';
import PagingMetadata from '@/Models/PagingMetadata';
import CoursePage from '@/Models/Course/CoursePage';
import Unit from '@/Models/Unit/Unit';
import type User from '@/Models/User/User';

export default class CourseService {

    static get NumberOfCoursesPerPage() {
        return 150;
    }


    /**
     * Currently loaded course page.
     */
    public coursePage = new CoursePage([], new PagingMetadata());

    public isLoading: boolean = false;
    public isSaving: boolean = false;
    public request: AxiosRequest | null = null;

    /**
     * Cancel any ongoing requests
     */
    async cancelRequests(): Promise<any> {
        // @NOTE: Only working with a single request at the moment!
        if (this.request !== null) {
            return await this.request.cancel();
        }
        return Promise.resolve('Requests canceled');
    }

    /**
     * Fetch all courses for the current user from API
     *
     * @param page current page to fetch; starting with 1
     * @param filters e.g. {policy: ["standard", "sample", "template"]}
     * @param search keywords
     * @param orderBy e.g. "title", "created_at", "updated_at"
     * @param descending
     */
    async fetchCourses(
        page: number = 1,
        filters: object | null = null,
        search: string | null = null,
        orderBy: string | null = null,
        descending: boolean = false
    ): Promise<CoursePage> {
        if (this.isLoading || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const params = {
            page: page,
            per_page: CourseService.NumberOfCoursesPerPage,
            filter: filters,
            search: search,
            sort: orderBy,
            sort_order: descending ? 'desc' : 'asc'
        };

        return this.request
            .get(route('api.courses.index'), { params: params })
            .then(({ data }) => {
                const pagingMetadata = new PagingMetadata(data.meta);

                const courses = data.data
                    .map((courseData: any) => {
                        try {
                            return this.parseCourse(courseData);
                        } catch (ex) {
                            return null;
                        }
                    })
                    .filter((course: Course | null) => course !== null);

                this.coursePage = new CoursePage(courses, pagingMetadata);
                return this.coursePage;
            })
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Fetches the course with the given uid from API.
     */
    async fetchCourse(uid: string): Promise<Course> {
        if (this.isLoading || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        return this.request
            .get(route('api.courses.details', { 'course': uid }))
            .then(({ data }) => this.parseAndReplaceCourse(data.data))
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Fetches the course with the given uid from API.
     */
    async fetchUnitsForCourse(course_uid: string, per_page: number = 15, page: number = 0): Promise<Unit[]> {
        if (this.isLoading || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const params = {
            'per_page': Math.max(1, per_page),
            'page': Math.max(0, page),
        };

        return this.request
            .get(route('api.courses.units', { 'course': course_uid }), { 'params': params })
            .then(({ data }) => {
                try {
                    return data.data
                        .map((unitData: any) => {
                            try {
                                return new Unit(unitData);
                            } catch (ex) {
                                console.warn(
                                    'CourseService->fetchUnitsForCourse(): Skipping unit with invalid or incompatible data.',
                                    unitData,
                                    ex
                                );
                                return null;
                            }
                        })
                        .filter((unit: Unit | null) => unit !== null);
                } catch (ex) {
                    console.error(
                        'CourseService->fetchUnitsForCourse(): API returned invalid or incompatible units data.',
                        data,
                        ex
                    );
                    return Promise.reject(trans('errors.course.invalid_data'));
                }
            })
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Create a course through the API
     */
    async createCourseFromFormData(formData: FormData): Promise<Course> {
        if (this.isSaving || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return this.request
            .post(
                route('api.courses.create'),
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )
            .then(({ data }) => this.parseAndReplaceCourse(data.data))
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Save a course through the API.
     * The latest draft revision is used to pull updated properties from.
     */
    async saveCourse(course: Course): Promise<Course> {
        if (this.isSaving || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        // Prepare form data if a preview file should be included:
        const propertiesToUpdate = {
            ...course.updateApiProperties,
        };
        let config = {};
        let formData: FormData | null = null;
        let method = 'patch';
        if (course.previewImageForUpload instanceof File) {
            // Send a POST request since PATCH doesn't support files:
            formData = new FormData();
            method = 'post';
            formData.append('_method', 'PATCH');
            formData.append('course', JSON.stringify(propertiesToUpdate));
            formData.append('preview_image', course.previewImageForUpload);
            config = { headers: { 'Content-Type': 'multipart/form-data' } };
        }

        return this.request[method](
            route('api.courses.update', { course: course.uid }),
            formData || propertiesToUpdate,
            config
        )
            .then(({ data }) => this.parseAndReplaceCourse(data.data))
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Delete a course through the API
     */
    async deleteCourse(course: Course): Promise<Course> {
        if (this.isSaving || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return this.request
            .delete(route('api.courses.delete', { course: course.uid }))
            .then(() => course)
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Duplicate a course through the API
     */
    async duplicateCourse(course: Course, newTitle: string, targetTenantUid: string): Promise<Course> {
        if (this.isSaving || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return this.request
            .post(route('api.courses.duplicate', { course: course.uid }), {
                title: newTitle,
                target_tenant_uid: targetTenantUid,
            })
            .then(({ data }) => this.parseCourse(data.data))
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Adds and/or removes user enrollments through the API.
     * All given users must be members of the logged-in user's current tenant.
     * On success the user enrollments of the given course will be updated.
     *
     * @param course Course that users should be enrolled in / disenrolled from.
     * @param usersToEnroll List users that should be enrolled to the course.
     * Users that are already enrolled will not be added again.
     * @param usersToDisenroll List of users that should be disenrolled from the course.
     * Users that aren't enrolled will not throw any errors.
     * @param autoEnrollment
     */
    async changeUserEnrollments(
        course: Course,
        usersToEnroll: User[],
        usersToDisenroll: User[],
        autoEnrollment: boolean | undefined = undefined
    ): Promise<Course> {
        if (this.isSaving || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        const requestData = {
            users_to_enroll: usersToEnroll.map(user => user.uid),
            users_to_disenroll: usersToDisenroll.map(user => user.uid),
        };

        if (typeof autoEnrollment === 'boolean') {
            requestData['auto_enrollment'] = autoEnrollment;
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return this.request
            .post(
                route('api.courses.enrollments.update', { course: course.uid }),
                requestData
            )
            .then(({ data }) => {
                // Reflect user changes in given course reference
                if (Object.prototype.hasOwnProperty.call(data, 'enrollments')) {
                    // The response might already have newer data because the users are cached on page load
                    course.setEnrollments(data.enrollments);
                } else {
                    // Fallback if the response does not include an updated user list
                    course.addEnrollments(usersToEnroll);
                    course.removeEnrollments(usersToDisenroll);
                }

                course.auto_enrollment = data.auto_enrollment;

                return course;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Tries to parse the given course data into a valid course.
     * Will print and throw usable errors when this fails.
     */
    private parseCourse(courseData: any): Course {
        try {
            return new Course(courseData);
        } catch (ex) {
            console.error(
                'CourseService->parseCourse(): API returned invalid or incompatible course data.',
                courseData,
                ex
            );
            throw new Error(trans('errors.course.invalid_data'));
        }
    }

    /**
     * Tries to parse the given course data into a valid course and save it to the current course page.
     * Will print and throw usable errors when this fails.
     */
    private parseAndReplaceCourse(courseData: any): Course {
        const course = this.parseCourse(courseData);
        this.coursePage.replaceCourse(course);
        return course;
    }
}
