import {cloneDeep} from 'lodash';
import AbstractDataObject from '@/Models/AbstractDataObject';
import UnitData from '@/Models/UnitData/UnitData';
import NumberVariable from '@/Models/UnitData/Variables/Variables/NumberVariable';

/* NOTE: New Operand classes have to be added to mapping at the end of this file! */

/**
 * Can be used as input parameter for variable comparisons or operations.
 *
 * @abstract
 */
export default class Operand extends AbstractDataObject
{
    static get constructorName() { return 'Operand'; }
    static get Type() {
        return 'Operand';
    }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        if (new.target === Operand) {
            throw new TypeError(`Cannot construct Operand instances directly`);
        }

        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && !(attributes instanceof Array)) ? attributes : {};

        this.type = attributes.type;
    }

    get isValid() {
        return this.type === this.constructor.Type;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        // @NOTE: Override this method on subclasses to make sure an operand only uses valid data
        let hasChanged = false;
        if (this.type !== this.constructor.Type)
        {
            console.info('Operand->cleanUpData(): Changing incorrect type.', this.type, this);
            this.type = this.constructor.Type;
            hasChanged = true;
        }
        return hasChanged;
    }

    /**
     * Create a new operand with the given type string
     *
     * @param {String} operandType
     * @param {Object} attributes
     * @param {Object} parent
     * @returns {Operand}
     */
    static createWithType(operandType, attributes = {}, parent = null) {
        const operandClass = getOperandClassFromType(operandType);

        // Merge default attributes:
        if (operandClass !== null && operandClass.defaultAttributes instanceof Object) {
            attributes = {
                ...operandClass.defaultAttributes, ...attributes
            };
        }

        // Set the operand type:
        attributes = {
            ...attributes,
            ...{
                type: operandType,
            }
        };

        return Operand.createFromAttributes(attributes, parent);
    }

    /**
     * Create a new operand from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent
     * @returns {Operand}
     */
    static createFromAttributes(attributes = {}, parent = null) {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        const className = getOperandClassFromType(clonedAttributes.type) || Operand;
        return new className(clonedAttributes, parent);
    }
}

/**
 * Operand representing a static value.
 *
 * @abstract
 */
export class ValueOperand extends Operand {

    static get Type() {
        return 'ValueOperand';
    }

    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        if (new.target === ValueOperand) {
            throw new TypeError(`Cannot construct ValueOperand instances directly`);
        }

        this.value = attributes.value !== undefined ? attributes.value : this.defaultValue;
    }

    get defaultValue() {
        return null;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (this.value === undefined)
        {
            console.info('ValueOperand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = this.defaultValue;
            return true;
        }
        return hasChanged;
    }
}

/**
 * A boolean with static (true or false) value.
 */
export class BooleanValueOperand extends ValueOperand {

    static get Type() {
        return 'bool';
    }

    get defaultValue() {
        return true;
    }

    get isValid() {
        return typeof this.value === 'boolean' && super.isValid;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (typeof this.value !== 'boolean')
        {
            console.info('BooleanValueOperand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = this.defaultValue;
            return true;
        }
        return hasChanged;
    }
}

/**
 * Represents the toggled state of a boolean value.
 */
export class ToggledValueOperand extends Operand {

    static get Type() {
        return 'toggled';
    }
}

/**
 * A number with static value.
 */
export class NumberValueOperand extends ValueOperand {

    static get Type() {
        return 'number';
    }

    get defaultValue() {
        return 0;
    }

    get isValid() {
        return typeof this.value === 'number' && super.isValid;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (isNaN(this.value))
        {
            console.info('NumberValueOperand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = this.defaultValue;
            hasChanged = true;
        }
        else if (this.value !== parseInt(this.value, 10))
        {
            console.info('NumberValueOperand->cleanUpData(): Changing float or string value to integer.', this.value, this);
            this.value = parseInt(this.value, 10);
            hasChanged = true;
        }
        if (this.value > NumberVariable.MaxValue)
        {
            console.info('NumberValueOperand->cleanUpData(): Resetting out-of-range value to maximum.', this.value, this);
            this.value = NumberVariable.MaxValue;
            hasChanged = true;
        }
        else if (this.value < NumberVariable.MinValue)
        {
            console.info('NumberValueOperand->cleanUpData(): Resetting out-of-range value to minimum.', this.value, this);
            this.value = NumberVariable.MinValue;
            hasChanged = true;
        }
        return hasChanged;
    }
}

/**
 * Represents the value initially set on a variable.
 */
export class InitialValueOperand extends Operand {

    static get Type() {
        return 'initial';
    }
}

/**
 * Represents the reference of another variable.
 */
export class ReferenceValueOperand extends Operand {

    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        this.object = attributes.object !== undefined ? attributes.object : null;
        this.variable = attributes.variable !== undefined ? attributes.variable : null;
    }

    static get Type() {
        return 'reference';
    }

    get isValid() {
        return this.object !== null && this.variable !== null && super.isValid;
    }

    /**
     * Get possible target objects from parents
     *
     * @returns {SceneObject[]}
     */
    get possibleTargets() {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('ReferenceValueOperand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any variable module from the unit with at least one variable if the operand's parent is a global object or any variable module from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(parentUnitData.allSceneObjects)).filter(o => o.hasVariables);
    }

    /**
     * Get the referenced target object
     *
     * @returns {SceneObjectModuleVariable|null}
     */
    get targetObject() {
        if ((this.object === null || (this.object === 'self' && this.isGlobal)) && this.variable !== null)
        {
            return this.possibleTargets.find(o => o.hasVariable(this.variable)) || null;
        }
        return (this.object !== null) ? this.possibleTargets.find(o => o.uid === this.object) || null : null;
    }

    /**
     * Get the referenced target variable
     *
     * @returns {Variable|null}
     */
    get targetVariable() {
        const targetObject = (this.variable !== null) ? this.targetObject : null;
        return (targetObject !== null) ? targetObject.getVariable(this.variable) : null;
    }

    /**
     * Clean up data (e.g. remove invalid target)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();

        // Find target object and variable:
        const targetObject = this.targetObject;
        const targetVariable = this.targetVariable;

        // Reassign invalid object target to variable parent object:
        if (targetVariable !== null && this.object !== targetObject.uid)
        {
            console.info('ReferenceValueOperand->cleanUpData(): Reassigning invalid target object.', this.object, this);
            this.object = targetObject.uid;
            hasChanged = true;
        }

        // Remove reference to unknown variable:
        else if ((targetObject === null && targetVariable !== null) || (targetVariable === null && this.variable !== null))
        {
            console.info('ReferenceValueOperand->cleanUpData(): Removing unknown variable reference.', this.variable, this);
            this.variable = null;
            hasChanged = true;
        }

        // Reference operands must always have an object and a variable.
        // So reset both if either one is not present.
        if ((this.variable !== null && targetVariable === null) || (this.object !== null && targetObject === null)) {
            console.info('ReferenceValueOperand->cleanUpData(): Removing unknown object and variable references.', this.object, this.variable, this);
            this.object = null;
            this.variable = null;
            hasChanged = true;
        }

        return hasChanged;
    }
}

/**
 * OperandType to Operand subclass mapping
 * @type {Map<string, Operand>}
 */
export const operandMapping = new Map([
    [BooleanValueOperand.Type, BooleanValueOperand],
    [ToggledValueOperand.Type, ToggledValueOperand],
    [NumberValueOperand.Type, NumberValueOperand],
    [InitialValueOperand.Type, InitialValueOperand],
    [ReferenceValueOperand.Type, ReferenceValueOperand],
]);

/**
 * @param {String} type operand type
 * @returns {Operand} Operand subclass mapped to the given type.
 */
export function getOperandClassFromType(type) {
    if (!operandMapping.has(type)) {
        throw new Error(`Operand type "${type}" not mapped to its class!`);
    }

    return operandMapping.get(type);
}
