<template>
    <ButtonCustom
        class="btn-circular"
    />
</template>

<script>
    import ButtonCustom from "@/Vue/Common/ButtonCustom.vue";

    export default {
        name: 'ButtonCircular',
        components: {
            ButtonCustom
        },
    }
</script>

<style lang="scss" scoped>

</style>
