import { isUUID, uuid4 } from '@/Utility/Helpers';
import Waypoint from '@/Models/Unity/Waypoint';

export default class WaypointCollection
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.global = (attributes.global || []).map(w => new Waypoint(w));  // List of global waypoints

        // Map scene waypoints:
        for (const uuid in attributes)
        {
            if (attributes.hasOwnProperty(uuid) && isUUID(uuid) === true)
            {
                this[uuid] = attributes[uuid].map(w => new Waypoint(w));
            }
        }
    }

    /**
     * Helper methods
     */
    get waypointsCount()    {let count = this.global.length; Object.keys(this).filter(k => isUUID(k) && this[k].length >= 1).forEach(uuid => count += this[uuid].length); return count;}
    get hasWaypoints()      {return (this.waypointsCount >= 1);}
    get groups() {
        const groups = {
            global: this.global
        };
        for (const uuid in this)
        {
            if (this.hasOwnProperty(uuid) && isUUID(uuid) === true)
            {
                groups[uuid] = this[uuid];
            }
        }
        return groups;
    }

    /**
     * Check if this collection has a specific waypoint in the "global" group
     *
     * @param {String} uid
     * @returns {Boolean}
     */
    hasGlobalWaypointWithUid(uid) {
        return this.global.some(w => w.uid === uid);
    }

    /**
     * Check if this collection has a specific waypoint
     *
     * @param {String} uid
     * @returns {Boolean}
     */
    hasWaypointWithUid(uid) {
        return Object.values(this.groups).some(g => g.some(w => w.uid === uid));
    }

    /**
     * Check if this collection has a specific waypoint for a specific scene
     *
     * @param {String} uid
     * @param {TrainingScene} scene
     * @returns {Boolean}
     */
    hasWaypointWithUidForScene(uid, scene) {
        return (scene instanceof Object && scene.uid && this.hasOwnProperty(scene.uid) && this[scene.uid].some(w => w.uid === uid));
    }

    /**
     * Check if this collection has waypoints for a specific scene
     *
     * @param {TrainingScene} scene
     * @returns {Boolean}
     */
    hasWaypointsForScene(scene) {
        return (this[scene.uid] instanceof Array && this[scene.uid].length >= 1);
    }

    /**
     * Duplicate
     *
     * @returns {WaypointCollection}
     */
    duplicate()
    {
        const duplicated = new WaypointCollection(this);
        duplicated.global.forEach(w => w.uid = uuid4());
        for (const uuid in duplicated)
        {
            if (duplicated.hasOwnProperty(uuid) && isUUID(uuid) === true)
            {
                duplicated[uuid].forEach(w => w.uid = uuid4());
            }
        }
        return duplicated;
    }

    /**
     * Duplicate waypoints for a new scene
     *
     * @param {TrainingScene} scene
     * @param {TrainingScene} newScene
     */
    duplicateWaypointsForNewScene(scene, newScene) {
        this[newScene.uid] = (this[scene.uid] instanceof Array) ? this[scene.uid].map(w => w.duplicate()) : [];
        return this;
    }
}
