import {Permission} from '@/Models/User/Permission';
import FilterCategory from "@/Filters/FilterCategory";
import Asset from "@/Models/Asset/Asset";
import {permission, trans} from "@/Utility/Helpers";
import {Feature} from "@/Models/Features/Feature";
import {inject} from "vue";
import {featureRepositoryKey} from "@/Vue/Bootstrap/InjectionKeys";

export default class AssetPolicy {
    static get featureRepository() {
        return inject(featureRepositoryKey);
    }

    /**
     * Returns a list of all asset policies
     *
     * @returns {AssetPolicy[]}
     */
    static get all() {
        return [...StaticAssetPolicyInstances.values()];
    }

    /**
     * @param  {User}  user
     * @return {AssetPolicy[]}
     */
    static allPoliciesUserIsAllowedToCreate(user) {
        return AssetPolicy.all.filter(policy => user.permissions.find(permission => permission === policy.createPermission));
    }

    /**
     * @param  {User}  user
     * @param  {Tenant}  tenant
     * @return {AssetPolicy[]}
     */
    static allPoliciesUserIsAllowedToCreateInTenant(user, tenant) {
        return AssetPolicy
            .allPoliciesUserIsAllowedToCreate(user)
            .filter(policy => {
                return tenant.is_default_asset_tenant || !policy.hasToExistInsideAssetDefaultTenant;
            });
    }

    /**
     * Searches for an AssetPolicy with the desired type and returns its static instance.
     *
     * @param type
     * @returns {AssetPolicy}
     */
    static getAssetPolicyForType(type) {
        return StaticAssetPolicyInstances.get(type);
    }

    static get type() {
        return 'undefined';
    }

    /**
     * @return {string}
     */
    get type() {
        return this.constructor.type;
    }

    /**
     * @return {string}
     */
    get createPermission() {
        throw new Error("Please override createPermission inside child class.");
    }

    /**
     * @return {string}
     */
    get updatePermission() {
        throw new Error("Please override updatePermission inside child class.");
    }

    /**
     * @return {string}
     */
    get deletePermission() {
        throw new Error("Please override deletePermission inside child class.");
    }

    /**
     * @return {string}
     */
    get archivePermission() {
        throw new Error("Please override archivePermission inside child class.");
    }

    /**
     * @return {string}
     */
    get restoreArchivedPermission() {
        throw new Error("Please override restoreArchivedPermission inside child class.");
    }

    get hasToExistInsideAssetDefaultTenant() {
        return true;
    }

    get canBeIndexedByForeignTenants() {
        return false;
    }

    get filterCategory() {
        return new FilterCategory({
            title: trans('asset_policies.' + this.type),
            callback: (elements) => elements.filter(e => e instanceof Asset && e.policy === this.type),
            paramName: this.type,
        });
    }
}

export class AssetPolicyStandard extends AssetPolicy {

    static get type() {
        return 'standard';
    }

    get createPermission() {
        return Permission.AssetsCreatePolicyStandard();
    }

    get updatePermission() {
        return Permission.AssetsUpdatePolicyStandard();
    }

    get deletePermission() {
        return Permission.AssetsDeletePolicyStandard();
    }

    get archivePermission() {
        return Permission.AssetsArchivePolicyStandard();
    }

    get restoreArchivedPermission() {
        return Permission.AssetsRestoreArchivedPolicyStandard();
    }

    get hasToExistInsideAssetDefaultTenant() {
        return false;
    }
}

export class AssetPolicySample extends AssetPolicy {

    static get type() {
        return 'sample';
    }

    get createPermission() {
        return Permission.AssetsCreatePolicySample();
    }

    get updatePermission() {
        return Permission.AssetsUpdatePolicySample();
    }

    get deletePermission() {
        return Permission.AssetsDeletePolicySample();
    }

    get archivePermission() {
        return Permission.AssetsArchivePolicySample();
    }

    get restoreArchivedPermission() {
        return Permission.AssetsRestoreArchivedPolicySample();
    }

    get canBeIndexedByForeignTenants() {
        return true;
    }
}

export class AssetPolicyUseOnly extends AssetPolicy {

    static get type() {
        return 'use_only';
    }

    get createPermission() {
        return Permission.AssetsCreatePolicyUseOnly();
    }

    get updatePermission() {
        return Permission.AssetsUpdatePolicyUseOnly();
    }

    get deletePermission() {
        return Permission.AssetsDeletePolicyUseOnly();
    }

    get archivePermission() {
        return Permission.AssetsArchivePolicyUseOnly();
    }

    get restoreArchivedPermission() {
        return Permission.AssetsRestoreArchivedPolicyUseOnly();
    }

    get canBeIndexedByForeignTenants() {
        return true;
    }
}

export class AssetPolicyTemplate extends AssetPolicy {

    static get type() {
        return 'template';
    }

    get createPermission() {
        return Permission.AssetsCreatePolicyTemplate();
    }

    get updatePermission() {
        return Permission.AssetsUpdatePolicyTemplate();
    }

    get deletePermission() {
        return Permission.AssetsDeletePolicyTemplate();
    }

    get archivePermission() {
        return Permission.AssetsArchivePolicyTemplate();
    }

    get restoreArchivedPermission() {
        return Permission.AssetsRestoreArchivedPolicyTemplate();
    }
}

/**
 * Map of AssetPolicies and their types
 *
 * @type {Map<string, AssetPolicy>}
 */
const StaticAssetPolicyInstances = new Map([
    [AssetPolicyStandard.type, Object.freeze(new AssetPolicyStandard())],
    [AssetPolicyUseOnly.type, Object.freeze(new AssetPolicyUseOnly())],
    [AssetPolicySample.type, Object.freeze(new AssetPolicySample())],
    [AssetPolicyTemplate.type, Object.freeze(new AssetPolicyTemplate())],
]);
