export default class HintObjectiveAssignment
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.objective = attributes.objective || null;                                  // UID of the objective trigger that should use the cue trigger
        this.cue = attributes.cue || null;                                              // UID of the cue trigger that executes the commands to give the hint to the user
    }

    /**
     * Duplicate
     *
     * @returns {HintObjectiveAssignment}
     */
    duplicate()
    {
        return new HintObjectiveAssignment(this);
    }
}
