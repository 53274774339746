import KeyboardKey from '@/Utility/KeyboardKey';
import type {DirectiveBinding, VNode} from 'vue';
import {getSelectedText, trans} from '@/Utility/Helpers';

/**
 * Operating system flags
 */
const isMac = navigator.platform.toLowerCase().indexOf('mac') === 0;

/**
 * Key aliases
 */
const KeyboardKeyAliases: Map<string, string> = new Map([

    ['LeftAlt', 'Alt'],
    ['RightAlt', 'Alt'],

    ['LeftApple', 'OS'],
    ['RightApple', 'OS'],

    ['LeftCommand', 'OS'],
    ['RightCommand', 'OS'],

    ['LeftCtrl', 'Ctrl'],
    ['RightCtrl', 'Ctrl'],

    ['LeftMeta', 'OS'],
    ['RightMeta', 'OS'],

    ['LeftShift', 'Shift'],
    ['RightShift', 'Shift'],

    ['LeftWindows', 'OS'],
    ['RightWindows', 'OS'],

    ['OS+C', 'Copy'],
    ['Ctrl+C', 'Copy'],

    ['OS+D', 'Duplicate'],
    ['Ctrl+D', 'Duplicate'],

    ['OS+X', 'Cut'],
    ['Ctrl+X', 'Cut'],

    ['OS+V', 'Paste'],
    ['Ctrl+V', 'Paste'],

    ['OS+S', 'Save'],
    ['Ctrl+S', 'Save'],

    ['OS+A', 'SelectAll'],
    ['Ctrl+A', 'SelectAll'],

    ['OS+O', 'Open'],
    ['Ctrl+O', 'Open'],

    ['OS+P', 'Publish'],
    ['Ctrl+P', 'Publish'],

    ['Ctrl+Shift+V', 'Preview'],
    ['Shift+Ctrl+V', 'Preview'],
    ['OS+Shift+V', 'Preview'],
    ['Shift+OS+V', 'Preview'],

    ['OS+H', 'Replace'],
    ['Ctrl+H', 'Replace'],

    ['OS+Backspace', 'Delete'],
    ['Ctrl+Backspace', 'Delete'],

    ['F5', 'Reload'],
    ['OS+R', 'Reload'],
    ['OS+Shift+R', 'Reload'],
    ['Shift+OS+R', 'Reload'],
    ['Ctrl+R', 'Reload'],
    ['Ctrl+Shift+R', 'Reload'],
    ['Shift+Ctrl+R', 'Reload'],

    ['OS+Alt+I', 'DevTools'],
    ['Alt+OS+I', 'DevTools'],
    ['Ctrl+Shift+I', 'DevTools'],
]);

/**
 * List of key enums currently being pressed by the user
 */
let keysPressed: string[] = [];

/**
 * macOS keyup workaround timers
 */
const keyupTimers: Map<any, any> = new Map();

/**
 * Remove a pressed key from the list, simulating a (fake) keyup event
 *
 * @NOTE: On macOS the keyup event will not be triggered for any other keys if the command (meta) key is being held down!
 */
const removePressedKeyAfterDelay = (keyEnum: string): void => {
    if (keyupTimers.has(keyEnum)) {
        window.clearTimeout(keyupTimers.get(keyEnum));
    }
    keyupTimers.set(keyEnum, window.setTimeout((): void => {
        keysPressed = keysPressed.filter((k: string): boolean => k !== keyEnum);
        window.clearTimeout(keyupTimers.get(keyEnum));
        keyupTimers.delete(keyEnum);
    }, 100));
};

/**
 * Shortcut listener class for keeping track of all listeners
 */
class ShortcutListener {
    public el: Element;
    public shortcut: string;
    public callback: (e: CustomEvent) => void;
    public vnode: VNode;
    public binding: DirectiveBinding;
    public prevent: boolean;
    public stop: boolean;
    public global: boolean;
    public containsTarget: boolean|undefined;

    constructor(
        el: Element,                // Reference to the DOM element that registered the listener
        shortcut: string,           // Name of the shortcut, e.g. 'ctrl+shift+c', 'copy'
        callback: () => void,       // Reference to the callback function
        vnode: VNode,               // Reference to the vnode of the component that registered the listener
        binding: DirectiveBinding   // Reference to the binding of the component that registered the listener
    ) {
        const shortcutLowerCase: string = shortcut.toLowerCase();

        // Populate the model:
        this.el = el;
        this.shortcut = shortcutLowerCase.replace(/\.prevent|\.stop|\.global/gi, '');  // Only use lowercase name without modifiers
        this.callback = callback;
        this.vnode = vnode;
        this.binding = binding;
        this.prevent = binding.modifiers.prevent || shortcutLowerCase.includes('.prevent');
        this.stop = binding.modifiers.stop || shortcutLowerCase.includes('.stop');
        this.global = binding.modifiers.global || shortcutLowerCase.includes('.global');
    }

    /**
     * Handle the shortcut event
     */
    handleEvent(e: CustomEvent): boolean {
        // Add a reference to the target element that the shortcut was registered on:
        e.detail.currentTarget = this.el;

        // Add a reference to the vnode for the component that registered the shortcut:
        e.detail.vnode = this.vnode;

        // Add a reference to the binding for the component that registered the shortcut:
        e.detail.binding = this.binding;

        if (this.prevent) {
            e.preventDefault();
            (e.detail.keyboardEvent || e.detail.clipboardEvent).preventDefault();
            e.returnValue = false;
            (e.detail.keyboardEvent || e.detail.clipboardEvent).returnValue = false;
        }

        if (this.stop) {
            e.stopPropagation();
            (e.detail.keyboardEvent || e.detail.clipboardEvent).stopPropagation();
            e.stopImmediatePropagation();
            (e.detail.keyboardEvent || e.detail.clipboardEvent).stopImmediatePropagation();
        }

        this.callback?.call(this.binding.instance, e);

        return !e.defaultPrevented;
    }
}

/**
 * List of ShortcutListeners for all Vue components with the order in which they were registered
 */
let shortcutListeners: ShortcutListener[] = [];

/**
 * Sort shortcuts with "Any" being first because specific shortcuts should always be triggered before the "Any" shortcut
 */
const sortWithAnyFirst = (a: any, b: any): number => a[0].toLowerCase().startsWith('any') ? -1 : b[0].toLowerCase().startsWith('any') ? 1 : 0;

/**
 * Create the shortcut listeners for an element
 */
const createShortcutListeners = (
    el: Element,                // Reference to the DOM element that registered the listener
    vnode: VNode,               // Reference to the vnode of the component that registered the listener
    binding: DirectiveBinding   // Reference to the binding of the component that registered the listener
) => {

    // Get the shortcut mapping from binding.value or binding.value.shortcuts or data() of the component:
    const shortcutMap = (binding.value instanceof Map)
        ? binding.value
        : (
            (
                binding.value instanceof Object
                && binding.value.shortcuts instanceof Map
            )
                ? binding.value.shortcuts
                : ((binding.instance as any)?.shortcuts || null)
        );
    if (!(shortcutMap instanceof Map) || shortcutMap.size === 0) {
        console.warn(
            `KeyboardShortcuts->createShortcutListeners(): Invalid shortcut parameters on component ${binding.instance?.$options.name}. Must be data of type Map<String, Function>.`,
            shortcutMap
        );
        return;
    }

    // Maintain order like in the DOM:
    const insertIndex: number = shortcutListeners.findIndex(l => Boolean(el.compareDocumentPosition(l.el) & Node.DOCUMENT_POSITION_FOLLOWING));

    // Register events (always register "Any" first to make sure specific shortcuts are later being triggered before the "Any" shortcut):
    const newListeners: ShortcutListener[] = [...shortcutMap]
        .sort(sortWithAnyFirst)
        .map((s: [string, () => void]) => new ShortcutListener(el, s[0], s[1], vnode, binding));
    shortcutListeners.splice(insertIndex >= 0 ? insertIndex : shortcutListeners.length, 0, ...newListeners);
};

/**
 * Dispatch a custom shortcut event on the currently active DOM element
 */
const dispatchShortcutEvent = (shortcut: string, originalEvent: KeyboardEvent|ClipboardEvent): void => {
    const shortcutEvent: CustomEvent<{
        shortcut: string;
        clipboardEvent: ClipboardEvent | null;
        keyboardEvent: KeyboardEvent | null,
    }> = new CustomEvent('shortcut', {
        bubbles: true,
        cancelable: true,
        detail: {
            keyboardEvent: (originalEvent instanceof KeyboardEvent) ? originalEvent : null,
            clipboardEvent: (originalEvent instanceof ClipboardEvent) ? originalEvent : null,
            shortcut: shortcut
        }
    });
    // Inject custom methods:
    shortcutEvent['stopShortcutPropagation'] = function(): void {
        this.preventDefault();
        this.stopPropagation();
        this.stopImmediatePropagation();
        if (this.detail.keyboardEvent) {
            this.detail.keyboardEvent.preventDefault();
            this.detail.keyboardEvent.stopPropagation();
            this.detail.keyboardEvent.stopImmediatePropagation();
        }
        if (this.detail.clipboardEvent) {
            this.detail.clipboardEvent.preventDefault();
            this.detail.clipboardEvent.stopPropagation();
            this.detail.clipboardEvent.stopImmediatePropagation();
        }
    };
    document.activeElement?.dispatchEvent(shortcutEvent);
};

/**
 * Global keyboard shortcut handling
 */
export default {

    /**
     * Resets the internal state like pressed keys.
     * Use only for testing purposes!
     */
    resetForTests(): void {
        shortcutListeners = [];
        keysPressed = [];
        keyupTimers.clear();
    },

    /**
     * Install
     *
     */
    install(app: any): void {
        // Register global events:
        window.addEventListener('blur', this.onWindowBlur);
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
        document.addEventListener('copy', this.onClipboardEvent);
        document.addEventListener('cut', this.onClipboardEvent);
        document.addEventListener('paste', this.onClipboardEvent);
        document.addEventListener('shortcut', this.onShortcut as unknown as EventListener);

        // Create the Vue.js directive:
        app.directive(
            'shortcuts',
            {
                mounted: (el: Element, binding: DirectiveBinding, vnode: VNode): void => {

                    // Create shortcut listeners:
                    createShortcutListeners(el, vnode, binding);
                    //console.log('inserted', shortcutListeners.map(l => l.vnode.context.$options.name + ' ' + l.shortcut));
                },
                unmounted: (el: Element): void => {

                    // Remove all listeners for this element:
                    shortcutListeners = shortcutListeners.filter(l => !Object.is(l.el, el));
                    //console.log('unbind', shortcutListeners.map(l => l.vnode.context.$options.name + ' ' + l.shortcut));
                }
            }
        );
    },

    /**
     * Blur handler for the global window
     */
    onWindowBlur(): void {
        // Cancel all shortcuts since we cannot detect keyup outside the browser window:
        keysPressed = [];
    },

    /**
     * Keydown event
     */
    onKeyDown(e: KeyboardEvent): boolean {
        const key: KeyboardKey|null = KeyboardKey.findByEvent(e);
        if (key === null) {
            return !e.defaultPrevented;
        }
        // Map aliases and store the pressed key if it's not being pressed already:
        const keyEnum: string = KeyboardKeyAliases.get(key.enum) || key.enum;
        if (keysPressed.includes(keyEnum)) {
            // Workaround for macOS only sending a keyup event for the command (meta) key if it is being used in a shortcut:
            if (isMac && e.metaKey && ![
                'OS',
                'Shift',
                'Apple',
                'Command',
                'Ctrl',
                'Meta',
                'Alt',
                'AltGr',
                'CapsLock'
            ].includes(keyEnum)) {
                removePressedKeyAfterDelay(keyEnum);
            }

            // Prevent shortcut spamming:
            if ([
                'Tab',
                'Enter',
                'Escape',
                'OS',
                'Alt',
                'Apple',
                'Command',
                'Ctrl',
                'Meta',
                'Shift',
                'Windows'
            ].some(k => keysPressed.includes(k))) {
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();
            }

            return !e.defaultPrevented;
        }
        keysPressed[keysPressed.length] = keyEnum;

        // Map shortcut name and convert it to lowercase:
        let shortcut: string = keysPressed.join('+');
        shortcut = KeyboardKeyAliases.get(shortcut) || shortcut;
        shortcut = shortcut.toLowerCase();

        // Ignore copy, paste and cut since they're handled by the clipboard events:
        if (['copy', 'cut', 'paste'].includes(shortcut)) {
            return !e.defaultPrevented;
        }

        // Dispatch a custom event if one was registered:
        if (shortcutListeners.filter(l => l.shortcut === shortcut || l.shortcut === 'any').length === 0) {
            return !e.defaultPrevented;
        }
        dispatchShortcutEvent(shortcut, e);

        // Workaround for Mac only sending a keyup event for the command (meta) key if it is being used in a shortcut:
        if (isMac && e.metaKey && ![
            'OS',
            'Shift',
            'Apple',
            'Command',
            'Ctrl',
            'Meta',
            'Alt',
            'AltGr',
            'CapsLock'
        ].includes(keyEnum)) {
            removePressedKeyAfterDelay(keyEnum);
        }

        return !e.defaultPrevented;
    },

    /**
     * Keyup event
     *
     * @NOTE: On macOS the keyup event will not be triggered for any other keys if the command (meta) key is being held down!
     */
    onKeyUp(e: KeyboardEvent): boolean {
        const key: KeyboardKey|null = KeyboardKey.findByEvent(e);
        if (key === null) {
            return !e.defaultPrevented;
        }
        // Remove key from list of pressed keys:
        const keyEnum: string = KeyboardKeyAliases.get(key.enum) || key.enum;
        keysPressed = keysPressed.filter((k: string): boolean => k !== keyEnum);

        // Workaround for macOS only sending a keyup event for the command (meta) key if it is being used in a shortcut:
        if (isMac && keyEnum === 'OS') {
            for (const [_, v] of keyupTimers) {
                if (v !== null) {
                    window.clearTimeout(v);
                }
            }
            keyupTimers.clear();
            keysPressed = [];
        }

        return !e.defaultPrevented;
    },

    /**
     * Handler for clipboard events
     */
    onClipboardEvent(e: ClipboardEvent): boolean {

        // Ignore any events on the clipboard helper textarea:
        if ((e.target as Element)?.matches('textarea#clipboard-copy-helper')) {
            return !e.defaultPrevented;
        }

        // Use default browser behaviour if any text is selected (does not work on number inputs):
        let selectedText: string = getSelectedText().replace(/(?:\r\n|\r|\n)/g, '').trim();
        if (selectedText.length > 0) {

            // Show toast notification:
            if (e.type === 'copy') {
                if (selectedText.length > 30) {
                    selectedText = selectedText.substring(0, 30) + '...';
                }
                window.toast(
                    trans(
                        'labels.copied_to_clipboard',
                        {
                            placeholder: `${trans('labels.text')} "${selectedText}"`
                        }, false
                    ), { timeout: 1500, closeButton: false });
            }

            return !e.defaultPrevented;
        }

        if (!['copy', 'cut', 'paste'].includes(e.type)) {
            return !e.defaultPrevented;
        }

        const shortcut: string = e.type;

        // Dispatch a custom event if one was registered:
        if (shortcutListeners.filter(l => l.shortcut === shortcut || l.shortcut === 'any').length === 0) {
            return !e.defaultPrevented;
        }
        dispatchShortcutEvent(shortcut, e);

        return !e.defaultPrevented;
    },

    /**
     * Shortcut event
     */
    onShortcut(e: CustomEvent): boolean {
        // Do nothing if the event was stopped:
        if (e.cancelBubble) {
            return !e.defaultPrevented;
        }

        // Call the listeners in reversed order so the listener that was registered last gets triggered first!
        // @NOTE: Only using global listeners or those from within the element that registered a shortcut
        let listeners: ShortcutListener[] = shortcutListeners.filter(l => {
            delete l.containsTarget;
            if (l.shortcut === 'any' || l.shortcut === e.detail['shortcut']) {
                // Add a temporary attribute, so we don't have to filter again for sorting:
                l.containsTarget = l.el.contains(e.target as Node);
                return (l.global || l.containsTarget);
            }
            return false;
        }).reverse();

        // Sort any listeners from the target element first (before any other global listeners):
        listeners = listeners.filter(l => l.containsTarget).concat(listeners.filter(l => !l.containsTarget));
        const mapByElement: Map<any, any> = new Map();
        for (const listener of listeners) {
            // Skip "Any" shortcuts if another shortcut on the element has been triggered already:
            if (mapByElement.has(listener.el)) {
                continue;
            }
            mapByElement.set(listener.el, true);
            listener.handleEvent(e);
            if (listener.stop || e.cancelBubble) {
                break;
            }
        }

        return !e.defaultPrevented;
    }
};
