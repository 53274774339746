<template>
    <tr>
        <td class="column-name-selection">
            <Dropdown
                :options="options"
                :required="true"
                :model="this"
                property="selectedName"
            />
        </td>
        <td colspan="2">
            <ButtonPrimary
                :caption="trans('labels.add_feature')"
                :disabled="!canSubmit"
                @trigger="onAddClicked"
            />
        </td>
    </tr>

</template>

<script>
import DropdownOption from "@/Utility/DropdownOption";

export default {
    name: "AddLocalFeatureForm",

    emits: [
        'click-add',
    ],

    props: {
        featureNames: {
            type: Array,
            default: []
        },
    },

    data() {
        return {
            /**
             @type {String}
             */
            selectedName: null,
        }
    },

    computed: {

        /**
         * @returns {DropdownOption[]}
         */
        options() {
            return this.featureNames.map(name => {
                return new DropdownOption({
                    value: name,
                    caption: name,
                })
            });
        },

        /**
         * @returns {boolean}
         */
        canSubmit() {
            return this.selectedName !== null;
        },
    },

    methods: {

        onAddClicked() {
            this.$emit('click-add', this.selectedName);
            this.selectedName = null;
        },

    }
}
</script>

<style lang="scss" scoped>

td {
    padding-top: 16px;
    padding-right: 8px;
}

.btn {
    width: 100%;
}

</style>
