import Tenant from '@/Models/Tenant/Tenant';
import {TenantRole} from "@/Models/Tenant/TenantRole";

export default class TenantWithDetails extends Tenant {

    /**
     * Role of the current user for this tenant
     */
    public readonly tenant_role: TenantRole | null;

    public readonly unit_count: number | null;
    public readonly user_count: number | null;

    constructor(attributes: any) {
        super(attributes);

        this.tenant_role = (attributes.tenant_role instanceof Object) ? new TenantRole(attributes.tenant_role) : null;

        this.unit_count = isNaN(attributes.unit_count) ? null : attributes.unit_count;
        this.user_count = isNaN(attributes.user_count) ? null : attributes.user_count;
    }

}
