import type AbstractDataObject from '@/Models/AbstractDataObject';
import type Variable from '@/Models/UnitData/Variables/Variables/Variable';
import BoolVariable from '@/Models/UnitData/Variables/Variables/BoolVariable';
import NumberVariable from '@/Models/UnitData/Variables/Variables/NumberVariable';

export default class VariableFactory {

    /* NOTE: New Variable classes have to be added here, so they can be parsed correctly! */
    static Classes = [
        BoolVariable,
        NumberVariable,
    ];

    /**
     * Get the Variable class for the specified type
     */
    static getVariableClassFromType(type: string) {
        return this.Classes.find(variableType => variableType.Type === type) || null;
    }

    /**
     * Get a proper Variable object for attributes
     */
    static getVariableForData(attributes: any, parent: AbstractDataObject | null = null): Variable | null {
        if (!(attributes instanceof Object)) {
            return null;
        }

        const className = this.getVariableClassFromType(attributes.type);
        return className === null ? null : new className(attributes, parent);
    }

    /**
     * Create a new variable with the given variableType and filled with default values.
     */
    static createDefaultVariableWithType(
        variableType: string,
        parent: AbstractDataObject | null = null
    ): Variable | null {
        return this.getVariableForData({ type: variableType }, parent);
    }

}
