<template>
    <div class="variable-properties">
        <div class="variable-property-dropdown number-input-wrapper">
            <NumberInput
                :maxValue="maxValue"
                :minValue="minValue"
                :model="operand"
                property="value"
                :placeholder="trans('labels.number')"
                :precision="0"
                :step="1"
                @change="onChangeOperation"
            />
        </div>
    </div>
</template>

<script>
    import Operand from '@/Models/UnitData/Variables/Operand';
    import NumberVariable from '@/Models/UnitData/Variables/Variables/NumberVariable';

    /**
     * Component to select an operand with number value.
     * At the moment only NumberValueOperand is supported.
     */
    export default {
        name: 'PanelVariableOperandNumber',
        emits: [
            'change',
        ],
        props: {
            operand: {
                type: Operand,
                default: null
            },
            minValue: {
                type: Number,
                default: NumberVariable.MinValue
            },
            maxValue: {
                type: Number,
                default: NumberVariable.MaxValue
            }
        },

        methods: {
            /**
             *  Change handler for the operation
             */
            onChangeOperation(value) {
                this.operand.value = value;
                this.$emit('change', this.operand);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
