<template>
    <StatisticsCard class="statistics-card-count" :is-loading="isLoading">
        <Icon :name="icon"/>
        <div class="texts">
            <span class="title-text">{{ title }}</span>
            <span class="count-text">{{ count }}</span>
        </div>
    </StatisticsCard>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
import Icon from "@/Vue/Common/Icon.vue";

export default defineComponent({
    name: "StatisticsCardCount",

    components: {
        Icon,
        StatisticsCard
    },

    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        count: {
            type: [Number, String],
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },
});

</script>

<style lang="scss" scoped>

.statistics-card-count {
    display: flex;
    padding: 28px;
    gap: 16px;
    align-items: center;
    justify-content: center;

    .icon {
        background-color: var(--color-anthracite);
        border-radius: 50%;
        padding: 16px;
        width: 64px;
        height: 64px;
        flex-basis: 64px;
        color: white;
    }

    .title-text {
        display: block;
        font-family: var(--font-family-condensed-demibold);
        font-size: 14px;
        line-height: 18px;
        color: var(--color-anthracite40);
        text-transform: uppercase;
    }

    .count-text {
        display: block;
        font-family: var(--font-family-condensed-demibold);
        font-size: 28px;
        line-height: 34px;
        color: var(--color-anthracite);
        white-space: nowrap;
    }
}

</style>
