import KeyboardShortcuts from '@/Vue/Plugins/KeyboardShortcuts';
import FocusAndSelect from '@/Vue/Plugins/FocusAndSelect';
import Tooltips from '@/Vue/Plugins/Tooltips';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

export function registerPlugins(app) {
    // Register global Vue plugins:
    KeyboardShortcuts.install(app);
    FocusAndSelect.install(app);
    Tooltips.install(app);

    // Toast notifications
    // @see https://github.com/Maronato/vue-toastification
    app.use(Toast, {
        maxToasts: 7,
        position: 'bottom-center',
        hideProgressBar: true,
    });
}
