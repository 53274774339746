export default class Tags
{
    /**
     * Get all tags
     *
     * @returns {string[]}
     */
    static get all() {
        return Object.getOwnPropertyNames(this).filter(prop => ['all', 'prototype', 'length', 'name'].indexOf(prop) === -1).map(t => Tags[t]);
    }

    /**
     * Predefined tags
     *
     * @var {String}
     */
    static get HandAny()                    { return 'hand any'; }
    static get HandLeft()                   { return 'hand left'; }
    static get HandRight()                  { return 'hand right'; }
    static get User()                       { return 'user'; }

    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === Tags)
        {
            throw new TypeError('Static class "Tags" cannot be instantiated directly.');
        }
    }
}
