<template>

    <div class="property property-users">
        <header>{{ trans('labels.assigned_for_editing') }}</header>

        <p v-if="unitIsSample" class="info-demo-users">{{ trans('units.index.assigned_authors_demo') }}</p>
        <p v-else-if="unitIsTemplate" class="info-demo-users">{{ trans('units.index.assigned_authors_template') }}</p>

        <p v-else-if="!hasAssignedAuthors" class="info-no-authors">{{ trans('units.index.no_authors_assigned') }}</p>

        <ul v-else>
            <li v-for="(user, index) in assignedAuthors"
                :key="'assignedAuthor'+index+'_'+unit.uid+'_'+user.uid"
                class="assigned-user">
                <span class="image">
                    <img :src="user.image" alt=""/>
                </span>
                <section class="info">
                    <h4 class="name">{{ user.firstname }} {{ user.lastname }}</h4>
                    <p class="email">{{ user.email }}</p>
                </section>
                <span v-if="canUnassignAuthorsFromUnits" class="remove-btn">
                    <Icon class="icon-delete" name="icon_delete" @click="onClickRemoveAuthor(user)"/>
                </span>

                <!-- Delete confirm overlay -->
                <OverlayConfirmRemove
                    v-if="isRemoveConfirmDialogVisible[user.uid] === true"
                    @cancel="onCancelAuthorRemove(user)"
                    @confirm="onConfirmAuthorRemove(user)"
                />
            </li>
        </ul>

        <div
            v-if="!unitIsSample && !unitIsTemplate && (shouldShowAssignAuthorsButton || shouldShowUnassignAuthorsButton)"
            class="buttons"
        >
            <ButtonPrimary
                v-if="shouldShowAssignAuthorsButton"
                caption="labels.assign_authors"
                class="assign-users"
                icon="icon_add"
                @trigger="onClickBtnAssignAuthor"
            />

            <ButtonSecondary
                v-if="shouldShowUnassignAuthorsButton"
                caption="labels.remove_all"
                class="assign-users"
                @trigger="onClickBtnRemoveAllAuthors"
            />
        </div>

    </div>
</template>

<script>

    // Import VueJS components:
    import OverlayConfirmRemove from '@/Vue/Inspector/OverlayConfirmRemove.vue';

    // Import classes:
    import {Permission} from '@/Models/User/Permission';
    import EventType from '@/Utility/EventType';
    import Unit from '@/Models/Unit/Unit';
    import {UnitPermissionPolicySample, UnitPermissionPolicyTemplate} from '@/Models/Unit/UnitPermissionPolicy';
    import {inject} from "vue";
    import {unitServiceKey, userServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import {trans} from "@/Utility/Helpers";

    export default {
        name: 'PanelAuthorAssignment',
        components: {
            OverlayConfirmRemove,
        },
        props: {
            unit: {
                type: Unit,
                default: null
            },
        },
        data() {
            return {
                userService: inject(userServiceKey),
                unitService: inject(unitServiceKey),

                // Whether the remove confirmation dialog is visible for a specific user
                isRemoveConfirmDialogVisible: {},
            }
        },
        computed: {

            canAssignAuthors() {
                return this.$gate.allows(Permission.ability(Permission.UnitsAssignAuthors()), this.unit);
            },

            unitIsSample() {
                return UnitPermissionPolicySample.type === this.unit.policy;
            },

            unitIsTemplate() {
                return UnitPermissionPolicyTemplate.type === this.unit.policy;
            },

            canUnassignAuthorsFromUnits() {
                return this.canAssignAuthors;
            },

            shouldShowAssignAuthorsButton() {
                return this.canAssignAuthors && this.unassignedAuthors.length >= 1
            },

            shouldShowUnassignAuthorsButton() {
                return this.canUnassignAuthorsFromUnits && this.hasAssignedAuthors;
            },

            /**
             * Does the unit have any assigned authors?
             *
             * @returns {Boolean}
             */
            hasAssignedAuthors() {
                return (this.unit.authors.length >= 1);
            },

            /**
             * Get assigned authors for the unit
             *
             * @returns {User[]}
             */
            assignedAuthors() {
                return (this.unit !== null) ? this.userService.getAssignedAuthorsForUnit(this.unit) : [];
            },

            /**
             * Get unassigned authors for the unit
             *
             * @returns {User[]}
             */
            unassignedAuthors() {
                return (this.unit !== null) ? this.userService.getUnassignedAuthorsForUnit(this.unit) : [];
            },

        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_REMOVE_AUTHORS_FROM_UNIT_APPLY, this.onApplyRemoveAuthorsFromUnit);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_ASSIGN, this.onAssignAuthorsToUnit);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_REMOVE_AUTHORS_FROM_UNIT_APPLY, this.onApplyRemoveAuthorsFromUnit);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_ASSIGN, this.onAssignAuthorsToUnit);
        },
        methods: {

            /**
             * Click handler for assigning authors button
             *
             * @param {MouseEvent} e
             */
            onClickBtnAssignAuthor(e) {
                // Hide any remove confirm dialogs:
                this.isRemoveConfirmDialogVisible = {};
                // Clear the selected state:
                this.userService.users.forEach(u => u.selected = false);
                // Show the side panel:
                this.$globalEvents.emit(EventType.SIDEPANEL_USERS_SHOW);
                return this;
            },

            /**
             * Click handler for remove all authors button
             *
             * @param {MouseEvent} e
             */
            onClickBtnRemoveAllAuthors(e) {
                // Hide any remove confirm dialogs:
                this.isRemoveConfirmDialogVisible = {};
                // Show the modal dialog:
                this.$globalEvents.emit(EventType.MODAL_REMOVE_AUTHORS_FROM_UNIT_SHOW);
                return this;
            },

            /**
             * Click handler for author remove buttons
             *
             * @param {User} user User object reference
             */
            onClickRemoveAuthor(user) {
                this.isRemoveConfirmDialogVisible = {};    // Only allow one open dialog at the same time
                this.isRemoveConfirmDialogVisible[user.uid] = true;
                return this;
            },

            /**
             * Confirm handler for author remove confirmation dialog
             *
             * @param {User} user User object reference
             */
            onConfirmAuthorRemove(user) {
                delete this.isRemoveConfirmDialogVisible[user.uid];

                this.sendAssignedAuthorsToApi(null, [user]);
            },

            /**
             * Cancel handler for author remove confirmation dialog
             *
             * @param {User} user Trigger object reference
             */
            onCancelAuthorRemove(user) {
                delete this.isRemoveConfirmDialogVisible[user.uid];
                return this;
            },

            /**
             * Click handler for remove all authors dialog apply button
             */
            onApplyRemoveAuthorsFromUnit() {
                this.sendAssignedAuthorsToApi(null, this.assignedAuthors);
            },

            /**
             * Assign authors to selected unit
             *
             * @param {User[]} users
             */
            onAssignAuthorsToUnit(users) {
                this.sendAssignedAuthorsToApi(users);
            },

            /**
             * Syncs the given author assignments with the API and handles errors in the process.
             * Author assignments will be synced to the unit property and propagated on success.
             *
             * @param {?User[]} usersToAssign
             * @param {?User[]} usersToUnassign
             * @returns {Promise<T>}
             */
            async sendAssignedAuthorsToApi(usersToAssign = null, usersToUnassign = null) {
                usersToAssign = usersToAssign || [];
                usersToUnassign = usersToUnassign || [];

                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

                return this.unitService
                    .changeAuthorsAssignment(this.unit, usersToAssign, usersToUnassign)
                    .then(() => {
                        this.$globalEvents.emit(EventType.AUTHOR_ASSIGNMENT_AUTHORS_CHANGED, this.unit);
                    })
                    .catch(error => {
                        this.$root.showErrorDialog(error);
                    })
                    .finally(() => {
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                    });
            }
        }
    }

</script>


<style lang="scss" scoped>

</style>
