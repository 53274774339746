import {trans} from "@/Utility/Helpers/trans";

export enum Posture {
    Standing = 'standing',
    Sitting = 'sitting',
}

export namespace PostureHelpers {
    export function getCaption(posture: Posture): string {
        return trans('authoring.postures.type_captions.' + posture.toString());
    }

    export function all(): Posture[] {
        return Object.values(Posture);
    }
}
