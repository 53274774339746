import {isUUID} from "@/Utility/Helpers";

export default class DropdownOption
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.value = (typeof attributes.value === 'boolean') ? attributes.value : attributes.value || null; // Option value
        this.caption = attributes.caption || null;                                                          // Option caption text
        this.disabled = (typeof attributes.disabled === 'boolean') ? attributes.disabled : false;           // Disabled state
        this.icon = attributes.icon || null;                                                                // Icon identifier name
        this.title = attributes.title || null;                                                              // Title for option tooltip
        this.isSeparator = (typeof attributes.isSeparator === 'boolean') ? attributes.isSeparator : false;  // Whether this option is just a separator item
        this.referenceUid = attributes.referenceUid || null;                                                // Optional UID of a referenced object
    }

    get referencedObjectUid() {
        return this.referenceUid || this.value?.uid || (isUUID(this.value) ? this.value : null) || null;
    }
}
