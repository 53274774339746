export default class InputConfiguration {

    constructor(attributes = {}) {
        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && !(attributes instanceof Array)) ? attributes : {};

        // Populate the model:

        // Input style (e.g. "controllers")
        this.style = (typeof attributes.style === 'string') ? attributes.style : 'controllers';

        // Is movement with controllers enabled
        this.controller_movement = attributes.controller_movement ?? true;
    }

    /**
     * @returns {InputConfiguration}
     */
    duplicate() {
        return new InputConfiguration(this);
    }
}
