import { trans } from '@/Utility/Helpers';
import Asset from "@/Models/Asset/Asset";
import FilterCategory from "@/Filters/FilterCategory";

export default class AssetType
{
    /**
     * Main type for all assets
     *
     * @var {String}
     */
    static get type() { return 'asset'; }

    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || AssetType.isValidType(attributes.type) === false)
        {
            console.warn('AssetType->constructor(): Invalid data.', attributes);
            throw new TypeError('AssetType->constructor(): Property "type" has to be set on AssetType. Must be a valid type from AssetType class.');
        }
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('AssetType->constructor(): Invalid data.', attributes);
            throw new TypeError('AssetType->constructor(): Property "title" has to be set on AssetType.');
        }
        // @NOTE: Description is not mandatory at the moment since we don't have any description texts yet
        //if (attributes.description === null || typeof attributes.description !== 'string' || attributes.description.length === 0)
        //{
        //    console.warn('AssetType->constructor(): Invalid data.', attributes);
        //    throw new TypeError('AssetType->constructor(): Property "description" has to be set on AssetType.');
        //}
        if (typeof attributes.icon !== 'string' || attributes.icon.length === 0)
        {
            console.warn('AssetType->constructor(): Invalid data.', attributes);
            throw new TypeError('AssetType->constructor(): Property "icon" has to be set on AssetType.');
        }

        // Populate the model:
        this.enabled = (typeof attributes.enabled === 'boolean') ? attributes.enabled : true;   // Enabled state
        this.type = attributes.type;                                             // Type identifier
        this.icon = attributes.icon;                                             // Icon identifier
        this.title = attributes.title;                                           // Translated title of this AssetType (e.g. '3D Environment')
        this.description = attributes.description || null;                       // Translated description of this AssetType
        this.preview = attributes.preview || null;                               // Preview image
        this.previewIcon = attributes.previewIcon || null;                       // Preview icon / whether preview is supported
        this.supportedFileExtensions = attributes.supportedFileExtensions || null;  // List of file extensions for browser file input dialog
    }

    get filterCategory()
    {
        return new FilterCategory({
            title: this.title,
            callback: (elements) => elements.filter(e => e instanceof Asset && e.type === this.type),
            paramName: this.type,
        });
    }

    /**
     * Whether preview is supported for this type of asset.
     * "Support" means this always works without the user having to upload additional files.
     *
     * @returns {Boolean}
     */
    get supportsPreview()
    {
        switch (this.type) {
            case AssetType.CharacterModel3D.type:
            case AssetType.Image.type:
            case AssetType.EnvironmentImage.type:
            case AssetType.Sound.type:
            case AssetType.SoundTts.type:
            case AssetType.Video.type:
            case AssetType.EnvironmentVideo.type:
            case AssetType.Model3D.type:
            case AssetType.EnvironmentModel3D.type:
                return true;
            default:
                return false;
        }
    }

    /**
     * AssetType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     * @NOTE: Also update definitions in SceneObjectType class!
     *
     * @returns {AssetType}
     */
    static get Unknown()      { return StaticAssetTypes.Unknown; }
    static get CharacterModel3D()       { return StaticAssetTypes.CharacterModel3D; }
    static get EnvironmentImage()       { return StaticAssetTypes.EnvironmentImage; }
    static get EnvironmentModel3D()     { return StaticAssetTypes.EnvironmentModel3D; }
    static get EnvironmentVideo()       { return StaticAssetTypes.EnvironmentVideo; }
    static get Image()                  { return StaticAssetTypes.Image; }
    static get Model3D()                { return StaticAssetTypes.Model3D; }
    static get Sound()                  { return StaticAssetTypes.Sound; }
    static get SoundTts()               { return StaticAssetTypes.SoundTts; }
    static get Text()                   { return StaticAssetTypes.Text; }
    static get Video()                  { return StaticAssetTypes.Video; }

    /**
     * Get all types as an array
     *
     * @returns {AssetType[]}
     */
    static get all()
    {
        return Object.values(StaticAssetTypes);
    }

    /**
     * Get all types that can be uploaded by the user (= only with supported file extensions) as an array
     *
     * @returns {AssetType[]}
     */
    static get allUploadable()
    {
        return AssetType.all.filter(at => at.supportedFileExtensions !== null);
    }

    /**
     * Get AssetType by a given type name
     *
     * @param {String} type
     * @returns {AssetType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }

    /**
     * Check whether a given type is an environment
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isEnvironmentType(type)
    {
        return (type.toLowerCase().indexOf('environment') >= 0);
    }

    /**
     * Check whether a given type is a 3D model
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isModel3dType(type)
    {
        return (type.toLowerCase().indexOf('model3d') >= 0);
    }

    /**
     * Check whether a given type is based on an image file.
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isImageType(type)
    {
        return (type.toLowerCase().indexOf('image') >= 0);
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in AssetTypes)
        {
            if (AssetTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }
}

/**
 * Type definitions
 */
const StaticAssetTypes = {};
const AssetTypes = {

    Unknown: {
        type: 'unknown',
        icon: 'icon_asset',
        title: trans('assets.types.unknown'),
        description: null,
        preview: '/images/preview/asset.jpg',
        previewIcon: null,
        supportedFileExtensions: null,
    },

    CharacterModel3D: {
        type: 'character_model3d',
        icon: 'icon_character',
        title: trans('assets.types.character_model3d'),
        description: null,
        preview: '/images/preview/character_model3d.jpg',
        previewIcon: 'icon_preview',
        supportedFileExtensions: '.glb',
    },

    EnvironmentImage: {
        type: 'environment_image',
        icon: 'icon_environment',
        title: trans('assets.types.environment_image'),
        description: null,
        preview: '/images/preview/environment_image.jpg',
        previewIcon: 'icon_preview',
        supportedFileExtensions: '.jpeg,.jpg,.png',
    },

    EnvironmentVideo: {
        type: 'environment_video',
        icon: 'icon_environment',
        title: trans('assets.types.environment_video'),
        description: null,
        preview: '/images/preview/environment_video.jpg',
        previewIcon: 'icon_play',
        supportedFileExtensions: '.mp4,.mov,.qt',
    },

    EnvironmentModel3D: {
        type: 'environment_model3d',
        icon: 'icon_environment',
        title: trans('assets.types.environment_model3d'),
        description: null,
        preview: '/images/preview/environment_model3d.jpg',
        previewIcon: 'icon_preview',
        supportedFileExtensions: '.glb',
    },

    Image: {
        type: 'image',
        icon: 'icon_asset-image',
        title: trans('assets.types.image'),
        description: null,
        preview: '/images/preview/image.jpg',
        previewIcon: 'icon_preview',
        supportedFileExtensions: '.jpeg,.jpg,.png',
    },

    Model3D: {
        type: 'model3d',
        icon: 'icon_asset',
        title: trans('assets.types.model3d'),
        description: null,
        preview: '/images/preview/model3d.jpg',
        previewIcon: 'icon_preview',
        supportedFileExtensions: '.glb',
    },

    Sound: {
        type: 'sound',
        icon: 'icon_asset-sound',
        title: trans('assets.types.sound'),
        description: null,
        preview: '/images/preview/sound.jpg',
        previewIcon: 'icon_play',
        supportedFileExtensions: '.aac,.mp3,.wav',
    },

    SoundTts: {
        type: 'sound_tts',
        icon: 'icon_asset-tts',
        title: trans('assets.types.sound_tts'),
        description: null,
        preview: '/images/preview/tts.jpg',
        previewIcon: 'icon_play',
        supportedFileExtensions: '.mp3,.wav',
    },

    Text: {
        type: 'text',
        icon: 'icon_asset-text',
        title: trans('assets.types.text'),
        description: null,
        preview: '/images/preview/text.png',
        previewIcon: null,
        supportedFileExtensions: null,
    },

    Video: {
        type: 'video',
        icon: 'icon_asset-video',
        title: trans('assets.types.video'),
        description: null,
        preview: '/images/preview/video.jpg',
        previewIcon: 'icon_play',
        supportedFileExtensions: '.mp4,.mov,.qt',
    },
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in AssetTypes)
{
    StaticAssetTypes[i] = new AssetType(AssetTypes[i]);
}
