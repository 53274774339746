export interface LearningRecordVerbWithCount {
    verb: string;
    count: number;
}

export interface LearningRecordObjectWithCount {
    object: object;
    count: number;
}

export default class LearningRecords {

    readonly records: Object[];
    constructor(apiModel: any) {
        this.records = apiModel;
    }

}
