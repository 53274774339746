export default class SketchfabUser {

    public readonly displayName: string;
    public readonly profileUrl: string;
    public readonly avatarUrl: string;

    constructor(apiModel: any) {
        this.displayName = apiModel.displayName;
        this.profileUrl = apiModel.profileUrl;

        const sortedAvatars = apiModel.avatar.images.sort((image1, image2) => image1.width - image2.width);
        // select smallest avatar (32x32 px)
        this.avatarUrl = sortedAvatars[0].url;
    }
}
