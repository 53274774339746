<template>
    <div id="sidepanel-users" class="sidepanel-content" v-if="isVisible" v-shortcuts.global.stop>
        <header>
            <Icon name="icon_doublearrow_right" class="icon-close" @click="onClickCloseSidepanel" />
            {{ trans(headlineLabel) }}
        </header>

        <ListFiltersBar
            class="sidepanel-filters"
            :categories="filterCategories"
            :filterWords="filterWords"
            @change="applyFilters"
            @cancel="onClickCloseSidepanel"
        />

        <ul class="selectable-list" v-not-focusable>
            <li v-for="user in filteredList" :key="user.uid" v-focusable @click.capture.stop="selectUser(user)" @keyup.space="selectUser(user)">
                <span class="preview">
                    <img :src="user.image" alt="" />
                </span>
                <section class="info">
                    <h4 class="name">{{ user.firstname }} {{ user.lastname }}</h4>
                    <p class="email">{{ user.email }}</p>
                </section>
                <Checkbox
                    :key="'userSelected'+user.uid+'_'+user.selected"
                    :model="user"
                    property="selected"
                    :events-disabled="true"
                    v-not-focusable
                />
            </li>
        </ul>

        <footer class="buttons">
            <ButtonSecondary v-if="countSelectedUsers !== filteredList.length" class="select-all" caption="labels.select_all" @trigger="onClickSelectAll" />
            <ButtonSecondary v-if="countSelectedUsers === filteredList.length" class="deselect-all" caption="labels.deselect_all" @trigger="onClickDeselectAll" />
            <ButtonPrimary :disabled="countSelectedUsers === 0" class="assign-to-training" icon="icon_add" :caption="assignmentButtonLabel" @trigger="onClickAssignToTraining" />
        </footer>
    </div>
</template>

<script>

    // Import VueJS components:
    import ListFiltersBar           from '@/Vue/Common/ListFiltersBar.vue';

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import Search                   from '@/Utility/Search';
    import UserFilters              from '@/Filters/UserFilters';

    export default {
        name: 'SidepanelUsers',
        components: {
            ListFiltersBar
        },
        props: {
            headlineLabel: {
                type: String,
                default: 'labels.users',
            },
            assignmentButtonLabel: {
                type: String,
                default: 'labels.assign_to_unit',
            },
            isVisible:  {                       // Whether the content is visible (set by the parent component)
                type: Boolean,
                default: false
            },
            users: {                            // The list of users passed from the parent component
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                filterCategories: [             // List of FilterCategory items
                    UserFilters.All.setActive(true)
                ],
                filteredList: [],               // Filtered list of users
                filterOptions: null,            // Filter options
                filterWords: null,              // Filter words for the text input
                shortcuts: new Map([
                    ['Escape', this.onClickCloseSidepanel],
                    ['Space.prevent', null],
                    ['Shift+Space.prevent', null]
                ])
            }
        },
        created() {
            // @NOTE: Only using one filter by default for now so it should be active
            this.filterCategories[0].setActive(true);
        },
        mounted() {
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_FILTERS_SET, this.setFilters);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_FILTERS_SET, this.setFilters);
        },
        computed: {

            /**
             * Number of selected users
             *
             * @returns {Number}
             */
            countSelectedUsers() {
                return this.filteredList.filter((u) => u.selected === true).length;
            }
        },
        methods: {

            /**
             * Click handler for close button
             *
             * @param {CustomEvent|KeyboardEvent|MouseEvent} e
             */
            onClickCloseSidepanel(e) {
                this.$globalEvents.emit(EventType.SIDEPANEL_USERS_CANCEL);
                return this;
            },

            /**
             * Set and apply the filters to be used by the ListFiltersBar component
             *
             * @param {FilterCategory[]} filters       // List of FilterCategory items
             * @param {Mixed} options                       // Additional options to be passed to the filter
             */
            setFilters(filters, options = null) {
                this.filterCategories = filters;
                this.filterOptions = options;
                this.applyFilters();
                return this;
            },

            /**
             * Apply filters
             *
             * @param {FilterCategory|String|Null} filterText
             */
            applyFilters(filterText) {
                // Store filter text:
                if ((filterText === null || typeof filterText === 'string') && filterText !== this.filterWords)
                {
                    this.filterWords = filterText;
                }
                // Reset the list first:
                this.filteredList = Array.from(this.users);
                // Apply filter categories:
                this.filterCategories.filter(fc => fc.isActive === true).forEach(fc => {
                    this.filteredList = fc.callback(this.filteredList, this.filterOptions);
                }, this);
                // Apply word filter:
                if (this.filterWords !== null)
                {
                    this.filteredList = Search.filterObjects(this.filteredList, ['firstname', 'lastname', 'email'], this.filterWords);
                }
                return this;
            },

            /**
             * Select a specific user
             *
             * @param {User} user
             */
            selectUser(user) {
                user.selected = !user.selected;
                //this.$globalEvents.emit(EventType.SIDEPANEL_USERS_SELECT, user);
                return this;
            },

            /**
             * Click handler for select all button
             *
             * @param {MouseEvent} e
             */
            onClickSelectAll(e) {
                this.filteredList.forEach((u) => u.selected = true);
                return this;
            },

            /**
             * Click handler for select all button
             *
             * @param {MouseEvent} e
             */
            onClickDeselectAll(e) {
                this.filteredList.forEach((u) => u.selected = false);
                return this;
            },

            /**
             * Click handler for assign-to-training button
             *
             * @param {MouseEvent} e
             */
            onClickAssignToTraining(e) {
                if (this.countSelectedUsers >= 1)
                {
                    this.$globalEvents.emit(EventType.SIDEPANEL_USERS_ASSIGN, this.filteredList.filter((u) => u.selected === true));
                }
                return this;
            }
        },
        watch: {
            isVisible(newValue, oldValue) {
                if (newValue === true)
                {
                    this.previousFocusElement = document.activeElement;
                }
                else
                {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                }
            },
            users() {
                // Re-apply the filters:
                this.applyFilters();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
