import { updateUidReferences } from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';
import Command from '@/Models/UnitData/Commands/Command';

export default class OverlayButton extends AbstractDataObject
{
    static get constructorName() { return 'OverlayButton'; }

    /**
     * Types
     *
     * @var {String}
     */
    static get Type() {
        return {
            Checkmark:          'checkmark',
            IntroRestart:       'intro_restart',
            Next:               'next',
            Okay:               'okay',
            Previous:           'previous',
            TrainingRestart:    'training_restart',
            Start:              'start',
            TryAgain:           'try_again',
        };
    }

    /**
     * Styles
     *
     * @var {String}
     */
    static get Style() {
        return {
            Primary:            'primary',
            Secondary:          'secondary',
        };
    }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || OverlayButton.isValidType(attributes.type) === false)
        {
            console.warn('OverlayButton->constructor(): Invalid data.', attributes);
            throw new TypeError('OverlayButton->constructor(): Property "type" has to be set on OverlayButton. Must be a valid type from OverlayButton class.');
        }
        if (typeof attributes.style !== 'undefined' && attributes.style !== null && OverlayButton.isValidStyle(attributes.style) === false)
        {
            console.warn('OverlayButton->constructor(): Invalid data.', attributes);
            throw new TypeError('OverlayButton->constructor(): Property "style" has to be set on OverlayButton. Must be a valid style from OverlayButton class.');
        }

        // Populate the model:
        this.type = attributes.type;                                        // Type identifier
        this.style = attributes.style || OverlayButton.Style.Secondary;     // Button style
        this.commands = (attributes.commands || []).map(c => Command.createFromAttributes(c, this));    // List of commands this button should execute
    }

    /**
     * Do the objectives have any commands?
     *
     * @returns {Boolean}
     */
    get hasCommands() {
        return (this.commandsCount > 0);
    }

    /**
     * Get the count of commands
     *
     * @returns {Number}
     */
    get commandsCount() {
        return (this.commands instanceof Array) ? this.commands.length : 0;
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // All commands must be valid:
        return this.commands.every(c => c.isValid);
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {OverlayButton}
     */
    duplicate(updateUidMapping = true)
    {
        const duplicated = new OverlayButton(this, this.parent);

        // Create new instances for child objects:
        duplicated.commands = duplicated.commands.map(c => c.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        if (type === null || typeof type !== 'string' || type.length === 0)
        {
            return false;
        }
        for (let i in OverlayButton.Type)
        {
            if (OverlayButton.Type[i] === type)
            {
                return true;
            }
        }
        return false;
    }

    /**
     * Check whether a given style is valid
     *
     * @param {String} style
     * @returns {Boolean}
     */
    static isValidStyle(style)
    {
        if (style === null || typeof style !== 'string' || style.length === 0)
        {
            return false;
        }
        for (let i in OverlayButton.Style)
        {
            if (OverlayButton.Style[i] === style)
            {
                return true;
            }
        }
        return false;
    }
}
