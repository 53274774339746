<template>
    <span class="search-has-no-results no-items-available">
        <Icon name="icon_error" />
        
        <span class="caption">{{ caption }}</span>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span class="description" v-html="description" />
    </span>
</template>

<script lang="ts">
import Icon from '@/Vue/Common/Icon.vue';
import {trans} from '@/Utility/Helpers.js';
import {defineComponent} from 'vue';

export default defineComponent({

    components: {
        Icon
    },

    props: {
        caption: {
            type: String,
            default: trans('search.no_items_available'),
        },

        description: {
            type: String,
            default: trans('search.no_items_available_text'),
        },
    },

    methods: {
        trans
    },

});

</script>

<style lang="scss" scoped>

</style>
