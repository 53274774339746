export default class Scale
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.x = (typeof attributes.x === 'number') ? attributes.x : 1; // Scale X
        this.y = (typeof attributes.y === 'number') ? attributes.y : 1; // Scale Y
        this.z = (typeof attributes.z === 'number') ? attributes.z : 1; // Scale Z
    }

    /**
     * @returns {boolean}
     */
    get isDefault() {
        return this.x === 1
            && this.y === 1
            && this.z === 1
        ;
    }

    /**
     * @param {Scale} scale
     * @returns {boolean}
     */
    isEqualTo(scale) {
        return scale instanceof Scale
            && this.x === scale.x
            && this.y === scale.y
            && this.z === scale.z
        ;
    }
}
