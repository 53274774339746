import { uuid4 } from '@/Utility/Helpers';
import TransformComponent from '@/Models/Unity/Components/TransformComponent';

export default class StartPointComponent extends TransformComponent
{
    /**
     * Type for this component
     *
     * @var {String}
     */
    static get type() { return 'start_point'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Set type for this component:
        super({...attributes, ...{type: attributes.type || StartPointComponent.type}});

        // Populate the model:
        this.title = attributes.title || null;  // Title text
    }

    /**
     * Duplicate
     *
     * @returns {StartPointComponent}
     */
    duplicate()
    {
        const duplicated = new StartPointComponent(this);
        duplicated.uid = uuid4();

        return duplicated;
    }
}
