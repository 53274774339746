<template>
    <div
        v-not-focusable
        v-shortcuts.global.prevent.stop
        class="container">

        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('tokens.create.headline') }}</div>

                    <div class="card-body">
                        <form ref="form" class="form-user-update" method="post">

                            <div class="form-group row">

                                <div class="col-md-6">
                                    <TextInput
                                        :key="'name'+key"
                                        ref="nameInput"
                                        :label="trans('labels.name')"
                                        :maxlength="191"
                                        :model="form"
                                        property="name"
                                        :placeholder="trans('labels.name')"
                                        :required="true"
                                        :validationErrors="validationErrors('name')"
                                        @change="removeValidationError('name')"
                                    />
                                </div>

                                <div class="col-md-6">
                                    <TagList
                                        :key="'scopes'+key"
                                        :label="trans('tokens.create.scopes')"
                                        :model="form"
                                        property="scopes"
                                        :placeholder="trans('tokens.create.scope_placeholder')"
                                        :suggestions="scopeSuggestions"
                                    />
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="buttons col-md-6">
                                    <ButtonSecondary
                                        @trigger="onCancel"
                                        caption="labels.cancel"
                                    />
                                    <ButtonPrimary
                                        :disabled="isSubmitting"
                                        type="submit"
                                        @trigger="onSubmit"
                                        caption="labels.create"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import TextInput from '@/Vue/Common/TextInput.vue';
    import TagList from '@/Vue/Common/TagList.vue';
    import {inject} from "vue";
    import {tokenServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

    export default {
        name: 'CreateApiTokenForm',

        emits: [
            'cancel',
            'created',
        ],

        components: {
            TextInput,
            TagList,
        },

        data() {
            return {
                isSubmitting: false,
                form: {
                    name: null,
                    scopes: [],
                },
                errors: {},
                key: 0,
                tokenService: inject(tokenServiceKey),
                shortcuts: new Map([
                    ['Escape', this.onCancel],
                ])
            }
        },

        mounted() {
            this.$refs.nameInput.$refs.domElement.focus();
        },

        computed: {

            scopeSuggestions() {
                return window.currentUser.permissions;
            }

        },

        methods: {

            onSubmit(event) {
                event.preventDefault();

                const form = this.$refs.form;

                if (!form.reportValidity()) {
                    return;
                }

                this.tokenService
                    .createToken(this.form.name, this.form.scopes)
                    .then(this.onCreateSuccess)
                    .catch(this.onErrorApi);
            },

            onCancel(event) {
                event.preventDefault();
                this.$emit('cancel', event, this);
            },

            /**
             * Get the validation errors for a specific field.
             *
             * @param {String} property
             * @returns {Array}
             */
            validationErrors(property) {
                return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
            },

            /**
             * Clear the validation errors for a specific field.
             *
             * @param {String} property
             */
            removeValidationError(property) {
                delete this.errors[property];
            },

            onCreateSuccess(token) {
                this.$emit('created', token, this);
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                this.$root.showErrorDialog(error);
                return this;
            },

        }
    }
</script>

<style lang="scss" scoped>

    .container {
        margin: 0;
        padding: 0;
    }

</style>
