import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route, trans} from '@/Utility/Helpers';
import UnitLink from "@/Models/Unit/UnitLink";

export type UnitLinkForm = {
    unit_uid: string,
    tenant_uid: string,
};

export default class UnitLinkService {

    public isLoading: Boolean = false;
    public isSaving: Boolean = false;
    public isDeleting: Boolean = false;
    private request: AxiosRequest | null = null;

    /**
     * Cancel any ongoing requests.
     */
    async cancelRequests(): Promise<any> {
        // @NOTE: Only working with a single request at the moment!
        return this.request?.cancel();
    }

    async createUnitLink(data: UnitLinkForm): Promise<UnitLink> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const createUnitLinkRoute = route('api.units.links.create', {'unit': data.unit_uid});

        return this.request
            .post(createUnitLinkRoute, {
                'tenant_uid': data.tenant_uid,
                'name': 'Public Link',
            })
            .then(({data}) => {
                return new UnitLink(data.data);
            })
            .catch(error => {
                console.error('UnitLinkService->createUnitLink():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Deletes the given tenant permanently.
     */
    async deleteUnitLink(unitLink: UnitLink): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isDeleting = true;
        this.request = new AxiosRequest();

        const deleteUnitLinkRoute = route('api.units.links.delete', {unitLink: unitLink.uid});

        return this.request
            .delete(deleteUnitLinkRoute)
            .then(({ data }) => {
                try {
                    return Promise.resolve(unitLink);
                } catch(exception) {
                    console.error('UnitLinkService->deleteUnitLink(): API returned invalid or incompatible unit link data.', data, exception);
                    return Promise.reject(trans('errors.unitLinks.invalid_data'));
                }
            })
            .catch(error => {
                console.error('UnitLinkService->deleteUnitLink():', error);
                throw error;
            })
            .finally(() => {
                this.isDeleting = false;
                this.request = null;
            });
    }
}
