import AssetFile from '@/Models/Asset/AssetFile';
import Asset from '@/Models/Asset/Asset';

export abstract class AbstractVideoAsset extends Asset {

    public readonly videos: AssetFile[];

    constructor(attributes: any) {
        super(attributes);

        this.videos = attributes.videos.map(video => new AssetFile(video));
    }

    get fileList() {
        return this.videos;
    }

    get supportsPreviewImage(): boolean {
        return false;
    }
}

