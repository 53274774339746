import { uuid4 } from '@/Utility/Helpers';
import Component from '@/Models/Unity/Components/Component';

export default class PlaceableComponent extends Component
{
    /**
     * Type for this component
     *
     * @var {String}
     */
    static get type() { return 'placeable'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Set type for this component:
        super({...attributes, ...{type: attributes.type || PlaceableComponent.type}});

        // Populate the model:
        this.placed = (typeof attributes.placed === 'boolean') ? attributes.placed : false; // Placed state
    }

    /**
     * Duplicate
     *
     * @returns {PlaceableComponent}
     */
    duplicate()
    {
        const duplicated = new PlaceableComponent(this);
        duplicated.uid = uuid4();

        return duplicated;
    }
}
