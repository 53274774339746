import {AzureVoiceList} from "@/Services/CognitiveServices/AzureVoiceList";
import type {Dictionary} from "lodash";
import {groupBy} from "lodash";
import {sortArrayByProperty} from "@/Utility/Helpers";

export interface TextToSpeechVoiceParameters {
    Name: string;
    DisplayName: string;
    LocalName: string;
    ShortName: string;
    Gender: 'Male' | 'Female' | 'Neutral';
    Locale: string;
    LocaleName: string;
    SecondaryLocaleList: string[] | undefined;
    StyleList: string[] | undefined;
    SampleRateHertz: string;
    VoiceType: string;
    Status: string;
    WordsPerMinute: string;
}

export default class TextToSpeechVoice {
    public readonly name: string;
    public readonly displayName: string;
    public readonly localName: string;
    public readonly shortName: string;
    public readonly gender: 'Male' | 'Female' | 'Neutral';
    public readonly locale: string;
    public readonly localeName: string;
    public readonly secondaryLocaleList: string[] | null;
    public readonly styleList: string[];
    public readonly sampleRateHertz: string;
    public readonly voiceType: string;
    public readonly status: string;
    public readonly wordsPerMinute: string;

    constructor(params: TextToSpeechVoiceParameters) {
        this.name = params.Name;
        this.displayName = params.DisplayName;
        this.localName = params.LocalName;
        this.shortName = params.ShortName;
        this.gender = params.Gender;
        this.locale = params.Locale;
        this.localeName = params.LocaleName;
        this.secondaryLocaleList = params.SecondaryLocaleList || null;
        this.styleList = [...(params.StyleList && params.StyleList.length > 0) ? ['automatic'] : [], 'default', ...params.StyleList || []];
        this.sampleRateHertz = params.SampleRateHertz;
        this.voiceType = params.VoiceType;
        this.status = params.Status;
        this.wordsPerMinute = params.WordsPerMinute;
    }

    get hasMultipleStyles() {
        return this.styleList !== null && this.styleList.length > 1;
    }

    get isMale(): boolean {
        return this.gender === 'Male';
    }

    static fromJson(json: string): TextToSpeechVoice {
        return new TextToSpeechVoice(JSON.parse(json));
    }

    static get all(): TextToSpeechVoice[] {
        return TextToSpeechVoices;
    }

    static get allByLocale(): Dictionary<TextToSpeechVoice[]> {
        return groupBy(TextToSpeechVoice.all, voice => voice.locale);
    }
}

const allowedLocales: string[] = [
    'de-CH',
    'de-DE',
    'en-GB',
    'en-US',
    'es-ES',
    'fr-FR',
    'it-IT',
];

const allowedStatuses: string[] = [
    'GA'
];

const TextToSpeechVoices: TextToSpeechVoice[] = [];

for (let i in AzureVoiceList) {
    if (
        allowedStatuses.includes(AzureVoiceList[i]['Status'])
        && allowedLocales.includes(AzureVoiceList[i]['Locale'])
    ) {
        TextToSpeechVoices.push(new TextToSpeechVoice(AzureVoiceList[i] as TextToSpeechVoiceParameters));
    }
}

// sort by display name
sortArrayByProperty(TextToSpeechVoices, 'displayName');
// sort voices with styles to top
TextToSpeechVoices.sort((voice1, voice2) => Number(voice2.hasMultipleStyles) - Number(voice1.hasMultipleStyles));
