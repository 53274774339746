<template>
    <StatisticsCard class="statistics-card-scenes" :is-loading="isLoading">

        <StatisticsCardHeader
            :title="trans('learning_records.statistics.labels.scene_activity')"
            :subtitle="trans('learning_records.statistics.labels.scene_activity_subtitle')"
        />

        <figure class="chart-wrapper" ref="chartWrapper">
            <svg ref="chart" class="chart" viewBox="0 0 200 200">
                <g class="root">
                </g>
            </svg>

            <figcaption>
                <ul class="legend" ref="legend">
                </ul>
            </figcaption>
        </figure>
    </StatisticsCard>
</template>

<script lang="ts">

import {defineComponent, PropType} from "vue";
import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
import {trans} from "@/Utility/Helpers";
import {select} from "d3-selection";
import {arc, pie, PieArcDatum, scaleOrdinal} from "d3";
import StatisticsCardHeader from "@/Vue/Statistics/General/Cards/StatisticsCardHeader.vue";
import {SceneDuration} from "@/Models/Statistics/LearningRecordsStatistics";

export default defineComponent({
    name: "LearningRecordsCardScenes",

    components: {
        StatisticsCardHeader,
        StatisticsCard
    },

    props: {
        scenes: {
            type: Object as PropType<SceneDuration[]>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },

    mounted() {
        this.redraw();
    },

    computed: {
        orderedScenes() {
            return this.scenes.sort((a, b) => {
                return b.duration - a.duration;
            });
        },
        colors() {
            const defaultColors = ['#FAB200', '#FFD428', '#CCC1A3'];
            const fillColors = Array(Math.max(0, this.orderedScenes.length - defaultColors.length)).fill(defaultColors[defaultColors.length - 1])

            return [
                ...defaultColors,
                ...fillColors
            ];
        },
    },

    methods: {
        trans,

        redraw() {
            const chartElement = this.$refs.chart as HTMLElement;

            if (!chartElement) {
                return;
            }

            const innerRadius = 50;
            const outerRadius = 100;

            const colorTransform = scaleOrdinal<SceneDuration, string>()
                .domain(this.orderedScenes)
                .range(this.colors)

            const dataTransform = pie<SceneDuration>()
                .value(scene => scene.duration)
                .padAngle(0.015);

            // Clear old chart
            select(chartElement)
                .select('.root')
                .selectAll('.piece')
                .remove();

            select(chartElement)
                .select('.root')
                .attr('transform', `translate(${outerRadius}, ${outerRadius})`)
                .selectAll('.piece')
                .data(dataTransform(this.orderedScenes))
                .enter()
                .append('path')
                .attr('class', 'piece')
                .attr('d', arc<PieArcDatum<SceneDuration>>()
                    .innerRadius(innerRadius)
                    .outerRadius(outerRadius)
                )
                .attr('fill', d => colorTransform(d.data))
                .append('title')
                .text((d, i) => {
                  const data = this.orderedScenes[i];
                  const duration = (data.duration >= 60)
                      ? Math.round(data.duration / 60) + ' min'
                      : Math.round(data.duration) + ' sec';
                  return `${data.scene_title}: ${duration}`;
                });

            const legendElement = this.$refs.legend as HTMLElement;

            // Clear old list items
            select(legendElement)
                .selectAll('li')
                .remove();

            select(legendElement)
                .selectAll()
                .data(this.orderedScenes)
                .enter()
                .append('li')
                .append('span')
                .attr('class', 'duration')
                .html(d => (d.duration >= 60) ? Math.round(d.duration / 60) + ' min' : Math.round(d.duration) + ' sec')
                .select(function() { return this.parentNode})
                .append('span')
                .attr('class', 'label')
                .html(d => d.scene_title)
                .select(function() { return this.parentNode})
                .append('span')
                .attr('class', 'color-indicator')
                .style('background-color', d => colorTransform(d));
        },
    },

    watch: {
        scenes() {
            // delay to make sure the dom is ready
            this.$nextTick(() => {
                this.redraw();
            });
        }
    },
});

</script>

<style lang="scss">

.statistics-card-scenes {

    .chart-wrapper {

        display: flex;
        flex-direction: row;
        margin-top: 16px;
        padding: 20px 40px;
        gap: 40px;

        svg {
            max-width: 35%;
        }

        figcaption {
            font-size: var(--font-size-small);
            font-family: var(--font-family-condensed);
            white-space: nowrap;
            max-width: 60%;
            max-height: 280px;
            padding-right: 20px;
            overflow: hidden;
            overflow-y: auto;

            .legend {
                list-style-type: none;

                li {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: start;
                    gap: 16px;
                }

                .label {
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .duration {
                    flex-grow: 0;
                    font-family: var(--font-family-condensed-demibold);
                }

                .color-indicator {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    flex-basis: 8px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }
    }

    .chart {
        width: 100%;
        aspect-ratio: 1;
    }
}

</style>
