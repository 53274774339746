import PagingMetadata from '@/Models/PagingMetadata';

export default class PagingPage<Model extends { uid: string }> {

    constructor(
        public readonly items: Model[] = [],
        public readonly metadata: PagingMetadata = new PagingMetadata()
    ) {
    }

    getItem(uid: string): Model | null {
        return this.items.find(item => item.uid === uid) || null;
    }

    removeItem(uid: string) {
        const itemIndex = this.items.findIndex(item => item.uid === uid);

        // Remove the item from the cached list
        if (itemIndex >= 0) {
            this.items.splice(itemIndex, 1);
        }
    }
}
