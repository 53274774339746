import Position from '@/Models/Unity/Position';
import Rotation from '@/Models/Unity/Rotation';
import Scale from '@/Models/Unity/Scale';

export default class Transform
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.position = new Position(attributes.position); // Position
        this.rotation = new Rotation(attributes.rotation); // Rotation
        this.scale = new Scale(attributes.scale);          // Scale
    }

    /**
     * Duplicate
     *
     * @returns {Transform}
     */
    duplicate()
    {
        const duplicated = new Transform();
        duplicated.position = new Position(this.position);
        duplicated.rotation = new Rotation(this.rotation);
        duplicated.scale = new Scale(this.scale);
        return duplicated;
    }

    /**
     * @returns {boolean}
     */
    get isDefault() {
        return this.position.isDefault
            && this.rotation.isDefault
            && this.scale.isDefault
        ;
    }

    /**
     * @param {Transform} transform
     * @returns {boolean}
     */
    isEqualTo(transform) {
        return transform instanceof Transform
            && this.position.isEqualTo(transform.position)
            && this.rotation.isEqualTo(transform.rotation)
            && this.scale.isEqualTo(transform.scale)
        ;
    }
}
