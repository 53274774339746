import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.ManagedSessionsUpdate() = 'managed_sessions:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('ManagedSession', new ManagedSessionPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.ManagedSessionsUpdate()), session);
 *
 */
export default class ManagedSessionPolicy {

    update(): boolean {
        return permission(Permission.ManagedSessionsUpdate());
    }

    delete(): boolean {
        return permission(Permission.ManagedSessionsDelete());
    }
}
