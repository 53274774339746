import AssetFile from '@/Models/Asset/AssetFile';
import Asset from '@/Models/Asset/Asset';

export abstract class AbstractModel3DAsset extends Asset {

    public readonly files: AssetFile[];

    constructor(attributes: any) {
        super(attributes);

        this.files = attributes.files.map(file => new AssetFile(file));
    }

    get fileList() {
        return this.files;
    }

    get supportsPreviewImage(): boolean {
        return true;
    }
}

