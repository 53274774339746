<template>
    <div
        :data-loading="isLoading"
        :data-saving="isSaving"
        class="container"
    >

        <div class="description card">
            <p>{{ trans('tokens.index.description_1', {email: userMail}) }}</p>
            <p>{{ trans('tokens.index.description_2') }}</p>
        </div>

        <template v-if="canCreateToken">

            <ButtonPrimary
                v-if="!isCreatingNewToken"
                :caption="trans('labels.add_token')"
                icon="icon_add"
                @trigger="onAddButtonTriggered"
            />

            <CreateApiTokenForm
                v-else
                @cancel="onCreateNewTokenCanceled"
                @created="onNewTokenCreated"
            />

        </template>

        <ApiTokenList
            :token-list="tokenService.tokens"
            class="token-list"
            @view-access-token="onViewAccessTokenTriggered"
            @revoke-access-token="onRevokeAccessTokenTriggered"
        />

        <ModalProgress/>
        <ModalNotification/>
        <ModalAccessToken/>

        <ModalApplyCancel
            :description="trans('tokens.dialogs.confirm_delete.description')"
            :title="trans('tokens.dialogs.confirm_delete.headline')"
            event-type="MODAL_REVOKE_API_TOKEN"
        />

    </div>
</template>

<script>

    // Import VueJS components:
    import ApiTokenList from '@/Vue/Tokens/ApiTokenList';
    import CreateApiTokenForm from '@/Vue/Tokens/CreateApiTokenForm';
    import ModalAccessToken from '@/Vue/Modals/ModalAccessToken.vue';
    import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
    import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
    import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';

    // Import classes:
    import {permission, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import EventType from '@/Utility/EventType';
    import {inject} from "vue";
    import {tokenServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

    export default {
        name: 'ApiTokenPage',

        data() {
            return {
                tokenService: inject(tokenServiceKey),
                isCreatingNewToken: false,
                userMail: window.currentUser.email,
            }
        },

        mounted() {
            this.$globalEvents.on(EventType.MODAL_REVOKE_API_TOKEN_APPLY, this.onRevokeTokenConfirmed);
            this.fetchTokens();
        },

        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_REVOKE_API_TOKEN_APPLY, this.onRevokeTokenConfirmed);
        },

        components: {
            ModalAccessToken,
            ModalProgress,
            ModalApplyCancel,
            CreateApiTokenForm,
            ModalNotification,
            ButtonSecondary,
            ApiTokenList,
        },

        computed: {

            canCreateToken() {
                return permission(Permission.TokensCreate())
            },

            /**
             * Loading state
             *
             * @returns {Boolean}
             */
            isLoading() {
                if (this.tokenService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * Saving state
             *
             * @returns {Boolean}
             */
            isSaving() {
                if (this.tokenService.isSaving) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            }
        },

        methods: {
            trans,

            /**
             * Fetch tokens for the current user from API
             */
            fetchTokens() {
                this.tokenService
                    .fetchTokens()
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                this.$root.showErrorDialog(error);
                return this;
            },

            onAddButtonTriggered() {
                this.isCreatingNewToken = true;
            },

            onCreateNewTokenCanceled() {
                this.isCreatingNewToken = false;
            },

            onNewTokenCreated(token) {
                this.isCreatingNewToken = false;
                this.$globalEvents.emit(EventType.MODAL_ACCESS_TOKEN_SHOW, token);
            },

            /**
             * @param {Token} token
             */
            onViewAccessTokenTriggered(token) {
                this.$globalEvents.emit(EventType.MODAL_ACCESS_TOKEN_SHOW, token);
            },

            /**
             * @param {Token} token
             */
            onRevokeAccessTokenTriggered(token) {
                this.$globalEvents.emit(EventType.MODAL_REVOKE_API_TOKEN_SHOW, token);
                return this;
            },

            /**
             * @param {Token} token
             */
            onRevokeTokenConfirmed(token) {
                this.tokenService
                    .revokeToken(token)
                    .catch(this.onErrorApi);
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .container {
        max-width: var(--container-max-width);
        padding: 0;
    }

    .description {
        padding: 0.7rem 1rem;
        margin-bottom: 1rem;
    }

    .token-list {
        margin-top: 30px;
        margin-left: 30px;
    }

</style>
