<template>

    <main id="layout-main" v-shortcuts>

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <form
                    id="create-session-form"
                    ref="form"
                    class="content-panel"
                    enctype="multipart/form-data"
                    method="post"
                    @submit.prevent="onSubmit"
                >
                    <h1 class="headline">
                        <Icon class="create-form-header-icon" name="icon_managed_sessions" />
                        {{ trans('sessions.create.headline') }}
                    </h1>

                    <!-- Title -->
                    <TextInput
                        :error-msg="trans('errors.managed_session.title')"
                        :label="trans('sessions.create.session_title')"
                        :maxlength="50"
                        :model="sessionData"
                        :placeholder="trans('sessions.create.session_title_placeholder')"
                        :required="true"
                        :validation-errors="validationErrors('title')"
                        class="panel-row session-name-input"
                        property="title"
                    />

                    <!-- Description -->
                    <TextInput
                        :label="trans('sessions.create.session_description')"
                        :maxlength="600"
                        :model="sessionData"
                        :placeholder="trans('sessions.create.session_description_placeholder')"
                        :required="false"
                        :validation-errors="validationErrors('description')"
                        class="panel-row session-description-input"
                        property="description"
                        type="textarea"
                    />

                    <!-- Buttons -->
                    <div class="panel-row buttons">
                        <ButtonPrimary
                            v-tooltip="'tooltips.buttons.managed_sessions.create'"
                            :caption="trans('labels.create')"
                            :disabled="isSubmitting"
                            @trigger="onSubmit"
                        />
                        <ButtonSecondary
                            :href="route('sessions.index')"
                            caption="sessions.create.btn_cancel"
                        />
                    </div>
                </form>
            </div>

            <ModalProgress />
            <ModalNotification />
        </div>
    </main>
</template>

<script lang="ts">

import {route, trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import AuthorizationError from '@/Errors/AuthorizationError';
import RequestError from '@/Errors/RequestError';
import {defineComponent, inject} from 'vue';
import {sessionServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import Icon from '@/Vue/Common/Icon.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';

export default defineComponent({

    components: {
        ButtonSecondary,
        ButtonPrimary,
        TextInput,
        Icon,
        ModalProgress,
        ModalNotification,
    },

    data() {
        return {
            sessionService: inject(sessionServiceKey)!,

            sessionData: {
                title: null as string | null,
                description: null as string | null,
            },

            isSubmitting: false,

            errors: {} as Record<string, string[]>,

            shortcuts: new Map([
                ['Save.prevent', null],
                ['Enter', this.onSubmit],
                ['Escape.prevent', this.onClickCancel],
            ])
        };
    },

    methods: {
        route,
        trans,

        /**
         * Get the validation errors for a specific field.
         */
        validationErrors(property: string): string[] {
            return Object.prototype.hasOwnProperty.call(this.errors, property) ? this.errors[property] : [];
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }
            this.$root!.showErrorDialog(error);
        },

        onSubmit(e: Event) {
            e.preventDefault();

            const form = this.$refs.form as HTMLFormElement;

            if (this.isSubmitting || !form.reportValidity()) {
                return;
            }

            this.isSubmitting = true;
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

            this.sessionService
                .createSession(this.sessionData.title!, this.sessionData.description)
                .catch((error) => {
                    this.errors = error.validationErrors || {};

                    // Show error dialog unless it's a validation error:
                    if (!(error instanceof RequestError && error.isValidationError)) {
                        this.$root!.showErrorDialog(error);
                    }
                })
                .finally(() => {
                    this.isSubmitting = false;
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                })
                .then(session => {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    window.location.href = route('sessions.edit', { 'managed_session': session.uid });
                });
        },

        onClickCancel(e: Event) {
            e.preventDefault();
            window.location.href = route('sessions.index');
        }
    }
});
</script>

<style lang="scss" scoped>
body.create-session {

    #content {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .create-form-header-icon {
        vertical-align: middle;
        margin: -4px 10px 0 -42px;
        width: 32px;
        height: 32px;
    }

    .content-panel {
        position: relative;
        width: 600px;
        padding: 30px 48px;
        display: flex;
        flex-direction: column;
        border-radius: var(--card-border-radius);
        background: var(--background-color-white);
        box-shadow: var(--card-box-shadow);
        text-align: left;

        .headline {
            text-align: center;
            margin-bottom: 56px;
        }

        .invalid-feedback {
            display: initial;
        }

        .panel-row {
            &:deep(label) {
                font-family: var(--font-family-condensed-demibold);
                display: block;
                padding-bottom: 8px;
            }

            :deep(textarea) {
                min-height: 120px;
            }
        }

        .panel-row.buttons {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 16px;
        }
    }
}
</style>
