<template>
    <main
        id="layout-main"
        :data-loading="isLoading"
        :data-saving="isSaving"
    >

        <PageHeader
            :buttons="headerButtons"
            :page-title="session.title"
        />

        <div id="layout-content">
            <div id="content">
                <p>{{ session.description }}</p>

                <section>
                    <h2>{{ trans('labels.unit') }}</h2>

                    <div class="select-unit-form">
                        <UnitRevisionLine :unit-revision="selectedUnitRevision" class="payload" />

                        <div class="buttons">
                            <ButtonSecondary
                                :caption="selectUnitCaption"
                                @trigger="onTriggerSelectUnit"
                            />
                            <ButtonPrimary
                                v-if="canCreateInstruction"
                                v-tooltip="Instruction.getDescriptionForAllDevices('unit_start')"
                                :caption="Instruction.getButtonLabelForAllDevices('unit_start')"
                                :disabled="!isStartAllButtonEnabled"
                                icon="icon_play"
                                @trigger="onTriggerStartUnitInstruction()"
                            />
                        </div>
                    </div>
                </section>

                <session-devices
                    ref="sessionDeviceList"
                    :can-create-instruction="canCreateInstruction"
                    :can-start-unit="canCreateInstruction && session.unit_uid !== null"
                    :session="session"
                    @add-device-click="onAddDeviceClick"
                    @start-unit-click="onTriggerStartUnitInstruction"
                    @stop-unit-click="onTriggerStopUnitInstruction"
                    @device-count-changed="(count: number) => sessionDeviceCount = count"
                />
            </div>

            <SidepanelUnitRevisions
                :key="'sidepanel-unit-revisions-'+units.length"
                :units="units"
            />
            <SidepanelDevices
                :devices="devicesToAssign"
                :is-loading="isLoadingDevices"
            />

            <!-- Modals go here -->
            <ModalDeleteSession />
            <ModalEditManagedSessionProperties />
            <ModalNotification />
            <ModalProgress />

        </div>
    </main>
</template>

<script lang="ts">

import {route, trans} from '@/Utility/Helpers';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import EventType from '@/Utility/EventType';
import {defineComponent, inject} from 'vue';
import {deviceServiceKey, sessionServiceKey, unitServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ManagedSession from '@/Models/Sessions/ManagedSession';
import type Unit from '@/Models/Unit/Unit';
import UnitRevisionLine from '@/Vue/Sessions/UnitRevisionLine.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import SessionDevices from '@/Vue/Sessions/SessionDevices.vue';
import SidepanelUnitRevisions from '@/Vue/Sidepanel/SidepanelUnitRevisions.vue';
import type Device from '@/Models/Devices/Device';
import {Permission} from '@/Models/User/Permission';
import InstructionPayloadUnitStart from '@/Models/Sessions/InstructionPayloadUnitStart';
import type UnitRevision from '@/Models/Unit/UnitRevision';
import PageHeaderButton from '@/Utility/PageHeaderButton';
import ModalEditManagedSessionProperties from '@/Vue/Modals/ModalEditManagedSessionProperties.vue';
import type {UpdateSessionParameters} from '@/Services/SessionService';
import SidepanelDevices from '@/Vue/Sidepanel/SidepanelDevices.vue';
import Instruction from '@/Models/Sessions/Instruction';
import InstructionPayloadUnitStop from '@/Models/Sessions/InstructionPayloadUnitStop';
import type InstructionPayload from '@/Models/Sessions/InstructionPayload';
import ModalDeleteSession from '@/Vue/Modals/ModalDeleteSession.vue';
import {debounce} from 'lodash';

export default defineComponent({
    components: {
        ModalDeleteSession,
        SidepanelDevices,
        ModalEditManagedSessionProperties,
        SidepanelUnitRevisions,
        SessionDevices,
        ButtonSecondary,
        ButtonPrimary,
        UnitRevisionLine,
        ModalProgress,
        ModalNotification,
        PageHeader,
    },

    props: {
        sessionJson: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            sessionService: inject(sessionServiceKey)!,
            unitService: inject(unitServiceKey)!,
            deviceService: inject(deviceServiceKey)!,

            reloadDevicesInterval: 0,
            isLoadingDevices: false,

            debouncedIsSaving: false,
            updateIsSavingDebounced: debounce(() => {
                this.debouncedIsSaving = this.isSaving;
            }, 800),

            events: new Map([
                [EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav],
                [EventType.SIDEPANEL_UNITREVISIONS_SELECT, this.onSelectUnitRevision],
                [EventType.MODAL_EDIT_MANAGED_SESSION_PROPERTIES_APPLY, this.onApplyPropertyChanges],
                [EventType.MODAL_DELETE_MANAGED_SESSION_APPLY, this.onDeleteSessionConfirmed],
                [EventType.SIDEPANEL_DEVICES_ASSIGN, this.onAddDevicesToSession],
                [EventType.SIDEPANEL_DEVICES_SHOW, this.onDevicePanelShow],
                [EventType.SIDEPANEL_DEVICES_HIDE, this.onDevicePanelHide],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
                ['click.global', this.onClickGlobal],
            ]),

            session: new ManagedSession(JSON.parse(this.sessionJson)),
            sessionDeviceCount: 0,

            /**
             * All devices available to this tenant.
             */
            allDevices: [] as Device[],

            units: [] as Unit[],
        };
    },

    computed: {
        Instruction() {
            return Instruction;
        },

        canDelete() {
            return this.$gate.allows(Permission.ability(Permission.ManagedSessionsDelete()), this.session);
        },

        canCreateInstruction() {
            return this.$gate.allows(Permission.ManagedSessionsUpdate()) && this.$gate.allows(Permission.InstructionsCreate());
        },

        selectedUnitRevision() {
            return this.units.find(unit => unit.uid === this.session.unit_uid)?.latestReleasedRevision || null;
        },

        isStartAllButtonEnabled() {
            return !this.isSaving && this.session.hasUnit && this.hasSessionDevices;
        },

        hasSessionDevices() {
            return this.sessionDeviceCount > 0;
        },

        headerButtons() {
            return {
                editSession: new PageHeaderButton({
                    caption: trans('labels.rename'),
                    icon: 'icon_edit',
                    tooltip: 'buttons.managed_sessions.rename',
                    callback: this.onEditClick,
                }),
                deleteSession: new PageHeaderButton({
                    caption: trans('labels.delete'),
                    icon: 'icon_delete',
                    disabled: !this.canDelete,
                    tooltip: 'buttons.managed_sessions.delete',
                    callback: this.onDeleteClick,
                }),
            };
        },

        selectUnitCaption() {
            return this.session.unit_uid ? trans('labels.change_unit') : trans('labels.select_unit');
        },

        devicesToAssign() {
            const sessionDevices = this.$refs.sessionDeviceList?.devices || [];
            return this.allDevices.filter(globalDevice =>
                sessionDevices.every((sessionDevice: Device) => globalDevice.uid !== sessionDevice.uid)
            );
        },

        isLoading(): boolean {
            if (this.unitService.isLoading || this.sessionService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isSaving(): boolean {
            return this.unitService.isSaving || this.sessionService.isSaving;
        },
    },

    watch: {
        isSaving(isSaving) {
            if (isSaving) {
                // delay a bit
                this.updateIsSavingDebounced();
            } else {
                // when no longer saving, set it immediately
                this.debouncedIsSaving = false;
            }
        },

        debouncedIsSaving(isSaving) {
            if (isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
            } else {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            }
        },
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
        this.$globalEvents.addEvent('click.global', this.onClickGlobal);

        this.fetchUnits()
            .then(this.fetchDevices);
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
        this.$globalEvents.removeEvent('click.global', this.onClickGlobal);

        clearInterval(this.reloadDevicesInterval);
    },

    methods: {
        trans,

        async saveSession(updateParameters: UpdateSessionParameters) {
            return this.sessionService
                .updateSession(this.session.uid, updateParameters)
                .then(session => {
                    this.session = session;
                })
                .catch(this.onErrorApi);
        },

        async fetchDevices() {
            this.isLoadingDevices = true;

            return this.deviceService
                .fetchDevices()
                .then((devices) => {
                    this.allDevices = devices;
                })
                .catch(this.onErrorApi)
                .finally(() => {
                    this.isLoadingDevices = false;
                });
        },

        async fetchUnits() {
            return this.unitService
                .fetchUnits(1, 999999, { status: ['released'] }, null, 'title')
                .then(data => {
                    this.units = data.unitList;
                })
                .catch(this.onErrorApi);
        },

        onAddDevicesToSession(devices: Device[]) {
            this.$globalEvents.emit(EventType.SIDEPANEL_DEVICES_HIDE);

            this.sessionService
                .updateSessionDevices(this.session.uid, [], devices.map(device => device.uid))
                .then((devices) => {
                    this.$refs.sessionDeviceList.devices = devices;
                })
                .catch(this.onErrorApi);
        },

        onDevicePanelShow() {
            clearInterval(this.reloadDevicesInterval);
            this.fetchDevices();
            this.reloadDevicesInterval = window.setInterval(() => this.fetchDevices(), 10_000);
        },

        onDevicePanelHide() {
            clearInterval(this.reloadDevicesInterval);
        },

        async onApplyPropertyChanges(title: string, description: string | null) {
            if (title === this.session.title && description === this.session.description) {
                return;
            }

            return this.saveSession({
                title: title,
                description: description,
            });
        },

        onSelectUnitRevision(unitRevision: UnitRevision) {
            this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_HIDE);

            this.saveSession({
                unit_uid: unitRevision.unit_uid
            });
        },

        onTriggerSelectUnit() {
            this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_SHOW);
        },

        onTriggerStartUnitInstruction(device: Device | undefined = undefined) {
            const unitStartInstructionPayload = new InstructionPayloadUnitStart({
                unit_uid: this.session.unit_uid!
            });

            this.sendInstruction(unitStartInstructionPayload, device);
        },

        onTriggerStopUnitInstruction(device: Device | undefined = undefined) {
            const unitStopInstructionPayload = new InstructionPayloadUnitStop();
            this.sendInstruction(unitStopInstructionPayload, device);
        },

        async sendInstruction(instructionPayload: InstructionPayload, device: Device | undefined) {
            return this.sessionService
                .createSessionInstructions(this.session.uid, instructionPayload, device?.uid)
                .then(() => this.showInstructionSuccessToast(instructionPayload, device))
                .catch(this.onErrorApi);
        },

        showInstructionSuccessToast(instructionPayload: InstructionPayload, device: Device | undefined) {
            let unitTitle = this.selectedUnitRevision?.title || '';

            if (unitTitle.length > 30) {
                unitTitle = unitTitle.slice(0, -1).trim() + '…';
            }

            const toastText = device ?
                Instruction.getToastForSingleDevice(instructionPayload.type, device, unitTitle) :
                Instruction.getToastForAllDevices(instructionPayload.type, unitTitle);

            this.$toast.success(toastText);
        },

        /**
         * Click handler for header navigation buttons that delegates the action to the button callback method
         */
        onClickHeaderNav(buttonConfig: PageHeaderButton) {
            if (buttonConfig.callback === null) {
                return this;
            }

            buttonConfig.callback.call(this, buttonConfig);
            return this;
        },

        onEditClick() {
            this.$globalEvents.emit(EventType.MODAL_EDIT_MANAGED_SESSION_PROPERTIES_SHOW, this.session);
        },

        onDeleteClick() {
            this.$globalEvents.emit(EventType.MODAL_DELETE_MANAGED_SESSION_SHOW, this.session);
        },

        onDeleteSessionConfirmed() {
            this.sessionService
                .deleteSession(this.session.uid)
                .then(() => {
                    window.location.href = route('sessions.index');
                })
                .catch(this.onErrorApi);
        },

        onAddDeviceClick() {
            this.$globalEvents.emit(EventType.SIDEPANEL_DEVICES_SHOW);
        },

        onClickGlobal(e: MouseEvent) {
            const target = e.target as HTMLElement;
            if (!document.getElementById('sidepanel-devices')?.contains(target)) {
                this.$globalEvents.emit(EventType.SIDEPANEL_DEVICES_HIDE);
            }
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }
            this.$root?.showErrorDialog(error);
        },

        onBeforeUnload() {
            this.sessionService.cancelRequests();
            this.unitService.cancelRequests();
            this.deviceService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },
    }
});
</script>

<style lang="scss" scoped>

#content {
    > * {
        max-width: var(--container-max-width);
        min-width: 670px;
    }

    section h2 {
        margin-top: 24px;
    }

    .select-unit-form {
        display: grid;
        grid-template-columns: auto max-content;
        grid-template-rows: auto auto;
        gap: 0 16px;
        background-color: white;
        border-radius: var(--card-border-radius);
        padding: 16px;

        .buttons {
            align-self: center;
        }
    }
}

</style>
