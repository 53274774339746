/**
 * Handle focus and selected states on components
 */
export default {

    /**
     * Install
     *
     */
    install(app)
    {
        // Create the VueJS directives:
        app.directive(
            'focusable',
            {
                beforeMount: (el, binding, vnode) => {
                    // Add a tabindex attribute so the element can be focused by the user:
                    el.setAttribute('tabindex', '0');
                }
            }
        );
        app.directive(
            'focusable-if',
            {
                beforeMount: (el, binding, vnode) => {
                    // Toggle tabindex attribute depending on the given condition from the binding:
                    if (binding.value)
                    {
                        el.setAttribute('tabindex', '0');
                    }
                    else
                    {
                        el.setAttribute('tabindex', '-1');
                    }
                },
                updated: (el, binding, vnode) => {
                    // Toggle tabindex attribute depending on the given condition from the binding:
                    if (binding.oldValue !== binding.value)
                    {
                        binding.dir.beforeMount(el, binding, vnode);
                    }
                }
            }
        );
        app.directive(
            'not-focusable',
            {
                beforeMount: (el, binding, vnode) => {
                    // Add a tabindex attribute so the element can not be focused by the user:
                    el.setAttribute('tabindex', '-1');
                }
            }
        );
    }
};
