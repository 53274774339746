<template>
    <div :class="cssClasses">
        <TextInput
            class="no-wrap"
            :disabled="disabled"
            :label="label"
            :maxlength="6"
            :required="required"
            :initial-value="(typeof color === 'string') ? color.substr(0,6) : ''"
            @change="onColorTextChange"
            @blur="onColorTextBlur"
            placeholder="#HEX"
            :restrictKeys="colorKeys"
        />
        <div class="color-picker-wrapper">
            <input
                type="color"
                :value="(typeof color === 'string') ? '#' + color.substr(0, 6) : ''"
                :disabled="disabled"
                @change="onColorInputChange"
            />
            <div class="color-patch"
                 :style="(typeof color === 'string') ? 'background-color: #' + color.substr(0, 6) : ''">
            </div>
        </div>
    </div>
</template>

<script>
    // Import classes:
    import { parseColor }           from '@/Utility/Helpers';
    import KeyboardKey              from '@/Utility/KeyboardKey';

    export default {
        name: 'ColorHexInput',
        emits: [
            'change',
        ],
        props: {
            initialValue: {         // Initial text (either use this or model+property!)
                type: String,
                default: null
            },
            model: {                // Associated model reference
                type: Object,
                default: null
            },
            property: {             // Property name from the associated model that should be modified
                type: String,
                default: null
            },
            disabled: {             // Disabled state
                type: Boolean,
                default: false
            },
            required: {             // Required state
                type: Boolean,
                default: false
            },
            label: {                // Optional label text
                type: String,
                default: null
            },
        },
        data() {
            return {
                color: '',                                  // The edited color
            }
        },
        computed: {
            /**
             * Key restrictions for color input
             *
             * @returns {KeyboardKey[]}
             */
            colorKeys() {
                return [...KeyboardKey.KeySets.Controls, ...KeyboardKey.KeySets.ColorHEX];
            },

            /**
             * CSS classes for the component
             *
             * @returns {String}
             */
            cssClasses() {
                const classes = ['color-input-hex'];

                // Enabled/disabled state:
                classes.push((this.disabled === true) ? 'disabled' : 'enabled');

                return classes.join(' ');
            },
        },
        mounted() {
            // Check properties
            if (this.model !== null && this.property === null) {
                console.warn('ColorHexInput->mounted(): Property :model="" is set but no property="" name is given.', this);
            }
            if (this.model !== null && this.initialValue !== null) {
                console.warn('ColorHexInput->mounted(): Both :model="" and :initial-value="" are set. You should use just one of them.', this);
            }

            this.resetValue();
        },
        methods: {

            /**
             * Reset to initial value
             */
            resetValue() {
                // Reset to initial value:
                if (this.model !== null && this.property !== null && typeof this.model[this.property] === 'string')
                {
                    this.color = this.model[this.property].trim();
                }
                else
                {
                    this.color = (typeof this.initialValue === 'string') ? this.initialValue.trim() : '';
                }
                return this;
            },

            /**
             * Change handler for color picker
             *
             * @param {Event} e  // change event
             */
            onColorInputChange(e) {
                const color = e.target.value.substr(1, 6);
                this.emitParsedColor(color, e);
            },

            /**
             * Change handler for color text input field
             *
             * @param {String} color            // Color value from TextInput
             * @param {Event} e
             */
            onColorTextChange(color, e) {
                const defaultColor = (typeof window.appData.THEME.app.colors['main'] === 'string') ? window.appData.THEME.app.colors['main'] : null;
                const parsedColor = parseColor(color) || ((color.length >= 1) ? this.resetValue().color : null) || defaultColor;
                if (e.type === 'change' || e.type === 'blur' || color.length >= 6)
                {
                    e.target.value = (parsedColor !== null) ? parsedColor.substr(0, 6) : null;
                    this.emitParsedColor(parsedColor, e);
                }
                return this;
            },

            /**
             * Blur handler for color text input field
             *
             * @param {Event} e
             * @param {Object} component
             */
            onColorTextBlur(e, component) {
                return this.onColorTextChange(component.$data.text, e);
            },

            /**
             * @param {String} parsedColor  // validated, six digit color string without # sign
             * @param {Event} e            // event causing the color to change
             */
            emitParsedColor(parsedColor, e) {
                if (this.model !== null && this.property !== null && typeof this.model[this.property] !== 'undefined')
                {
                    if (parsedColor !== this.model[this.property])
                    {
                        this.model[this.property] = this.color = parsedColor;
                        this.$emit('change', parsedColor, e);
                    }
                }
                else if (parsedColor !== this.initialValue)
                {
                    this.color = parsedColor;
                    this.$emit('change', parsedColor, e);
                }
            },
        },
        watch: {

            initialValue()
            {
                this.resetValue();
            }
        }
    }
</script>

<style lang="scss">

    .color-input-hex {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        .textinput {
            flex-basis: 100%;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0 !important;

            input {
                flex-basis: 80px !important;
                width: 80px !important;
                flex-grow: 0 !important;
                text-transform: uppercase;
            }

            label {
                flex-grow: 1;
                padding: 0 44px 0 0;
            }
        }

        &.disabled .textinput {
            opacity: 0.5;
        }

        .color-picker-wrapper {
            flex-basis: 31px;
            width: 31px;
            height: 31px;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 92px;

            /* position .color-patch and input above each other */
            .color-patch {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                border: var(--forminput-border);
                border-radius: var(--forminput-border-radius);
                transition: border-color .1s;
            }

            input[type="color"] {
                cursor: pointer;
                opacity: 0;
                width: 100%;
                height: 100%;

                &:disabled {
                    cursor: auto;
                }

                &:not(:disabled) {
                    &:active + .color-patch,
                    &:hover + .color-patch,
                    &:focus + .color-patch,
                    &:focus-within + .color-patch {
                        border-color: var(--color-primary-hover);
                    }
                }
            }
        }
    }

</style>
