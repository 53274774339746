/*jshint esversion: 6 */

import Behaviour, {
    CollidableBehaviour,
    MovableBehaviour,
    PhysicsBehaviour,
    TeleportableBehaviour,
}                                   from '@/Models/UnitData/Behaviours/Behaviour';
import PhysicsMaterialProperties    from '@/Models/Unity/PhysicsMaterialProperties';
import DropdownOption               from '@/Utility/DropdownOption';
import { trans }                    from '@/Utility/Helpers';

/**
 * Get the component name for the command property
 *
 * @param {Behaviour} behaviour
 * @returns {String|null}
 */
export function getBehavioursPropertiesComponentName(behaviour) {
    if (!(behaviour instanceof Behaviour))
    {
        return null;
    }
    return new Map([
        [CollidableBehaviour.Type, 'PanelCommandBehaviourChangeCollidable'],
        [MovableBehaviour.Type, 'PanelCommandBehaviourChangeMovable'],
        [PhysicsBehaviour.Type, 'PanelCommandBehaviourChangePhysics'],
        [TeleportableBehaviour.Type, 'PanelCommandBehaviourChangeTeleportable'],
    ]).get(behaviour.type) || null;
}

/**
 * Get the panel component name for the behaviour
 *
 * @param {Behaviour} behaviour
 * @returns {String|null}
 */
export function getPanelComponentName(behaviour) {
    return new Map([
        [CollidableBehaviour.Type, 'PanelBehaviourCollidable'],
        [MovableBehaviour.Type, 'PanelBehaviourMovable'],
        [PhysicsBehaviour.Type, 'PanelBehaviourPhysics'],
        [TeleportableBehaviour.Type, 'PanelBehaviourTeleportable'],
    ]).get(behaviour.type) || null;
}

/**
 * Get the properties for a specific material
 *
 * @param {String} material
 * @returns {Object|null}
 */
export function getPhysicsMaterialCharacteristicsProperties(material) {
    return PhysicsMaterialProperties[material] || null;
}

/**
 * Get the supported behaviour dropdown options for a scene object
 *
 * @param {SceneObject} sceneObject
 * @returns {{}}
 */
export function supportedBehaviourDropdownOptions(sceneObject) {
    const supportedOptions = {};
    const availableOptions = behaviourDropdownOptions();

    sceneObject.supportedBehaviours.forEach(element => {
        supportedOptions[element] = availableOptions[element];
    });

    return supportedOptions;
}

/**
 * Get all behaviour dropdown options
 *
 * @returns {{}}
 */
export function behaviourDropdownOptions() {
    return {
        [CollidableBehaviour.Type]: {
            caption: trans('authoring.behaviours.types.collidable'),
            disabled: false,
            value: 'collidable',
        },
        [MovableBehaviour.Type]: {
            caption: trans('authoring.behaviours.types.movable'),
            disabled: false,
            value: 'movable',
        },
        [PhysicsBehaviour.Type]: {
            caption: trans('authoring.behaviours.types.physics'),
            disabled: false,
            value: 'physics',
        },
        [TeleportableBehaviour.Type]: {
            caption: trans('authoring.behaviours.types.teleportable'),
            disabled: false,
            value: 'teleportable',
        },
    };
}

/**
 * Get the dropdown options for the "enabled" property
 *
 * @returns {[DropdownOption]}
 */
export function targetOptionsForPropertyEnabled() {
    return [

        // ON:
        new DropdownOption({
            caption: trans('labels.on').toUpperCase(),
            disabled: false,
            value: true,
        }),

        // OFF:
        new DropdownOption({
            caption: trans('labels.off').toUpperCase(),
            disabled: false,
            value: false,
        })
    ];
}

/**
 * Get the dropdown options for the "material_characteristics" property
 *
 * @returns {[DropdownOption]}
 */
export function targetOptionsForPropertyMaterialCharacteristics() {
    return Object.keys(PhysicsMaterialProperties).sort().map(characteristic =>
        new DropdownOption({
            caption: trans('authoring.behaviours.material_characteristics.' + characteristic),
            disabled: false,
            value: characteristic,
        })
    );
}
