<template>
    <ul class="asset-preview-usage-overlay">
        <li v-if="canRotate">
            <Icon name="icon_rotate"/>
            {{ trans('assets.preview.usage.rotate') }}
        </li>
        <li v-if="canZoom">
            <Icon name="icon_zoom_in"/>
            {{ trans('assets.preview.usage.zoom') }}
        </li>
    </ul>
</template>

<script lang="ts">

import {defineComponent} from 'vue';
import {trans} from '@/Utility/Helpers';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({
    components: {
        Icon
    },

    props: {
        canZoom: {
            type: Boolean,
            default: false,
        },
        canRotate: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        trans
    },
});

</script>

<style lang="scss">

.asset-preview-usage-overlay {
    position: absolute;
    margin: 0 !important;
    bottom: 16px;
    left: 18px;
    list-style: none;
    background: rgba(255, 255, 255, 0.6);
    border-radius: var(--card-border-radius-small);
    padding: 4px 12px;

    li {
        font-size: var(--font-size-small);
        line-height: 24px;
        text-transform: capitalize;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}

</style>
