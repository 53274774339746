import ServiceIsBusyError from "@/Errors/ServiceIsBusyError";
import SketchfabModel from "@/Models/Asset/Sketchfab/SketchfabModel";
import RequestError from "@/Errors/RequestError";
import ThirdPartyAuthService from "@/Services/ThridPartyAuth/ThirdPartyAuthService";
import SketchfabUser from "@/Models/Asset/Sketchfab/SketchfabUser";
import AuthResult from "@/Services/ThridPartyAuth/AuthResult";
import AuthorizationError from "@/Errors/AuthorizationError";
import {Provider} from "@/Services/ThridPartyAuth/Provider";

export default class SketchfabService {

    private maxFaceCount = 6000;
    private maxFileSize = 1024 * 1024; // 1MB - filters source archive size only - glb may be bigger
    private listModelsUrl = `https://api.sketchfab.com/v3/search
        ?type=models
        &available_archive_type=glb
        &q={{query}}
        &cursor={{cursor}}
        &license=by
        &downloadable=true
        &sort_by=-likeCount
        &archives_flavours=true
        &archives_max_size=${this.maxFileSize}
        &archives_max_face_count=${this.maxFaceCount}`;
    private meUrl = 'https://api.sketchfab.com/v3/me';
    private downloadUrl = 'https://api.sketchfab.com/v3/models/{{uid}}/download';

    private authData: AuthResult | null = null;

    public isAuthenticating: boolean = false;
    public isLoadingModels: boolean = false;
    public isDownloading: boolean = false;
    public models: SketchfabModel[] = [];
    public user: SketchfabUser | null = null;
    public hasNextPage: boolean = true;

    get isAuthenticated(): Boolean {
        return this.authData?.isExpired === false;
    }

    constructor(private authService: ThirdPartyAuthService) {
    }

    /**
     * Prompts the user to authenticate against sketchfab if needed.
     * @param withoutUserInteraction set true if you want to try to use cached auth result
     * @returns the currently logged-in user
     */
    async authenticate(withoutUserInteraction = false): Promise<SketchfabUser> {
        this.isAuthenticating = true;

        try {
            this.authData = await this.authService.requestAuth(Provider.Sketchfab, withoutUserInteraction);

            const response = await fetch(this.meUrl, {
                headers: {
                    'Authorization': `Bearer ${this.authData.token}`
                }
            });

            if (!response.ok) {
                throw new RequestError("wrong status code: " + response.status);
            }

            const responseObject = await response.json();

            this.user = new SketchfabUser(responseObject);
            return this.user;
        } finally {
            this.isAuthenticating = false;
        }
    }

    /**
     * @throws {AuthorizationError} If no user is authenticated. Call authenticate() first.
     */
    async downloadModel(modelUid: string): Promise<Blob> {
        const downloadUrl = await this.getDownloadUrl(modelUid);
        const response = await fetch(downloadUrl);
        return await response.blob();
    }

    /**
     * @returns temp download url for glb version of the given model
     * @throws {AuthorizationError} If no user is authenticated. Call authenticate() first.
     */
    async getDownloadUrl(modelUid: string): Promise<string> {

        if (this.isDownloading) {
            throw new ServiceIsBusyError();
        }

        this.isDownloading = true;

        try {
            const token = this.validatedAuthData().token;

            const url = this.downloadUrl.replace("{{uid}}", modelUid)
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new RequestError("wrong status code: " + response.status);
            }

            const responseObject = await response.json();
            return responseObject.glb.url;
        } finally {
            this.isDownloading = false;
        }
    }

    /**
     * Load sketchfab models into the models property.
     * @param query search query
     * @param fresh If true, the models array will be emptied before adding the new models.
     * Use this when your search query changes, or you want to trigger a reload.
     * If false, the next batch of models will be appended to the existing ones.
     * @return All models currently loaded.
     */
    async getModels(query: string | null, fresh: boolean = true): Promise<SketchfabModel[]> {

        if (this.isLoadingModels) {
            throw new ServiceIsBusyError();
        }

        if (!fresh && !this.hasNextPage) {
            throw new Error("There are no more pages to load. Check 'hasNextPage' before loading more models.");
        }

        if (query === null) {
            query = '';
        }

        const cursor = fresh ? 0 : this.models.length;

        this.isLoadingModels = true;

        try {
            const url = this.listModelsUrl
                .replace(/\s+/g, "")
                .replace("{{cursor}}", cursor.toString())
                .replace("{{query}}", query);
            const response = await fetch(url);

            if (!response.ok) {
                throw new RequestError("wrong status code: " + response.status);
            }

            const responseObject = await response.json();

            const models = responseObject.results
                .map(apiModel => new SketchfabModel(apiModel));

            this.hasNextPage = responseObject.cursors.next !== null;

            if (fresh) {
                this.models = models;
            } else {
                this.models = this.models.concat(models);
            }

            return this.models;

        } finally {
            this.isLoadingModels = false;
        }
    }

    /**
     * @throws {AuthorizationError} If no user is authenticated. Call authenticate() to fix.
     * @private
     */
    private validatedAuthData(): AuthResult {
        if (this.authData === null || !this.isAuthenticated) {
            throw new AuthorizationError();
        }

        return this.authData;
    }
}
