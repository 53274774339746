import type AssetFile from '@/Models/Asset/AssetFile';
import Asset from '@/Models/Asset/Asset';

export class UnknownAsset extends Asset {
    get fileList(): AssetFile[] {
        return [];
    }

    get supportsPreviewImage(): boolean {
        return false;
    }
}
