<template>
    <Modal
        ref="modal"
        :show-close-button="false"
        :title="trans('users.delete.modals.confirm.title')"
        event-type="MODAL_DELETE_USER"
        @show="onShow"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="description" v-html="description" />

        <template #buttons>
            <ButtonSecondary
                caption="users.delete.modals.confirm.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                caption="users.delete.modals.confirm.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import {defineComponent} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import type User from '@/Models/User/User';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';

export default defineComponent({
    components: {
        ButtonSecondary,
        ButtonPrimary,
        Modal,
    },

    data() {
        return {
            /**
             * Reference to the User for which this dialog is being shown
             */
            user: null as User | null,
        };
    },

    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        description() {
            if (this.user === null) {
                return '';
            }

            return trans('users.delete.modals.confirm.description', {
                firstname: this.user.firstname || '',
                lastname: this.user.lastname || '',
                email: this.user.email
            });
        },
    },

    methods: {
        trans,

        onShow(user: User) {
            this.user = user;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();

            this.$globalEvents.emit(
                EventType.MODAL_DELETE_USER_APPLY,
                this.user,
            );
        },
    }
});
</script>

<style lang="scss" scoped>

</style>
