/**
 * Downloads the file pointed to by the given url using the native browser ui.
 * @param fileUrl Url of the file to download. This may be created from a blob object using URL.createObjectURL.
 * @param fileName Optional name of the downloaded file. This may not work depending on browser and url type.
 */
export function downloadFileFromUrl(fileUrl: string, fileName: string | null = null): void {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.rel = "noopener noreferrer";
    link.target = '_blank';

    if (fileName !== null) {
        link.download = fileName;
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
