<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 max-content-width">
                <h1 v-text="headline"></h1>
                <p v-text="headline_sub"></p>
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center d-flex">
                            <div class="col-lg-3 justify-content-center d-flex">
                                <img class="mw-100 illustration" src="/images/undraw_my_password.svg" />
                            </div>
                            <div class="col-lg-7">
                                <form method="post" :action="url">
                                    <input type="hidden" name="token" :value="form.token">
                                    <input v-if="form._token" type="hidden" name="_token" :value="form._token">
                                    <input type="hidden" name="email" :value="form.email">

                                    <UserFormTextInput
                                        :key="'password'+key"
                                        :model="form"
                                        property="password"
                                        :type="passwordFieldType"
                                        name="password"
                                        :required="true"
                                        :maxlength="50"
                                        :minlength="8"
                                        :label="trans('labels.password')"
                                        @change="removeValidationError('password')"
                                        :validationErrors="validationErrors('password')"
                                        labelClasses="col-lg-3 text-md-left text-lg-right no-wrap"
                                        inputSizeClasses="col-lg-9"
                                        :labelHint="trans('tooltips.form_labels.password')"
                                    />

                                    <UserFormTextInput
                                        :key="'password_confirmation'+key"
                                        :model="form"
                                        property="password_confirmation"
                                        :type="passwordFieldType"
                                        name="password_confirmation"
                                        :required="true"
                                        :maxlength="50"
                                        :minlength="8"
                                        :label="trans('labels.password_confirm')"
                                        @change="removeValidationError('password_confirmation')"
                                        :validationErrors="validationErrors('password_confirmation')"
                                        labelClasses="col-lg-3 text-md-left text-lg-right no-wrap"
                                        inputSizeClasses="col-lg-9"
                                    />

                                    <div class="form-group row">
                                        <div class="col-lg-6 offset-lg-3">
                                            <div class="form-check">
                                                <input
                                                    :key="'show_password'+key"
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    name="show_password"
                                                    id="show_password"
                                                    v-model="form.show_password"
                                                    :true-value="true"
                                                    :false-value="false"
                                                >
                                                <label class="form-check-label" for="show_password">
                                                    {{ trans('users.set_initial_password.show_password') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="showWelcomeMailSend"
                                        class="form-group row"
                                    >
                                        <div class="col-lg-9 offset-lg-3">
                                            <span>
                                                <strong>
                                                    {{ trans('users.set_initial_password.new_welcome_email_send') }}
                                                </strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        v-else-if="showWelcomeMailFailed"
                                        class="form-group row textinput error"
                                    >
                                        <div class="col-lg-9 offset-lg-3">
                                            <span class="invalid-feedback error-msg" role="alert">
                                                <strong>{{ trans('users.set_initial_password.failed_to_send_welcome_mail') }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        v-else-if="validationErrors('email').length > 0"
                                        class="form-group row textinput error"
                                    >
                                        <div class="col-lg-9 offset-lg-3">
                                            <span class="invalid-feedback error-msg" role="alert">
                                                <strong>
                                                    {{ trans('users.set_initial_password.token_expired') }}
                                                    <a
                                                        v-if="!isSubmitting"
                                                        href="#" @click.prevent="requestNewWelcomeMail"
                                                    >
                                                        {{ trans('users.set_initial_password.request_new_welcome_mail_link') }}
                                                    </a>
                                                </strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row mb-0">
                                        <div class="col-lg-6 offset-lg-3">
                                            <button type="submit" class="btn btn-primary no-wrap">
                                                {{ trans('users.set_initial_password.complete_registration') }}
                                            </button>
                                            <span class="d-xs-none d-sm-none"></span>
                                        </div>
                                    </div>
                                </form>

                                <div class="login-details">
                                    <p>{{ trans('users.set_initial_password.line_login_details') }}</p>
                                    <ul>
                                        <li v-html="trans('users.set_initial_password.line_login_details_server', {server: server})"></li>
                                        <li v-html="trans('users.set_initial_password.line_login_details_email', {email: email})"></li>
                                    </ul>
                                    <p>{{ trans('users.set_initial_password.line_login_with_details') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { route, trans } from '@/Utility/Helpers';

import UserFormTextInput from '@/Vue/Users/UserFormTextInput';
import axios from 'axios';

export default {
    name: 'SetInitialPasswordPage',
    components: {
        UserFormTextInput,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        sendWelcomeMailUrl: {
            type: String,
            default: null,
        },
        server: {
            type: String,
            default: null,
        },
        token: {
          type: String,
          default: null,
        },
        initial_errors: {
            type: Object,
            default: null,
        },
        csrf: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            form: {
                _token: null,
                token: null,
                email: null,
                password: null,
                password_confirmation: null,
                show_password: null,
            },
            errors: {},
            key: 0,
            showWelcomeMailSend: false,
            showWelcomeMailFailed: false,
            isSubmitting: false,
        }
    },

    beforeMount() {
        if (this.email !== null) {
            this.form.email = this.email;
        }

        if (this.initial_errors !== null) {
            this.errors = this.initial_errors;
        }

        if (this.token !== null) {
            this.form.token = this.token;
        }

        if (this.csrf !== null) {
            this.form._token = this.csrf;
        }
    },

    computed: {
        headline() {
            return trans('users.set_initial_password.headline', { name: this.name });
        },

        headline_sub() {
            return trans('users.set_initial_password.headline_sub');
        },

        passwordFieldType() {
            return this.form.show_password ? 'text' : 'password';
        },
    },

    methods: {

        /**
         * Get the validation errors for a specific field.
         *
         * @param property
         * @returns {array}
         */
        validationErrors(property) {
            return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
        },

        /**
         * Clear the validatin errors for a specific field.
         *
         * @param property
         */
        removeValidationError(property) {
            delete this.errors[property];
        },

        requestNewWelcomeMail() {
            if (!this.isSubmitting) {
                axios.post(this.sendWelcomeMailUrl)
                    .then(() => {
                        this.showWelcomeMailSend = true;
                        this.showWelcomeMailFailed = false;
                    })
                    .catch((_) => {
                        this.showWelcomeMailSend = false;
                        this.showWelcomeMailFailed = true;
                    })
                    .finally(() => {
                        this.key++;
                        this.submitting = false;
                    });

                return;
            }

            this.isSubmitting = true;
        },
    },
}
</script>

<style lang="scss" scoped>

    :deep(label) {
        &.no-wrap {
            white-space: nowrap;
        }
    }

    .illustration {
        align-self: start;
    }

    .card {
        border: none;
        border-radius: 4px;
        margin-top: 32px;
    }

    .card-body {
        padding-top: 80px;
        padding-bottom: 64px;
    }

    .max-content-width {
        max-width: 1035px;
    }

    .login-details {
        margin-top: 48px;

        ul {
            margin-bottom: 24px;
            padding-left: 24px;

            li {}
        }
    }

    @media (min-width: 992px) {
        .col-lg-7 {
            padding-left: 48px;
        }
    }

    .form-group + .form-group.row.textinput.error {
        margin-top: -1rem;
    }

</style>
