import { trans } from '@/Utility/Helpers';

export default class NetworkControlMode
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Check for mandatory properties:
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('NetworkControlMode->constructor(): Invalid data.', attributes);
            throw new TypeError('NetworkControlMode->constructor(): Property "title" has to be set on NetworkControlMode.');
        }
        if (typeof attributes.type !== 'string' || NetworkControlMode.isValidType(attributes.type) === false)
        {
            console.warn('NetworkControlMode->constructor(): Invalid data.', attributes);
            throw new TypeError('NetworkControlMode->constructor(): Property "type" has to be set on NetworkControlMode. Must be a valid type from NetworkControlMode class.');
        }
        if (attributes.description === null || typeof attributes.description !== 'string' || attributes.description.length === 0)
        {
            console.warn('NetworkControlMode->constructor(): Invalid data.', attributes);
            throw new TypeError('NetworkControlMode->constructor(): Property "description" has to be set on NetworkControlMode.');
        }

        // Populate the model:
        this.type = attributes.type;                                              // Type identifier
        this.title = attributes.title;                                            // Translated title of this NetworkControlMode (e.g. "Single learner")
        this.description = attributes.description || null;                        // Translated description of this NetworkControlMode
    }

    /**
     * NetworkControlMode "constants"
     *
     * @returns {NetworkControlMode}
     */
    static get SingleUser()                 { return StaticNetworkControlModes.SingleUser; }
    static get MultiUser()                  { return StaticNetworkControlModes.MultiUser; }

    /**
     * Get all types as an array
     *
     * @returns {NetworkControlMode[]}
     */
    static get all()
    {
        return Object.values(StaticNetworkControlModes);
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        return Object.values(NetworkControlModes).find(m => m.type === type) !== undefined;
    }

    /**
     * Get NetworkControlMode by a given type name
     *
     * @param {String} type
     * @returns {NetworkControlMode|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }
}

/**
 * Type definitions
 */
const StaticNetworkControlModes = {};
const NetworkControlModes = {

    SingleUser: {
        type: 'single_user',
        title: trans('networkcontrolmodes.single_user'),
        description: trans('networkcontrolmodes.single_user_description'),
    },

    Hands: {
        type: 'multi_user',
        title: trans('networkcontrolmodes.multi_user'),
        description: trans('networkcontrolmodes.multi_user_description'),
    }
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in NetworkControlModes)
{
    StaticNetworkControlModes[i] = new NetworkControlMode(NetworkControlModes[i]);
}
