<template>
    <div
        class="reveal-button"
        ref="reveal-button"
    >
        <button
            class="btn btn-reveal-button"
            :class="{ open: open, active: active }"
            v-focusable
            @click="onClick"
        >
            <Icon class="reveal-button-icon" v-if="icon" :name="icon" />
            <span class="reveal-button-caption">{{ caption }}<span class="reveal-button-count" v-if="count">{{ count }}</span></span>
            <Icon name="icon_arrow-down" class="icon-arrow" />
        </button>

        <div
            v-if="open"
            class="reveal-button-content"
        >
            <slot name="body" />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue'
    import Icon from "@/Vue/Common/Icon.vue";

    export default defineComponent({
        name: "RevealButton",
        components: {
            Icon,
        },

        emits: [
            'click',
        ],

        props: {
            caption: {
                type: String,
                default: null,
            },
            icon: {
                type: String,
                default: null,
            },
            active: {
                type: Boolean,
                default: false,
            },
            count: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                open: false,
            }
        },

        mounted() {
            document.addEventListener('click', this.onClickOutside, {capture: true});
        },

        beforeUnmount() {
            document.removeEventListener('click', this.onClickOutside, {capture: true});
        },

        computed: {
            component() {
                return this.$refs['reveal-button'] as HTMLElement;
            },
        },

        methods: {
            onClickOutside(event) {
                let container = this.$el;
                if (container === event.target || !container.contains(event.target)) {
                    if (this.open) {
                        this.open = false;
                    }
                }
            },

            onClick() {
                this.open = !this.open;
            },
        },
    })
</script>

<style scoped lang="scss">
    .reveal-button {
        position: relative;

        .btn-reveal-button {
            display: flex;
            padding: calc(var(--btn-padding-topbottom) - var(--btn-border-width)) calc(var(--btn-padding-leftright) - var(--btn-border-width));
            background-color: var(--background-color-white);
            border-width: var(--btn-border-width);
            border-color: var(--background-color-white);
            border-style: solid;

            .reveal-button-icon {
                margin-left: 0;
            }

            .reveal-button-caption {
                flex-basis: 100%;
                flex-grow: 1;
                white-space: nowrap;
            }

            .reveal-button-count {
                font-family: var(--font-family-mono-bold);
                margin-left: 4px;
            }

            .icon-arrow {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 40px;
                padding: 0 14px;
                transition: transform .1s linear 0s;
                margin-left: 0;
                margin-right: -14px;
            }

            &.active {
                border-style: solid;
                border-color: var(--color-primary);
                color: var(--color-primary);

                &.open,
                &:hover,
                &:focus,
                &:active {
                    border-style: solid;
                    border-color: var(--color-primary-hover);
                    color: var(--color-primary-hover);
                }
            }

            &.open {
                color: var(--color-primary-hover);

                .icon-arrow {
                    transform: rotate(180deg);
                }

                &:hover,
                &:focus,
                &:active {
                    color: var(--color-primary-hover);
                }
            }

            &:hover,
            &:focus,
            &:active {
                border-color: var(--background-color-white);
                color: var(--color-primary-hover);
            }
        }

        .reveal-button-content {
            position: absolute;
            z-index: 1;
            background-color: var(--background-color-white);
            border-radius: var(--card-border-radius-small);
            box-shadow: var(--card-box-shadow);
            padding: 24px;
            margin-top: 10px;

            :deep(.btn) {
                white-space: nowrap;
            }
        }

      &.align-right .reveal-button-content {
        right: 0;
      }
    }
</style>
