import { getTypeOf } from '@/Utility/Helpers';

export default class AbstractError extends Error
{
    /**
     * Constructor
     *
     * @NOTE: Allows multiple different argument signatures
     */
    constructor()
    {
        if (new.target === AbstractError)
        {
            throw new TypeError(`Cannot construct AbstractError instances directly`);
        }

        // Initialize parent class:
        super(...arguments);

        // Parse arguments:
        const params = {};
        [...arguments].forEach(arg => {
            const type = getTypeOf(arg);
            if (params[type] !== undefined)
            {
                console.error('AbstractError->constructor(): Multiple arguments of type ' + type, arguments);
                throw new TypeError('AbstractError->constructor(): Multiple arguments of type ' + type);
            }
            params[type] = arg;
        });

        // Populate the model:
        this.name = 'AbstractError';
        this.message = params.string || this.message || undefined;
        this.originalError = params.error || this.originalError || null;
        this.response = params.object?.response || params.object || this.response || ((this.originalError) ? this.originalError.response || null : null);
        this.callback = params.function || this.callback || ((this.originalError) ? this.originalError.callback || null : null);

        // Remove "Error: " prefix from message:
        if (typeof this.message === 'string')
        {
            this.message = this.message.replace(/^.*?error:\s/i, '');
        }

        // Fix instanceof not working properly for inherited classes:
        Object.setPrototypeOf(this, AbstractError.prototype);
    }
}
