import {cloneDeep} from "lodash";
import AbstractDataObject from '@/Models/AbstractDataObject';
import Operand from '@/Models/UnitData/Variables/Operand';

/* NOTE: New VariableComparison classes have to be added to mapping at the end of this file! */

/**
 * @abstract
 */
export default class VariableComparison extends AbstractDataObject
{
    static get constructorName() { return 'VariableComparison'; }
    static get Type() {
        return 'Comparison';
    }

    /**
     * @return {boolean} True if this comparison is user selectable.
     * Override with false e.g. for non-selectable default comparisons.
     */
    static get ShowInDropdown() {
        return true;
    }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        if (new.target === VariableComparison) {
            throw new TypeError(`Cannot construct VariableComparison instances directly`);
        }

        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && !(attributes instanceof Array)) ? attributes : {};

        this.type = attributes.type;
        this.operand = attributes.operand ? Operand.createFromAttributes(attributes.operand, this) : null;
    }

    get isValid() {
        return this.type === this.constructor.Type
            && this.operand instanceof Operand
            && this.operand.isValid;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        // @NOTE: Override this method on subclasses to make sure a variable comparison only uses valid data
        let hasChanged = false;
        if (this.type !== this.constructor.Type)
        {
            console.info('VariableComparison->cleanUpData(): Changing incorrect type.', this.type, this);
            this.type = this.constructor.Type;
            hasChanged = true;
        }
        if (this.operand !== null && this.operand.cleanUpData())
        {
            hasChanged = true;
        }
        return hasChanged;
    }

    /**
     * Create a new comparison with the given comparisonType or type string
     *
     * @param {String} comparisonType
     * @param {Object} attributes
     * @param {Object} parent               // Parent object reference
     * @returns {VariableComparison}
     */
    static createWithType(comparisonType, attributes = {}, parent = null) {
        const comparisonClass = getComparisonClassFromType(comparisonType);

        // Merge default attributes:
        if (comparisonClass !== null && comparisonClass.defaultAttributes instanceof Object) {
            attributes = {
                ...comparisonClass.defaultAttributes, ...attributes
            };
        }

        // Set the command type:
        attributes = {
            ...attributes,
            ...{
                type: comparisonType,
            }
        };

        return VariableComparison.createFromAttributes(attributes, parent);
    }

    /**
     * Create a new comparison from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent               // Parent object reference
     * @returns {VariableComparison}
     */
    static createFromAttributes(attributes = {}, parent = null) {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        const className = getComparisonClassFromType(clonedAttributes.type);
        return className == null ? null : new className(clonedAttributes, parent);
    }
}

export class BoolEqualsComparison extends VariableComparison {

    static get Type() {
        return 'bool_equal';
    }

    static get ShowInDropdown() {
        return false;
    }
}

export class NumberEqualsComparison extends VariableComparison {

    static get Type() {
        return 'number_equal';
    }
}

export class NumberGreaterOrEqualsComparison extends VariableComparison {

    static get Type() {
        return 'number_greater_or_equal';
    }
}

export class NumberLessOrEqualsComparison extends VariableComparison {

    static get Type() {
        return 'number_less_or_equal';
    }
}

/**
 * ComparisonType to Comparison subclass mapping
 * @type {Map<string, VariableComparison>}
 */
export const comparisonMapping = new Map([
    [BoolEqualsComparison.Type, BoolEqualsComparison],
    [NumberEqualsComparison.Type, NumberEqualsComparison],
    [NumberGreaterOrEqualsComparison.Type, NumberGreaterOrEqualsComparison],
    [NumberLessOrEqualsComparison.Type, NumberLessOrEqualsComparison],
]);

/**
 * @param {String} type variable comparison type
 * @returns {VariableComparison} VariableComparison subclass mapped to the given type.
 */
export function getComparisonClassFromType(type) {

    if (!comparisonMapping.has(type)) {
        throw new Error(`Comparison type "${type}" not mapped to its class!`);
    }

    return comparisonMapping.get(type);
}
