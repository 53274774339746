import {route, trans} from '@/Utility/Helpers';
import Device from '@/Models/Devices/Device';
import type {AxiosRequestConfig} from 'axios';
import axios from 'axios';

export default class DeviceService {

    public isLoading: boolean = false;
    public isSaving: boolean = false;

    private abortController = new AbortController();

    /**
     * Cancel any ongoing requests
     */
    async cancelRequests(): Promise<any> {
        this.abortController.abort();
        this.abortController = new AbortController();

        return Promise.resolve('Requests canceled');
    }

    async fetchDevices(): Promise<Device[]> {
        return axios
            .get(
                route('api.devices.index'),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data.data
                    .map((deviceData: any) => {
                        try {
                            return this.parseDevice(deviceData);
                        } catch (_ex) {
                            return null;
                        }
                    })
                    .filter((device: Device | null) => device !== null) as Device[];
            });
    }

    /**
     * Tries to parse the given device data into a valid device.
     * Will print and throw usable errors when this fails.
     */
    private parseDevice(deviceData: any): Device {
        try {
            return new Device(deviceData);
        } catch (ex) {
            console.error(
                'DeviceService->parseDevice(): API returned invalid or incompatible device data.',
                deviceData,
                ex
            );
            throw new Error(trans('errors.device.invalid_data'));
        }
    }
}
