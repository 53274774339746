<template>
    <div class="toast-notification-content">
        <Icon v-if="options?.icon" :name="options.icon.toString()" class="Vue-Toastification__icon"/>
        <div class="Vue-Toastification__toast-body" v-html="text"></div>
    </div>
</template>

<script lang="ts">
    import Icon from "@/Vue/Common/Icon.vue";
    import {defineComponent, PropType} from "vue";
    import type {ToastOptions} from "vue-toastification/dist/types/types";

    export default defineComponent({
        name: 'ToastNotification',
        components: {
            Icon
        },
        props: {
            text: {
                type: String,
                default: null
            },
            options: {
                type: Object as PropType<ToastOptions>,
                default: {}
            }
        }
    });
</script>

<style lang="scss" scoped>

    .Vue-Toastification__icon {
        display: unset;
        flex-basis: 20px;
        width: 20px;
        height: 20px;
        margin: auto 8px auto 0;
    }

    .toast-notification-content {
        display: flex;
        justify-content: start;
        align-items: center;
        min-height: 24px;

        :deep(em) {
            font-style: normal;
        }
    }

</style>
