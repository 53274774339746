<template>
    <ModalApplyCancel
        ref="modal"
        :description="description"
        :title="trans('modals.delete_managed_session.title')"
        event-type="MODAL_DELETE_MANAGED_SESSION"
        @show="onShow"
    />
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import type ManagedSession from '@/Models/Sessions/ManagedSession';

export default defineComponent({
    components: {
        ModalApplyCancel,
    },

    data() {
        return {
            description: null as string | null,
        };
    },

    methods: {
        trans,

        onShow(managedSession: ManagedSession) {
            this.description = trans('modals.delete_managed_session.description', {
                title: managedSession.title
            });
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
