<template>
    <table class="token-list">
        <thead>
        <tr>
            <th class="name">{{ trans('labels.name') }}</th>
            <th class="scopes">{{ trans('labels.scopes') }}</th>
            <th>{{ trans('labels.updated_at') }}</th>
            <th>{{ trans('labels.expires_at') }}</th>
            <th>{{ trans('labels.access_token') }}</th>
            <th v-if="canDeleteTokens"></th>
        </tr>
        </thead>
        <tbody>
        <ApiTokenListItem
            v-for="token in tokens"
            :key="token.uid"
            :allow-revoke="canDeleteTokens"
            :token="token"
            @revoke="onRevokeTriggered"
            @view-access-token="onViewAccessTokenTriggered"/>
        </tbody>
    </table>
</template>

<script>

    // Import VueJS components:
    import ApiTokenListItem from '@/Vue/Tokens/ApiTokenListItem';

    // Import classes:
    import {permission} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';

    export default {
        name: 'ApiTokenList',
        
        emits: [
            'revoke-access-token',
            'view-access-token',
        ],

        props: {
            tokenList: {
                type: Array,
                default: []
            }
        },

        components: {
            ApiTokenListItem
        },

        computed: {
            tokens() {
                // sort tokens on update date - the newest first
                return this.tokenList.sort((a, b) => b.updated_at.valueOf() - a.updated_at.valueOf());
            },

            canDeleteTokens() {
                return permission(Permission.TokensDelete())
            },
        },

        methods: {

            /**
             * @param {Token} token
             */
            onRevokeTriggered(token) {
                this.$emit('revoke-access-token', token);
                return this;
            },

            /**
             * @param {Token} token
             */
            onViewAccessTokenTriggered(token) {
                this.$emit('view-access-token', token);
                return this;
            },

        },
    }
</script>

<style lang="scss" scoped>

    .token-list {
        width: 100%;
    }

    .token-list,
    .token-list thead,
    .token-list tbody {
        display: block;
    }

    .token-list tr {
        display: flex;
        margin-bottom: 1em;
    }

    .token-list th {
        flex: 1;
        font-family: var(--font-family-condensed-demibold);
        font-weight: normal;
    }

    .token-list th {
        margin-bottom: 1em;
        text-align: center;
    }

    .token-list th.name {
        flex: 1.5;
        text-align: start;
    }

    .token-list th.scopes {
        flex: 2;
    }

</style>
