export function getSelectedText(): string {

    // Check if an input or textarea element is focused
    const activeElement: Element | null = document.activeElement;
    if (activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement) {

        // @NOTE: Only works for type text|search|password|tel|url

        const start: number | null = activeElement.selectionStart;
        const end: number | null = activeElement.selectionEnd;
        if (start !== null && end !== null) {
            return activeElement.value.substring(start, end);
        }

        // General selection on the page
    } else if (window.getSelection) {
        return window.getSelection()?.toString() || '';
    }

    return '';
}
