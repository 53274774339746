import User from "@/Models/User/User";
import CourseWithoutRelations from "@/Models/Course/CourseWithoutRelations";

export default class Course extends CourseWithoutRelations {

    public readonly unit_uids: string[];

    /**
     * UUIDs of enrolled users. Only includes users from the current tenant.
     */
    private enrollments: Set<string> = new Set();

    private preview_upload_file: File | null = null;

    static get constructorName() {
        return 'Course';
    }

    constructor(attributes: any = {}) {

        super(attributes);

        this.unit_uids = attributes.unit_uids || [];

        this.setEnrollments(attributes.enrollments || []);

        // Hidden attributes
        // (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        [
            'preview_upload_file',
        ].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));
    }

    /**
     * Get the constructor name from the instance's class
     */
    get constructorName(): string {
        return Course.constructorName;
    }

    get hasUnits(): boolean {
        return this.numberOfUnits > 0;
    }

    get numberOfUnits(): number {
        return this.unit_uids.length;
    }

    /**
     * Get preview image for uploading
     */
    get previewImageForUpload(): File | null {
        return this.preview_upload_file || null;
    }

    /**
     * Set preview image for uploading
     */
    set previewImageForUpload(file: File | null) {
        this.preview_upload_file = (file instanceof File) ? file : null;
    }

    /**
     * @return {Object} Properties that should be sent to the update course api endpoint.
     * You have to add the properties of a revision manually.
     */
    get updateApiProperties(): object {
        return {
            'uid': this.uid,
            'policy': this.policy,
            'title': this.title,
            'description': this.description,
            'auto_enrollment': this.auto_enrollment,
            'unit_uids': this.unit_uids,
        };
    }

    get hasEnrolledUsers(): boolean {
        return this.enrollments.size >= 1;
    }

    get numberOfEnrollments(): number {
        return this.enrollments.size;
    }

    isEnrolled(user: User | string): boolean {
        const userUid = typeof user === 'string' ? user : user.uid;
        return this.enrollments.has(userUid);
    }

    /**
     * Set enrolled users
     */
    setEnrollments(learnerUids: string[]) {
        this.enrollments = new Set(learnerUids);
    }

    /**
     * Add enrolled users
     */
    addEnrollments(newLearners: User[]) {
        newLearners.forEach(learner => this.enrollments.add(learner.uid));
    }

    /**
     * Remove enrolled users
     */
    removeEnrollments(learnersToRemove: User[]) {
        learnersToRemove.forEach(learner => this.enrollments.delete(learner.uid));
    }
}
