<template>
    <tr
        class="sketchfab-model"
        :key="model.uid"
        @click.stop.prevent="showModelPreview">

        <td class="preview-cell">
            <span
                class="preview preview-image-pointer preview-cell-image-wrapper">
                <Icon name="icon_preview"/>
                <img
                    :src="model.imageUrlMini"
                    :alt="model.description"
                />
            </span>
        </td>

        <td class="asset-info-cell">
            <div class="asset-info" :title="assetInfoTooltip">
                <h4 class="asset-info-title">{{ model.name }}</h4>
                <p class="asset-info-description">{{ model.description }}</p>
                <p class="asset-info-description">{{ tagsString }}</p>
            </div>
        </td>

        <td class="small">
            <span class="cell-title">{{ trans('labels.artist') }}</span>
            <span class="cell-description">{{ model.userName }}</span>
        </td>

        <td class="small">
            <span class="cell-title">{{ trans('labels.size') }}</span>
            <span class="cell-description">{{ formatBytes(model.fileSize.toString(), 0) }}</span>
        </td>

        <td class="import-cell">
            <ButtonSecondary
                :caption="trans('labels.import')"
                :disabled="!isDownloadEnabled"
                @trigger="importModel"
            />
        </td>
    </tr>
</template>

<script>

import SketchfabModel from "@/Models/Asset/Sketchfab/SketchfabModel";
import {formatBytes, trans} from "@/Utility/Helpers";
import EventType from "@/Utility/EventType";
import {toRaw} from "vue";
import ButtonSecondary from "@/Vue/Common/ButtonSecondary.vue";

export default {
    name: "SketchfabListItem",

    components: {
        ButtonSecondary
    },

    props: {
        model: {
            type: SketchfabModel,
            default: null
        },
        isDownloadEnabled: {
            type: Boolean,
            default: true
        }
    },

    emits: [
        'import'
    ],

    computed: {

        tagsString() {
            return this.model.tags.map(tag => '#' + tag).join(', ');
        },

        assetInfoTooltip() {
            return this.model.description + '\n\n' + this.tagsString;
        }
    },

    methods: {
        trans,
        formatBytes,

        showModelPreview() {
            this.$globalEvents.emit(EventType.MODAL_SKETCHFAB_MODEL_PREVIEW_SHOW, toRaw(this.model));
        },

        importModel() {
            this.$emit('import', this.model);
        },
    }
}
</script>

<style scoped lang="scss">

.sketchfab-model {

    td.small {
        width: 130px;

        .cell-description {
            overflow-wrap: break-word;
        }
    }

    td.import-cell {
        padding-left: 12px;
        padding-right: 12px;
    }

}

.sketchfab-model:hover .preview {
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color-primary-hover-transparent);
    }

    .icon {
        display: block !important;
    }
}

</style>
