export default class Position
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.x = (typeof attributes.x === 'number') ? attributes.x : 0; // Position X
        this.y = (typeof attributes.y === 'number') ? attributes.y : 0; // Position Y
        this.z = (typeof attributes.z === 'number') ? attributes.z : 0; // Position Z
    }

    /**
     * @returns {boolean}
     */
    get isDefault() {
        return this.x === 0
            && this.y === 0
            && this.z === 0
        ;
    }

    /**
     * @param {Position} position
     * @returns {boolean}
     */
    isEqualTo(position) {
        return position instanceof Position
            && this.x === position.x
            && this.y === position.y
            && this.z === position.z
        ;
    }
}
