import {Feature} from "@/Models/Features/Feature";

export default class FeatureRepository {

    constructor(protected enabledFeaturesMap: Record<string, boolean | undefined>) {
    }

    active(feature: Feature): boolean {
        const featureName = Feature.getName(feature);
        return this.enabledFeaturesMap[featureName] === true;
    }

    inactive(feature: Feature): boolean {
        return !this.active(feature);
    }

    allActive(features: Set<Feature>|Feature[]): boolean {
        return [...features].every(feature => this.active(feature));
    }
}
