import { trans, uuid4 } from '@/Utility/Helpers';

/**
 * Generate a UUID so we can later check if pasting happens from a different browser tab
 *
 * @private
 * @type {string}
 */
const clipboardInstanceUID = uuid4();

/**
 * Does the browser support the clipboard API?
 *
 * @private
 * @type {boolean}
 */
let supportsClipboardApi = false;

/**
 * May the browser read from clipboard through the clipboard API?
 *
 * @private
 * @type {boolean}
 */
let hasPermissionRead = false;

/**
 * May the browser write to the clipboard through the clipboard API?
 *
 * @private
 * @type {boolean}
 */
let hasPermissionWrite = false;

/**
 * Change handler for the permission states (e.g. when user grants or revokes clipboard permissions in the browser)
 *
 * @private
 * @param {Event} e
 */
const onChangePermissionState = function(e) {
    switch (e.currentTarget.name)
    {
        case 'clipboard-read':
        case 'clipboard_read':
            hasPermissionRead = (e.currentTarget.state === 'granted');
            break;

        case 'clipboard-write':
        case 'clipboard_write':
            hasPermissionWrite = (e.currentTarget.state === 'granted');
            break;
    }
};

/**
 * Initial check for clipboard API browser support
 *
 * @private
 * @async
 * @returns {Promise<boolean>}
 */
(async () => {
    if (!Boolean(window.isSecureContext && navigator.clipboard && navigator.permissions && navigator.permissions.query))
    {
        supportsClipboardApi = false;
        //console.warn('Clipboard API is not supported.');
        return Promise.resolve(false);
    }
    try
    {
        const readPermissionState = await navigator.permissions.query({name: 'clipboard-read'});
        const writePermissionState = await navigator.permissions.query({name: 'clipboard-write'});
        hasPermissionRead = (readPermissionState.state === 'granted');
        hasPermissionWrite = (writePermissionState.state === 'granted');
        supportsClipboardApi = true;
        readPermissionState.onchange = onChangePermissionState;
        writePermissionState.onchange = onChangePermissionState;
    }
    catch (exception)
    {
        hasPermissionRead = false;
        hasPermissionWrite = false;
        supportsClipboardApi = false;
        //console.warn('Clipboard API is not fully supported.', exception);
    }
    return Promise.resolve(supportsClipboardApi);
})();

/**
 * ClipboardData class
 */
export class ClipboardData
{
    /**
     * Constructor
     *
     * @param {*} data                      // Actual data to be copied into the clipboard
     * @param {?string} clipboardUID        // Reference UID to the clipboard instance the data was copied from
     * @param {?string} appVersion          // Version of the WMS that the data was copied from
     */
    constructor(data, clipboardUID = null, appVersion = null) {

        // Actual data to be copied into the clipboard:
        Object.defineProperty(this, 'data', {value: data, enumerable: true, writable: false, configurable: false});

        // Reference UID to the clipboard instance the data was copied from:
        Object.defineProperty(this, 'clipboardUID', {value: clipboardUID || clipboardInstanceUID, enumerable: true, writable: false, configurable: false});

        // Version of the WMS application that the data was copied from:
        Object.defineProperty(this, 'appVersion', {value: appVersion || window.appData.APP_VERSION, enumerable: true, writable: false, configurable: false});
    }

    /**
     * Whether the data is an array of values or objects
     *
     * @returns {boolean}
     */
    get isArray() { return (this.data instanceof Array); }

    /**
     * Whether the data is a boolean value
     *
     * @returns {boolean}
     */
    get isBoolean() { return (typeof this.data === 'boolean'); }

    /**
     * Whether the data is empty
     *
     * @returns {boolean}
     */
    get isEmpty() { return (this.data === null || (this.isArray && this.data.length === 0) || (this.isObject && Object.keys(this.data).length === 0)); }

    /**
     * Whether the data is a model object (e.g. can be parsed into a model instance)
     *
     * @returns {boolean}
     */
    get isModel() { return (this.isObject && typeof this.data.constructorName === 'string'); }

    /**
     * Check if the data has a specific constructor name
     *
     * @param {object} constructor
     * @returns {boolean}
     */
    isInstanceOf(constructor) { return (this.isModel && this.data.constructorName === constructor.constructorName); }

    /**
     * Whether the data is a number
     *
     * @returns {boolean}
     */
    get isNumber() { return (typeof this.data === 'number'); }

    /**
     * Whether the data is an object
     *
     * @returns {boolean}
     */
    get isObject() { return (this.data instanceof Object && !(this.data instanceof Array)); }

    /**
     * Whether the data is a string
     *
     * @returns {boolean}
     */
    get isString() { return (typeof this.data === 'string'); }

    /**
     * Convert the object into a serialized string
     *
     * @returns {string}
     */
    serialize() {
        // @TODO encrypt
        return JSON.stringify({
            data: this.data,
            clipboardUID: this.clipboardUID,
            appVersion: this.appVersion,
        });
    }

    /**
     * Deserialize a string into a ClipboardData instance
     *
     * @param {string} input
     * @returns {ClipboardData}
     */
    static deserialize(input) {
        if (typeof input !== 'string')
        {
            console.error('ClipboardData->deserialize(): Invalid input data.', input);
            throw new TypeError('ClipboardData->deserialize(): Invalid input data.');
        }
        // @TODO decrypt
        try
        {
            const data = JSON.parse(input);
            // @TODO validation etc.
            return new ClipboardData(data.data, data.clipboardUID || null, data.appVersion || 'unknown');
        }
        catch (exception)
        {
            //console.error('ClipboardData->deserialize(): Invalid input data.', input);
            throw exception;
        }
    }
}

/**
 * Clipboard class
 *
 * @static
 */
export default class Clipboard
{
    /**
     * Constructor
     *
     * @NOTE: This class is static since there is only one clipboard being used by the operating system
     */
    constructor() {
        if (this.constructor === Clipboard) {
            throw new TypeError('Static class "Clipboard" cannot be instantiated directly.');
        }
    }

    /**
     * Get the clipboard instance's UUID
     *
     * @static
     * @returns {string}
     */
    static get instanceUID() { return clipboardInstanceUID; }

    /**
     * Does the browser support the clipboard API?
     *
     * @static
     * @returns {boolean}
     */
    static get supportsClipboardApi() { return supportsClipboardApi; }

    /**
     * May the browser read from clipboard through the clipboard API?
     *
     * @static
     * @returns {boolean}
     */
    static get hasPermissionRead() { return hasPermissionRead; }

    /**
     * May the browser write to the clipboard through the clipboard API?
     *
     * @static
     * @returns {boolean}
     */
    static get hasPermissionWrite() { return hasPermissionWrite; }

    /**
     * Get the ClipboardData object from a ClipboardEvent
     *
     * @NOTE: This is needed for browsers that don't support the clipboard API
     *
     * @static
     * @param {ClipboardEvent} e
     * @returns {?ClipboardData}
     */
    static getDataFromClipboardEvent(e) {
        if (!(e instanceof ClipboardEvent))
        {
            throw new TypeError('Clipboard->getTextFromClipboardEvent(): Invalid parameter. Can only be used with ClipboardEvent instances.');
        }
        try
        {
            const text = (e.clipboardData || window.clipboardData).getData('text/plain');
            const dataObject = ClipboardData.deserialize(text);
            // Prevent browser behaviour if it's a valid ClipboardData object, so it won't be inserted into inputs or textareas:
            e.preventDefault();
            return dataObject;
        }
        catch (exception)
        {
            //console.error(exception);
            return null;
        }
    }

    /**
     * Read ClipboardData from clipboard
     *
     * @static
     * @async
     * @returns {Promise<?ClipboardData>}
     */
    static async getClipboardDataAsync() {
        if (!supportsClipboardApi)
        {
            return Promise.reject('Clipboard->getClipboardDataAsync(): Reading from clipboard is not supported.');
        }
        if (!hasPermissionRead)
        {
            return Promise.reject('Clipboard->getClipboardDataAsync(): Reading from clipboard is not allowed.');
        }
        try
        {
            return ClipboardData.deserialize(await navigator.clipboard.readText());
        }
        catch (exception)
        {
            //console.error('Failed to read clipboard contents.', exception);
            return Promise.reject(null);
        }
    }

    /**
     * Write data to clipboard. If it is not yet an instance of ClipboardData,
     * it will be wrapped in its data structure.
     *
     * @async
     * @param {*} data
     * @returns {Promise<boolean>}
     */
    static async setClipboardDataAsync(data) {

        // Convert to ClipboardData object and serialize to string:
        const clipboardData = (
            (data instanceof ClipboardData)
                ? data
                : (
                    (data instanceof Object && data.toClipboardData instanceof Function)
                        ? data.toClipboardData()
                        : new ClipboardData(data)
                )
        );

        return Clipboard.setClipboardStringAsync(
            clipboardData.serialize(),
            data.clipboardTitle || null
        );
    }

    /**
     * Write string to clipboard. The given string will be copied as plain text and
     * not be wrapped in any data structure.
     *
     * @async
     * @param {String} clipboardString
     * @param {String|null} displayTitle
     * @returns {Promise<boolean>}
     */
    static async setClipboardStringAsync(clipboardString, displayTitle = null) {

        const displayString = displayTitle
            ? trans('labels.copied_to_clipboard', {placeholder: displayTitle}, false)
            : trans('labels.copied_to_clipboard_default');

        // Use clipboard API:
        if (supportsClipboardApi)
        {
            if (!hasPermissionWrite)
            {
                return Promise.reject('Clipboard->_setClipboardStringAsync(): Writing to clipboard is not allowed.');
            }
            try
            {
                await navigator.clipboard.writeText(clipboardString);
                //console.log('Data copied to clipboard.', clipboardData);
                window.toast(displayString, {timeout: 1500, closeButton: false});
                return Promise.resolve(true);
            }
            catch (exception)
            {
                //console.error('Failed to copy to clipboard.', exception);
                return Promise.reject(exception);
            }
        }

        // Fallback for browsers that don't support the clipboard API:
        // @see: https://stackoverflow.com/a/30810322
        const activeFocusElement = document.activeElement;
        const textarea = document.createElement('textarea');
        textarea.id = 'clipboard-copy-helper';
        textarea.value = clipboardString;
        textarea.textContent = clipboardString;
        textarea.style.setProperty('position', 'fixed', 'important');
        textarea.style.setProperty('top', '-20px', 'important');
        textarea.style.setProperty('left', '-20px', 'important');
        textarea.style.setProperty('width', '10px', 'important');
        textarea.style.setProperty('height', '10px', 'important');
        textarea.style.setProperty('border', '0 none', 'important');
        textarea.style.setProperty('padding', '0', 'important');
        textarea.style.setProperty('margin', '0', 'important');
        try
        {
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            const copied = document.execCommand('copy');
            //console.log('Fallback data copied to clipboard.', clipboardData, copied);
            window.toast(displayString, {timeout: 1500, closeButton: false});
            return Promise.resolve(copied);
        }
        catch (exception)
        {
            return Promise.reject(exception);
        }
        finally
        {
            // Clean up:
            document.body.removeChild(textarea);

            // Give focus back to previously selected element:
            if (activeFocusElement !== null)
            {
                activeFocusElement.focus();
            }
        }
    }
}
