<template>
    <li @click.stop="$emit('click', revision)" @dblclick.stop="$emit('dblclick', revision)">
        <span class="preview">
            <img :src="revision.preview_thumbnail" alt="" />
        </span>

        <section class="info">
            <h4 class="title">{{ revision.title }}</h4>
            <p class="description" :title="revision.description">{{ revision.description }}</p>
        </section>
    </li>
</template>

<script>

import UnitRevision from '@/Models/Unit/UnitRevision';

export default {
    name: 'SidepanelUnitRevisionsListItem',
    emits: [
        'click',
        'dblclick'
    ],
    props: {
        revision: {
            type: UnitRevision,
            default() {
                return new UnitRevision();
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
