import AssetType from '@/Models/Asset/AssetType';

export default class CommandTargetType
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || CommandTargetType.isValidType(attributes.type) === false)
        {
            console.warn('CommandTargetType->constructor(): Invalid data.', attributes);
            throw new TypeError('CommandTargetType->constructor(): Property "type" has to be set on CommandTargetType. Must be a valid type from CommandTargetType class.');
        }

        // Populate the model:
        this.type = attributes.type;                // Type identifier
        this.filters = attributes.filters || null;  // List of FilterCategory names
        this.assetTypes = attributes.assetTypes || null;    // List of AssetTypes that can be selected for this CommandTargetType
    }

    /**
     * CommandTargetType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {CommandTargetType}
     */
    static get Asset()                          { return StaticCommandTargetTypes.Asset; }
    static get Condition()                      { return StaticCommandTargetTypes.Condition; }
    static get Image()                          { return StaticCommandTargetTypes.Image; }
    static get Model3d()                        { return StaticCommandTargetTypes.Model3d; }
    static get Module()                         { return StaticCommandTargetTypes.Module; }
    static get Scene()                          { return StaticCommandTargetTypes.Scene; }
    static get SceneObject()                    { return StaticCommandTargetTypes.SceneObject; }
    static get SceneObject_AI()                 { return StaticCommandTargetTypes.SceneObject_AI; }
    static get SceneObject_Asset()              { return StaticCommandTargetTypes.SceneObject_Asset; }
    static get SceneObject_AssetHotspotParticles()  { return StaticCommandTargetTypes.SceneObject_AssetHotspotParticles; }
    static get SceneObject_Behaviour()          { return StaticCommandTargetTypes.SceneObject_Behaviour; }
    static get SceneObject_CharacterModel3d()   { return StaticCommandTargetTypes.SceneObject_CharacterModel3d; }
    static get SceneObject_Hotspot()            { return StaticCommandTargetTypes.SceneObject_Hotspot; }
    static get SceneObject_Module()             { return StaticCommandTargetTypes.SceneObject_Module; }
    static get Sound()                          { return StaticCommandTargetTypes.Sound; }
    static get Text()                           { return StaticCommandTargetTypes.Text; }
    static get Trigger()                        { return StaticCommandTargetTypes.Trigger; }
    static get Variable()                       { return StaticCommandTargetTypes.Variable; }
    static get Video()                          { return StaticCommandTargetTypes.Video; }
    static get Waypoint()                       { return StaticCommandTargetTypes.Waypoint; }

    /**
     * Get all command target types as an array
     *
     * @returns {CommandTargetType[]}
     */
    static get all()
    {
        return Object.values(StaticCommandTargetTypes);
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in CommandTargetTypes)
        {
            if (CommandTargetTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }

    /**
     * Get CommandTargetType by a given type name
     *
     * @param {String} type
     * @returns {CommandTargetType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }
}

/**
 * Type definitions
 */
const StaticCommandTargetTypes = {};
const CommandTargetTypes = {

    Asset: {
        type: 'asset',
        filters: [
            'Assets'    // from SceneObjectFilters
        ],
        assetTypes: AssetType.allUploadable,
    },

    Condition: {
        type: 'condition',
        filters: null,
        assetTypes: null,
    },

    Image: {
        type: 'image',
        filters: [
            'Images'    // from SceneObjectFilters
        ],
        assetTypes: [
            AssetType.Image
        ],
    },

    Model3d: {
        type: 'model3d',
        filters: [
            'Model3d'   // from SceneObjectFilters
        ],
        assetTypes: [
            AssetType.Model3D
        ],
    },

    Module: {
        type: 'widget',
        filters: [
            'Modules'   // from SceneObjectFilters
        ],
        assetTypes: null,
    },

    Scene: {
        type: 'scene',
        filters: null,
        assetTypes: null,
    },

    SceneObject: {
        type: 'sceneObject',
        filters: null,
        assetTypes: null,
    },

    SceneObject_AI: {
        type: 'sceneObject_ai',
        filters: null,
        assetTypes: null,
    },

    SceneObject_Asset: {
        type: 'sceneObject_asset',
        filters: null,
        assetTypes: AssetType.allUploadable,
    },

    SceneObject_AssetHotspotParticles: {
        type: 'sceneObject_asset_hotspot_particles',
        filters: null,
        assetTypes: AssetType.allUploadable,
    },

    SceneObject_Behaviour: {
        type: 'sceneObject_behaviour',
        filters: null,
        assetTypes: null,
    },

    SceneObject_CharacterModel3d: {
        type: 'sceneObject_character_model3d',
        filters: null,
        assetTypes: null,
    },

    SceneObject_Hotspot: {
        type: 'sceneObject_hotspot',
        filters: null,
        assetTypes: null,
    },

    SceneObject_Module: {
        type: 'sceneObject_module',
        filters: null,
        assetTypes: null,
    },

    Sound: {
        type: 'sound',
        filters: [
            'Sounds', // from SceneObjectFilters
            'TextToSpeechSounds', // from SceneObjectFilters
        ],
        assetTypes: [
            AssetType.Sound,
            AssetType.SoundTts,
        ],
    },

    Text: {
        type: 'text',
        filters: null,
        assetTypes: null,
    },

    Trigger: {
        type: 'trigger',
        filters: null,
        assetTypes: null,
    },

    Variable: {
        type: 'variable_module',
        filters: [
            'VariableModules'   // from SceneObjectFilters
        ],
        assetTypes: null,
    },

    Video: {
        type: 'video',
        filters: [
            'Videos'    // from SceneObjectFilters
        ],
        assetTypes: [
            AssetType.Video,
        ],
    },

    Waypoint: {
        type: 'waypoint',
        filters: null,
        assetTypes: null,
    },
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in CommandTargetTypes)
{
    StaticCommandTargetTypes[i] = new CommandTargetType(CommandTargetTypes[i]);
}
