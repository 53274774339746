<template>
    <table class="table">
        <thead>
        <tr>
            <th>Key</th>
            <th>EN</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="translation in translations">
            <td v-if="translation !== null" class="key">{{ translation.key }}</td>
            <td v-if="translation !== null">{{ translation.en }}</td>
            <td v-if="translation === null" class="space" colspan="2"></td>
        </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
    import {trans} from "@/Utility/Helpers";
    import {defineComponent} from "vue";

    type Translation = {
        key: string,
        en: string,
    }

    export default defineComponent({

        computed: {

            translations(): (Translation | null)[] {
                return this.parseTranslations(window.i18n);
            }

        },

        methods: {
            trans,

            parseTranslations(translations: Record<string, string | Record<string, string>>, currentKey: string = "", results: (Translation | null)[] = []) {
                for (const key in translations) {
                    const value = translations[key];
                    const newKey = currentKey ? `${currentKey}.${key}` : key;

                    if (typeof value === "string") {
                        results.push({key: newKey, en: value});
                    } else {
                        // move in to a new group
                        if (results[results.length - 1] !== null) {
                            results.push(null);
                        }

                        this.parseTranslations(value, newKey, results);

                        // move out from an old group
                        if (results[results.length - 1] !== null) {
                            results.push(null);
                        }
                    }
                }

                return results;
            }
        },

    });
</script>

<style lang="scss" scoped>
    .table td {
        padding: 4px 0.75rem;
    }

    td.key {
        font-family: var(--font-family-mono-regular);
        font-size: var(--font-size-small);
    }

    td.space {
        background-color: var(--background-color-light);
        border: none;
        padding: 2px;
    }

    thead > tr {
        background-color: var(--color-anthracite);
        color: white;
        font-family: var(--font-family-default);
        font-weight: 400;
    }

    tbody {
        background-color: white;
    }
</style>
