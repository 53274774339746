import {parseDate, uuid4} from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';
import UnitRevision from '@/Models/Unit/UnitRevision';
import UnitPermissionPolicy, {UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
import User from '@/Models/User/User';
import UnitLink from "@/Models/Unit/UnitLink";

export default class Unit extends AbstractDataObject
{
    static get constructorName() { return 'Unit'; }

    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     */
    constructor(attributes = {})
    {
        super();

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        [
            'selected',
            'is_new',
        ].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Populate the model:
        /**
         * Unique ID
         * @type {String}
         */
        this.uid = attributes.uid || uuid4();

        /**
         * Unit policy (e.g. 'standard', 'sample')
         * @type {string}
         */
        this.policy = attributes.policy || UnitPermissionPolicyStandard.type;

        /**
         * ID of the associated owner
         * @type {string|null}
         */
        this.owned_by = attributes.owned_by || null;

        /**
         * Associated owner User model
         * @type {User|null}
         */
        this.owner = (attributes.owner) ? new User(attributes.owner) : null;

        /**
         * ID of the associated tenant
         * @type {string|null}
         */
        this.owned_by_tenant = attributes.owned_by_tenant || null;

        /**
         * List of uids of users assigned for editing
         * @type {string[]}
         */
        this.authors = attributes.authors || [];

        /**
         * Created date
         * @type {Date|Null}
         */
        this.created_at = parseDate(attributes.created_at || null);

        /**
         * Updated date
         * @type {Date|Null}
         */
        this.updated_at = parseDate(attributes.updated_at || null);

        /**
         * Last fetched from API date
         * @type {Date|Null}
         */
        this.fetched_at = parseDate(attributes.fetched_at || null);

        // Hidden and UI-related
        /**
         * Newly added state (hidden)
         * @type {boolean}
         */
        this.is_new = (typeof attributes.is_new === 'boolean') ? attributes.is_new : false;

        /**
         * Selected state (hidden)
         * @type {boolean}
         */
        this.selected = (typeof attributes.selected === 'boolean') ? attributes.selected : false;

        // Revisions
        /**
         * @type {string|null}
         */
        this.latest_revision_uid = attributes.latest_revision_uid || null;

        /**
         * @type {string|null}
         */
        this.latest_released_revision_uid = attributes.latest_released_revision_uid || null;

        /**
         * @type {UnitRevision[]}
         */
        this.revisions = (attributes.revisions || []).map(r => new UnitRevision(r, this));

        /**
         * @type {string|null}
         */
        this.source_reference = attributes.source_reference || null;

        /**
         * @type {UnitLink[]}
         */
        this.unit_links = (attributes.unit_links || []).map(unitLink => new UnitLink(unitLink));
    }

    /**
     * Create a new Unit from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent
     * @returns {Unit}
     */
    static createFromAttributes(attributes = {}, parent = null)
    {
        return new (this)(...arguments);
    }

    /**
     * Clean up data (e.g. remove empty components from objects)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        const latestRevision = this.latestRevision;
        return latestRevision && latestRevision.cleanUpData();
    }

    /**
     * Does the unit have unreleased changes?
     *
     * @returns {Boolean}
     */
    get hasUnreleasedChanges() {
        return this.isReleased && (this.latest_revision_uid !== this.latest_released_revision_uid);
    }

    /**
     * Is the unit in a draft state?
     *
     * @returns {Boolean}
     */
    get isDraft() {
        return (this.latest_released_revision_uid === null);
    }

    /**
     * Has the unit been released yet?
     *
     * @returns {Boolean}
     */
    get isReleased() {
        return (this.latest_released_revision_uid !== null);
    }

    /**
     * Check if the unit is from a user guiding tour
     *
     * @returns {Boolean}
     */
    get isUserGuidingUnit() {
        return this.source_reference !== null;
    }

    /**
     * Check if the unit is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // The latest revision must be valid
        const latestRevision = this.latestRevision;
        return latestRevision && latestRevision.isValid;
    }

    /**
     * Get the latest revision
     *
     * @returns {UnitRevision|null}
     */
    get latestRevision() {
        return (this.latest_revision_uid === null) ? null : this.revisions.find(r => r.uid === this.latest_revision_uid) || null;
    }

    /**
     * Get the latest released revision
     *
     * @returns {UnitRevision|null}
     */
    get latestReleasedRevision() {
        return this.isDraft ? null : this.revisions.find(r => r.uid === this.latest_released_revision_uid) || null;
    }

    /**
     * @return {UnitPermissionPolicy}
     */
    get parsedPolicy() {
        return UnitPermissionPolicy.getPolicyForType(this.policy);
    }
}
