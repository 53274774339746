<template>
    <div class="inspector-panel preview-image"
        @drop="onDropFile"
        @dragenter.prevent
        @dragover.prevent
    >
        <img v-if="!supportsPreviewModal" :src="src" :alt="alt" />
        <span v-else class="preview-image-pointer">
            <img
                :src="src"
                :alt="alt"
                @click="$emit('click', $event)"
            />
            <Icon :name="previewIcon" />
        </span>
        <template v-if="editable">
            <input
                ref="image_upload_input"
                v-not-focusable
                type="file"
                :accept="accepted_extension"
                name="preview_image"
                class="form-control input-preview-image-upload"
                value=""
                :placeholder="placeholder"
                @change="onPreviewImageChanged"
            />
            <ButtonCircular
                class="btn-preview-image-upload"
                icon="icon_edit"
                @trigger="$refs.image_upload_input.click()"
            />
        </template>
    </div>
</template>

<script>

import { trans, validateImageDimensions } from '@/Utility/Helpers';
import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";

export default {
    name: 'InspectorItemPreviewImage',
    components: {
        ButtonCircular
    },
    emits: [
        'change',
        'click',
    ],
    props: {
        src: {
           type: String,
        },
        accepted_extension: {
            type: String,
            default: '.jpeg,.jpg,.png',
        },
        alt: {
            type: String,
            default: null,
        },
        editable: {
            type: Boolean,
            default: false
        },
        supportsPreviewModal: {
            type: Boolean,
            default: false
        },
        previewIcon: {
            type: String,
            default: 'icon_preview',
        },
        placeholder: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            fileReader: new FileReader(),
            previewFiles: null,
        }
    },
    mounted() {
        this.fileReader.onload = (e) => {
            validateImageDimensions(e.target.result).then(() => {
                this.$emit('change', {
                    'result': e.target.result,
                    'files': this.previewFiles,
                });
            }).catch(() => {
                this.$root.showErrorDialog(trans('modals.preview_image.preview_requirements_error'));
                this.$refs.image_upload_input.value = null;
            });
        };
    },
    beforeUnmount() {
        this.fileReader.onload = null;
    },
    methods: {
        onPreviewImageChanged(e) {
            this.previewFiles = e.target.files || null;
            if (this.previewFiles && this.previewFiles.length >= 1)
            {
                this.fileReader.readAsDataURL(this.previewFiles[0]);
            }
            return this;
        },

        onDropFile(e) {
            e.preventDefault();
            if (!this.editable || !(e.dataTransfer?.files)) {
                return this;
            }

            this.previewFiles = e.dataTransfer.files;
            if (this.previewFiles && this.previewFiles.length >= 1)
            {
                this.fileReader.readAsDataURL(this.previewFiles[0]);
            }
            return this;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
