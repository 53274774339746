<template>
    <nav class="pagination">

        <template v-for="page in numberOfPages" :key="page">

            <span
                v-if="page === currentPage"
                class="pagination-page-link active"
            >
                {{ page }}
            </span>

            <a
                v-else
                class="pagination-page-link"
                href="#"
                @click="onPageClick(page)"
            >
                {{ page }}
            </a>

        </template>
    </nav>
</template>

<script>

export default {
    name: 'Pagination',

    props: {
        /**
         * Currently selected page; Index is based on 1.
         */
        currentPage: {
            type: Number,
            default: 1
        },
        /**
         * Total number of pages to display.
         */
        numberOfPages: {
            type: Number,
            default: 1
        },
    },

    emits: [
        'click',
    ],

    methods: {
        /**
         * Called when a page link has been clicked by the user.
         * @param {number} page
         */
        onPageClick(page) {
            this.$emit('click', page);
        }
    },
};
</script>

<style lang="scss" scoped>
.pagination {
    display: inline-flex;
    border: 2px solid var(--color-white);
    border-radius: 4px;

    .pagination-page-link {
        border-width: 0;
        background: none;
        border-radius: 4px;
        color: var(--font-color-dark);
        font-family: var(--font-family-condensed-demibold);
        padding: 12px 24px;

        &.active {
            color: var(--color-primary);
            background-color: var(--color-white);
        }

        &:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:not(.active) {
            cursor: pointer;

            &:hover,
            &:focus {
                color: var(--color-primary-hover);
            }
        }
    }
}
</style>
