<template>
    <tr
        class="token-list-item"
        :class="{
            'revoked': token.revoked,
            'expired': token.isExpired
        }"
    >

        <td :title="token.name"
            class="name">
            {{ token.name }}
        </td>

        <td class="scopes">
            <span>{{ token.scopes.join(", ") }}</span>
        </td>

        <td class="updated-at">
            <span>{{ moment(token.updated_at).format('DD.MM.YYYY') }}</span>
        </td>

        <td class="expired-at">
            <span>{{ moment(token.expires_at).format('DD.MM.YYYY') }}</span>
        </td>

        <td :title="trans('tokens.index.access_token_tooltip')" class="access-token">
            <ButtonCustom
                v-if="token.hasAccessToken"
                :caption="trans('labels.view')"
                class="access-token-btn"
                icon="icon_trigger-gaze"
                @trigger="onViewAccessTokenTriggered"
            />
            <span v-else>-</span>
        </td>

        <td>
            <ButtonCustom
                v-if="allowRevoke"
                :caption="trans('labels.revoke')"
                :disabled="token.revoked"
                icon="icon_delete"
                @trigger="onRevokeTriggered"
            />
        </td>

    </tr>
</template>

<script>

    // Import classes:
    import Token from '@/Models/User/Token';

    export default {
        name: 'ApiTokenListItem',

        emits: [
            'revoke',
            'view-access-token',
        ],

        props: {
            token: {
                type: Token,
                default: null
            },
            allowRevoke: {
                type: Boolean,
                default: false
            }
        },

        methods: {

            /**
             * @param {KeyboardEvent|MouseEvent} e
             */
            onRevokeTriggered(e) {
                this.$emit('revoke', this.token);
                return this;
            },

            /**
             * @param {KeyboardEvent|MouseEvent} e
             */
            onViewAccessTokenTriggered(e) {
                this.$emit('view-access-token', this.token);
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    tr {
        display: flex;
    }

    td {
        flex: 1;
        display: flex;
        justify-content: center;
        align-self: center;
        text-align: center;
    }

    tr.revoked td {
        opacity: 0.5;
    }

    td span {
        display: block;
    }

    .name {
        flex: 1.5;
        justify-content: flex-start;
        font-family: var(--font-family-condensed-demibold);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: start;
    }

    .scopes {
        flex: 2;
        justify-content: center;
    }

    tr.expired .expired-at {
        color: var(--color-signal);
    }

</style>
