<template>
    <header>
        <div class="texts">
            <span class="title-text">{{ title }}</span>
            <span v-if="subtitle !== null" class="subtitle-text">{{ subtitle }}</span>
            <span v-if="count !== null" class="count-text">{{ count }}</span>
        </div>
    </header>
</template>

<script lang="ts">

import {defineComponent, PropType} from "vue";
import Icon from "@/Vue/Common/Icon.vue";

export default defineComponent({
    name: "StatisticsCardHeader",

    components: {
        Icon
    },

    props: {
        title: {
            type: String,
            required: false,
        },
        subtitle: {
            type: String as PropType<String | null>,
            required: false,
            default: null,
        },
        count: {
            type: Number as PropType<number | null>,
            required: false,
            default: null,
        },
    },
});

</script>

<style lang="scss" scoped>

header {
    border-bottom: 1px solid #ECECEC;

    .texts {
        margin: 22px 40px;

        .title-text {
            display: block;
            font-family: var(--font-family-condensed-demibold);
            font-size: 14px;
            line-height: 18px;
            color: var(--color-anthracite40);
            text-transform: uppercase;
        }

        .subtitle-text {
            font-family: var(--font-family-condensed);
            line-height: 24px;
        }

        .count-text {
            display: block;
            font-family: var(--font-family-condensed-demibold);
            font-size: 28px;
            line-height: 34px;
            color: var(--color-anthracite);
            white-space: nowrap;
        }
    }
}

</style>
