import {formatBytes} from '@/Utility/Helpers';

export default class AssetFile {

    public readonly uid: string;
    public readonly filename: string;
    public readonly filesize: number;
    public readonly mimetype: string;

    constructor(attributes: any = {}) {
        this.uid = attributes.uid;
        this.filename = attributes.filename;
        this.filesize = attributes.filesize;
        this.mimetype = attributes.mimetype;
    }

    get humanReadableFileSize() {
        return formatBytes(this.filesize.toString(), 1);
    }
}
