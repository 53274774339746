import {Ref, ref, watch} from "vue";

/**
 * Listens to resize events on the given dom element and returns its width and height without padding and margin.
 * @param element dom element you want to know the size of
 * @return inner width and height of the element (content - without padding and margin)
 * @example ```
 * const chart = ref<HTMLElement>();
 * const {width: chartWidth} = useResize(chart);
 *
 * return {
 *     chartWidth,
 *     chart,
 * };
 * ```
 */
export function useResize(element: Ref<HTMLElement | undefined | null>) {
    const width = ref(0);
    const height = ref(0);

    const chartResizeObserver = new ResizeObserver((entries) => {
        width.value = entries[0].contentRect.width;
        height.value = entries[0].contentRect.height;
    });

    watch(element, (domElement) => {
        chartResizeObserver.disconnect();

        if (domElement) {
            chartResizeObserver.observe(domElement);
        }
    }, {immediate: true});

    return {width, height};
}
