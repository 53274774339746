<template>

    <footer>
        <div class="launch-page-container footer-stage">
            <div class="footer-stage-links">
                <a
                    :href="trans('modals.about.link_target_imprint')"
                    class=""
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ trans('modals.about.link_text_imprint') }}
                </a>
                <a
                    :href="trans('modals.about.link_target_tos')"
                    class=""
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ trans('modals.about.link_text_tos') }}
                </a>
            </div>
            <div class="footer-stage-buttons">
                <ButtonSecondary
                    v-if="helpUrl"
                    :href="helpUrl"
                    caption="lms_xapi.launch_page.footer.help"
                    target="_blank"
                />
                <ButtonSecondary
                    :href="contactUrl"
                    caption="lms_xapi.launch_page.footer.contact"
                    target="_blank"
                />
            </div>

        </div>
    </footer>

</template>

<script>
import ButtonSecondary from '@/Vue/Common/ButtonSecondary';
import {trans} from '@/Utility/Helpers';

export default {
    name: 'PageLmsXApiLaunchFooter',

    components: {
        ButtonSecondary
    },

    props: {
        contactUrl: {
            type: String,
            default: 'https://www.3spin-learning.com/en/contact',
        },
        helpUrl: {
            type: String,
            default: null,
        }
    },

    methods: {
        trans
    },
};
</script>

<style lang="scss" scoped>

$xapi-launch-page-margin: 24px;
$xapi-launch-page-max-width: 1200px;

.launch-page-container {
    width: 100%;
    max-width: $xapi-launch-page-max-width;
    display: flex;
    margin: 0 auto;
    padding: 0 $xapi-launch-page-margin 0 $xapi-launch-page-margin;
}

footer .footer-stage {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 32px 24px;
    gap: 24px;

    .footer-stage-links {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        a {
            color: var(--font-color-dark);

            &:hover {
                color: var(--color-primary-hover);
            }
        }
    }

    .footer-stage-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 12px;
    }
}

</style>
