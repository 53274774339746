/**
 * @implements ITab
 */
export default class Category
{
    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     */
    constructor(attributes = {})
    {
        this.title = attributes.title;  // Translated title name for this category
        this.icon = attributes.icon || null;    // Icon identifier

        this.active = (typeof attributes.active === 'boolean' && attributes.active === true);        // Active state
        this.defaultActive = (this.active === true);    // Default active state (must not be a reference!)
        this.disabled = (typeof attributes.disabled === 'boolean' && attributes.disabled === true);  // Disabled state
        this.defaultDisabled = (this.disabled === true);    // Default disabled state (must not be a reference)
    }

    /**
     * Reset to initial state
     *
     * @returns {this}
     */
    reset() {
        this.active = (this.defaultActive === true);
        this.disabled = (this.defaultDisabled === true);
        return this;
    }

    /**
     * Set active state
     *
     * @param {Boolean} active
     * @param {Boolean} defaultActive
     * @returns {this}
     */
    setActive(active = true, defaultActive = null) {
        this.active = (active === true);
        if (typeof defaultActive === 'boolean') {
            this.defaultActive = defaultActive;
        }
        return this;
    }

    /**
     * Get active state
     *
     * @returns {Boolean}
     */
    get isActive()
    {
        return this.active;
    }

    /**
     * Set disabled state
     *
     * @param {Boolean} disabled
     * @param {Boolean} defaultDisabled
     * @returns {Category}
     */
    setDisabled(disabled = false, defaultDisabled = null) {
        this.disabled = (disabled === true);
        if (typeof defaultDisabled === 'boolean') {
            this.defaultDisabled = defaultDisabled;
        }
        return this;
    }

    /**
     * Get disabled state
     *
     * @returns {Boolean}
     */
    get isDisabled()
    {
        return this.disabled;
    }
}
