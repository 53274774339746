import {trans} from '@/Utility/Helpers';

export default class KeyboardKey {
    public code: string;
    public enum: string;
    public key: string;

    public static None: KeyboardKey;
    public static Space: KeyboardKey;
    public static Enter: KeyboardKey;
    public static Tab: KeyboardKey;
    public static Backquote: KeyboardKey;
    public static Quote: KeyboardKey;
    public static Semicolon: KeyboardKey;
    public static Comma: KeyboardKey;
    public static Period: KeyboardKey;
    public static Slash: KeyboardKey;
    public static Backslash: KeyboardKey;
    public static LeftBracket: KeyboardKey;
    public static RightBracket: KeyboardKey;
    public static Minus: KeyboardKey;
    public static Equals: KeyboardKey;
    public static A: KeyboardKey;
    public static B: KeyboardKey;
    public static C: KeyboardKey;
    public static D: KeyboardKey;
    public static E: KeyboardKey;
    public static F: KeyboardKey;
    public static G: KeyboardKey;
    public static H: KeyboardKey;
    public static I: KeyboardKey;
    public static J: KeyboardKey;
    public static K: KeyboardKey;
    public static L: KeyboardKey;
    public static M: KeyboardKey;
    public static N: KeyboardKey;
    public static O: KeyboardKey;
    public static P: KeyboardKey;
    public static Q: KeyboardKey;
    public static R: KeyboardKey;
    public static S: KeyboardKey;
    public static T: KeyboardKey;
    public static U: KeyboardKey;
    public static V: KeyboardKey;
    public static W: KeyboardKey;
    public static X: KeyboardKey;
    public static Y: KeyboardKey;
    public static Z: KeyboardKey;
    public static Digit1: KeyboardKey;
    public static Digit2: KeyboardKey;
    public static Digit3: KeyboardKey;
    public static Digit4: KeyboardKey;
    public static Digit5: KeyboardKey;
    public static Digit6: KeyboardKey;
    public static Digit7: KeyboardKey;
    public static Digit8: KeyboardKey;
    public static Digit9: KeyboardKey;
    public static Digit0: KeyboardKey;
    public static LeftShift: KeyboardKey;
    public static RightShift: KeyboardKey;
    public static LeftAlt: KeyboardKey;
    public static AltGr: KeyboardKey;
    public static RightAlt: KeyboardKey;
    public static LeftCtrl: KeyboardKey;
    public static RightCtrl: KeyboardKey;
    public static LeftMeta: KeyboardKey;
    public static LeftCommand: KeyboardKey;
    public static LeftApple: KeyboardKey;
    public static LeftWindows: KeyboardKey;
    public static RightMeta: KeyboardKey;
    public static RightCommand: KeyboardKey;
    public static RightApple: KeyboardKey;
    public static RightWindows: KeyboardKey;
    public static ContextMenu: KeyboardKey;
    public static Escape: KeyboardKey;
    public static LeftArrow: KeyboardKey;
    public static RightArrow: KeyboardKey;
    public static UpArrow: KeyboardKey;
    public static DownArrow: KeyboardKey;
    public static Backspace: KeyboardKey;
    public static PageDown: KeyboardKey;
    public static PageUp: KeyboardKey;
    public static Home: KeyboardKey;
    public static End: KeyboardKey;
    public static Insert: KeyboardKey;
    public static Delete: KeyboardKey;
    public static CapsLock: KeyboardKey;
    public static NumLock: KeyboardKey;
    public static PrintScreen: KeyboardKey;
    public static ScrollLock: KeyboardKey;
    public static Pause: KeyboardKey;
    public static NumpadEnter: KeyboardKey;
    public static NumpadDivide: KeyboardKey;
    public static NumpadMultiply: KeyboardKey;
    public static NumpadPlus: KeyboardKey;
    public static NumpadMinus: KeyboardKey;
    public static NumpadPeriod: KeyboardKey;
    public static NumpadEquals: KeyboardKey;
    public static Numpad0: KeyboardKey;
    public static Numpad1: KeyboardKey;
    public static Numpad2: KeyboardKey;
    public static Numpad3: KeyboardKey;
    public static Numpad4: KeyboardKey;
    public static Numpad5: KeyboardKey;
    public static Numpad6: KeyboardKey;
    public static Numpad7: KeyboardKey;
    public static Numpad8: KeyboardKey;
    public static Numpad9: KeyboardKey;
    public static F1: KeyboardKey;
    public static F2: KeyboardKey;
    public static F3: KeyboardKey;
    public static F4: KeyboardKey;
    public static F5: KeyboardKey;
    public static F6: KeyboardKey;
    public static F7: KeyboardKey;
    public static F8: KeyboardKey;
    public static F9: KeyboardKey;
    public static F10: KeyboardKey;
    public static F11: KeyboardKey;
    public static F12: KeyboardKey;

    constructor(attributes: any = {}) {
        // Attributes must always be an object:
        attributes = (attributes instanceof Object && !(attributes instanceof Array)) ? attributes : {};

        // Check for mandatory properties:
        if (typeof attributes.enum !== 'string' || attributes.enum === '') {
            console.warn('KeyboardKey->constructor(): Invalid data.', attributes);
            throw new TypeError('KeyboardKey->constructor(): Property "enum" has to be set on KeyboardKey. Must be a valid "UnityEngine.InputSystem.Key" enum string.');
        } else if (typeof KeyDefinitions[attributes.enum] === 'undefined' || !(KeyDefinitions[attributes.enum] instanceof Object)) {
            console.warn('KeyboardKey->constructor(): Invalid data.', attributes);
            throw new TypeError('KeyboardKey->constructor(): Invalid key enum definition. Enum "' + attributes.enum + '" is unknown.');
        }
        // Use attributes from the actual key definitions to make sure they always match with the key enum:
        else if (attributes.code !== KeyDefinitions[attributes.enum].code) {
            console.warn(
                'KeyboardKey->constructor(): Found incorrect "code" definition:',
                attributes.code,
                'Changing to:',
                KeyDefinitions[attributes.enum].code
            );
            attributes.code = KeyDefinitions[attributes.enum].code;
        }
        if (typeof attributes.code !== 'string' || attributes.code === '') {
            console.warn('KeyboardKey->constructor(): Invalid data.', attributes);
            throw new TypeError(
                'KeyboardKey->constructor(): Property "code" has to be set on KeyboardKey. Must be a valid "event.code" string.');
        }

        // Populate the model:
        this.code = attributes.code;                                            // Event key code (event.code, @see https://keycode.info/)
        this.enum = attributes.enum;                                            // Unity enum value (@see UnityEngine.InputSystem.Key enum)
        this.key = this.getKeyName(attributes.key || this.code);                // Localized key character representation defined by the user's keyboard layout
    }

    /**
     * Get localized key display name
     */
    public getKeyName(keyOrEnumOrCode: string | null = null): string {

        let name = keyOrEnumOrCode || this.code;

        // Look for translation of the code or name at first:
        name = trans('keyboard.keys')[this.code] || trans('keyboard.keys')[name] || name;

        // Use uppercase letter for A-Z if there was no translation:
        name = (this.code.startsWith('Key') ? this.enum : name);

        // Use the plain digit:
        name = (this.code.startsWith('Digit') ? this.enum.replace('Digit', '') : name);

        // Remove unwanted characters at the beginning:
        name = (name.length >= 2) ? name.replace(/^[\^´`]?/, '') : name;

        // Make uppercase:
        name = ['ä', 'ö', 'ü'].includes(name) ? name.toUpperCase() : name;

        return name;
    }

    /**
     * Whether the key is unidentified or dead
     */
    public get isUnidentified(): boolean {
        return (this.key === null || this.key === 'Dead' || this.key === 'Unidentified');
    }

    /**
     * Get all keys
     */
    public static get all(): object {
        return Keys;
    }

    /**
     * Find a specific key by a given event
     */
    public static findByEvent(e: KeyboardEvent): KeyboardKey | null {
        let key = KeyboardKey.findByCode(e.key || e.code || e.keyCode || e.which || null) || KeyboardKey.findByCode(e.code || null);

        // Return if no key was found:
        if (key === null) {
            return null;
        }

        // Fix Numpad mapping for Edge:
        if (
            e instanceof KeyboardEvent
            && e.location === KeyboardEvent.DOM_KEY_LOCATION_NUMPAD
            && !key.enum.includes('Numpad')
        ) {
            const enumMapping = {};
            enumMapping[KeyboardKey.Enter.enum] = KeyboardKey.NumpadEnter;
            enumMapping[KeyboardKey.Delete.enum] = KeyboardKey.NumpadPeriod;
            enumMapping[KeyboardKey.Insert.enum] = KeyboardKey.Numpad0;
            enumMapping[KeyboardKey.End.enum] = KeyboardKey.Numpad1;
            enumMapping[KeyboardKey.DownArrow.enum] = KeyboardKey.Numpad2;
            enumMapping[KeyboardKey.PageDown.enum] = KeyboardKey.Numpad3;
            enumMapping[KeyboardKey.LeftArrow.enum] = KeyboardKey.Numpad4;
            //enumMapping[KeyboardKey.Clear.enum]      = KeyboardKey.Numpad5; // The Clear key is not supported
            enumMapping[KeyboardKey.RightArrow.enum] = KeyboardKey.Numpad6;
            enumMapping[KeyboardKey.Home.enum] = KeyboardKey.Numpad7;
            enumMapping[KeyboardKey.UpArrow.enum] = KeyboardKey.Numpad8;
            enumMapping[KeyboardKey.PageUp.enum] = KeyboardKey.Numpad9;

            if (enumMapping[key.enum] instanceof KeyboardKey) {
                key = new KeyboardKey(enumMapping[key.enum]);
            }
        }

        // Get the display name for the given key:
        key.key = key.getKeyName(e.key || e.code || null);

        //console.log(e.key, e.keyCode, e.code, e.charCode, e.location, String.fromCharCode(e.charCode), e, key);
        return key;
    }

    /**
     * Find a specific key by a given name or key code
     */
    public static findByCode(code: string | number | null): KeyboardKey | null {
        if (code === null) {
            return null;
        }
        const keyIndex = Object.keys(Keys).find(k => {
            return (
                k === code
                || k === code.toString().toUpperCase()
                || Keys[k].code === code
                || Keys[k].enum === code
                || Keys[k].keyCode === code
            );
        }) || null;
        return (keyIndex !== null) ? Keys[keyIndex] : null;
    }

    /**
     * Key sets
     */
    public static get KeySets(): {
        ColorHEX: KeyboardKey[];
        Controls: KeyboardKey[];
        Digits: KeyboardKey[];
        TriggerKeyPress: KeyboardKey[]
    } {
        return {

            Controls: [
                KeyboardKey.Enter,
                KeyboardKey.Escape,
                KeyboardKey.Tab,
                KeyboardKey.Backspace,

                KeyboardKey.LeftShift,
                KeyboardKey.RightShift,
                KeyboardKey.LeftAlt,
                KeyboardKey.AltGr,
                KeyboardKey.RightAlt,
                KeyboardKey.LeftCtrl,
                KeyboardKey.RightCtrl,
                KeyboardKey.LeftMeta,
                KeyboardKey.LeftCommand,
                KeyboardKey.LeftApple,
                KeyboardKey.LeftWindows,
                KeyboardKey.RightMeta,
                KeyboardKey.RightCommand,
                KeyboardKey.RightApple,
                KeyboardKey.RightWindows,
                KeyboardKey.ContextMenu,

                KeyboardKey.LeftArrow,
                KeyboardKey.RightArrow,
                KeyboardKey.UpArrow,
                KeyboardKey.DownArrow,

                KeyboardKey.PageDown,
                KeyboardKey.PageUp,
                KeyboardKey.Home,
                KeyboardKey.End,
                KeyboardKey.Insert,
                KeyboardKey.Delete,
                KeyboardKey.CapsLock,
                KeyboardKey.NumLock,
                KeyboardKey.PrintScreen,
                KeyboardKey.ScrollLock,
                KeyboardKey.Pause,

                KeyboardKey.NumpadEnter,
            ],

            Digits: [
                KeyboardKey.Digit0,
                KeyboardKey.Digit1,
                KeyboardKey.Digit2,
                KeyboardKey.Digit3,
                KeyboardKey.Digit4,
                KeyboardKey.Digit5,
                KeyboardKey.Digit6,
                KeyboardKey.Digit7,
                KeyboardKey.Digit8,
                KeyboardKey.Digit9,
                KeyboardKey.Numpad0,
                KeyboardKey.Numpad1,
                KeyboardKey.Numpad2,
                KeyboardKey.Numpad3,
                KeyboardKey.Numpad4,
                KeyboardKey.Numpad5,
                KeyboardKey.Numpad6,
                KeyboardKey.Numpad7,
                KeyboardKey.Numpad8,
                KeyboardKey.Numpad9,
            ],

            ColorHEX: [
                KeyboardKey.Digit0,
                KeyboardKey.Digit1,
                KeyboardKey.Digit2,
                KeyboardKey.Digit3,
                KeyboardKey.Digit4,
                KeyboardKey.Digit5,
                KeyboardKey.Digit6,
                KeyboardKey.Digit7,
                KeyboardKey.Digit8,
                KeyboardKey.Digit9,
                KeyboardKey.Numpad0,
                KeyboardKey.Numpad1,
                KeyboardKey.Numpad2,
                KeyboardKey.Numpad3,
                KeyboardKey.Numpad4,
                KeyboardKey.Numpad5,
                KeyboardKey.Numpad6,
                KeyboardKey.Numpad7,
                KeyboardKey.Numpad8,
                KeyboardKey.Numpad9,
                KeyboardKey.A,
                KeyboardKey.B,
                KeyboardKey.C,
                KeyboardKey.D,
                KeyboardKey.E,
                KeyboardKey.F,
            ],

            TriggerKeyPress: [
                KeyboardKey.A,
                KeyboardKey.B,
                KeyboardKey.C,
                KeyboardKey.D,
                KeyboardKey.E,
                KeyboardKey.F,
                KeyboardKey.G,
                KeyboardKey.H,
                KeyboardKey.I,
                KeyboardKey.J,
                KeyboardKey.K,
                KeyboardKey.L,
                KeyboardKey.M,
                KeyboardKey.N,
                KeyboardKey.O,
                KeyboardKey.P,
                KeyboardKey.Q,
                KeyboardKey.R,
                KeyboardKey.S,
                KeyboardKey.T,
                KeyboardKey.U,
                KeyboardKey.V,
                KeyboardKey.W,
                KeyboardKey.X,
                KeyboardKey.Y,
                KeyboardKey.Z,
                KeyboardKey.Digit0,
                KeyboardKey.Digit1,
                KeyboardKey.Digit2,
                KeyboardKey.Digit3,
                KeyboardKey.Digit4,
                KeyboardKey.Digit5,
                KeyboardKey.Digit6,
                KeyboardKey.Digit7,
                KeyboardKey.Digit8,
                KeyboardKey.Digit9,
                KeyboardKey.Numpad0,
                KeyboardKey.Numpad1,
                KeyboardKey.Numpad2,
                KeyboardKey.Numpad3,
                KeyboardKey.Numpad4,
                KeyboardKey.Numpad5,
                KeyboardKey.Numpad6,
                KeyboardKey.Numpad7,
                KeyboardKey.Numpad8,
                KeyboardKey.Numpad9,
                KeyboardKey.NumpadEnter,
                KeyboardKey.NumpadDivide,
                KeyboardKey.NumpadMultiply,
                KeyboardKey.NumpadPlus,
                KeyboardKey.NumpadMinus,
                KeyboardKey.NumpadPeriod,
                KeyboardKey.NumpadEquals,
                KeyboardKey.Quote,
                KeyboardKey.Semicolon,
                KeyboardKey.Comma,
                KeyboardKey.Period,
                KeyboardKey.Slash,
                KeyboardKey.Backslash,
                KeyboardKey.LeftBracket,
                KeyboardKey.RightBracket,
                KeyboardKey.Minus,
                KeyboardKey.Equals,
                KeyboardKey.Enter,
                KeyboardKey.Space,
            ]
        };
    }
}

// @NOTE: Object keys are the representations from the UnityEngine.InputSystem.Key enum
// @see: https://www.w3.org/TR/uievents-code/
// @see: https://developer.mozilla.org/de/docs/Web/API/KeyboardEvent/keyCode
// @see: https://developer.mozilla.org/de/docs/Web/API/KeyboardEvent/key/Key_Values
const KeyDefinitions = {
    'None':             {   keyCode:        0,              code:       'None',                         },
    'Space':            {   keyCode:       32,              code:       'Space',                        },
    'Enter':            {   keyCode:       13,              code:       'Enter',                        },
    'Tab':              {   keyCode:        9,              code:       'Tab',                          },
    'Backquote':        {   keyCode:      220,              code:       'Backquote',                    },
    'Quote':            {   keyCode:      222,              code:       'Quote',                        },
    'Semicolon':        {   keyCode:      192,              code:       'Semicolon',                    },
    'Comma':            {   keyCode:      188,              code:       'Comma',                        },
    'Period':           {   keyCode:      190,              code:       'Period',                       },
    'Slash':            {   keyCode:      189,              code:       'Slash',                        },
    'Backslash':        {   keyCode:      191,              code:       'Backslash',                    },
    'LeftBracket':      {   keyCode:      186,              code:       'BracketLeft',                  },
    'RightBracket':     {   keyCode:      187,              code:       'BracketRight',                 },
    'Minus':            {   keyCode:      219,              code:       'Minus',                        },
    'Equals':           {   keyCode:      221,              code:       'Equal',                        },
    'A':                {   keyCode:       65,              code:       'KeyA',                         },
    'B':                {   keyCode:       66,              code:       'KeyB',                         },
    'C':                {   keyCode:       67,              code:       'KeyC',                         },
    'D':                {   keyCode:       68,              code:       'KeyD',                         },
    'E':                {   keyCode:       69,              code:       'KeyE',                         },
    'F':                {   keyCode:       70,              code:       'KeyF',                         },
    'G':                {   keyCode:       71,              code:       'KeyG',                         },
    'H':                {   keyCode:       72,              code:       'KeyH',                         },
    'I':                {   keyCode:       73,              code:       'KeyI',                         },
    'J':                {   keyCode:       74,              code:       'KeyJ',                         },
    'K':                {   keyCode:       75,              code:       'KeyK',                         },
    'L':                {   keyCode:       76,              code:       'KeyL',                         },
    'M':                {   keyCode:       77,              code:       'KeyM',                         },
    'N':                {   keyCode:       78,              code:       'KeyN',                         },
    'O':                {   keyCode:       79,              code:       'KeyO',                         },
    'P':                {   keyCode:       80,              code:       'KeyP',                         },
    'Q':                {   keyCode:       81,              code:       'KeyQ',                         },
    'R':                {   keyCode:       82,              code:       'KeyR',                         },
    'S':                {   keyCode:       83,              code:       'KeyS',                         },
    'T':                {   keyCode:       84,              code:       'KeyT',                         },
    'U':                {   keyCode:       85,              code:       'KeyU',                         },
    'V':                {   keyCode:       86,              code:       'KeyV',                         },
    'W':                {   keyCode:       87,              code:       'KeyW',                         },
    'X':                {   keyCode:       88,              code:       'KeyX',                         },
    'Y':                {   keyCode:       89,              code:       'KeyY',                         },
    'Z':                {   keyCode:       90,              code:       'KeyZ',                         },
    'Digit1':           {   keyCode:       49,              code:       'Digit1',                       },
    'Digit2':           {   keyCode:       50,              code:       'Digit2',                       },
    'Digit3':           {   keyCode:       51,              code:       'Digit3',                       },
    'Digit4':           {   keyCode:       52,              code:       'Digit4',                       },
    'Digit5':           {   keyCode:       53,              code:       'Digit5',                       },
    'Digit6':           {   keyCode:       54,              code:       'Digit6',                       },
    'Digit7':           {   keyCode:       55,              code:       'Digit7',                       },
    'Digit8':           {   keyCode:       56,              code:       'Digit8',                       },
    'Digit9':           {   keyCode:       57,              code:       'Digit9',                       },
    'Digit0':           {   keyCode:       48,              code:       'Digit0',                       },
    'LeftShift':        {   keyCode:       16,              code:       'ShiftLeft',                    },
    'RightShift':       {   keyCode:       16,              code:       'ShiftRight',                   },
    'LeftAlt':          {   keyCode:       18,              code:       'AltLeft',                      },
    'AltGr':            {   keyCode:       18,              code:       'AltRight',                     },
    'RightAlt':         {   keyCode:       18,              code:       'AltRight',                     },
    'LeftCtrl':         {   keyCode:       17,              code:       'ControlLeft',                  },
    'RightCtrl':        {   keyCode:       17,              code:       'ControlRight',                 },
    'LeftMeta':         {   keyCode:       91,              code:       'MetaLeft',                     },
    'LeftCommand':      {   keyCode:      224,              code:       'OSLeft',                       },
    'LeftApple':        {   keyCode:      224,              code:       'OSLeft',                       },
    'LeftWindows':      {   keyCode:       91,              code:       'OSLeft',                       },
    'RightMeta':        {   keyCode:       93,              code:       'MetaRight',                    },
    'RightCommand':     {   keyCode:      224,              code:       'OSRight',                      },
    'RightApple':       {   keyCode:      224,              code:       'OSRight',                      },
    'RightWindows':     {   keyCode:       91,              code:       'OSRight',                      },
    'ContextMenu':      {   keyCode:       93,              code:       'ContextMenu',                  },
    'Escape':           {   keyCode:       27,              code:       'Escape',                       },
    'LeftArrow':        {   keyCode:       37,              code:       'ArrowLeft',                    },
    'RightArrow':       {   keyCode:       39,              code:       'ArrowRight',                   },
    'UpArrow':          {   keyCode:       38,              code:       'ArrowUp',                      },
    'DownArrow':        {   keyCode:       40,              code:       'ArrowDown',                    },
    'Backspace':        {   keyCode:        8,              code:       'Backspace',                    },
    'PageDown':         {   keyCode:       34,              code:       'PageDown',                     },
    'PageUp':           {   keyCode:       33,              code:       'PageUp',                       },
    'Home':             {   keyCode:       36,              code:       'Home',                         },
    'End':              {   keyCode:       35,              code:       'End',                          },
    'Insert':           {   keyCode:       45,              code:       'Insert',                       },
    'Delete':           {   keyCode:       46,              code:       'Delete',                       },
    'CapsLock':         {   keyCode:       20,              code:       'CapsLock',                     },
    'NumLock':          {   keyCode:      144,              code:       'NumLock',                      },
    'PrintScreen':      {   keyCode:       44,              code:       'PrintScreen',                  },
    'ScrollLock':       {   keyCode:      145,              code:       'ScrollLock',                   },
    'Pause':            {   keyCode:       19,              code:       'Pause',                        },
    'NumpadEnter':      {   keyCode:       13,              code:       'NumpadEnter',                  },
    'NumpadDivide':     {   keyCode:      111,              code:       'NumpadDivide',                 },
    'NumpadMultiply':   {   keyCode:      106,              code:       'NumpadMultiply',               },
    'NumpadPlus':       {   keyCode:      107,              code:       'NumpadAdd',                    },
    'NumpadMinus':      {   keyCode:      109,              code:       'NumpadSubtract',               },
    'NumpadPeriod':     {   keyCode:      110,              code:       'NumpadDecimal',                },
    'NumpadEquals':     {   keyCode:       61,              code:       'NumpadEqual',                  },
    'Numpad0':          {   keyCode:       96,              code:       'Numpad0',                      },
    'Numpad1':          {   keyCode:       97,              code:       'Numpad1',                      },
    'Numpad2':          {   keyCode:       98,              code:       'Numpad2',                      },
    'Numpad3':          {   keyCode:       99,              code:       'Numpad3',                      },
    'Numpad4':          {   keyCode:      100,              code:       'Numpad4',                      },
    'Numpad5':          {   keyCode:      101,              code:       'Numpad5',                      },
    'Numpad6':          {   keyCode:      102,              code:       'Numpad6',                      },
    'Numpad7':          {   keyCode:      103,              code:       'Numpad7',                      },
    'Numpad8':          {   keyCode:      104,              code:       'Numpad8',                      },
    'Numpad9':          {   keyCode:      105,              code:       'Numpad9',                      },
    'F1':               {   keyCode:      112,              code:       'F1',                           },
    'F2':               {   keyCode:      113,              code:       'F2',                           },
    'F3':               {   keyCode:      114,              code:       'F3',                           },
    'F4':               {   keyCode:      115,              code:       'F4',                           },
    'F5':               {   keyCode:      116,              code:       'F5',                           },
    'F6':               {   keyCode:      117,              code:       'F6',                           },
    'F7':               {   keyCode:      118,              code:       'F7',                           },
    'F8':               {   keyCode:      119,              code:       'F8',                           },
    'F9':               {   keyCode:      120,              code:       'F9',                           },
    'F10':              {   keyCode:      121,              code:       'F10',                          },
    'F11':              {   keyCode:      122,              code:       'F11',                          },
    'F12':              {   keyCode:      123,              code:       'F12',                          },
//    'F13':              {   keyCode:      124,              code:       'F13',                          }, // @NOTE: not supported in Unity
//    'F14':              {   keyCode:      125,              code:       'F14',                          }, // @NOTE: not supported in Unity
//    'F15':              {   keyCode:      126,              code:       'F15',                          }, // @NOTE: not supported in Unity
//    'F16':              {   keyCode:      127,              code:       'F16',                          }, // @NOTE: not supported in Unity
//    'F17':              {   keyCode:      128,              code:       'F17',                          }, // @NOTE: not supported in Unity
//    'F18':              {   keyCode:      129,              code:       'F18',                          }, // @NOTE: not supported in Unity
//    'F19':              {   keyCode:      130,              code:       'F19',                          }, // @NOTE: not supported in Unity
//    'F20':              {   keyCode:      131,              code:       'F20',                          }, // @NOTE: not supported in Unity
//    'F21':              {   keyCode:      132,              code:       'F21',                          }, // @NOTE: not supported in Unity
//    'F22':              {   keyCode:      133,              code:       'F22',                          }, // @NOTE: not supported in Unity
//    'F23':              {   keyCode:      134,              code:       'F23',                          }, // @NOTE: not supported in Unity
//    'F24':              {   keyCode:      135,              code:       'F24',                          }, // @NOTE: not supported in Unity
//    'OEM1':             {   keyCode:      null,             code:       null,                           },
//    'OEM2':             {   keyCode:      null,             code:       null,                           },
//    'OEM3':             {   keyCode:      null,             code:       null,                           },
//    'OEM4':             {   keyCode:      null,             code:       null,                           },
//    'OEM5':             {   keyCode:      null,             code:       null,                           },
//    'IMESelected':      {   keyCode:      null,             code:       null,                           },
//    '':                 {   keyCode:      173,              code:       'AudioVolumeMute',              }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:      174,              code:       'AudioVolumeDown',              }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:      175,              code:       'AudioVolumeUp',                }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:       12,              code:       'Clear',                        }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:      226,              code:       'IntlBackslash',                }, // @NOTE: not supported in Unity
};

const Keys: any = {};
Object.keys(KeyDefinitions).forEach(k => {
    const keyDef = KeyDefinitions[k];
    Keys[k] = new KeyboardKey({
        code: keyDef.code,
        enum: k,
        keyCode: keyDef.keyCode
    });

    // Expose each key as a class property:
    KeyboardKey[k] = Keys[k];
});
