import { trans } from '@/Utility/Helpers';
import AbstractError from '@/Errors/AbstractError';

export default class ServiceIsBusyError extends AbstractError
{
    /**
     * Constructor
     *
     * @NOTE: Allows multiple different argument signatures
     */
    constructor()
    {
        super(...arguments);
        this.name = 'ServiceIsBusyError';
        this.message = this.message || trans('errors.service_is_busy');
        Object.setPrototypeOf(this, ServiceIsBusyError.prototype);
    }
}
