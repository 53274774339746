import ServiceIsBusyError from "@/Errors/ServiceIsBusyError";
import {route} from "@/Utility/Helpers";
import LearningRecordStatistics from "@/Models/Statistics/LearningRecordsStatistics";

export default class LearningRecordsStatisticsService {

    public isLoading: boolean = false;

    public statistics: LearningRecordStatistics | null = null;

    async index(abortSignal?: AbortSignal): Promise<LearningRecordStatistics> {

        if (this.isLoading) {
            throw new ServiceIsBusyError();
        }

        this.isLoading = true;

        try {

            const url = route('api.learning_records.statistics');
            const response = await window.axios.get(url, {signal: abortSignal});

            this.statistics = new LearningRecordStatistics(response.data.data);
            return this.statistics;

        } finally {
            this.isLoading = false;
        }
    }
}
