<template>
    <header>
        <span class="logo">
            <svg><use xlink:href="#logo_3spin_white" /></svg>
        </span>
        <ButtonPrimary
            v-if="helpUrl"
            :href="helpUrl"
            caption="lms_xapi.launch_page.header.help"
            target="_blank"
        />
    </header>
</template>

<script>
import ButtonPrimary from '@/Vue/Common/ButtonPrimary';

export default {
    name: 'PageLmsXApiLaunchHeader',

    components: {
        ButtonPrimary
    },

    props: {
        helpUrl: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>

header {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 48px 24px;
    background-color: var(--color-primary);

    .logo {
        display: block;

        &, svg {
            height: 32px;
            width: 64px;
        }
    }
}

</style>
