<template>
    <div :class="'search-input ' + (showSearchButton ? 'has-button' : '')">
        <TextInput
            type="search"
            ref="searchbarInput"
            :initial-value="filterWords"
            :maxlength="99"
            :placeholder="trans('labels.search')"
            :delayChangeOnInput="true"
            :blurOnEnterKey="false"
            @change="onChangeWordFilter"
            @cancel="onCancelSearchInput"
            @key-enter="onKeyEnter"
        />
        <Icon v-if="!showSearchButton" name="icon_search" class="icon-search" />
        <Icon v-if="typeof filterWords === 'string' && filterWords.length >= 1" name="icon_close" class="icon-clear" @click.stop="onClickClearWordFilter" />
        <ButtonCircular v-if="showSearchButton" class="search-btn" icon="icon_search" @trigger="onKeyEnter" />
    </div>
</template>

<script>

import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";

export default {
    name: 'SearchInput',
    components: {
        ButtonCircular
    },
    emits: [
        'change',
        'cancel',
        'clear',
        'trigger-search',
    ],
    props: {
        filterWords: {                  // Text input words for the filter
            type: String,
            default: null
        },
        showSearchButton: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        // Set focus on the text input:
        this.setFocus();
    },
    methods: {

        /**
         * Set focus on the filter input
         */
        setFocus() {
            const inputElement = this.$refs.searchbarInput?.$refs?.domElement;
            if (inputElement) {
                inputElement.focus();
                inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
            }
            return this;
        },

        /**
         * Change handler for the word filter
         *
         * @param {String} text
         */
        onChangeWordFilter(text) {
            if (text.trim() === '')
            {
                text = null;
            }
            if (this.filterWords !== text)
            {
                this.$emit('change', text);
            }
            return this;
        },

        /**
         * Click handler for clearing the word filter
         */
        onClickClearWordFilter(e) {
            this.setFocus();
            this.$emit('change', null);
            this.$emit('trigger-search', e, null);
            return this;
        },

        /**
         * Cancel handler for the search input
         *
         * @param {KeyboardEvent|MouseEvent} e
         */
        onCancelSearchInput(e) {
            this.$emit('cancel', e);
            return this;
        },

        /**
         * Keydown handler triggering the search
         *
         * @param {KeyboardEvent} e
         */
        onKeyEnter(e, component) {
            this.$emit('trigger-search', e, (component || this.$refs.searchbarInput).$refs.domElement.value);
            return this;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
