export default class AppPanelConfiguration
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};
        
        // Populate the model:
        this.show_app_panel = (typeof attributes.show_app_panel === 'boolean') ? attributes.show_app_panel : true;
    }

    /**
     * Duplicate
     *
     * @returns {AppPanelConfiguration}
     */
    duplicate() {
        return new AppPanelConfiguration(this);
    }
}
