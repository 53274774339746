import {Permission} from '@/Models/User/Permission';
import UnitPermissionPolicy, {UnitPermissionPolicyTemplate} from '@/Models/Unit/UnitPermissionPolicy';
import {UnitPermissionPolicySample, UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
import type User from '@/Models/User/User';

export default abstract class CoursePermissionPolicy {
    /**
     * @returns a Course Policy for the specified permission
     */
    static getPolicyForPermission(permission: string): CoursePermissionPolicy | undefined {
        return PermissionPolicyMap.get(permission);
    }

    static getPolicyPermissions(): string[] {
        return [...PermissionPolicyMap.keys()];
    }

    static getPermissionPolicyMapping(): Array<[string, CoursePermissionPolicy]> {
        return [...PermissionPolicyMap.entries()];
    }

    static allAvailablePoliciesForUser(user: User): Array<[string, CoursePermissionPolicy]> {
        return this
            .getPermissionPolicyMapping()
            .filter(mapping => user.permissions.find(permission => permission === mapping[0]));
    }

    /**
     * Returns a course policy with the specified type.
     */
    static getPolicyForType(type: string): CoursePermissionPolicy | undefined {
        return StaticCoursePolicyInstances.get(type);
    }

    static get type(): string {
        return 'undefined';
    }

    get type(): string {
        return (<typeof CoursePermissionPolicy>this.constructor).type;
    }

    abstract get requiresAutoEnrollment(): boolean;

    abstract get hasToExistInsideAssetDefaultTenant(): boolean;

    abstract doesAllowForUnitWithPolicy(_: UnitPermissionPolicy): boolean;
}

export class CoursePermissionPolicyStandard extends CoursePermissionPolicy {

    static get type(): string {
        return 'standard';
    }

    get requiresAutoEnrollment(): boolean {
        return false;
    }

    get hasToExistInsideAssetDefaultTenant(): boolean {
        return false;
    }

    doesAllowForUnitWithPolicy(unitPermissionPolicy: UnitPermissionPolicy): boolean {
        return unitPermissionPolicy.type === UnitPermissionPolicyStandard.type
            || unitPermissionPolicy.type === UnitPermissionPolicySample.type
            || unitPermissionPolicy.type === UnitPermissionPolicyTemplate.type;
    }
}

export class CoursePermissionPolicySample extends CoursePermissionPolicy {

    static get type(): string {
        return 'sample';
    }

    get requiresAutoEnrollment(): boolean {
        return true;
    }

    get hasToExistInsideAssetDefaultTenant(): boolean {
        return true;
    }

    doesAllowForUnitWithPolicy(unitPermissionPolicy: UnitPermissionPolicy): boolean {
        return unitPermissionPolicy.type === UnitPermissionPolicySample.type;
    }
}

/**
 * Map of CoursePolicies and their types
 */
const StaticCoursePolicyInstances: Map<string, CoursePermissionPolicy> = new Map([
    [CoursePermissionPolicySample.type, Object.freeze(new CoursePermissionPolicySample())],
    [CoursePermissionPolicyStandard.type, Object.freeze(new CoursePermissionPolicyStandard())],
]);

/**
 * Map of Permissions and associated CoursePolicies
 */
const PermissionPolicyMap: Map<string, CoursePermissionPolicy> = new Map([
    [
        Permission.CoursesAccessPolicySample(),
        CoursePermissionPolicy.getPolicyForType(CoursePermissionPolicySample.type)!
    ],
    [
        Permission.CoursesAccessPolicyStandard(),
        CoursePermissionPolicy.getPolicyForType(CoursePermissionPolicyStandard.type)!
    ],
]);
