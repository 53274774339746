<template>

    <SketchfabHeader></SketchfabHeader>

    <table
        class="asset-list"
        @scroll="onScroll"
        :data-loading="isLoading"
    >
        <SketchfabListItem
            v-for="model in sketchfabService.models"
            :model="model"
            :is-download-enabled="isDownloadEnabled"
            @import="onImportClick"
        />

        <tr
            class="row-loading"
            v-if="sketchfabService.isLoadingModels"
        >
            <LoadingIndicator/>
        </tr>

        <NoItemsFound v-if="sketchfabService.models.length === 0 && !sketchfabService.isLoadingModels"/>
    </table>

    <ModalSketchfabModelPreview/>

</template>

<script>
import {debounce} from 'lodash';
import {inject} from "vue";
import {sketchfabServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import SketchfabListItem from "@/Vue/Assets/Sketchfab/SketchfabListItem.vue";
import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";
import NoItemsFound from "@/Vue/Search/NoItemsFound.vue";
import {trans} from "@/Utility/Helpers";
import SketchfabHeader from "@/Vue/Assets/Sketchfab/SketchfabHeader.vue";
import ModalSketchfabModelPreview from "@/Vue/Modals/Sketchfab/ModalSketchfabModelPreview.vue";
import EventType from "@/Utility/EventType";
import AssetType from "@/Models/Asset/AssetType";
import {AssetProvider} from "@/Models/Asset/AssetProvider";

export default {
    name: "SketchfabList",

    components: {
        SketchfabHeader,
        ModalSketchfabModelPreview,
        NoItemsFound,
        LoadingIndicator,
        SketchfabListItem
    },

    props: {
        filterWords: {
            type: String,
            default: null
        },
        searchDebounceMillis: {
            type: Number,
            default: 500
        },
    },

    data() {
        return {
            sketchfabService: inject(sketchfabServiceKey),
            loadModelsDebounced: debounce(this.loadModels, this.searchDebounceMillis),
        }
    },

    mounted() {
        this.loadModels();
    },

    computed: {

        /**
         * @returns {Boolean}
         */
        isLoading() {
            if (this.sketchfabService.isDownloading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        /**
         * @returns {Boolean}
         */
        isDownloadEnabled() {
            return this.sketchfabService.isAuthenticated;
        },
    },

    methods: {

        /**
         * @param {Boolean} fresh false, if you want to append the next model page
         */
        loadModels(fresh = true) {
            if (!fresh && !this.sketchfabService.hasNextPage) {
                // no more pages to load
                return;
            }

            this.sketchfabService
                .getModels(this.filterWords, fresh)
                .catch(this.onloadFailed);
        },

        onloadFailed() {
            this.$root.showErrorDialog(trans('errors.server_communication_failed'));
        },

        /**
         * @param {UIEvent} e
         */
        onScroll(e) {
            const isEndOfPage = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight * 0.85;

            if (isEndOfPage && !this.sketchfabService.isLoadingModels) {
                this.loadModels(false);
            }
        },

        /**
         * @param {SketchfabModel} model
         */
        onImportClick(model) {
            try {
                /**
                 * @type {ModalCreateAssetShowEventParameters}
                 */
                const createAssetEvent = {
                    assetTypes: [
                        AssetType.Model3D,
                        AssetType.EnvironmentModel3D,
                    ],
                    assetProvider: AssetProvider.Sketchfab,
                    providerAssetId: model.uid,
                    initialAssetTitle: model.name,
                    initialAssetDescription: model.description,
                    initialAssetAttribution: model.creditsPlainText,
                    initialPreviewImageUrl: model.imageUrl720p,
                    lockAssetAttribution: true,
                    assetFileDownloader: () => this.sketchfabService.downloadModel(model.uid),
                };
                this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_SHOW, createAssetEvent);
            } catch (e) {
                this.$root.showErrorDialog(trans('errors.server_communication_failed'));
            }
        },
    },

    watch: {

        filterWords() {
            this.loadModelsDebounced();
        }

    }
}
</script>

<style scoped lang="scss">

.row-loading {
    cursor: auto;

    .loading-indicator {
        margin: auto;
    }
}

</style>
