import type {InstructionPayloadClass} from '@/Models/Sessions/InstructionPayloadClass';
import type {InstructionType} from '@/Models/Sessions/InstructionType';

export default abstract class InstructionPayload {

    readonly type: InstructionType;

    protected get ctor() {
        return this.constructor as InstructionPayloadClass;
    }

    protected constructor(attributes: any = {}) {
        const expectedType = this.ctor.type;

        if (typeof attributes.type !== 'undefined' && attributes.type !== expectedType) {
            throw new Error(`Instruction type '${attributes.type}' does not match the expected type '${expectedType}'.`);
        }

        this.type = this.ctor.type;
    }
}
