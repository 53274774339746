/**
 * @return First character of the given word (aware of 16-bit surrogates)
 * or an empty string.
 */
export function getInitial(word: string | undefined | null): string {
    if (word === undefined || word === null || word.length === 0) {
        return '';
    }

    return [...word][0];
}
