import AssetFile from '@/Models/Asset/AssetFile';
import Asset from '@/Models/Asset/Asset';

export abstract class AbstractSoundAsset extends Asset {

    public readonly sounds: AssetFile[];

    constructor(attributes: any) {
        super(attributes);

        this.sounds = attributes.sounds.map(image => new AssetFile(image));
    }

    get fileList() {
        return this.sounds;
    }

    get supportsPreviewImage(): boolean {
        return false;
    }
}
