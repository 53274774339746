import User from '@/Models/User/User';
import Tenant from '@/Models/Tenant/Tenant';
import {TenantRole} from '@/Models/Tenant/TenantRole';

/**
 * Membership relation between user and tenants with its attached TenantRole.
 */
export class TenantMemberRole {

    public readonly user: User | null;
    public readonly tenant: Tenant | null;
    public readonly role: TenantRole;

    constructor(attributes: any = {}) {
        this.user = attributes.user ? new User(attributes.user) : null;
        this.tenant = attributes.tenant ? new Tenant(attributes.tenant) : null;
        this.role = new TenantRole(attributes.role);
    }
}
