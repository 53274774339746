<template>
    <div class="buttons" v-not-focusable v-shortcuts>

        <!-- Btn: Archive Asset -->
        <ButtonCustom
            v-if="asset.archived === false"
            :disabled="!canAssetBeArchived"
            v-tooltip="'buttons.assets.archive'"
            icon="icon_archive"
            caption="labels.archive"
            @trigger="onClickArchiveSelectedAsset"
        />

        <!-- Btn: Restore Asset -->
        <ButtonCustom
            v-else
            :disabled="!canAssetBeRestoredFromArchive"
            v-tooltip="'buttons.assets.restore'"
            icon="icon_archive-restore"
            caption="labels.restore"
            @trigger="onClickRestoreArchivedSelectedAsset"
        />

        <!-- Btn: Delete Asset -->
        <ButtonCustom
            v-if="!canAssetBeRemovedFromLibrary"
            :disabled="!canAssetBeDeleted"
            v-tooltip="'buttons.assets.delete'"
            icon="icon_delete"
            caption="labels.delete"
            @trigger="onClickDeleteSelectedAsset"
        />

        <!-- Btn: Remove Asset -->
        <ButtonCustom
            v-else-if="canAssetBeRemovedFromLibrary"
            :disabled="!canAssetBeRemovedFromLibrary"
            v-tooltip="'buttons.assets.remove_from_library'"
            icon="icon_delete"
            caption="labels.remove"
            @trigger="onClickRemoveSelectedAssetFromLibrary"
        />

    </div>
</template>

<script>
    import { Permission }           from '@/Models/User/Permission';
    import Asset                    from '@/Models/Asset/Asset';
    import EventType                from '@/Utility/EventType';

    export default {
        name: "SidepanelAssetButtons",
        props: {
            asset: {                 // Selected asset for which to show the properties
                type: Asset,
            },
        },

        data() {
            return {
                shortcuts: new Map([
                    ['Delete.global', this.onShortcutDelete],
                ]),
            }
        },

        computed: {
            /**
             * Can the selected asset be deleted
             *
             * @returns {Boolean}
             */
            canAssetBeDeleted() {
                return this.$gate.allows(Permission.ability(Permission.AssetsDelete()), this.asset);
            },

            /**
             * Can the selected asset be removed
             *
             * @returns {Boolean}
             */
            canAssetBeRemovedFromLibrary() {
                return this.$gate.allows(Permission.ability(Permission.AssetsRemoveFromLibrary()), this.asset);
            },

            /**
             * Can the selected asset be archived
             *
             * @returns {Boolean}
             */
            canAssetBeArchived() {
                return this.$gate.allows(Permission.ability(Permission.AssetsArchive()), this.asset);
            },

            /**
             * Can the selected asset be restored from archive
             *
             * @returns {Boolean}
             */
            canAssetBeRestoredFromArchive() {
                return this.$gate.allows(Permission.ability(Permission.AssetsRestoreArchived()), this.asset);
            },
        },

        methods: {
            /**
             * Click handler for archive selected asset
             */
            onClickArchiveSelectedAsset() {
                this.$globalEvents.emit(EventType.MODAL_ARCHIVE_ASSET_SHOW, this.asset);
                return this;
            },

            /**
             * Click handler for restore archived selected asset
             */
            onClickRestoreArchivedSelectedAsset() {
                this.$globalEvents.emit(EventType.MODAL_RESTORE_ASSET_APPLY, this.asset);
                return this;
            },

            /**
             * Click handler for delete selected asset
             */
            onClickDeleteSelectedAsset() {
                this.$globalEvents.emit(EventType.MODAL_DELETE_ASSET_SHOW, this.asset);
                return this;
            },

            /**
             * Click handler for remove selected asset
             */
            onClickRemoveSelectedAssetFromLibrary() {
                this.$globalEvents.emit(EventType.MODAL_REMOVE_ASSET_FROM_LIBRARY_SHOW, this.asset);
                return this;
            },

            /**
             * Shortcut handler
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                // Asset list:
                if (!this.canAssetBeRemovedFromLibrary && this.canAssetBeDeleted) {
                    return this.onClickDeleteSelectedAsset();
                }
                else if (this.canAssetBeRemovedFromLibrary) {
                    return this.onClickRemoveSelectedAssetFromLibrary();
                }

                return this;
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>
