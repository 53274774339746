import {trans} from "@/Utility/Helpers";

export default class TextToSpeechVoiceConfig {

    /**
     * @param {string} languageCode
     * @param {string} voiceName
     * @param {boolean} isFemale
     * @param {number} ratePercent
     * @param {number} pitchPercent
     * @param {string|null} style
     */
    constructor(languageCode, voiceName, isFemale, ratePercent = 0, pitchPercent = 0, style = null) {
        this.languageCode = languageCode;
        this.voiceName = voiceName;
        this.isFemale = isFemale;
        this.ratePercent = ratePercent;
        this.pitchPercent = pitchPercent;
        this.style = style;

        this.ssmlWrapper = this._generateSsmlWrapper();
    }

    get label() {
        const genderLabel = this.isFemale ? trans('labels.female') : trans('labels.male');
        const languageLabel = this.languageCode.substring(0, 2).toLocaleUpperCase();
        return `${languageLabel} – ${genderLabel}`;
    }

    /**
     * @param {string} text
     * @return {string}
     */
    getSsmlText(text) {
        const escapedText = text
            .replace('&', '&amp;')
            .replace('<', '&lt;')
            .replace('>', '&gt;')
            .replace('"', '&quot;')
            .replace('\'', '&apos;');

        return this.ssmlWrapper.replace('${text}', escapedText);
    }

    _generateSsmlWrapper() {
        let inner = '${text}';

        if (this.pitchPercent !== 0 || this.ratePercent !== null) {
            inner = `<prosody rate="${this.ratePercent}%" pitch="${this.pitchPercent}%">${inner}</prosody>`;
        }

        if (this.style !== null) {
            inner = `<mstts:express-as style="${this.style}">${inner}</mstts:express-as>`;
        }

        return `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" version="1.0" xml:lang="${this.languageCode}"><voice name="${this.voiceName}">${inner}</voice></speak>`;
    }
}

/**
 * @type {TextToSpeechVoiceConfig[]}
 */
TextToSpeechVoiceConfig.All = [
    new TextToSpeechVoiceConfig(
        'en-US',
        'en-US-JennyNeural',
        true,
        4,
        -3,
        'friendly'
    ),
    new TextToSpeechVoiceConfig(
        'en-US',
        'en-US-TonyNeural',
        false,
        -5,
        -5,
        'friendly'
    ),
    new TextToSpeechVoiceConfig(
        'de-DE',
        'de-DE-KatjaNeural',
        true,
        6,
        -3
    ),
    new TextToSpeechVoiceConfig(
        'de-DE',
        'de-DE-ChristophNeural',
        false,
        2,
        0
    ),
    new TextToSpeechVoiceConfig(
        'fr-FR',
        'fr-FR-JacquelineNeural',
        true
    ),
    new TextToSpeechVoiceConfig(
        'fr-FR',
        'fr-FR-HenriNeural',
        false
    ),
];
