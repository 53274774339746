<template>
    <div class="add-user-to-tenant">
        <h3>{{ trans('users.invitation.add_user_to_tenant.headline') }}</h3>
        <p>{{ trans('users.invitation.add_user_to_tenant.description') }}</p>

        <div class="add-user-to-tenant-form">
            <TextInput
                :key="'email_'+key"
                :label="trans('labels.email')"
                :maxlength="50"
                :model="form"
                :required="true"
                :validation-errors="validationErrors('email')"
                property="email"
                type="email"
                @change="removeValidationError('email')"
            />
            <Dropdown
                :key="'tenantrole_'+key"
                :class="(validationErrors('tenant_role_uid').length > 0 ? ' is-invalid' : '')"
                :model="form"
                :options="tenantRoleOptions"
                :required="true"
                :label="trans('labels.tenant_role')"
                property="tenant_role_uid"
                @select="removeValidationError('tenant_role_uid')"
            />

            <div class="add-user-to-tenant-form-button-bar">

                <div
                    v-if="canAddUsers"
                    class="form-check checkbox-invite"
                >
                    <input
                        id="invite_user_checkbox"
                        :key="'invite_users_'+key"
                        v-model="inviteUsers"
                        :false-value="false"
                        :true-value="true"
                        class="form-check-input"
                        name="invite_user"
                        type="checkbox"
                    >
                    <label class="form-check-label" for="invite_user_checkbox">
                        {{ trans('labels.send_invitation') }}
                    </label>
                </div>
                <span v-else></span>

                <ButtonPrimary
                    :key="'button_add_'+key"
                    :caption="buttonCaption"
                    @trigger="addUser"
                />
            </div>
        </div>


    </div>

</template>

<script lang="ts">

import {defineComponent, inject} from 'vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {shortId, trans} from '@/Utility/Helpers';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import DropdownOption from '@/Utility/DropdownOption';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import RequestError from '@/Errors/RequestError';
import EventType from '@/Utility/EventType';
import AuthorizationError from '@/Errors/AuthorizationError';
import type {TenantRole} from '@/Models/Tenant/TenantRole';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        TextInput,
        Dropdown,
        ButtonPrimary,
    },

    props: {
        tenantRoles: {
            type: Array<TenantRole>,
            required: true,
        },
    },

    data() {
        return {
            key: shortId(),
            tenantService: inject(tenantServiceKey)!,
            form: {
                email: null as string | null,
                tenant_role_uid: null as string | null,
            },
            inviteUsers: true,
            errors: {},
            user: window.currentUser,
        };
    },

    computed: {

        buttonCaption() {
            return this.inviteUsers ? 'labels.invite' : 'labels.add';
        },

        canAddUsers() {
            return this.$gate.allows(Permission.TenantsAddUsers());
        },

        canInviteUsers() {
            return this.$gate.allows(Permission.TenantInvitationsCreate());
        },

        isSaving() {
            if (this.tenantService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            } else {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            }
        },

        tenant() {
            return this.user?.tenant;
        },

        initialTenantRole() {
            const role: TenantRole | undefined = this.tenantRoles.find((role: TenantRole) => role.name === 'learner');

            return role ? role.uid : null;
        },

        tenantRoleOptions() {
            const options = Array<DropdownOption>();

            this.tenantRoles.forEach((role: TenantRole) => options.push(
                new DropdownOption({
                    caption: trans('tenant_roles.' + role.name),
                    disabled: false,
                    value: role.uid
                })
            ));

            return options;
        },
    },

    watch: {
        inviteUsers: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.inviteUsers = newValue;
                this.key = shortId();
            }
        }
    },

    mounted() {
        this.form.tenant_role_uid = this.initialTenantRole;
        this.key = shortId();
    },

    methods: {
        trans,

        /**
         * Get the validation errors for a specific field.
         */
        validationErrors(property: string): Array<string> {
            return Object.prototype.hasOwnProperty.call(this.errors, property) ? this.errors[property] : [];
        },

        /**
         * Clear the validation errors for a specific field.
         */
        removeValidationError(property: string): void {
            delete this.errors[property];
        },

        addUser(_: SubmitEvent) {

            if (this.form.email === null || this.form.email.trim() === '') {
                return;
            }

            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

            if (!this.inviteUsers && this.canAddUsers) {
                this.tenantService.addUser(this.tenant!, this.form.email!, this.form.tenant_role_uid!)
                    .then(() => {
                        this.$toast.success(trans('users.invitation.add_success'));
                    })
                    .catch(this.onErrorApi)
                    .finally(() => {
                        this.form.email = null;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.key = shortId();
                    });
            } else if (this.inviteUsers && this.canInviteUsers) {
                this.tenantService.inviteUser(this.tenant!, this.form.email!, this.form.tenant_role_uid!)
                    .then(() => {
                        this.$toast.success(trans('users.invitation.invitation_success'));
                    })
                    .catch(this.onErrorApi)
                    .finally(() => {
                        this.form.email = null;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.key = shortId();
                    });
            }
        },

        onErrorApi(error: any) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }

            if (error instanceof RequestError && error.isValidationError) {
                this.errors = error.validationErrors;
            } else {
                this.$root!.showErrorDialog(error);
            }
        },
    }
});
</script>

<style lang="scss" scoped>
.add-user-to-tenant {
    background: var(--color-white);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    padding: 16px;
    transition: box-shadow 0.15s ease-in-out;

    & > h3 {
        text-transform: capitalize;
    }

    .add-user-to-tenant-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: space-between;
        gap: 12px;
        margin-top: 16px;

        .dropdown-element {
            width: 100%;
            max-width: 450px;
            margin-bottom: 0;

            :deep(label) {
                padding-bottom: var(--typo-spacing-default);
                font-family: var(--font-family-condensed-demibold);
            }
        }

        .textinput {
            margin-bottom: 0;
            width: 100%;
            max-width: 450px;

            :deep(label) {
                font-family: var(--font-family-condensed-demibold);
            }
        }

        & > .btn {
            align-self: end;
        }

        .checkbox-invite {
            margin-bottom: 0;
        }

        .add-user-to-tenant-form-button-bar {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            gap: 24px;
            margin-top: 12px;
        }
    }

}
</style>
