<template>
    <component
        v-if="componentForBehaviour(behaviour)"
        :is="componentForBehaviour(behaviour)"
        :behaviour="behaviour"
        @change="onChangeBehaviour"
    />
</template>

<script>
import { getPanelComponentName }    from '@/Models/UnitData/Behaviours/BehaviourHelpers';
import Behaviour                      from '@/Models/UnitData/Behaviours/Behaviour';

export default {
    name: 'PanelBehaviour',
    emits: [
        'change',
    ],
    props: {
        behaviour: {                      // The behaviour object to be edited
            type: Behaviour,
            default: null
        }
    },
    methods: {
        /**
         * Get the component name for the panel
         *
         * @param {Behaviour} behaviour
         * @returns {String|null}
         */
        componentForBehaviour(behaviour) {
            return getPanelComponentName(behaviour);
        },

        /**
         * Change handler for the behaviour settings
         *
         * @param {Behaviour} behaviour
         */
        onChangeBehaviour(behaviour) {
            this.$emit('change', behaviour);
            return this;
        }
    }
}
</script>
