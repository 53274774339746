import Category from "@/Filters/Category";

export default class VueComponentCategory extends Category {

    constructor(attributes = {}) {
        super(attributes);

        // Check for mandatory properties:
        if (typeof attributes.componentName !== 'string' || attributes.componentName.length === 0) {
            console.warn('VueComponentCategory->constructor(): Invalid data.', attributes);
            throw new TypeError('VueComponentCategory->constructor(): Property "componentName" has to be set on VueComponentCategory.');
        }

        /**
         * Kebab case name of the vue component to display if this category is selected.
         * @type {String}
         */
        this.componentName = attributes.componentName;

        /**
         * @return {*[]} An empty list because these components will fetch their own assets.
         */
        this.callback = () => [];
    }

}
