import { trans } from '@/Utility/Helpers';

export default class SceneObjectivesSequenceType
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || SceneObjectivesSequenceType.isValidType(attributes.type) === false)
        {
            console.warn('SceneObjectivesSequenceType->constructor(): Invalid data.', attributes);
            throw new TypeError('SceneObjectivesSequenceType->constructor(): Property "type" has to be set on SceneObjectivesSequenceType. Must be a valid type from SceneObjectivesSequenceType class.');
        }
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('SceneObjectivesSequenceType->constructor(): Invalid data.', attributes);
            throw new TypeError('SceneObjectivesSequenceType->constructor(): Property "title" has to be set on SceneObjectivesSequenceType.');
        }

        // Populate the model:
        this.type = attributes.type;                                        // Type of sequence (e.g. 'linear')
        this.title = attributes.title;                                      // Translated title of this SceneObjectivesSequenceType (e.g. 'Linear')
    }

    /**
     * SceneObjectivesSequenceType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {SceneObjectivesSequenceType}
     */
    static get Linear()                     { return StaticSequenceTypes.Linear; }
    static get Free()                       { return StaticSequenceTypes.Free; }

    /**
     * Get all types as an array
     *
     * @returns {SceneObjectivesSequenceType[]}
     */
    static get all()
    {
        return Object.values(StaticSequenceTypes);
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in SequenceTypes)
        {
            if (SequenceTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }
}

/**
 * Type definitions
 */
const StaticSequenceTypes = {};
const SequenceTypes = {

    Linear: {
        type: 'linear',
        title: trans('labels.linear')
    },
    Free: {
        type: 'free',
        title: trans('labels.free')
    }
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in SequenceTypes)
{
    StaticSequenceTypes[i] = new SceneObjectivesSequenceType(SequenceTypes[i]);
}
