<template>
    <div class="behaviour-properties">
        <div class="property behaviour-property">
            <Dropdown
                :label="trans('authoring.initial_state')"
                :model="behaviour"
                property="enabled"
                @select="onChangeBehaviour"
                :options="targetOptionsForPropertyEnabled"
                :deselected-caption="trans('authoring.enable_behaviour')"
                class="no-wrap"
            />
        </div>
    </div>
</template>

<script>
import { targetOptionsForPropertyEnabled }     from '@/Models/UnitData/Behaviours/BehaviourHelpers';

import Behaviour                    from '@/Models/UnitData/Behaviours/Behaviour';

export default {
    name: 'PanelBehaviourCollidable',
    emits: [
        'change',
    ],
    props: {
        behaviour: {                      // The behaviour object to be edited
            type: Behaviour,
            default: null
        }
    },

    computed: {
        targetOptionsForPropertyEnabled() {
            return targetOptionsForPropertyEnabled();
        },
    },

    methods: {
        /**
         *  Change handler for the behaviour
         */
        onChangeBehaviour() {
            this.$emit('change', this.behaviour);

            return this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
