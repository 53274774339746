<template>
    <Modal
        :animated="true"
        :show-close-button="true"
        :title="title"
        event-type="MODAL_ASSET_PREVIEW"
        @hide="onHide"
        @show="onShow"
    >

        <h3 v-if="subtitle" class="subtitle">{{ subtitle }}</h3>

        <div v-if="asset && assetData" class="preview-wrapper">
            <SoundRenderer
                v-if="isSoundAsset"
                :content-description="asset.content_description"
                :mime-type="assetData.mimetype"
                :sound-url="assetUrl"
            />
            <video
                v-else-if="isVideoAsset"
                ref="video-player"
                :src="assetUrl"
                :type="assetData.mimetype"
                autoplay
                controls=""
                controlsList="nodownload"
                disablePictureInPicture
                @contextmenu.prevent
            />
            <EnvironmentVideoRenderer
                v-else-if="isEnvironmentVideoAsset"
                ref="environment-image-player"
                :src="assetUrl"
            />
            <img
                v-else-if="isImageAsset"
                ref="image-player"
                :src="assetUrl"
                @contextmenu.prevent
            />
            <EnvironmentImageRenderer
                v-else-if="isEnvironmentImageAsset"
                ref="environment-image-player-component"
                :src="assetUrl"
            />
            <ThreeDObjectRenderer
                v-else-if="isPreviewable3DObject"
                ref="object-player"
                :src="assetUrl"
            />
            <img
                v-else-if="hasPreviewImage"
                ref="preview-image-player"
                :src="asset.preview"
                @contextmenu.prevent
            />
        </div>
    </Modal>
</template>

<script lang="ts">

import type Asset from '@/Models/Asset/Asset';
import AssetType from '@/Models/Asset/AssetType';
import EnvironmentImageRenderer from '@/Vue/Modals/AssetPreview/EnvironmentImageRenderer.vue';
import EnvironmentVideoRenderer from '@/Vue/Modals/AssetPreview/EnvironmentVideoRenderer.vue';
import ThreeDObjectRenderer from '@/Vue/Modals/AssetPreview/ThreeDObjectRenderer.vue';
import SoundRenderer from '@/Vue/Modals/AssetPreview/SoundRenderer.vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import type AssetFile from '@/Models/Asset/AssetFile';
import type {SoundAsset} from '@/Models/Asset/Assets/SoundAsset';
import type {TtsSoundAsset} from '@/Models/Asset/Assets/TtsSoundAsset';
import type {AbstractModel3DAsset} from '@/Models/Asset/Assets/AbstractModel3DAsset';
import type {AbstractImageAsset} from '@/Models/Asset/Assets/AbstractImageAsset';
import type {AbstractVideoAsset} from '@/Models/Asset/Assets/AbstractVideoAsset';

export default defineComponent({
    components: {
        Modal,
        SoundRenderer,
        ThreeDObjectRenderer,
        EnvironmentImageRenderer,
        EnvironmentVideoRenderer,
    },
    data() {
        return {
            asset: null as Asset | null,
            titleOverride: null as String | null,
        }
    },

    computed: {

        isCharacter3DObject() {
            return (this.asset?.type === AssetType.CharacterModel3D.type);
        },

        is3DObject() {
            return (this.asset?.type === AssetType.Model3D.type);
        },

        isEnvironment3DObject() {
            return (this.asset?.type === AssetType.EnvironmentModel3D.type);
        },

        isPreviewable3DObject() {
            return (
                (this.is3DObject || this.isEnvironment3DObject || this.isCharacter3DObject)
                && !this.asset?.bundle
            );
        },

        isImageAsset() {
            return (this.asset?.type === AssetType.Image.type);
        },

        isEnvironmentImageAsset() {
            return (this.asset?.type === AssetType.EnvironmentImage.type)
        },

        isSoundAsset() {
            return (
                this.asset?.type === AssetType.Sound.type
                || this.asset?.type === AssetType.SoundTts.type
            );
        },

        isVideoAsset() {
            return (
                this.asset?.type === AssetType.Video.type
                || this.asset?.type === AssetType.EnvironmentVideo.type //@NOTE:  Disables the EnvironmentVideoRenderer
            );
        },

        isEnvironmentVideoAsset() {
            return this.asset?.type === AssetType.EnvironmentVideo.type;
        },

        hasPreviewImage() {
            return this.asset?.hasPreviewImage;
        },

        assetData(): AssetFile {
            switch (this.asset?.type) {
                case AssetType.Image.type:
                case AssetType.EnvironmentImage.type:
                    return (this.asset as AbstractImageAsset).images[0];
                case AssetType.Sound.type:
                    return (this.asset as SoundAsset).sounds[0];
                case AssetType.SoundTts.type:
                    return (this.asset as TtsSoundAsset).sounds[0];
                case AssetType.Video.type:
                case AssetType.EnvironmentVideo.type:
                    return (this.asset as AbstractVideoAsset).videos[0];
                case AssetType.Model3D.type:
                case AssetType.EnvironmentModel3D.type:
                case AssetType.CharacterModel3D.type:
                    return (this.asset as AbstractModel3DAsset).files[0];
                default:
                    throw new Error(`Unknown asset type ${this.asset?.type}`);
            }
        },

        title(): string {
            return this.titleOverride ?? this.asset?.title;
        },

        subtitle(): string | null {
            return this.titleOverride ? this.asset?.title : null;
        },

        assetUrl(): string {
            let type = this.asset?.type;

            if (type === AssetType.EnvironmentVideo.type) {
                type = AssetType.Video.type;
            }

            if (type === AssetType.EnvironmentImage.type) {
                type = AssetType.Image.type;
            }

            if (type === AssetType.SoundTts.type) {
                type = AssetType.Sound.type;
            }

            if (
                type === AssetType.Model3D.type
                || type === AssetType.EnvironmentModel3D.type
                || type === AssetType.CharacterModel3D.type
            ) {
                type = 'file';
            }

            if (type === null) {
                throw new Error(`Unknown asset type ${this.asset?.type}`);
            }

            return '/assets/' + type + '/' + this.assetData.uid;
        },

    },

    methods: {
        onShow(asset: Asset, title: string | undefined = undefined) {
            this.asset = asset;
            this.titleOverride = title === asset.title ? null : title || null;
        },

        onHide() {
            this.asset = null;
            this.titleOverride = null;
        },

    }
});
</script>

<style lang="scss" scoped>

dialog {
    width: fit-content;
    max-width: 80vw;
    max-height: 80vh;
    overflow: hidden;

    .title,
    .subtitle {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .subtitle {
        margin-top: -10px;
        font-family: var(--font-family-condensed-demibold);
        color: var(--color-anthracite40);
    }

    .preview-wrapper {
        margin-top: 16px;
        display: flex;
        flex-shrink: 1;
        overflow: hidden;

        audio {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }

        video, img {
            max-width: 100%;
            max-height: calc(80vh - 150px);
            margin: 0 auto;
        }

        video:focus-visible {
            outline: 2px solid var(--color-primary-hover);
            outline-offset: -2px;
        }
    }
}

</style>
