export default class SpeechSdkAuthToken {

    static get OneMinuteInMilliseconds() {
        return 1000 * 60;
    }

    /**
     * @param {string} token JWT token as returned by cognitive services endpoint
     */
    constructor(token) {
        this._payload = JSON.parse(atob(token.split(".")[1]));

        /**
         * Use this to authorize against cognitive services
         */
        this.authToken = token;

        /**
         * @type {Date}
         */
        this.expiresAt = new Date(this._payload.exp * 1000);

        /**
         * @type {string}
         */
        this.region = this._payload.region;
    }

    get isValid() {
        return (this.expiresAt - Date.now()) > SpeechSdkAuthToken.OneMinuteInMilliseconds;
    }
}
