export interface AuthResultParameters {
    state: string;
    access_token: string;
    expires_in: string;
    token_type: string;
    scope: string;
    creationTimestamp: number | undefined;
}

export default class AuthResult {

    public readonly token: string;
    public readonly state: string;
    public readonly expiresIn: number;
    public readonly creationTimestamp: number;

    public get isExpired() {
        return this.creationTimestamp + (this.expiresIn * 1000) <= Date.now();
    }

    constructor(private params: AuthResultParameters) {
        this.token = params.access_token;
        this.state = params.state;
        this.expiresIn = parseInt(this.params.expires_in);

        params.creationTimestamp ??= Date.now();
        this.creationTimestamp = params.creationTimestamp;
    }

    toJson(): string {
        return JSON.stringify(this.params);
    }

    static fromJson(json: string): AuthResult {
        return new AuthResult(JSON.parse(json));
    }
}
