export interface UnitDurations {
    count: number;
    duration: number;
    title: string;
    uid: string;
}
export interface UnitCount {
    count: number;
    title: string;
    uid: string;
}

export interface SceneDuration {
    count: number;
    duration: number;
    unit_title: string;
    scene_title: string;
    object_uid: string;
}

export interface UserName {
    uid: string;
    name: string;
}

export default class LearningRecordsStatistics {

    readonly unitsAvgDurations: Record<UnitDurations['uid'], Record<string, UnitDurations>>;
    readonly unitsCompletedCount: Record<UnitCount['uid'], Record<string, UnitCount>>;
    readonly unitsStartedCount: Record<UnitCount['uid'], Record<string, UnitCount>>;
    readonly scenesAvgDurations: Record<string, Record<string, SceneDuration>>;
    readonly users: Record<UserName['uid'], UserName>;

    constructor(apiModel: any) {
        this.unitsAvgDurations = apiModel.units_avg_durations;
        this.unitsCompletedCount = apiModel.units_completed_count;
        this.unitsStartedCount = apiModel.units_started_count;
        this.scenesAvgDurations = apiModel.scenes_avg_durations;
        this.users = apiModel.users;
    }

}
