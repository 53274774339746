<template>
    <header id="layout-header">

        <ButtonCircular
            v-if="showBackButton"
            v-tooltip="'labels.back'"
            class="btn-back bordered"
            icon="icon_back"
            @trigger="onTriggerBackButton"
        />

        <slot name="icon"></slot>

        <div class="page-header-main">

            <!-- Title Text -->
            <h1 class="page-title">{{ pageTitle }}</h1>

            <!-- Status Labels -->
            <StatusLabel
                v-for="labelConfig in labels"
                :key="'page-header-statuslabel'+(labelConfig.type || labelConfig.caption)"
                :config="labelConfig"
            />

            <!-- Subtitle -->
            <p v-if="pageSubtitle" class="subtitle">{{ pageSubtitle }}</p>

        </div>

        <div v-if="Object.keys(buttons).length" class="header-nav-actions">
            <template v-for="(button, key, index) in buttons">

                <ButtonCustom
                    v-if="button.visible && button.style === 'icon+text'"
                    :key="'page-header-actions-button-'+index"
                    v-not-focusable
                    v-tooltip="button.tooltip"
                    :caption="button.caption"
                    :disabled="button.disabled"
                    :href="button.href"
                    :icon="button.icon"
                    class="icon-with-text"
                    @trigger="onTriggerButton($event, button)"
                />

                <ButtonPrimary
                    v-if="button.visible && button.style === 'button'"
                    :key="'page-header-actions-button-'+index"
                    v-not-focusable
                    v-tooltip="button.tooltip"
                    :caption="button.caption"
                    :disabled="button.disabled"
                    :icon="button.icon"
                    class="btn-green"
                    @trigger="onTriggerButton($event, button)"
                />
            </template>
        </div>
    </header>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";
import ButtonCustom from "@/Vue/Common/ButtonCustom.vue";
import PageHeaderButton from "@/Utility/PageHeaderButton";
import {defineComponent} from "vue";

export default defineComponent({
    name: 'PageHeader',
    emits: [
        'button-click',
    ],
    components: {
        ButtonCustom,
        ButtonCircular,
    },
    props: {
        pageTitle: String,
        pageSubtitle: String,
        showBackButton: {
            type: Boolean,
            default: false,
        },
        buttons: {
            type: Object,
            default() {
                return {};
            }
        },
        labels: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    methods: {

        onTriggerButton(event: MouseEvent, buttonConfig: PageHeaderButton) {
            if (buttonConfig.callback !== null) {
                event.preventDefault();
            }

            if (typeof buttonConfig.disabled === 'undefined' || !buttonConfig.disabled) {
                this.$globalEvents.emit(EventType.HEADER_NAVIGATION_BUTTON_CLICK, buttonConfig);
                this.$emit('button-click', buttonConfig);
            }
        },

        onTriggerBackButton() {
            history.back();
        },
    },
});
</script>

<style lang="scss" scoped>


</style>
