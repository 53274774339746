<template>
    <li class="support">
        <a
            :href="href"
            :title="trans('tooltips.navigation.support')"
            data-ug-id="sidemenu-item-help"
            @click.prevent="launchResourceCenter"
        >
            <Icon name="icon_help-center" />
        </a>
    </li>
</template>

<script>
import Icon from '@/Vue/Common/Icon.vue';
import {trans} from '@/Utility/Helpers.js';

export default {
    name: 'SidemenuItemUserGuiding',

    components: {
        Icon
    },

    props: {
        href: {
            type: String,
            default() {
                return null;
            },
        }
    },

    computed: {
        isUserGuidingInitiated() {
            if (!(window.userGuiding instanceof Object)) {
                console.error('UserGuiding script not available!');
                return false;
            }

            return true;
        },
    },

    methods: {
        trans,
        launchResourceCenter() {
            if (this.isUserGuidingInitiated) {
                window.userGuiding.expandResourceCenter();
            } else if (this.href !== null) {
                window.open(this.href, '_blank', 'noopener,noreferrer');
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
