<template>
    <span :class="cssClasses" @click="onClick" v-focusable-if="!disabled" v-shortcuts>

        <span v-if="(layout === 'checkbox-right' || layout === 'togglebtn-right') && caption" class="caption">{{ caption }}</span>

        <Icon v-if="layout === 'checkbox-right' || layout === 'checkbox-left'" name="icon_check" class="border icon-check" />
        <span v-else class="togglebtn">
            <span class="slider"></span>
        </span>

        <span v-if="(layout === 'checkbox-left' || layout === 'togglebtn-left') && caption" class="caption">{{ caption }}</span>
    </span>
</template>

<script>
    export default {
        name: 'Checkbox',
        emits: [
            'change',
        ],
        props: {
            initialValue: {         // Initial checked state (either use this or model+property!)
                type: Boolean,
                default: false
            },
            model: {                // Associated model reference
                type: Object,
                default: null
            },
            property: {             // Property name from the associated model that should be modified
                type: String,
                default: null
            },
            disabled: {             // Disabled state
                type: Boolean,
                default: false
            },
            required: {             // Required state
                type: Boolean,
                default: false
            },
            eventsDisabled: {       // Events disabled state
                type: Boolean,
                default: false
            },
            caption: String,        // Caption text
            layout: {               // Layout (e.g. 'checkbox-left', 'checkbox-right', 'togglebtn-left', 'togglebtn-right')
                type: String,
                default: 'checkbox-right'
            }
        },
        data() {
            return {
                checked: false,     // Checked state
                shortcuts: new Map([
                    ['Space.prevent.stop', this.onClick]
                ])
            }
        },
        computed: {

            /**
             * CSS classes for the checkbox
             *
             * @returns {String}
             */
            cssClasses() {
                const classes = ['checkbox', this.layout];

                // Enabled/disabled state:
                classes.push((this.disabled === true) ? 'disabled' : 'enabled');

                // Required state:
                if (this.required === true)
                {
                    classes.push('required');
                }

                // Checked state:
                if (this.checked === true)
                {
                    classes.push('checked');
                }

                return classes.join(' ');
            }
        },
        mounted() {
            // Check properties
            if (this.model !== null && this.property === null) {
                console.warn('Checkbox->mounted(): Property :model="" is set but no property="" name is given.', this);
            }
            if (this.model !== null && this.initialValue) {
                console.warn('Checkbox->mounted(): Both :model="" and :initial-value="" are set. You should use just one of them.', this);
            }

            // Set initial checked state:
            if (this.model !== null && this.property !== null && typeof this.model[this.property] === 'boolean')
            {
                this.checked = this.model[this.property];
            }
            else
            {
                this.checked = this.initialValue;
            }
        },
        methods: {

            /**
             * Click handler
             *
             * @param {Event} e
             */
            onClick(e) {
                if (this.disabled === false && this.eventsDisabled === false)
                {
                    this.checked = !this.checked;
                    if (this.model !== null && this.property !== null)
                    {
                        this.model[this.property] = this.checked;
                    }
                    this.$emit('change', this.checked, e);
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .checkbox {
        position: relative;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .icon {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 24px;
            width: 24px;
            height: 24px;
        }

        span.togglebtn {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .caption {
            flex-grow: 1;
            padding-left: 12px;
            align-self: center;
        }

        &.checkbox-right,
        &.togglebtn-right {
            .caption {
                padding-left: 0;
                padding-right: 12px;
            }
        }
    }

</style>
