<template>

    <main id="layout-main" v-shortcuts.global>

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <form
                    id="create-course-form"
                    ref="form"
                    class="content-panel"
                    enctype="multipart/form-data"
                    method="post"
                    @submit.prevent="onSubmit"
                >
                    <h1 class="headline">
                        <Icon class="create-form-header-icon" name="icon_courses" />
                        {{ trans('courses.create.panel_headline') }}
                    </h1>

                    <!-- Preview image -->
                    <div class="panel-row preview-image-upload-placeholder-wrapper">
                        <label for="preview_image">{{ trans('labels.preview_image') }}</label>
                        <ImageInputField
                            ref="imageInput"
                            :validation-rules="{
                                minWidth: 1280,
                                minHeight: 720,
                                aspectRatio: 16 / 9,
                                allowedTypes: ['PNG', 'JPG'],
                            }"
                            field-id="preview_image"
                            field-name="preview_image"
                            @validation-error="onImageValidationError"
                        />
                    </div>

                    <!-- Title -->
                    <TextInput
                        :error-msg="trans('errors.course.title')"
                        :label="trans('courses.create.course_title')"
                        :maxlength="50"
                        :model="courseData"
                        :placeholder="trans('courses.create.course_title_placeholder')"
                        :required="true"
                        :validation-errors="validationErrors('course.title')"
                        class="panel-row course-name-input"
                        property="title"
                    />

                    <!-- Description -->
                    <TextInput
                        :label="trans('courses.create.course_description')"
                        :maxlength="600"
                        :model="courseData"
                        :placeholder="trans('courses.create.course_description_placeholder')"
                        :required="false"
                        :validation-errors="validationErrors('course.description')"
                        class="panel-row course-description-input"
                        property="description"
                        type="textarea"
                    />

                    <!-- Auto Enrollment -->
                    <div class="panel-row panel-row-auto-enrollment">
                        <Dropdown
                            v-if="showAutoEnrollmentDropdown"
                            :label="trans('labels.auto_enrollment')"
                            :model="courseData"
                            :options="getOptionsForAutoEnrollment"
                            property="auto_enrollment"
                        />
                        <span
                            v-if="validationErrors('course.auto_enrollment').length > 0"
                            class="invalid-feedback"
                            role="alert"
                        >
                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <strong v-html="validationErrors('course.auto_enrollment')[0]" />
                        </span>
                    </div>

                    <!-- Buttons -->
                    <div class="panel-row buttons">
                        <ButtonPrimary
                            v-tooltip="'tooltips.buttons.courses.create'"
                            :disabled="isSubmitting"
                            caption="courses.create.btn_create"
                            @trigger="onSubmit"
                        />
                        <ButtonSecondary
                            :href="route('courses.index')"
                            caption="courses.create.btn_cancel"
                        />
                    </div>
                </form>
            </div>

            <ModalProgress />
            <ModalNotification />
        </div>
    </main>
</template>

<script lang="ts">

import {route, trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import AuthorizationError from '@/Errors/AuthorizationError';
import ImageInputField from '@/Vue/Common/ImageInputField.vue';
import RequestError from '@/Errors/RequestError';
import {defineComponent, inject} from 'vue';
import {courseServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import Icon from '@/Vue/Common/Icon.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import DropdownOption from '@/Utility/DropdownOption';
import {CoursePermissionPolicyStandard} from '@/Models/Course/CoursePermissionPolicy';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({

    components: {
        Dropdown,
        ButtonSecondary,
        ButtonPrimary,
        TextInput,
        Icon,
        ModalProgress,
        ImageInputField,
        ModalNotification,
    },

    data() {
        return {
            courseService: inject(courseServiceKey)!,

            courseData: {
                title: null as string | null,
                description: null as string | null,
                auto_enrollment: true,
                policy: CoursePermissionPolicyStandard.type,
            },

            isSubmitting: false,

            errors: {} as Record<string, string[]>,

            shortcuts: new Map([            // Shortcut mapping to methods
                ['Save.prevent', null],
                ['Enter', this.onSubmit],
                ['Escape.prevent', this.onClickCancel],
            ])
        };
    },

    computed: {
        imageInputElement(): typeof ImageInputField {
            return this.$refs.imageInput as typeof ImageInputField;
        },

        previewImageFile(): File | null {
            return this.imageInputElement.imageFile;
        },

        showAutoEnrollmentDropdown() {
            return this.$gate.allows(Permission.CoursesEnrollUsers());
        },

        getOptionsForAutoEnrollment() {
            return [
                new DropdownOption({
                    caption: trans('labels.auto_enrollment_enabled'),
                    value: true,
                }),
                new DropdownOption({
                    caption: trans('labels.auto_enrollment_disabled'),
                    value: false,
                })
            ];
        },
    },

    methods: {
        route,
        trans,

        onImageValidationError(e: Error) {
            this.$root!.showErrorDialog(e.message);
        },

        /**
         * Get the validation errors for a specific field.
         */
        validationErrors(property: string): string[] {
            return Object.prototype.hasOwnProperty.call(this.errors, property) ? this.errors[property] : [];
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }
            this.$root!.showErrorDialog(error);
        },

        onSubmit(e: Event) {
            e.preventDefault();

            const form = this.$refs.form as HTMLFormElement;

            if (this.isSubmitting || !form.reportValidity()) {
                return;
            }

            this.isSubmitting = true;
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('courses.create.saving'));

            const formData = new FormData();
            formData.append('course', JSON.stringify(this.courseData));
            if (this.previewImageFile !== null) {
                formData.append('preview_image', this.previewImageFile);
            }

            this.courseService
                .createCourseFromFormData(formData)
                .then(course => {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    window.location.href = route('courses.edit', { 'course': course.uid });
                })
                .catch((error) => {
                    this.errors = error.validationErrors || {};

                    // Show error dialog unless it's a validation error:
                    if (!(error instanceof RequestError && error.isValidationError)) {
                        this.$root!.showErrorDialog(error);

                        // Show error dialog when preview image is invalid:
                    } else if (this.errors.preview_image) {
                        this.$root!.showErrorDialog(trans('courses.create.preview_requirements_error'));
                        this.imageInputElement.reset();
                    }
                })
                .finally(() => {
                    this.isSubmitting = false;
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                });
        },

        onClickCancel(e: Event) {
            e.preventDefault();
            window.location.href = route('courses.index');
        }
    }
});
</script>

<style lang="scss" scoped>
body.create-course {

    $course-create-padding-left: 240px;
    $course-create-column-gap: 56px;

    #content {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .create-form-header-icon {
        vertical-align: middle;
        margin: -4px 10px 0 -42px;
        width: 32px;
        height: 32px;
    }

    .content-panel {
        position: relative;
        width: 848px;
        min-height: 520px;
        padding: 30px 48px 0 48px;
        border-radius: var(--card-border-radius);
        background: var(--background-color-white);
        box-shadow: var(--card-box-shadow);
        text-align: left;

        .headline {
            text-align: center;
            margin-bottom: $course-create-column-gap;
        }

        .invalid-feedback {
            display: initial;
        }

        .panel-row {
            position: relative;
            padding-left: $course-create-padding-left + $course-create-column-gap;

            :deep(label) {
                font-family: var(--font-family-condensed-demibold);
                display: block;
                padding-bottom: 8px;
            }

            :deep(textarea) {
                min-height: 120px;
            }
        }

        .preview-image-upload-placeholder-wrapper {
            position: absolute;
            z-index: 1;
            max-width: 244px;
            padding: 0;
        }

        .panel-row.buttons {
            position: absolute;
            bottom: 40px;
            left: 36px;
            right: 36px;
            padding: 0;
            display: flex;
            flex-direction: row-reverse;
        }
    }
}
</style>
