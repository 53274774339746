import {cloneDeep} from 'lodash';
import AbstractDataObject from '@/Models/AbstractDataObject';
import {parseColor, trans, updateUidReferences, uuid4} from '@/Utility/Helpers';
import SceneObjectives from '@/Models/UnitData/Scenes/Objectives/SceneObjectives';
import Trigger from '@/Models/UnitData/Triggers/Trigger';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import type {
    BaseSceneObjectAsset,
    BaseSceneObjectHotspot,
    BaseSceneObjectModule,
    SceneObjectAssetCharacterModel3D,
    SceneObjectAssetEnvironmentModel3D,
    SceneObjectAssetModel3D,
    SceneObjectModuleHelper,
    SceneObjectModuleVariable,
    SceneObjectParticleEmitter
} from '@/Models/UnitData/SceneObjects/SceneObject';
import SceneObject from '@/Models/UnitData/SceneObjects/SceneObject';
import type Behaviour from '@/Models/UnitData/Behaviours/Behaviour';
import TrainingScene from '@/Models/UnitData/Scenes/TrainingScene';
import Condition, {getConditionForData, ObjectiveCompletedCondition} from '@/Models/UnitData/Conditions/Condition';
import {LearningRecordVerbs} from '@/Models/UnitData/LearningRecords/LearningRecordVerb';
import VariableOperation from '@/Models/UnitData/Variables/VariableOperation';
import {
    defaultOperationForVariableType,
    getAvailableOperationClassesForVariableOfType
} from '@/Models/UnitData/Variables/VariableHelpers';
import AssetType from '@/Models/Asset/AssetType';
import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';
import {Feature} from '@/Models/Features/Feature';
import {AIKnowledgeType, AIKnowledgeTypeHelpers} from '@/Models/UnitData/Commands/AIKnowledgeType';
import {CharacterAnimation} from '@/Models/UnitData/SceneObjects/CharacterAnimation';
import UnitRevision from '@/Models/Unit/UnitRevision';
import UnitData from '@/Models/UnitData/UnitData';
import type Variable from '@/Models/UnitData/Variables/Variables/Variable';

export function getCommandClassFromType(type: string): any | null {
    const commandMapping = {
        [CommandType.BehaviourChange.type]:     BehaviourChangeCommand,
        [CommandType.Condition.type]:           ConditionCommand,
        [CommandType.Control3dAnimation.type]:  Control3dAnimationCommand,
        [CommandType.ControlCharacterAnimation.type]: ControlCharacterAnimationCommand,
        [CommandType.Fade.type]:                FadeCommand,
        [CommandType.Feedback.type]:            FeedbackCommand,
        [CommandType.HelperAnimationPlay.type]: HelperAnimationPlayCommand,
        [CommandType.HelperGlowChange.type]:    HelperGlowChangeCommand,
        [CommandType.HelperKnowledge.type]:     AIKnowledgeCommand,
        [CommandType.HelperPrompt.type]:        AIPromptCommand,
        [CommandType.HelperSpeak.type]:         SpeakCommand,
        [CommandType.HelperTriggerInvoke.type]: HelperTriggerInvokeCommand,
        [CommandType.HelperWaypointGoTo.type]:  HelperWaypointGoToCommand,
        [CommandType.AIKnowledge.type]:         AIKnowledgeCommand,
        [CommandType.AIPrompt.type]:            AIPromptCommand,
        [CommandType.Speak.type]:               SpeakCommand,
        [CommandType.Hide.type]:                HideCommand,
        [CommandType.ImageShow.type]:           ImageShowCommand,
        [CommandType.InputStyle.type]:          InputStyleCommand,
        [CommandType.LearningRecord.type]:      LearningRecordCommand,
        [CommandType.ModuleActivate.type]:      ActivateModuleCommand,
        [CommandType.ModuleDeactivate.type]:    DeactivateModuleCommand,
        [CommandType.SceneChange.type]:         ChangeSceneCommand,
        [CommandType.Script.type]:              ScriptCommand,
        [CommandType.Show.type]:                ShowCommand,
        [CommandType.SoundPlay.type]:           SoundPlayCommand,
        [CommandType.TextShow.type]:            ShowTextCommand,
        [CommandType.TriggerCancel.type]:       TriggerCancelCommand,
        [CommandType.TriggerInvoke.type]:       TriggerInvokeCommand,
        [CommandType.UnitExit.type]:            UnitExitCommand,
        [CommandType.UnitReset.type]:           UnitResetCommand,
        [CommandType.VariableOperation.type]:   VariableOperationCommand,
        [CommandType.VideoPlay.type]:           VideoPlayCommand,
        [CommandType.Wait.type]:                WaitCommand,
        [CommandType.WorldAnchorReset.type]:    WorldAnchorResetCommand,
    };

    return commandMapping.hasOwnProperty(type) ? commandMapping[type] : null;
}

export interface CommandAttributes {
    uid?: string;
    type?: string;
}

export default class Command extends AbstractDataObject
{
    static get constructorName() { return 'Command'; }

    /**
     * Targets
     *
     * @var {String}
     */
    static get TargetSelf(): string         { return 'self'; }
    static get TargetFirst(): string        { return 'first'; }
    static get TargetLast(): string         { return 'last'; }
    static get TargetNext(): string         { return 'next'; }
    static get TargetPrevious(): string     { return 'previous'; }

    public type: string;
    public uid: string;
    public originalUid: string;

    constructor(attributes: CommandAttributes, parent: AbstractDataObject | null = null)
    {
        super(parent);

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || !CommandType.isValidType(attributes.type))
        {
            console.warn('Command->constructor(): Invalid data.', attributes);
            throw new TypeError('Command->constructor(): Property "type" has to be set on instantiated command. Must be a valid type from CommandType class.');
        }

        // Populate the model:
        this.uid = attributes.uid || uuid4();                               // Unique ID
        this.originalUid = this.uid;                                        // Original unique ID from which the object was duplicated (hidden)
        this.type = attributes.type;                                        // Type identifier of CommandType
    }

    get commandType(): CommandType | null {
        return CommandType.getByTypeName(this.type);
    }

    /** @inheritdoc */
    get clipboardTitle()
    {
        return `${trans('labels.action')} "${this.commandType?.title}"`;
    }

    get supportedCommandTypes(): CommandType[] {
        // Get restrictions from parent object:
        if (this.parent !== null && this.parent.supportedCommandTypes instanceof Array)
        {
            // @NOTE: Excluding ConditionCommand so it cannot be nested into other commands:
            return [...this.parent.supportedCommandTypes]
                .filter(ct => ct.type !== CommandType.Condition.type);
        }
        return [];
    }

    get hasReachedMaxCount(): boolean {
        // No limitation is set if the command has no parent:
        const parent = this.getParent(Trigger) || this.parent;
        if (parent === null || !(parent instanceof Trigger || parent instanceof SceneObjectives))
        {
            console.warn('Command->hasReachedMaxCount(): Unable to check maximum count because parent is not a Trigger or SceneObjectives');
            return false;
        }
        // Check maximum count on parent trigger (or SceneObjectives!):
        const commands = parent.commands || [];
        const maxCount = this.commandType.maxCountPerTrigger || -1;
        return (maxCount >= 0 && commands.filter(c => c.type === this.type).length >= maxCount);
    }

    get isValid(): boolean {
        // Force implementation on inherited classes:
        if (!this.constructor.prototype.hasOwnProperty('isValid'))
        {
            throw new Error(`Command->isValid(): Subclass "${this.constructor.name}" must implement its own getter for isValid()`);
        }
        return true;
    }

    get referencedObjectUid(): string|null {
        return null;
    }

    typeOf(commandType: CommandType): boolean {
        return this.type === commandType.type;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     */
    duplicate(updateUidMapping: boolean = true): Command {
        const duplicated = Command.createFromAttributes(this, this.parent);
        duplicated.uid = uuid4();

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {
            updateUidReferences(duplicated);
        }

        return duplicated;
    }

    hasCommand(command: Command): boolean {
        return false;
    }

    get hasCommands(): boolean {
        return false;
    }

    get parentSceneObjectives(): SceneObjectives | null {
        return this.getParent(SceneObjectives);
    }

    cleanUpData(): boolean {
        // @NOTE: Override this method on subclasses to make sure a command only uses valid data
        // Return true if anything was changed and false otherwise.
        return false;
    }

    /**
     * Create a new command with the given CommandType or type string
     */
    static createWithType(commandType: CommandType | string, attributes: object | null = null, parent: object | null = null): Command {
        if (!(attributes instanceof Object)) {attributes = {};}
        const commandClass = getCommandClassFromType(commandType.type || commandType);

        // Merge default attributes:
        if (commandClass !== null && commandClass.defaultAttributes instanceof Object) {
            attributes = {
                ...commandClass.defaultAttributes,
                ...attributes
            };
        }

        // Enforce the 'type' that is provided by commandType:
        attributes = {
            ...attributes,
            ...{
                type: commandType.type || commandType,
            }
        };

        return Command.createFromAttributes(attributes, parent);
    }

    /**
     * Create a new command from given attributes
     */
    static createFromAttributes(attributes: object = {}, parent: object | null = null): Command {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = cloneDeep(attributes);
        const commandClass = getCommandClassFromType(clonedAttributes.type);

        return new (commandClass || Command)(clonedAttributes, parent);
    }
}

interface ActivateModuleCommandAttributes extends CommandAttributes {
    target?: string | null;
    await_completion?: boolean;
}

export class ActivateModuleCommand extends Command
{
    public target: string | null;
    public await_completion: boolean;

    constructor(attributes: ActivateModuleCommandAttributes = {}, parent: Object | null = null)
    {
        super(attributes, parent);

        this.target = attributes.target || null;
        this.await_completion = (typeof attributes.await_completion === 'boolean') ? attributes.await_completion : true;
    }

    get isValid(): boolean
    {
        return (this.target !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): SceneObject[]
    {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('ActivateModuleCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any module from the unit if the command's parent is a global object or any module from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects ||[])).filter(o => o.type === 'widget');
    }

    get targetObject(): BaseSceneObjectModule | null
    {
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    cleanUpData(): boolean
    {
        let hasChanged = super.cleanUpData();
        if (this.target === null) {return hasChanged;}

        // Handle target "self" (on scene objectives):
        if (this.target === Command.TargetSelf)
        {
            // Remove target "self" if not allowed:
            if (
                !CommandType.ModuleActivate.allowTargetSelf
                || this.parentSceneObjectives !== null
                || this.targetObject === null
            )
            {
                console.info('ActivateModuleCommand->cleanUpData(): Removing forbidden target "self" from command.', this);
                this.target = null;
                return true;
            }

            // No further checks needed:
            return hasChanged;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject !== null && parentSceneObject.uid === this.target && CommandType.ModuleActivate.allowTargetSelf)
        {
            console.info('ActivateModuleCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Check if target object exists and is allowed:
        if (!this.isValid || !this.possibleTargets.some(o => o.uid === this.target))
        {
            console.info('ActivateModuleCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target, this);
            this.target = null;
            return true;
        }
        return hasChanged;
    }

    static get defaultAttributes()
    {
        return {
            'await_completion': false,
        };
    }
}

interface BehaviourChangeCommandAttributes extends CommandAttributes {
    object?: string | null;
    behaviour?: Behaviour | null;
    properties?: Object | null;
}

export class BehaviourChangeCommand extends Command
{
    public object: string | null;
    public behaviour: Behaviour | null;
    public properties: Object | null;

    constructor(attributes: BehaviourChangeCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.object = attributes.object || null;
        this.behaviour = attributes.behaviour || null;
        this.properties = (attributes.properties instanceof Object) ? attributes.properties : null;
    }

    get isValid(): boolean
    {
        return (this.object !== null && this.behaviour !== null && this.properties instanceof Object && Object.keys(this.properties).length > 0) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.object === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.object;
    }

    get possibleTargets(): SceneObject[]
    {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('BehaviourChangeCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any object from the unit with at least one behaviour if the command's parent is a global object or any object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => o.hasBehaviours);
    }

    get targetObject(): SceneObject | null
    {
        if ((this.object === null || (this.object === Command.TargetSelf && this.isGlobal)) && this.behaviour !== null)
        {
            return this.possibleTargets.find(o => o.behaviours.some(b => b.uid === this.behaviour)) || null;
        }
        if (this.object === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.object !== null) ? this.possibleTargets.find(o => o.uid === this.object) || null : null;
    }

    get targetBehaviour(): Behaviour | null
    {
        const targetObject = (this.behaviour !== null) ? this.targetObject : null;
        return (targetObject !== null) ? targetObject.behaviours.find(b => b.uid === this.behaviour) || null : null;
    }

    cleanUpData(): boolean
    {
        let hasChanged = super.cleanUpData();

        // Reset to empty values if no target object is set:
        if (this.object === null)
        {
            if (this.behaviour !== null) {this.behaviour = null; hasChanged = true;}
            if (this.properties !== null) {this.properties = null; hasChanged = true;}
            return hasChanged;
        }

        // Handle target "self" on scene objectives:
        if (this.object === Command.TargetSelf && this.parentSceneObjectives !== null)
        {
            // Reset target if no behaviour is set since there is no way to find out which object the command was assigned to:
            if (this.behaviour === null)
            {
                console.info('BehaviourChangeCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
                this.object = null;
                this.properties = null;
                return true;
            }

            // Check if parent scene is set:
            const parentTrainingScene = this.getParent(TrainingScene);
            if (parentTrainingScene === null) {throw new Error('BehaviourChangeCommand->cleanUpData(): Unable to clean up data because parent scene is not set.');}

            // Reassign target "self" to original scene object when command is inside objectives:
            const originalTargetObject = this.getParent(UnitData)?.allGlobalObjects.concat(parentTrainingScene.allSceneObjects).find(o => o.hasBehaviours && o.behaviours.map(b => b.uid).includes(this.behaviour)) || null;
            if (originalTargetObject !== null)
            {
                console.info('BehaviourChangeCommand->cleanUpData(): Reassigning target "self" on command.', this);
                this.object = originalTargetObject.uid;
                hasChanged = true;
            }
            else
            {
                console.info('BehaviourChangeCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
                this.object = null;
                this.behaviour = null;
                this.properties = null;
                return true;
            }
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject?.uid === this.object && CommandType.BehaviourChange.allowTargetSelf)
        {
            console.info('BehaviourChangeCommand->cleanUpData(): Changing target to "self" on command.', this.object, this);
            this.object = Command.TargetSelf;
            hasChanged = true;
        }

        // Check if target object exists and is allowed:
        const targetObj = this.targetObject;
        if (targetObj === null || (this.object === Command.TargetSelf && !CommandType.BehaviourChange.allowTargetSelf))
        {
            console.info('BehaviourChangeCommand->cleanUpData(): Removing unknown or invalid target from command.', this.object, this);
            this.object = null;
            this.behaviour = null;
            this.properties = null;
            return true;
        }

        // Rewrite target "self" to UID if parent is a different object:
        if (this.object === Command.TargetSelf && parentSceneObject !== null && targetObj.uid !== parentSceneObject.uid)
        {
            console.info('BehaviourChangeCommand->cleanUpData(): Reassigning target to original scene object on command.', this.object, this);
            this.object = targetObj.uid;
            hasChanged = true;
        }

        // Reset behaviour and properties to empty values if the target object doesn't have the behaviour:
        const targetBehaviour = (targetObj.hasBehaviours) ? targetObj.behaviours.find(b => b.uid === this.behaviour) || null : null;
        if (targetBehaviour === null)
        {
            console.info('BehaviourChangeCommand->cleanUpData(): Removing missing behaviour and properties from command.', this.behaviour, this.properties, this);
            this.behaviour = null;
            this.properties = null;
            return true;
        }

        // Reset object if targetObject has no behaviours
        if (!targetObj.hasBehaviours)
        {
            console.info('BehaviourChangeCommand->cleanUpData(): Remove object, behaviour and properties because targetObject has no behaviours.', this);
            this.object = null;
            this.behaviour = null;
            this.properties = null;
            return true;
        }


        // Clean up properties according to the selected behaviour:
        // @NOTE: Using cleanUpData() on a temporary Behaviour instance to find only valid properties
        const tempBehaviour = new (targetBehaviour.constructor)(Object.assign({}, targetBehaviour, this.properties));
        tempBehaviour.cleanUpData();
        if (this.properties instanceof Object) {
            // Delete all properties that do not exist on the behaviour
            Object.keys(this.properties).forEach(property => {
                if (tempBehaviour.hasOwnProperty(property) === false) {
                    delete this.properties[property];
                    hasChanged = true;
                }
            });

            // If there are no properties to change, set properties to null
            if (Object.keys(this.properties).length === 0) {
                this.properties = null;
                hasChanged = true;
            }
        }
        // If the properties aren't an object or null, reset them
        else if (this.properties !== null) {
            this.properties = null;
            hasChanged = true;
        }

        return hasChanged;
    }
}

interface ChangeSceneCommandAttributes extends CommandAttributes {
    value?: string | null;
    fade?: boolean;
}

export class ChangeSceneCommand extends Command
{
    public value: string | null;
    public fade: boolean;

    constructor(attributes: ChangeSceneCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.SceneChange.defaultValue.value;
        this.fade = (typeof attributes.fade === 'boolean') ? attributes.fade : false;   // @NOTE: Should be false if no value is set at all #PRDA-12970
    }

    static get defaultAttributes(): {fade: any}
    {
        return {
            fade: CommandType.SceneChange.defaultValue.fade,
        };
    }

    get isValid(): boolean
    {
        const parentTrainingScene = this.getParent(TrainingScene);
        return (this.value !== null && (parentTrainingScene === null || parentTrainingScene.uid !== this.value)) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return this.targetScene?.uid || null;
    }

    get possibleTargets(): TrainingScene[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('ChangeSceneCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any scene from the unit for global objects or any other scene for objects within a scene:
        if (!this.isGlobal) {
            const parentScene = this.getParent(TrainingScene);
            return parentUnitData.scenes.filter((s: TrainingScene): boolean => s.uid !== parentScene?.uid);
        }
        return parentUnitData.scenes;
    }

    get targetScene(): TrainingScene | null
    {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData=== null || !parentUnitData.hasScenes)
        {
            return null;
        }
        const possibleTargets = this.possibleTargets;
        if (possibleTargets.length === 0)
        {
            return null;
        }
        const scenes = parentUnitData.scenes;
        let parentScene;
        switch (this.value)
        {
            case Command.TargetFirst:
                return (scenes[0].uid === possibleTargets[0].uid) ? scenes[0] : null;

            case Command.TargetLast:
                const lastIndex = scenes.length - 1;
                return (scenes[lastIndex].uid === possibleTargets[possibleTargets.length - 1].uid) ? scenes[lastIndex] : null;

            case Command.TargetPrevious:
                parentScene = this.getParent(TrainingScene);
                if (parentScene === null)
                {
                    return null;    // There's no parent scene for global objects so there can't be a previous scene either
                }
                const previousIndex = -1 + scenes.findIndex((s: TrainingScene): boolean => s.uid === parentScene.uid);
                return (previousIndex >= 0 && possibleTargets.some(s => s.uid === scenes[previousIndex].uid)) ? scenes[previousIndex] : null;

            case Command.TargetNext:
                parentScene = this.getParent(TrainingScene);
                if (parentScene === null)
                {
                    return null;    // There's no parent scene for global objects so there can't be a next scene either
                }
                const nextIndex = 1 + scenes.findIndex((s: TrainingScene): boolean => s.uid === parentScene.uid);
                return (nextIndex >= 1 && scenes.length > nextIndex && possibleTargets.some(s => s.uid === scenes[nextIndex].uid)) ? scenes[nextIndex] : null;

            case Command.TargetSelf:
                return null;    // Cannot change scene to itself. SceneResetCommand should be used for that case

            default:
                if (!isNaN(this.value))
                {
                    const sceneAtIndex = scenes[parseInt(this.value, 10)] || null;
                    const parentTrainingScene = this.getParent(TrainingScene);
                    return (sceneAtIndex !== null && (parentTrainingScene === null || parentTrainingScene.uid !== sceneAtIndex.uid)) ? sceneAtIndex : null;
                }
        }
        return (this.value !== null) ? this.possibleTargets.find(s => s.uid === this.value) || null : null;
    }

    cleanUpData(): boolean
    {
        let hasChanged = super.cleanUpData();

        // No need to clean up if no value is set or if it's child of a global object:
        if (this.value === null) {return hasChanged;}

        const parentUnitData = this.getParent(UnitData);

        // Check if parent is set:
        if (parentUnitData === null) {throw new Error('ChangeSceneCommand->cleanUpData(): Unable to clean up data because parent UnitData is not set.');}

        // Fix invalid fade property:
        if (typeof this.fade !== 'boolean')
        {
            console.info('ChangeSceneCommand->cleanUpData(): Resetting incorrect fade property to default value.', this.fade, this);
            this.fade = CommandType.SceneChange.defaultValue.fade;
            hasChanged = true;
        }

        // Remove invalid index when out of range:
        if (!isNaN(this.value))
        {
            const index = parseInt(this.value);
            if ((index < 0 || index >= parentUnitData.scenesCount))
            {
                console.info('ChangeSceneCommand->cleanUpData(): Removing invalid out-of-range scene target index.', this.value, this);
                this.value = null;
                return true;
            }
        }

        // Remove invalid scene targets:
        else if (![Command.TargetFirst, Command.TargetLast, Command.TargetNext, Command.TargetPrevious].includes(this.value) && !this.possibleTargets.some(s => s.uid === this.value))
        {
            console.info('ChangeSceneCommand->cleanUpData(): Removing invalid scene target.', this.value, this);
            this.value = null;
            return true;
        }

        // No further checks if the command is not part of a scene:
        const parentTrainingScene = this.getParent(TrainingScene);
        if (parentTrainingScene === null)
        {
            return hasChanged;
        }

        // Get scene index from the unit:
        const sceneIndex = (typeof parentTrainingScene.order === 'number') ? parentTrainingScene.order : parentUnitData.scenes.findIndex(s => s.uid === parentTrainingScene.uid);

        // Clear "First" and "Previous" if it's the first scene:
        if (sceneIndex === 0 && (this.value === sceneIndex.toString() || this.value === Command.TargetFirst || this.value === Command.TargetPrevious))
        {
            console.info('ChangeSceneCommand->cleanUpData(): Removing scene target "First" or "Previous" since parent is the first scene.', this);
            this.value = null;
            return true;
        }

        // Clear "Last" and "Next" if it's the last scene:
        else if (sceneIndex === (parentUnitData.scenesCount - 1) && (this.value === sceneIndex.toString() || this.value === Command.TargetNext || this.value === Command.TargetLast))
        {
            console.info('ChangeSceneCommand->cleanUpData(): Removing scene target "Next" or "Last" since parent is the last scene.', this);
            this.value = null;
            return true;
        }

        // Clear "By Index" if it points to the same parent scene:
        // @NOTE: Using ==, not === since value is a string
        if (sceneIndex == this.value || this.value === parentTrainingScene.uid)
        {
            console.info('ChangeSceneCommand->cleanUpData(): Removing scene target since parent is the same scene.', this.value, sceneIndex, this);
            this.value = null;
            return true;
        }

        return hasChanged;
    }
}

interface ConditionCommandAttributes extends CommandAttributes {
    condition?: object | null;
    true_action?: CommandAttributes | null;
    false_action?: CommandAttributes | null;
}

export class ConditionCommand extends Command
{
    public condition: Condition;
    public true_action: Command | null;
    public false_action: Command | null;

    constructor(attributes: ConditionCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.condition = getConditionForData(attributes.condition, this) || new ObjectiveCompletedCondition(null, this);
        this.true_action = attributes.true_action?.type ? Command.createWithType(attributes.true_action.type, attributes.true_action, this) : null;
        this.false_action = attributes.false_action?.type ? Command.createWithType(attributes.false_action.type, attributes.false_action, this) : null;
    }

    get commands(): Command[] {
        const commands: Command[] = [];

        [this.true_action, this.false_action].forEach((command) => {
            if (command instanceof Command) {
                commands.push(command);
            }
        });

        return commands;
    }

    get hasCommands(): boolean {
        return (this.true_action instanceof Command || this.false_action instanceof Command);
    }

    hasCommand(command: Command): boolean {
        const commandIsTrueAction = this.true_action !== null && this.true_action.uid === command.uid;
        const commandIsFalseAction = this.false_action !== null && this.false_action.uid === command.uid;
        return commandIsTrueAction || commandIsFalseAction;
    }

    /**
     * Remove a given command.
     */
    removeCommand(command: Command) {
        if (this.true_action === command) {
            this.true_action = null;
        }

        if (this.false_action === command) {
            this.false_action = null;
        }
    }

    get isValid(): boolean {
        // At least one action has to be set:
        return (
            (this.condition !== null && this.condition.isValid) &&
            (this.true_action !== null || this.false_action !== null) &&
            (this.true_action === null || this.true_action.isValid) &&
            (this.false_action === null || this.false_action.isValid)
        ) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return this.condition?.referencedObjectUid || null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.condition instanceof Condition) {hasChanged = this.condition.cleanUpData() || hasChanged;}
        // Remove forbidden commands:
        const allowedCommandTypes = this.supportedCommandTypes.map(ct => ct.type);
        if (this.true_action instanceof Command && !allowedCommandTypes.includes(this.true_action.type))
        {
            console.info('ConditionCommand->cleanUpData(): Removing forbidden command from condition TRUE action.', this.true_action, this);
            this.true_action = null;
            hasChanged = true;
        }
        if (this.false_action instanceof Command && !allowedCommandTypes.includes(this.false_action.type))
        {
            console.info('ConditionCommand->cleanUpData(): Removing forbidden command from condition FALSE action.', this.false_action, this);
            this.false_action = null;
            hasChanged = true;
        }
        if (this.true_action instanceof Command) {hasChanged = this.true_action.cleanUpData() || hasChanged;}
        if (this.false_action instanceof Command) {hasChanged = this.false_action.cleanUpData() || hasChanged;}
        return hasChanged;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     */
    duplicate(updateUidMapping: boolean = true): Command {
        const duplicated: ConditionCommand = super.duplicate(updateUidMapping) as ConditionCommand;

        // Duplicate child objects:
        if (duplicated.true_action) {duplicated.true_action = duplicated.true_action.duplicate(false);}
        if (duplicated.false_action) {duplicated.false_action = duplicated.false_action.duplicate(false);}

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {
            updateUidReferences(duplicated);
        }

        return duplicated;
    }
}

interface Control3dAnimationCommandAttributes extends CommandAttributes {
    subtype?: string | null;
    target?: string | null;
    animation_name?: string | null;
}

export class Control3dAnimationCommand extends Command
{
    public subtype: string;
    public target: string | null;
    public animation_name: string | null;

    static get Subtypes() {
        return {
            Play: 'play',
        };
    }

    constructor(attributes: Control3dAnimationCommandAttributes = {}, parent = null) {
        super(attributes, parent);

        this.subtype = attributes.subtype || Control3dAnimationCommand.Subtypes.Play;
        this.target = attributes.target || null;
        this.animation_name = attributes.animation_name || null;
    }

    get isValid(): boolean {
        return (this.target !== null)
            && this.animation_name !== null
            && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): (SceneObjectAssetModel3D | SceneObjectAssetEnvironmentModel3D)[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('Control3dAnimationCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any 3D model scene object from the unit if the command's parent is a global object or any 3D model scene object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => o.type === 'asset' && ['model3d', 'environment_model3d'].includes(o.subtype));
    }

    get targetObject(): SceneObjectAssetModel3D | SceneObjectAssetEnvironmentModel3D | null {
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset invalid subtypes:
        if (!Object.values(Control3dAnimationCommand.Subtypes).includes(this.subtype)) {
            console.info('Control3dAnimationCommand->cleanUpData(): Resetting invalid subtype to default value.', this.subtype);
            this.subtype = Control3dAnimationCommand.Subtypes.Play;
            hasChanged = true;
        }

        // No further cleanup needed if no target is set:
        if (this.target === null) {return hasChanged;}

        // Handle target "self" (on scene objectives):
        if (this.target === Command.TargetSelf)
        {
            // Remove target "self" if not allowed:
            if (
                !CommandType.Control3dAnimation.allowTargetSelf
                || this.parentSceneObjectives !== null
                || this.targetObject === null
            )
            {
                console.info('Control3dAnimationCommand->cleanUpData(): Removing forbidden target "self" from command.', this);
                this.target = null;
                return true;
            }

            // No further checks needed:
            return hasChanged;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject !== null && parentSceneObject.uid === this.target && CommandType.Control3dAnimation.allowTargetSelf)
        {
            console.info('Control3dAnimationCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Check if target object exists and is allowed:
        if (!this.possibleTargets.some(o => o.uid === this.target))
        {
            console.info('Control3dAnimationCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target, this);
            this.target = null;
            return true;
        }

        return hasChanged;
    }
}

interface ControlCharacterAnimationCommandAttributes extends CommandAttributes {
    subtype?: string | null;
    target?: string | null;
    animation_type?: string | null;
}

export class ControlCharacterAnimationCommand extends Command {
    public subtype: string;
    public target: string | null;
    public animation_type: string | null;

    static get Subtypes() {
        return {
            Play: 'play',
        };
    }

    constructor(attributes: ControlCharacterAnimationCommandAttributes = {}, parent = null) {
        super(attributes, parent);

        this.subtype = attributes.subtype || ControlCharacterAnimationCommand.Subtypes.Play;
        this.target = attributes.target || null;
        this.animation_type = attributes.animation_type || null;
    }

    get animation(): CharacterAnimation | null {
        return this.possibleAnimations.find(animation => animation.type === this.animation_type) || null;
    }

    get isValid(): boolean {
        return (this.target !== null)
            && this.animation_type !== null
            && this.animation !== null
            && super.isValid;
    }

    get possibleAnimations(): CharacterAnimation[] {
        const characterPosture = this.targetObject?.posture;
        const characterGender = this.targetObject?.asset.character_gender;

        return CharacterAnimation.All.filter(animation => animation.allowedFor(characterGender, characterPosture));
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): SceneObjectAssetCharacterModel3D[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('ControlCharacterAnimationCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any 3D character scene object from the unit if the command's parent is a global object or any 3D character scene object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => o.type === SceneObjectType.TypeOfAsset && o.subtype === AssetType.CharacterModel3D.type);
    }

    get targetObject(): SceneObjectAssetCharacterModel3D | null {
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset invalid subtypes:
        if (!Object.values(ControlCharacterAnimationCommand.Subtypes).includes(this.subtype)) {
            console.info('ControlCharacterAnimationCommand->cleanUpData(): Resetting invalid subtype to default value.', this.subtype);
            this.subtype = ControlCharacterAnimationCommand.Subtypes.Play;
            hasChanged = true;
        }

        // Reset invalid animation types
        if (this.animation === null && this.animation_type !== null) {
            console.info('ControlCharacterAnimationCommand->cleanUpData(): Resetting invalid animation_type to null.', this.animation_type);
            this.animation_type = null;
            hasChanged = true;
        }

        // No further cleanup needed if no target is set:
        if (this.target === null) {return hasChanged;}

        // Handle target "self" (on scene objectives):
        if (this.target === Command.TargetSelf)
        {
            // Remove target "self" if not allowed:
            if (
                !CommandType.ControlCharacterAnimation.allowTargetSelf
                || this.parentSceneObjectives !== null
                || this.targetObject === null
            )
            {
                console.info('ControlCharacterAnimationCommand->cleanUpData(): Removing forbidden target "self" from command.', this);
                this.target = null;
                return true;
            }

            // No further checks needed:
            return hasChanged;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject !== null && parentSceneObject.uid === this.target && CommandType.ControlCharacterAnimation.allowTargetSelf)
        {
            console.info('ControlCharacterAnimationCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Check if target object exists and is allowed:
        if (!this.possibleTargets.some(o => o.uid === this.target))
        {
            console.info('ControlCharacterAnimationCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target, this);
            this.target = null;
            return true;
        }

        return hasChanged;
    }
}

interface DeactivateModuleCommandAttributes extends CommandAttributes {
    target?: string | null;
}

export class DeactivateModuleCommand extends Command
{
    public target: string | null;

    constructor(attributes: DeactivateModuleCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.target = attributes.target || null;
    }

    get isValid(): boolean {
        return (this.target !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): BaseSceneObjectModule[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('DeactivateModuleCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any module from the unit if the command's parent is a global object or any module from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => o.type === 'widget');
    }

    get targetObject(): BaseSceneObjectModule | null {
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.target === null) {return hasChanged;}

        // Handle target "self" (on scene objectives):
        if (this.target === Command.TargetSelf)
        {
            // Remove target "self" if not allowed:
            if (
                !CommandType.ModuleDeactivate.allowTargetSelf
                || this.parentSceneObjectives !== null
                || this.targetObject === null
            )
            {
                console.info('DeactivateModuleCommand->cleanUpData(): Removing forbidden target "self" from command.', this);
                this.target = null;
                return true;
            }

            // No further checks needed:
            return hasChanged;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject !== null && parentSceneObject.uid === this.target && CommandType.ModuleDeactivate.allowTargetSelf)
        {
            console.info('DeactivateModuleCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Check if target object exists and is allowed:
        if (!this.isValid || !this.possibleTargets.some(o => o.uid === this.target))
        {
            console.info('DeactivateModuleCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target, this);
            this.target = null;
            return true;
        }

        return hasChanged;
    }
}

interface FadeCommandAttributes extends CommandAttributes {
    value?: FadeCommandValueProperty | null;
}

interface FadeCommandValueProperty {
    type: string | null,
    color: string | null,
}

export class FadeCommand extends Command
{
    public value: FadeCommandValueProperty;

    constructor(attributes: FadeCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.Fade.defaultValue;
    }

    get isValid(): boolean {
        return (
            this.value !== null
            && typeof this.value.type === 'string'
            && typeof this.value.color === 'string'
            && parseColor(this.value.color) !== null
            && Object.keys(CommandType.Fade.possibleValues).includes(this.value.type)
        ) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (!this.isValid)
        {
            console.info('FadeCommand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = CommandType.Fade.defaultValue;
            return true;
        }
        return hasChanged;
    }
}

interface FeedbackCommandAttributes extends CommandAttributes {
    value?: string | null;
    subtype?: string | null;
    message?: string;
}

export class FeedbackCommand extends Command
{
    public subtype: string;
    public message: string | null;

    static get Subtypes()
    {
        return {
            Fail: 'fail',
            Message: 'message',
            Success: 'success',
        };
    }

    constructor(attributes: FeedbackCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        // Migrate old data that was created with unit-data-version lower than 0.47.0
        // @TODO: Remove this line once we drop support for unit data < 0.47.0
        attributes.subtype = attributes.subtype || attributes.value;

        this.subtype = attributes.subtype || CommandType.Feedback.defaultValue;
        this.message = attributes.message || null;
    }

    get isValid(): boolean {
        if (!Object.keys(CommandType.Feedback.possibleValues).includes(this.subtype) || !Object.values(FeedbackCommand.Subtypes).includes(this.subtype))
        {
            return false;
        }
        if (this.subtype === FeedbackCommand.Subtypes.Message)
        {
            // Check that the message is a string and is not empty
            if (typeof this.message !== 'string' || this.message.trim() === '')
            {
                return false;
            }
        }
        return super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset invalid subtype to default value:
        if (!Object.keys(CommandType.Feedback.possibleValues).includes(this.subtype) || !Object.values(FeedbackCommand.Subtypes).includes(this.subtype))
        {
            console.info('FeedbackCommand->cleanUpData(): Resetting invalid subtype to default value.', this.subtype, this);
            this.subtype = CommandType.Feedback.defaultValue;
            this.message = null;
            return true;
        }

        if (this.subtype === FeedbackCommand.Subtypes.Message && this.message !== null)
        {
            if (typeof this.message !== 'string')
            {
                console.info('FeedbackCommand->cleanUpData(): Removing invalid message.', this.message, this);
                this.message = null;
                return true;
            }

            if (this.message !== this.message.trim())
            {
                console.info('FeedbackCommand->cleanUpData(): Removing whitespace from message.', this.message, this);
                this.message = this.message.trim();
                return true;
            }
        }

        return hasChanged;
    }
}

export interface AbstractHelperCommandAttributes extends CommandAttributes {
    target?: string | null,
}

export class AbstractHelperCommand extends Command
{
    public target: string | null;

    constructor(attributes: AbstractHelperCommandAttributes = {}, parent: Object | null = null)
    {
        if (new.target === AbstractHelperCommand) {
            throw new TypeError(`Cannot construct AbstractHelperCommand instances directly`);
        }

        super(attributes, parent);

        this.target = attributes.target || null;        // UID of the helper module
    }

    get isValid(): boolean
    {
        return (this.target !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get targetObject(): SceneObjectModuleHelper | null
    {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('AbstractHelperCommand->targetObject(): Unable to get target object because parent UnitData is not set.');}
        return parentUnitData.firstHelperModule;
    }

    cleanUpData(): boolean
    {
        let hasChanged = super.cleanUpData();

        // Check if helper module exists on the unit:
        const helperModule = this.targetObject;
        if (helperModule === null)
        {
            const newHelperModule = this.getParent(UnitData)?.createHelperModule();
            this.target = newHelperModule?.uid || null;
            console.info('AbstractHelperCommand->cleanUpData(): Created a helper module since none was found on the unit.', newHelperModule, this);
            return true;
        }

        const parentSceneObject = this.getParent(SceneObject);
        const parentIsHelperModule = (parentSceneObject !== null && parentSceneObject.uid === helperModule.uid);

        // Change target to "self" if parent has the same UID as the target:
        if (parentIsHelperModule && this.target !== Command.TargetSelf)
        {
            console.info('AbstractHelperCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Reassign target to current helper module:
        if (!parentIsHelperModule && this.target !== helperModule.uid)
        {
            console.info('AbstractHelperCommand->cleanUpData(): Changing unknown target to current helper module.', this.target, this);
            this.target = helperModule.uid;
            return true;
        }

        return hasChanged;
    }
}

interface HelperAnimationPlayCommandAttributes extends AbstractHelperCommandAttributes {
    value?: string | null;
}

export class HelperAnimationPlayCommand extends AbstractHelperCommand
{
    public value: string;

    constructor(attributes: HelperAnimationPlayCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.HelperAnimationPlay.defaultValue;     // Name of the animation to be played
    }

    get isValid(): boolean {
        return (CommandType.HelperAnimationPlay.possibleValues.includes(this.value)) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value !== null && !CommandType.HelperAnimationPlay.possibleValues.includes(this.value))
        {
            console.info('HelperAnimationPlayCommand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = CommandType.HelperAnimationPlay.defaultValue;
            return true;
        }
        return hasChanged;
    }
}

interface HelperGlowChangeCommandAttributes extends AbstractHelperCommandAttributes {
    value?: string | null;
}

export class HelperGlowChangeCommand extends AbstractHelperCommand
{
    public value: string;

    constructor(attributes: HelperGlowChangeCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.HelperGlowChange.defaultValue;     // Color hex value
    }

    get isValid(): boolean {
        return (
            parseColor(this.value) !== null
            && super.isValid
        );
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (parseColor(this.value) === null)
        {
            console.info('HelperGlowChangeCommand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = CommandType.HelperGlowChange.defaultValue;
            return true;
        }
        return hasChanged;
    }
}

interface HelperKnowledgeCommandAttributes extends AbstractHelperCommandAttributes {
    knowledge?: string | null;
}

export class HelperKnowledgeCommand extends AbstractHelperCommand
{
    public knowledge: string | null;

    constructor(attributes: HelperKnowledgeCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.knowledge = attributes.knowledge || CommandType.HelperKnowledge.defaultValue;
    }

    get isValid(): boolean {
        return typeof this.knowledge === 'string' && this.knowledge.trim() !== '' && super.isValid;
    }

    get entitlementsNeeded(): Feature[] {
        return [Feature.EntitlementHelperAiExtensions];
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        if (this.knowledge === null) {
            return hasChanged;
        }

        if (this.knowledge.trim().length === 0) {
            console.info('HelperKnowledgeCommand->cleanUpData(): Resetting invalid value to default.', this.knowledge, this);
            this.knowledge = CommandType.HelperKnowledge.defaultValue;
            return true;
        }

        return hasChanged;
    }
}

interface HelperPromptCommandAttributes extends AbstractHelperCommandAttributes {
    prompt?: string | null;
}

export class HelperPromptCommand extends AbstractHelperCommand
{
    public prompt: string | null;

    constructor(attributes: HelperPromptCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.prompt = attributes.prompt || CommandType.HelperPrompt.defaultValue;
    }

    get isValid(): boolean {
        return typeof this.prompt === 'string' && this.prompt.trim() !== '' && super.isValid;
    }

    get entitlementsNeeded(): Feature[] {
        return [Feature.EntitlementHelperAiExtensions];
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        if (this.prompt === null) {
            return hasChanged;
        }

        if (this.prompt.trim().length === 0) {
            console.info('HelperPromptCommand->cleanUpData(): Resetting invalid value to default.', this.prompt, this);
            this.prompt = CommandType.HelperPrompt.defaultValue;
            return true;
        }

        return hasChanged;
    }
}

interface HelperSpeakCommandAttributes extends AbstractHelperCommandAttributes {
    value?: string | null;
}

/**
 * @deprecated since version 12.x, use "SpeakCommand" instead, #PRDA-16168
 */
export class HelperSpeakCommand extends AbstractHelperCommand
{
    public value: string | null;

    constructor(attributes: HelperSpeakCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.HelperSpeak.defaultValue;     // UID of the sound asset to be played
    }

    get isValid(): boolean {
        return (this.value !== null) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value === null) {return hasChanged;}

        // Check if parent is set:
        const parentUnitRevision = this.getParent(UnitRevision);
        if (parentUnitRevision === null) {throw new Error('HelperSpeakCommand->cleanUpData(): Unable to clean up data because parent UnitRevision is not set.');}

        // Remove invalid asset reference:
        if (!parentUnitRevision.hasAssetWithUid(this.value))
        {
            console.info('HelperSpeakCommand->cleanUpData(): Removing reference to unknown or inaccessible asset.', this.value, this);
            this.value = null;
            return true;
        }
        return hasChanged;
    }
}

interface HelperTriggerInvokeCommandAttributes extends AbstractHelperCommandAttributes {
    value?: string | null;
    await_completion?: boolean;
}

export class HelperTriggerInvokeCommand extends AbstractHelperCommand
{
    public value: string | null;
    public await_completion: boolean;

    constructor(attributes: HelperTriggerInvokeCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.HelperTriggerInvoke.defaultValue;         // UID of the trigger to invoke
        this.await_completion = (typeof attributes.await_completion === 'boolean') ? attributes.await_completion : false;   // Whether to wait for the trigger's action to complete
    }

    get isValid(): boolean {
        return (this.value !== null) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value === null) {return hasChanged;}

        // Check if parent is set:
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('HelperTriggerInvokeCommand->cleanUpData(): Unable to clean up data because parent UnitData is not set.');}

        // Check if helper module exists and has the assigned trigger:
        const helperModule = this.targetObject;
        if ((helperModule === null || !helperModule.hasTriggers || !helperModule.triggers.some(t => t.uid === this.value)))
        {
            // Reassign original trigger UID for triggers that were duplicated into other non-helper scene objects:
            const parentSceneObject = this.getParent(SceneObject);
            const parentTrigger = this.getParent(Trigger);
            if (parentTrigger !== null
                && parentSceneObject !== null
                && helperModule !== null
                && parentSceneObject.uid !== helperModule.uid
                && parentTrigger.uid !== parentTrigger.originalUid
                && parentTrigger.uid === this.value
                && helperModule.hasTriggers
                && helperModule.triggers.some(t => t.uid === parentTrigger.originalUid)
            )
            {
                console.info('HelperTriggerInvokeCommand->cleanUpData(): Reassigning trigger target on duplicated command.', this.value, this);
                this.value = parentTrigger.originalUid;
                return true;
            }

            // Remove unknown or invalid trigger reference:
            console.info('HelperTriggerInvokeCommand->cleanUpData(): Removing unknown or invalid trigger target from command.', this.value, this);
            this.value = null;
            return true;
        }
        return hasChanged;
    }
}

interface HelperWaypointGoToCommandAttributes extends AbstractHelperCommandAttributes {
    value?: string | null;
}

export class HelperWaypointGoToCommand extends AbstractHelperCommand
{
    public value: string;

    constructor(attributes: HelperWaypointGoToCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.HelperWaypointGoTo.defaultValue;     // UID of the waypoint or static WaypointMode
    }

    get isValid(): boolean {
        return (this.value !== null) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value === null) {return hasChanged;}

        // Check if value is a valid WaypointMode:
        if (CommandType.HelperWaypointGoTo.possibleValues.includes(this.value)) {
            return hasChanged;
        }

        // Check if parent is set:
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('HelperWaypointGoToCommand->cleanUpData(): Unable to clean up data because parent UnitData is not set.');}

        // Check if the waypoint exists and is a valid target:
        const helperModule = this.targetObject;
        const helperHasWaypoints = (helperModule !== null && helperModule.hasWaypoints);
        const parentTrainingScene = this.getParent(TrainingScene);
        if (!this.isValid
            || helperModule === null
            || !helperHasWaypoints
            || (parentTrainingScene === null && !helperModule.waypoints.hasWaypointWithUid(this.value))
            || (parentTrainingScene !== null && !(helperModule.waypoints.hasGlobalWaypointWithUid(this.value) || helperModule.waypoints.hasWaypointWithUidForScene(this.value, parentTrainingScene)))
        )
        {
            console.info('HelperWaypointGoToCommand->cleanUpData(): Removing reference to unknown waypoint. Resetting to default waypoint mode.', this.value, this);
            this.value = CommandType.HelperWaypointGoTo.defaultValue;
            return true;
        }

        return hasChanged;
    }
}

export interface AbstractAICommandAttributes extends CommandAttributes {
    target?: string | null,
}

export class AbstractAICommand extends Command
{
    public target: string | null;

    constructor(attributes: AbstractAICommandAttributes = {}, parent: Object | null = null)
    {
        if (new.target === AbstractAICommand) {
            throw new TypeError(`Cannot construct AbstractAICommand instances directly`);
        }

        super(attributes, parent);

        this.target = attributes.target || null;        // UID of the helper module or 3D character asset
    }

    get isValid(): boolean
    {
        return (this.target !== null) && super.isValid;
    }

    get entitlementsNeeded(): Feature[] {
        switch (this.targetObject?.subtype) {
            case SceneObjectType.Modules.Helper.subtype:
                return [Feature.EntitlementHelperAiExtensions];
            case SceneObjectType.Assets.CharacterModel3D.subtype:
                return [Feature.EntitlementCharacterAiExtensions];
            default:
                return [Feature.EntitlementHelperAiExtensions, Feature.EntitlementCharacterAiExtensions];
        }
    }


    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): (SceneObjectAssetCharacterModel3D | SceneObjectModuleHelper)[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('AbstractAICommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any helper module/3D character asset from the unit if the command's parent is a global object or any from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || []))
            .filter(o => (
                (o.type === SceneObjectType.TypeOfAsset && o.subtype === AssetType.CharacterModel3D.type)
                || (o.type === SceneObjectType.TypeOfModule && o.subtype === SceneObjectType.Modules.Helper.subtype)
            ));
    }

    get targetObject(): SceneObjectAssetCharacterModel3D | SceneObjectModuleHelper | null
    {
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    cleanUpData(): boolean
    {
        let hasChanged = super.cleanUpData();

        const parentSceneObject = this.getParent(SceneObject);
        const parentIsTarget = (parentSceneObject !== null && parentSceneObject.uid === this.target);

        // Change target to "self" if parent has the same UID as the target:
        if (parentIsTarget && this.target !== Command.TargetSelf)
        {
            console.info('AbstractAICommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Remove target if it doesn't exist:
        if (this.target !== null && this.targetObject === null)
        {
            console.info('AbstractAICommand->cleanUpData(): Removing unknown or invalid trigger target from command.', this.target, this);
            this.target = null;
            return true;
        }

        return hasChanged;
    }
}

interface AIKnowledgeCommandAttributes extends AbstractAICommandAttributes {
    knowledge?: string | null;
    knowledge_type?: AIKnowledgeType | null;
}

export class AIKnowledgeCommand extends AbstractAICommand
{
    public knowledge: string | null;
    public knowledge_type: AIKnowledgeType;

    constructor(attributes: AIKnowledgeCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.knowledge = attributes.knowledge || CommandType.AIKnowledge.defaultValue;
        this.knowledge_type = attributes.knowledge_type || AIKnowledgeType.Temporary;

        // Overwrite type and knowledge_type to migrate old helper_knowledge commands
        if (this.type === CommandType.HelperKnowledge.type) {
            this.type = CommandType.AIKnowledge.type;
            this.knowledge_type = AIKnowledgeType.Permanent;
        }
    }

    get isValid(): boolean {
        return (
            super.isValid
            && typeof this.knowledge === 'string' && this.knowledge.trim() !== ''
            && typeof this.knowledge_type === 'string' && AIKnowledgeTypeHelpers.all().includes(this.knowledge_type)
        );
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        if (this.knowledge === null) {
            return hasChanged;
        }

        if (this.knowledge.trim().length === 0) {
            console.info('AIKnowledgeCommand->cleanUpData(): Resetting invalid value to default.', this.knowledge, this);
            this.knowledge = CommandType.AIKnowledge.defaultValue;
            return true;
        }

        return hasChanged;
    }
}

interface AIPromptCommandAttributes extends AbstractAICommandAttributes {
    prompt?: string | null;
}

export class AIPromptCommand extends AbstractAICommand
{
    public prompt: string | null;

    constructor(attributes: AIPromptCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        // Overwrite type to migrate old helper_prompt commands
        this.type = 'ai_prompt';

        this.prompt = attributes.prompt || CommandType.AIPrompt.defaultValue;
    }

    get isValid(): boolean {
        return typeof this.prompt === 'string' && this.prompt.trim() !== '' && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        if (this.prompt === null) {
            return hasChanged;
        }

        if (this.prompt.trim().length === 0) {
            console.info('AIPromptCommand->cleanUpData(): Resetting invalid value to default.', this.prompt, this);
            this.prompt = CommandType.AIPrompt.defaultValue;
            return true;
        }

        return hasChanged;
    }
}

interface SpeakCommandAttributes extends AbstractAICommandAttributes {
    value?: string | null;
}

export class SpeakCommand extends AbstractAICommand
{
    public value: string | null;

    constructor(attributes: SpeakCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        // Overwrite type to migrate old helper_speak commands
        this.type = CommandType.Speak.type;

        this.value = attributes.value || CommandType.Speak.defaultValue;     // UID of the sound asset to be played
    }

    get isValid(): boolean {
        return (this.value !== null) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value === null) {return hasChanged;}

        // Check if parent is set:
        const parentUnitRevision = this.getParent(UnitRevision);
        if (parentUnitRevision === null) {throw new Error('SpeakCommand->cleanUpData(): Unable to clean up data because parent UnitRevision is not set.');}

        // Remove invalid asset reference:
        if (!parentUnitRevision.hasAssetWithUid(this.value))
        {
            console.info('SpeakCommand->cleanUpData(): Removing reference to unknown or inaccessible asset.', this.value, this);
            this.value = null;
            return true;
        }
        return hasChanged;
    }
}

interface HideCommandAttributes extends CommandAttributes {
    value?: string | null;
    target?: string | null;
    node_paths?: string[];
}

export class HideCommand extends Command
{
    public value: string;
    public target: string | null;
    public node_paths: string[];

    constructor(attributes: HideCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = CommandType.Hide.defaultValue;
        this.target = attributes.hasOwnProperty('target') ? attributes.target : null;
        this.node_paths = attributes.node_paths || [];
    }

    get isValid(): boolean {
        return (this.value !== null && this.target !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): (BaseSceneObjectAsset|BaseSceneObjectHotspot|SceneObjectParticleEmitter)[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('HideCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any object from the unit if the command's parent is a global object or any object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => ['asset', 'hotspot', 'particle_emitter'].includes(o.type));
    }

    get targetObject(): BaseSceneObjectAsset | BaseSceneObjectHotspot | SceneObjectParticleEmitter | null {
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.target === null) {return hasChanged;}

        // Handle target "self" (on scene objectives):
        if (this.target === Command.TargetSelf)
        {
            // Remove target "self" if not allowed:
            if (
                !CommandType.Hide.allowTargetSelf
                || this.parentSceneObjectives !== null
                || this.targetObject === null
            )
            {
                console.info('HideCommand->cleanUpData(): Removing forbidden target "self" from command.', this);
                this.target = null;
                return true;
            }

            // No further checks needed:
            return hasChanged;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject !== null && parentSceneObject.uid === this.target && CommandType.Hide.allowTargetSelf)
        {
            console.info('HideCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Check if target object exists and is allowed:
        if (!this.isValid || !this.possibleTargets.some(o => o.uid === this.target))
        {
            console.info('HideCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target, this);
            this.target = null;
            return true;
        }

        return hasChanged;
    }
}

interface ImageShowCommandAttributes extends CommandAttributes {
    value?: string | null;
}

export class ImageShowCommand extends Command
{
    public value: string | null;

    constructor(attributes: ImageShowCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.ImageShow.defaultValue;
    }

    get isValid(): boolean {
        return (this.value !== null) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value === null) {return hasChanged;}

        // Check if parent is set:
        const parentUnitRevision = this.getParent(UnitRevision);
        if (parentUnitRevision === null) {throw new Error('ImageShowCommand->cleanUpData(): Unable to clean up data because parent UnitRevision is not set.');}

        // Remove invalid asset reference:
        if (!parentUnitRevision.hasAssetWithUid(this.value))
        {
            console.info('ImageShowCommand->cleanUpData(): Removing reference to unknown or inaccessible asset.', this.value, this);
            this.value = null;
            return true;
        }

        return hasChanged;
    }
}

interface InputStyleCommandAttributes extends CommandAttributes {
    value?: string | null;
}

export class InputStyleCommand extends Command
{
    public value: string;

    constructor(attributes: InputStyleCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.InputStyle.defaultValue;
    }

    get isValid(): boolean {
        return (Object.keys(CommandType.InputStyle.possibleValues).includes(this.value)) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (!this.isValid)
        {
            console.info('InputStyleCommand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = CommandType.InputStyle.defaultValue;
            return true;
        }
        return hasChanged;
    }
}

interface LearningRecordLanguageMap {
    "en-US"?: string;
    "en-GB"?: string;
    "de"?: string;
    "de-DE"?: string;
    "fr"?: string;
}

interface LearningRecordCommandStatementResultScore {
    min?: string;
    max?: string;
    raw?: string;
    scaled?: string;
}

interface LearningRecordCommandStatementResult {
    completion?: string;
    success?: string;
    response?: string;
    score?: LearningRecordCommandStatementResultScore;
}

interface LearningRecordCommandStatementVerb {
    id: string;
    display: LearningRecordLanguageMap;
}

interface LearningRecordCommandStatementObject {
    id: string;
    definition: LearningRecordCommandStatementObjectDefinition;
}

interface LearningRecordCommandStatementObjectDefinition {
    type?: string;
    name?: LearningRecordLanguageMap,
    description?: LearningRecordLanguageMap;
}

interface LearningRecordCommandStatement {
    verb: LearningRecordCommandStatementVerb;
    result?: LearningRecordCommandStatementResult;
    object: LearningRecordCommandStatementObject;
}

interface LearningRecordCommandAttributes extends CommandAttributes {
    statement?: LearningRecordCommandStatement | null;
    value?: string | null;
}

export class LearningRecordCommand extends Command
{
    public statement: LearningRecordCommandStatement;

    constructor(attributes: LearningRecordCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        // Convert old command into new format
        if (
            attributes.hasOwnProperty('value')
            && attributes.value === 'completed'
            && !attributes.hasOwnProperty('statement')
        ) {
            this.statement = {
                /**
                 * @type {LearningRecordCommandStatementVerb}
                 */
                'verb': {
                    'id': LearningRecordVerbs.Completed.id,
                    'display': LearningRecordVerbs.Completed.display
                },
                'object': {
                    'id': "urn:3spin-learning:unit:${unit.uid}",
                    'definition': {
                        'name': {
                            'en-US': '${unit.title}'
                        },
                    },
                },
            };

            return;
        }

        /**
         * @type {LearningRecordCommandStatement}
         */
        this.statement = attributes.statement || cloneDeep(CommandType.LearningRecord.defaultValue);

        if (!this.statement.hasOwnProperty('object')) {
            this.statement['object'] = cloneDeep(CommandType.LearningRecord.defaultValue.object);
        }

        if (!this.statement?.object?.definition?.name?.hasOwnProperty('en-US')) {
            if (this.statement['object']['id'] === CommandType.LearningRecord.defaultValue.object.id) {
                this.statement['object']['definition'] = cloneDeep(CommandType.LearningRecord.defaultValue.object.definition);
            } else {
                this.statement['object']['definition'] = {
                    'name': {
                        'en-US': ''
                    }
                };
            }
        }
    }

    get isValid(): boolean {
        const isVerbValid = (
            this.statement.hasOwnProperty('verb')
            && this.statement.verb.hasOwnProperty('id')
            && typeof this.statement.verb.id === 'string'
            && this.statement.verb.id.length > 0
            && this.statement.verb.hasOwnProperty('display')
            && this.statement.verb.display.hasOwnProperty('en-US')
            && typeof this.statement.verb.display['en-US'] === 'string'
            && this.statement.verb.display['en-US'].length > 0
        );

        const isObjectValid = (
            this.statement.hasOwnProperty('object')
            && this.statement.object.hasOwnProperty('id')
            && typeof this.statement.object.id === 'string'
            && this.statement.object.id.length > 0
            && (
                !this.statement.object.hasOwnProperty('definition')
                || (
                    this.statement.object.definition?.name?.["en-US"] !== undefined
                    && this.statement.object.definition?.name?.["en-US"].length > 0
                )
            )
        );

        return (
            super.isValid
            && isVerbValid
            && isObjectValid
        );
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        const hasObjectProperty = this.statement.hasOwnProperty('object');
        const isObjectPropertyObject = (hasObjectProperty && typeof this.statement.object === 'object' && this.statement.object !== null);

        if (
            isObjectPropertyObject
            && this.statement.object.hasOwnProperty('id')
            && (
                typeof this.statement.object.id !== 'string'
                || this.statement.object.id.length === 0
            )
        ) {
            this.statement.object = cloneDeep(CommandType.LearningRecord.defaultValue.object);
            hasChanged = true;
        }

        const hasResultProperty = this.statement.hasOwnProperty('result');
        if (!hasResultProperty) {
            return hasChanged;
        }

        const isResultObject = (typeof this.statement.result === 'object' && this.statement.result !== null && this.statement.result !== undefined);
        const isScoreObject = (
            isResultObject
            && this.statement.result.hasOwnProperty('score')
            && typeof this.statement.result.score === 'object'
            && this.statement.result.score !== null
        );

        // Clean up score
        if (isScoreObject && Object.keys(this.statement.result.score).length > 0) {
            Object.keys(this.statement.result.score).forEach((value, index, array) => {
                if (
                    typeof this.statement.result.score[value] !== 'string'
                    || this.statement.result.score[value].length === 0
                ) {
                    delete this.statement.result.score[value];
                    hasChanged = true;
                }
            })
        }

        // Remove score object if it is empty
        if (isScoreObject && Object.keys(this.statement.result.score).length === 0) {
            delete this.statement.result.score;
            hasChanged = true;
        }

        // Clean up result
        if (isResultObject && Object.keys(this.statement.result).length > 0) {
            // Clean up result.response if it is null or empty
            if (
                this.statement.result.hasOwnProperty('response')
                && (
                    typeof this.statement.result.response !== 'string'
                    || this.statement.result.response.length === 0
                )
            ) {
                delete this.statement.result.response;
                hasChanged = true;
            }
        }

        // Remove result object if it is empty
        if (isResultObject && Object.keys(this.statement.result).length === 0) {
            delete this.statement.result;
            hasChanged = true;
        }

        return hasChanged;
    }
}

interface ScriptCommandAttributes extends CommandAttributes {
    code?: string | null;
}

export class ScriptCommand extends Command {

    public code: string | null;

    constructor(attributes: ScriptCommandAttributes = {}, parent = null) {
        super(attributes, parent);
        this.code = attributes.code || CommandType.Script.defaultValue;
    }

    get isValid(): boolean {
        // There will be code / syntax validation here due to performance reasons.
        return !!this.code && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        if (!this.code) {
            this.code = CommandType.Script.defaultValue;
            hasChanged = true;
        }

        return hasChanged;
    }
}

interface ShowCommandAttributes extends CommandAttributes {
    value?: string | null;
    target?: string | null;
    node_paths?: string[];
}

export class ShowCommand extends Command
{
    public value: string;
    public target: string | null;
    public node_paths: string[];

    constructor(attributes: ShowCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = CommandType.Show.defaultValue;
        this.target = attributes.hasOwnProperty('target') ? attributes.target : null;
        this.node_paths = attributes.node_paths || [];
    }

    get isValid(): boolean {
        return (this.value !== null && this.target !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): (BaseSceneObjectAsset | BaseSceneObjectHotspot | SceneObjectParticleEmitter)[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('ShowCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any object from the unit if the command's parent is a global object or any object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => ['asset', 'hotspot', 'particle_emitter'].includes(o.type));
    }

    get targetObject(): BaseSceneObjectAsset | BaseSceneObjectHotspot | SceneObjectParticleEmitter | null {
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.target === null) {return hasChanged;}

        // Handle target "self" (on scene objectives):
        if (this.target === Command.TargetSelf)
        {
            // Remove target "self" if not allowed:
            if (
                !CommandType.Show.allowTargetSelf
                || this.parentSceneObjectives !== null
                || this.targetObject === null
            )
            {
                console.info('ShowCommand->cleanUpData(): Removing forbidden target "self" from command.', this);
                this.target = null;
                return true;
            }

            // No further checks needed:
            if (this.parentSceneObjectives === null)
            {
                return hasChanged;
            }
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject !== null && parentSceneObject.uid === this.target && CommandType.Show.allowTargetSelf)
        {
            console.info('ShowCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            return true;
        }

        // Check if target object exists and is allowed:
        if (!this.isValid || !this.possibleTargets.some(o => o.uid === this.target))
        {
            console.info('ShowCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target, this);
            this.target = null;
            return true;
        }

        return hasChanged;
    }
}

interface ShowTextCommandAttributes extends CommandAttributes {
    value?: ShowTextCommandValueProperty | string | null;
    type?: string | null;
}

interface ShowTextCommandValueProperty {
    headline: string | null,
    text: string | null,
}

export class ShowTextCommand extends Command
{
    public value: ShowTextCommandValueProperty;

    constructor(attributes: ShowTextCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        // Convert "old" text values to new object format:
        // @TODO: Remove once we have dropped support for units < 0.33.0
        if (attributes.type === CommandType.TextShow.type && typeof attributes.value === 'string')
        {
            const textValue = CommandType.TextShow.defaultValue;
            textValue.text = attributes.value;
            attributes.value = textValue;
        }

        // We need to clone the value or all instances will point to the same object
        this.value = attributes.value || cloneDeep(CommandType.TextShow.defaultValue);
    }

    get isValid(): boolean {
        return (this.value instanceof Object && ((typeof this.value.headline === 'string' && this.value.headline.trim().length > 0) || (typeof this.value.text === 'string' && this.value.text.trim().length > 0))) && super.isValid;
    }
}

interface SoundPlayCommandAttributes extends CommandAttributes {
    value?: string | null;
}

export class SoundPlayCommand extends Command
{
    public value: string | null;

    constructor(attributes: SoundPlayCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.SoundPlay.defaultValue;
    }

    get isValid(): boolean {
        return (this.value !== null) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value === null) {return hasChanged;}

        // Check if parent is set:
        const parentUnitRevision = this.getParent(UnitRevision);
        if (parentUnitRevision === null) {throw new Error('SoundPlayCommand->cleanUpData(): Unable to clean up data because parent UnitRevision is not set.');}

        // Remove invalid asset reference:
        if (!parentUnitRevision.hasAssetWithUid(this.value))
        {
            console.info('SoundPlayCommand->cleanUpData(): Removing reference to unknown or inaccessible asset.', this.value, this);
            this.value = null;
            return true;
        }

        return hasChanged;
    }
}

interface TriggerCancelCommandAttributes extends CommandAttributes {
    trigger_to_cancel?: string | null;
    target_object?: string | null;
}

export class TriggerCancelCommand extends Command
{
    public target_object: string | null;
    public trigger_to_cancel: string | null;

    constructor(attributes: TriggerCancelCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.trigger_to_cancel = attributes.trigger_to_cancel || CommandType.TriggerCancel.defaultValue;
        this.target_object = attributes.target_object || null;
    }

    get isValid(): boolean {
        return (this.trigger_to_cancel !== null && this.target_object !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target_object === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target_object;
    }

    get possibleTargets(): SceneObject[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('TriggerCancelCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any object from the unit with at least one trigger if the command's parent is a global object or any object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => o.hasTriggers);
    }

    get targetObject(): SceneObject | null {
        if ((this.target_object === null || (this.target_object === Command.TargetSelf && this.isGlobal)) && this.trigger_to_cancel !== null)
        {
            return this.possibleTargets.find(o => o.triggers.some(t => t.uid === this.trigger_to_cancel)) || null;
        }
        if (this.target_object === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target_object !== null) ? this.possibleTargets.find(o => o.uid === this.target_object) || null : null;
    }

    get targetTrigger(): Trigger | null {
        const targetObject = (this.trigger_to_cancel !== null) ? this.targetObject : null;
        return (targetObject !== null) ? targetObject.triggers.find(t => t.uid === this.trigger_to_cancel) || null : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset to empty values if no target object is set:
        if (this.target_object === null)
        {
            if (this.trigger_to_cancel !== null) {this.trigger_to_cancel = null; hasChanged = true;}
            return hasChanged;
        }

        // Handle target "self" on scene objectives:
        if (this.target_object === Command.TargetSelf && this.parentSceneObjectives !== null)
        {
            // Reset target if no trigger is set since there is no way to find out which object the command was assigned to:
            if (this.trigger_to_cancel === null)
            {
                console.info('TriggerCancelCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
                this.target_object = null;
                return true;
            }

            // Check if parent scene is set:
            const parentTrainingScene = this.getParent(TrainingScene);
            if (parentTrainingScene === null) {throw new Error('TriggerCancelCommand->cleanUpData(): Unable to clean up data because parent scene is not set.');}

            // Reassign target "self" to original scene object when command is inside objectives:
            const originalTargetObject = this.getParent(UnitData)?.allGlobalObjects.concat(parentTrainingScene.allSceneObjects).find(o => o.hasTriggers && o.triggers.map(t => t.uid).includes(this.trigger_to_cancel)) || null;
            if (originalTargetObject !== null)
            {
                console.info('TriggerCancelCommand->cleanUpData(): Reassigning target "self" on command.', this);
                this.target_object = originalTargetObject.uid;
                return true;
            }
            console.info('TriggerCancelCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
            this.target_object = null;
            this.trigger_to_cancel = null;
            return true;
        }

        // Check if target object exists and is allowed:
        const targetObj = this.targetObject;
        if (targetObj === null)
        {
            console.info('TriggerCancelCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target_object, this);
            this.target_object = null;
            this.trigger_to_cancel = null;
            return true;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject && this.target_object === parentSceneObject.uid && CommandType.TriggerCancel.allowTargetSelf)
        {
            console.info('TriggerCancelCommand->cleanUpData(): Changing target to "self" on command.', this.target_object, this);
            this.target_object = Command.TargetSelf;
            hasChanged = true;
        }

        // Check if target trigger exists and is allowed:
        if (this.trigger_to_cancel !== null && !targetObj.triggers.some(t => t.uid === this.trigger_to_cancel))
        {
            // Reassign original target UID for triggers that were duplicated into other scene objects:
            if (this.target_object === Command.TargetSelf)
            {
                const originalTargetObj = this.possibleTargets.find(o => o.hasTriggers && o.triggers.map(t => t.uid).includes(this.trigger_to_cancel)) || null;
                if (originalTargetObj !== null)
                {
                    console.info('TriggerCancelCommand->cleanUpData(): Reassigning target to original scene object on command.', this.target_object, this);
                    this.target_object = originalTargetObj.uid;
                    return true;
                }
            }

            // Remove unknown or invalid trigger reference:
            console.info('TriggerCancelCommand->cleanUpData(): Removing unknown or invalid trigger target from command.', this.trigger_to_cancel, this);
            this.trigger_to_cancel = null;
            return true;
        }

        return hasChanged;
    }
}

interface TriggerInvokeCommandAttributes extends CommandAttributes {
    value?: string | null;
    target?: string | null;
    await_completion?: boolean;
}

export class TriggerInvokeCommand extends Command
{
    public value: string | null;
    public target: string | null;
    public await_completion: boolean;

    constructor(attributes: TriggerInvokeCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.TriggerInvoke.defaultValue;
        this.target = attributes.target || null;
        this.await_completion = (typeof attributes.await_completion === 'boolean') ? attributes.await_completion : false;
    }

    get isValid(): boolean {
        return (this.value !== null && this.target !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.target === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.target;
    }

    get possibleTargets(): SceneObject[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('TriggerInvokeCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any object from the unit with at least one trigger if the command's parent is a global object or any object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => o.hasTriggers);
    }

    get targetObject(): SceneObject | null {
        if ((this.target === null || (this.target === Command.TargetSelf && this.isGlobal)) && this.value !== null)
        {
            return this.possibleTargets.find(o => o.triggers.some(t => t.uid === this.value)) || null;
        }
        if (this.target === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.target !== null) ? this.possibleTargets.find(o => o.uid === this.target) || null : null;
    }

    get targetTrigger(): Trigger | null  {
        const targetObject = (this.value !== null) ? this.targetObject : null;
        return (targetObject !== null) ? targetObject.triggers.find(t => t.uid === this.value) || null : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset to empty values if no target object is set:
        if (this.target === null)
        {
            if (this.value !== null) {this.value = null; hasChanged = true;}
            return hasChanged;
        }

        // Handle target "self" on scene objectives:
        if (this.target === Command.TargetSelf && this.parentSceneObjectives !== null)
        {
            // Reset target if no trigger is set since there is no way to find out which object the command was assigned to:
            if (this.value === null)
            {
                console.info('TriggerInvokeCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
                this.target = null;
                this.value = null;
                return true;
            }

            // Check if parent scene is set:
            const parentTrainingScene = this.getParent(TrainingScene);
            if (parentTrainingScene === null) {throw new Error('TriggerInvokeCommand->cleanUpData(): Unable to clean up data because parent scene is not set.');}

            // Reassign target "self" to original scene object when command is inside objectives:
            const originalTargetObject = this.getParent(UnitData)?.allGlobalObjects.concat(parentTrainingScene.allSceneObjects).find(o => o.hasTriggers && o.triggers.map(t => t.uid).includes(this.value)) || null;
            if (originalTargetObject !== null)
            {
                console.info('TriggerInvokeCommand->cleanUpData(): Reassigning target "self" on command.', this);
                this.target = originalTargetObject.uid;
                return true;
            }
            console.info('TriggerInvokeCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
            this.target = null;
            this.value = null;
            return true;
        }

        // Check if target object exists and is allowed:
        const targetObj = (this.target === Command.TargetSelf) ? this.getParent(SceneObject) : (this.possibleTargets.find(o => o.uid === this.target) || null);
        if (targetObj === null)
        {
            console.info('TriggerInvokeCommand->cleanUpData(): Removing unknown or invalid target from command.', this.target, this);
            this.target = null;
            this.value = null;
            return true;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject && this.target === parentSceneObject.uid && CommandType.TriggerInvoke.allowTargetSelf)
        {
            console.info('TriggerInvokeCommand->cleanUpData(): Changing target to "self" on command.', this.target, this);
            this.target = Command.TargetSelf;
            hasChanged = true;
        }

        // Check if target trigger exists and is allowed:
        if (this.value !== null && !targetObj.triggers.some(t => t.uid === this.value))
        {
            // Reassign original target UID for triggers that were duplicated into other scene objects:
            if (this.target === Command.TargetSelf)
            {
                const originalTargetObj = this.possibleTargets.find(o => o.hasTriggers && o.triggers.map(t => t.uid).includes(this.value)) || null;
                if (originalTargetObj !== null)
                {
                    console.info('TriggerInvokeCommand->cleanUpData(): Reassigning target to original scene object on command.', this.target, this);
                    this.target = originalTargetObj.uid;
                    return true;
                }
            }

            // Remove unknown or invalid trigger reference:
            console.info('TriggerInvokeCommand->cleanUpData(): Removing unknown or invalid trigger target from command.', this.value, this);
            this.value = null;
            return true;
        }

        return hasChanged;
    }
}

export class UnitExitCommand extends Command
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);
    }

    get isValid(): boolean {
        return super.isValid;
    }
}

export class UnitResetCommand extends Command
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);
    }

    get isValid(): boolean {
        return super.isValid;
    }
}

interface VariableOperationCommandAttributes extends CommandAttributes {
    object?: string | null;
    variable?: string | null;
    operation?: Object | null;
}

export class VariableOperationCommand extends Command
{
    public object: string | null;
    public variable: string | null;
    public operation: VariableOperation | null;

    constructor(attributes: VariableOperationCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.object = attributes.object || null;
        this.variable = attributes.variable || null;
        this.operation = (attributes.operation instanceof Object) ? VariableOperation.createFromAttributes(attributes.operation, this) : null;
    }

    get isValid() {
        return this.object !== null
            && this.variable !== null
            && this.operation !== null
            && this.operation.isValid
            && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.object === Command.TargetSelf) ? this.getParent(SceneObject)?.uid || null : this.object;
    }

    get possibleTargets(): SceneObjectModuleVariable[] {
        const parentUnitData = this.getParent(UnitData);
        if (parentUnitData === null) {throw new Error('VariableOperationCommand->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any variable module from the unit if the command's parent is a global object or any variable module from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.getParent(TrainingScene)?.allSceneObjects || [])).filter(o => o.hasVariables);
    }

    get targetObject(): SceneObjectModuleVariable | null {
        if ((this.object === null || (this.object === Command.TargetSelf && this.isGlobal)) && this.variable !== null)
        {
            return this.possibleTargets.find(o => this.variable !== null && o.hasVariable(this.variable)) || null;
        }
        if (this.object === Command.TargetSelf)
        {
            const parentSceneObject = this.getParent(SceneObject);
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.object !== null) ? this.possibleTargets.find(o => o.uid === this.object) || null : null;
    }

    get targetVariable(): Variable | null {
        const targetObject = (this.variable !== null) ? this.targetObject : null;
        return (targetObject !== null && this.variable !== null) ? targetObject.getVariable(this.variable) : null;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset to empty values if no target object is set:
        if (this.object === null)
        {
            if (this.variable !== null) {this.variable = null; hasChanged = true;}
            if (this.operation !== null) {this.operation = null; hasChanged = true;}
            return hasChanged;
        }

        // Handle target "self" on scene objectives:
        if (this.object === Command.TargetSelf && this.parentSceneObjectives !== null)
        {
            // Reset target if no variable is set since there is no way to find out which object the command was assigned to:
            if (this.variable === null)
            {
                console.info('VariableOperationCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
                this.object = null;
                this.variable = null;
                this.operation = null;
                return true;
            }

            // Check if parent scene is set:
            const parentTrainingScene = this.getParent(TrainingScene);
            if (parentTrainingScene === null) {throw new Error('VariableOperationCommand->cleanUpData(): Unable to clean up data because parent scene is not set.');}

            // Reassign target "self" to original scene object when command is inside objectives:
            const originalTargetObject = this.getParent(UnitData)?.allGlobalObjects.concat(parentTrainingScene.allSceneObjects).find(o => o.hasVariables && o.variables.map(t => t.uid).includes(this.variable)) || null;
            if (originalTargetObject !== null)
            {
                console.info('VariableOperationCommand->cleanUpData(): Reassigning target "self" on command.', this);
                this.object = originalTargetObject.uid;
                return true;
            }
            console.info('VariableOperationCommand->cleanUpData(): Removing forbidden target "self" from command on objectives.', this);
            this.object = null;
            this.variable = null;
            this.operation = null;
            return true;
        }

        // Check if target object exists and is allowed:
        const targetObj = this.targetObject;
        if (targetObj === null)
        {
            console.info('VariableOperationCommand->cleanUpData(): Removing unknown or invalid target from command.', this.object, this);
            this.object = null;
            this.variable = null;
            this.operation = null;
            return true;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject && this.object === parentSceneObject.uid && CommandType.VariableOperation.allowTargetSelf)
        {
            console.info('VariableOperationCommand->cleanUpData(): Changing target to "self" on command.', this.object, this);
            this.object = Command.TargetSelf;
            hasChanged = true;
        }
        else if (parentSceneObject && this.object === Command.TargetSelf && !CommandType.VariableOperation.allowTargetSelf)
        {
            console.info('VariableOperationCommand->cleanUpData(): Changing target "self" to UID on command.', this.object, this);
            this.object = targetObj.uid;
            hasChanged = true;
        }

        // Check if target variable exists and is allowed:
        const targetVariable = this.targetVariable;
        if (this.variable !== null && targetVariable === null)
        {
            // Reassign original target UID for variables that were duplicated into other scene objects:
            if (this.object === Command.TargetSelf)
            {
                const originalTargetObj = this.possibleTargets.find(o => this.variable !== null && o.hasVariables && o.hasVariable(this.variable)) || null;
                if (originalTargetObj !== null)
                {
                    console.info('VariableOperationCommand->cleanUpData(): Reassigning target to original scene object on command.', this.object, this);
                    this.object = originalTargetObj.uid;
                    return true;
                }
            }

            // Remove unknown or invalid variable reference:
            console.info('VariableOperationCommand->cleanUpData(): Removing unknown or invalid variable target from command.', this.variable, this);
            this.variable = null;
            this.operation = null;
            return true;
        } else if (this.variable === null) {
            console.info('VariableOperationCommand->cleanUpData(): Removing object reference from command because no variable is set.', this);
            this.object = null;
            this.operation = null;
            return true;
        }

        // Check if operation type is allowed for the given variable:
        if (targetVariable !== null && this.operation !== null)
        {
            // @TODO: Remove dependencies from VariableHelpers.js, available types and defaults should be on the variable class
            if (!getAvailableOperationClassesForVariableOfType(targetVariable.type).map(o => o.Type).includes(this.operation.type))
            {
                console.info('VariableOperationCommand->cleanUpData(): Resetting invalid operation to default.', this.operation, this);
                this.operation = defaultOperationForVariableType(targetVariable.type);
                return true;
            }
            else if (this.operation.cleanUpData())
            {
                return true;
            }
        }

        return hasChanged;
    }
}

interface VideoPlayCommandAttributes extends CommandAttributes {
    value?: string | null;
    video?: VideoPlayCommandVideoProperty;
}

interface VideoPlayCommandVideoProperty {
    autoplay?: boolean;
}

export class VideoPlayCommand extends Command
{
    public value: string | null;
    public video: VideoPlayCommandVideoProperty;

    constructor(attributes: VideoPlayCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = attributes.value || CommandType.VideoPlay.defaultValue;
        attributes.video = attributes.video || {};
        this.video = Object.assign({}, attributes.video, {
            autoplay: (typeof attributes.video.autoplay === 'boolean') ? attributes.video.autoplay : true
        });
    }

    get isValid(): boolean {
        return (this.value !== null && this.video instanceof Object) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.value === null) {return hasChanged;}

        // Check if parent is set:
        const parentUnitRevision = this.getParent(UnitRevision);
        if (parentUnitRevision === null) {throw new Error('VideoPlayCommand->cleanUpData(): Unable to clean up data because parent UnitRevision is not set.');}

        // Remove invalid asset reference:
        if (!parentUnitRevision.hasAssetWithUid(this.value))
        {
            console.info('VideoPlayCommand->cleanUpData(): Removing reference to unknown or inaccessible asset.', this.value, this);
            this.value = null;
            return true;
        }

        return hasChanged;
    }
}

interface WaitCommandAttributes extends CommandAttributes {
    value?: number | null;
}

export class WaitCommand extends Command
{
    public value: number;

    constructor(attributes: WaitCommandAttributes = {}, parent = null)
    {
        super(attributes, parent);

        this.value = (typeof attributes.value === 'number') ? attributes.value : CommandType.Wait.defaultValue;
    }

    get isValid(): boolean {
        return (typeof this.value === 'number' && this.value > 0 && this.value <= 86400) && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (!this.isValid)
        {
            console.info('WaitCommand->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = CommandType.Wait.defaultValue;
            return true;
        }
        return hasChanged;
    }
}

export class WorldAnchorResetCommand extends Command
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);
    }

    get isValid(): boolean {
        return super.isValid;
    }
}
