<template>
    <tr>
        <td class="column-name">
            {{ feature.name }}
        </td>
        <td class="column-created-at">
            {{ moment(feature.created_at).format('DD MMM YYYY, HH:mm') }}
        </td>
        <td class="column-remove">
            <ButtonCircular
                v-tooltip="'labels.remove'"
                class="small"
                icon="icon_delete"
                @trigger="onRemove"
                :disabled="!canDelete"
            />
        </td>
    </tr>
</template>

<script>
import LocalFeature from "@/Models/Features/Local/LocalFeature";

export default {
    name: "LocalFeatureListItem",

    emits: [
        'click-remove',
    ],

    props: {
        feature: {
            type: LocalFeature
        },
        canDelete: {
            type: Boolean
        }
    },

    methods: {

        onRemove() {
            this.$emit('click-remove', this.feature);
        }

    },
}
</script>

<style lang="scss" scoped>

.column-name {
    width: 100%;
    padding: 8px 0;
}

.column-created-at {
    white-space: nowrap;
    padding-right: 32px;
}

</style>
