/**
 * Note: if you add licenses here, please make sure to add them
 * to the assets.php translation file too.
 */
export enum LicenseId {
    CC_BY = 'cc_by',
}

export interface CreativeCommonsLicense {
    id: LicenseId,
    nameTransKey: string,
    shortNameTransKey: string,
    urlTransKey: string,
    usageTransKey: string,
}

function createLicense(id: LicenseId): CreativeCommonsLicense {
    return {
        id: id,
        nameTransKey: `assets.cc_licenses.${id}.name`,
        shortNameTransKey: `assets.cc_licenses.${id}.short_name`,
        urlTransKey: `assets.cc_licenses.${id}.url`,
        usageTransKey: `assets.cc_licenses.${id}.usage`,
    }
}

export const CreativeCommonsLicenses: { [key in LicenseId]: CreativeCommonsLicense } = {
    cc_by: createLicense(LicenseId.CC_BY),
};
