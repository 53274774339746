/**
 * Check whether the given DOM image has valid dimensions.
 *
 * @async
 * @param imageSrc      url or image data to validate
 * @param minWidth      minimum required width of the image in pixels
 * @param minHeight     minimum required height of the image in pixels
 * @param aspectRatio   expected aspect ratio of the image; e.g. "16/9"
 * @returns Resolves with the image object when validation was successful
 */
export async function validateImageDimensions(
    imageSrc: string,
    minWidth: number = 1280,
    minHeight: number = 720,
    aspectRatio: number = 16 / 9
): Promise<HTMLImageElement> {

    return new Promise((resolve, reject) => {

        const image: HTMLImageElement = new Image();

        image.addEventListener('error', error => {
            reject(error);
        });

        image.addEventListener('load', () => {
            // Check for valid image dimensions:
            if (
                image.naturalWidth >= minWidth &&
                image.naturalHeight >= minHeight &&
                Math.round(image.naturalHeight * aspectRatio) === image.naturalWidth
            ) {
                resolve(image);
            } else {
                reject();
            }
        });

        image.src = imageSrc;
    });

}
