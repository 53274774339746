<template>

    <aside class="controls">
        <h3>{{ trans('units.launch.controls.headline_view') }}</h3>

        <section>
            <img
                :alt="trans('units.launch.controls.instruction_lookaround_alt')"
                src="/svg/controls_click-drag.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_lookaround')" />
        </section>
        <section>
            <img
                :alt="trans('units.launch.controls.instruction_teleport_alt')"
                src="/svg/controls_double-click.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_teleport')" />
        </section>

        <section>
            <img
                :alt="trans('units.launch.controls.instruction_interact_alt')"
                src="/svg/controls_click-interact.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_interact')" />
        </section>
        <section>
            <img
                :alt="trans('units.launch.controls.instruction_pickup_alt')"
                src="/svg/controls_right-click.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_pickup')" />
        </section>

        <section>
            <img
                :alt="trans('units.launch.controls.instruction_move_alt')"
                src="/svg/controls_move-arrow-keys.svg"
            >
        </section>
        <section>
            <img
                :alt="trans('units.launch.controls.instruction_move_alt')"
                src="/svg/controls_move-wasd-keys.svg"
            >
        </section>
        <section class="move-text">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_move_view')" />
        </section>

        <section>
            <img
                :alt="trans('units.launch.controls.instruction_height_alt')"
                src="/svg/controls_key-c.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_height')" />
        </section>
        <section>
            <img
                :alt="trans('units.launch.controls.instruction_mode_alt')"
                src="/svg/controls_space.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_mode')" />
        </section>

        <section>
            <img
                :alt="trans('units.launch.controls.instruction_menu_alt')"
                src="/svg/controls_key-ctrl.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_menu')" />
        </section>
        <section>
            <img
                :alt="trans('units.launch.controls.instruction_exit_fullscreen_alt')"
                src="/svg/controls_key-esc.svg"
            >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="instruction" v-html="trans('units.launch.controls.instruction_exit_fullscreen')" />
        </section>
    </aside>

</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';

export default defineComponent({
    methods: {
        trans,
    },
});

</script>

<style lang="scss" scoped>

.controls {
    max-width: 324px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    padding: 16px 24px;
    background-color: white;
    border-radius: var(--card-border-radius-small);
    align-self: start;
    max-height: 100%;
    overflow-y: auto;

    h3 {
        font-size: var(--font-size-default);
        grid-column: 1 / span 2;
        margin-bottom: 0;
    }

    section {
        margin-top: 32px;

        img {
            margin-bottom: 8px;
        }

        .instruction {
            font-size: var(--font-size-small);
            font-family: var(--font-family-condensed);
            margin: 0;

            ::v-deep(em) {
                font-family: var(--font-family-condensed-demibold);
                font-style: normal;
                font-weight: normal;
            }

            .icon {
                width: 18px;
                height: 18px;
                margin: 0 4px;
            }
        }

        &.move-text {
            grid-column: 1 / span 2;
            margin-top: 0;
        }
    }
}

</style>
