/**
 * Wrapper for files in drag & drop and file input scenarios,
 * because different browsers handle paths a little bit different.
 */
export default class UserFile {

    /**
     * @param file underlying file this class is wrapping
     * @param relativePath path relative to the root dropped directory without trailing slash
     */
    constructor(readonly file: File, private relativePath: string) {

    }

    /**
     * @return true if this file is part of the root level directory; false if nested or if it *is* the root file
     */
    get isFileInRootLevelDirectory(): Boolean {
        return !this.isDroppedFile && this.relativePath.indexOf('/') === -1;
    }

    /**
     * @return true if this file is the only dropped file
     */
    get isDroppedFile(): Boolean {
        return this.relativePath === '';
    }

    get isGlbFile(): Boolean {
        return this.file.name.endsWith('glb');
    }

    get isGltfFile(): Boolean {
        return this.file.name.endsWith('gltf');
    }

    get isFbxFile(): Boolean {
        return this.file.name.endsWith('fbx');
    }

    get isObjFile(): Boolean {
        return this.file.name.endsWith('obj');
    }

    get isMtlFile(): Boolean {
        return this.file.name.endsWith('mtl');
    }

    public hasRelativePath(relativePath: string): Boolean {
        return this.relativePath === relativePath;
    }

    public async arrayBuffer(): Promise<ArrayBuffer> {
        return this.file.arrayBuffer();
    }

    public createObjectURL(): string {
        return URL.createObjectURL(this.file);
    }
}
