/**
 * List of physics material characteristics and their default properties
 *
 * @returns {Object}
 */
export default {

    rock: {
        drag: 0.0,
        friction: 2.0,
        bounciness: 0.1,
        angular_drag: 2.0,
        static_friction: 3.0,
    },

    metal: {
        drag: 0.0,
        friction: 1.5,
        bounciness: 0.0,
        angular_drag: 1.5,
        static_friction: 2.5,
    },

    wood: {
        drag: 0.0,
        friction: 2,
        bounciness: 0.5,
        angular_drag: 2,
        static_friction: 3,
    },

    plastic: {
        drag: 0.0,
        friction: 0.2,
        bounciness: 0.5,
        angular_drag: 1,
        static_friction: 0.2,
    },

    cardboard: {
        drag: 1.0,
        friction: 0.25,
        bounciness: 0.05,
        angular_drag: 0.8,
        static_friction: 0.25,
    },

    paper: {
        drag: 3.5,
        friction: 0.15,
        bounciness: 0.0,
        angular_drag: 1,
        static_friction: 0.15,
    },

    feather: {
        drag: 5.0,
        friction: 0.2,
        bounciness: 0.0,
        angular_drag: 1,
        static_friction: 1,
    },

    rubber: {
        drag: 0.0,
        friction: 2.0,
        bounciness: 1.0,
        angular_drag: 3.0,
        static_friction: 3.0,
    },

    foam_rubber: {
        drag: 0.5,
        friction: 2.0,
        bounciness: 0.8,
        angular_drag: 2,
        static_friction: 3,
    },

    textile: {
        drag: 1.0,
        friction: 0.8,
        bounciness: 0.2,
        angular_drag: 1,
        static_friction: 1,
    },

    glass: {
        drag: 0.0,
        friction: 0.1,
        bounciness: 0.3,
        angular_drag: 2,
        static_friction: 0.1,
    },

    ice: {
        drag: 0.0,
        friction: 0.0,
        bounciness: 0.15,
        angular_drag: 0.001,
        static_friction: 0.0,
    },

    straw: {
        drag: 3.5,
        friction: 0.3,
        bounciness: 0.15,
        angular_drag: 1,
        static_friction: 1,
    },
};