import {LoadingManager} from "three";
import {trans} from "@/Utility/Helpers/trans";
import UserFile from "@/Utility/Helpers/UserFile";

/**
 * Three.js LoadingManager that converts relative file system urls
 * into blob url based on the given File objects. This allows for
 * loading local files - including textures and other linked files -
 * if initiated by the user (e.g. via Drag & Drop of file input field).
 * Urls that are already blob url will not be transformed.
 */
export default class BlobUrlLoadingManager extends LoadingManager {

    private generatedBlobUrls: string[] = [];

    /**
     * @param files all files that might be relevant for loading
     * (like the original model, texture files, etc.)
     */
    constructor(private files: UserFile[]) {
        super();

        (this as LoadingManager).setURLModifier(url => this.convertUrl(url));
    }

    /**
     * Frees references to the blob urls generated during loading.
     * Call this when loading is complete.
     */
    public revokeBlobUrls() {
        this.generatedBlobUrls.forEach(URL.revokeObjectURL);
    }

    private convertUrl(url: string): string {

        // The loader produces invalid URLs in some cases, just ignore them:
        if (url === 'undefined') {
            return url;
        }

        url = decodeURI(url);

        if (url.startsWith('http:') || url.startsWith('https:')) {
            // These already are urls
            return url;
        }

        if (url.startsWith('blob:') || url.startsWith('data:')) {
            // For glb or embedded files the urls are just fine
            return url;
        }

        // remove dot, indicating an absolute path
        if (url.startsWith('.')) {
            url = url.slice(1);
        }

        const file = this.files.find(file => file.hasRelativePath(url));

        if (file === undefined) {
            const message = trans('errors.asset.converter_3d.error_blob_file_missing', {file:url}) ;
            console.error(message);
            throw new Error(message);
        }

        // Convert relative file system url in something we can load in a browser context
        const blobUrl = file.createObjectURL();
        this.generatedBlobUrls.push(blobUrl);

        return blobUrl;
    }
}
