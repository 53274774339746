<template>
    <main id="layout-main">
        <PageHeader
            :page-title="trans('statistics.index.headline')"
        />

        <div id="layout-content">
            <div id="content">

                <div class="tab-wrapper">
                    <Tabs :tabs="tabs" />
                </div>

                <StatisticsCards class="tab-component" v-if="tabs.statistics.active"/>

                <LearningRecordsStatisticsCards class="tab-component" v-if="tabs.learningRecordsStatistics.active"/>

                <LearningRecordsTable class="tab-component" v-if="tabs.learningRecords.active" />
            </div>

            <ModalNotification/>
        </div>
    </main>
</template>

<script lang="ts">

import PageHeader from "@/Vue/Common/PageHeader.vue";
import {trans} from "@/Utility/Helpers";
import Tabs from "@/Vue/Common/Tabs.vue";
import {defineComponent} from "vue";
import DefaultTab from "@/Utility/Tabs/DefaultTab";
import StatisticsCards from "@/Vue/Statistics/General/StatisticsCards.vue";
import ModalNotification from "@/Vue/Modals/ModalNotification.vue";
import LearningRecordsTable from "@/Vue/Statistics/LearningRecordsTable/LearningRecordsTable.vue";
import LearningRecordsStatisticsCards from "@/Vue/Statistics/LearningRecordsDashboards/LearningRecordsStatisticsCards.vue";

export default defineComponent({
    name: "PageStatistics",

    components: {
        LearningRecordsStatisticsCards,
        LearningRecordsTable,
        ModalNotification,
        StatisticsCards,
        Tabs,
        PageHeader
    },

    props: {},

    data() {
        return {
            tabs: {
                statistics: new DefaultTab(trans('statistics.index.tab_general'), true),
                learningRecordsStatistics: new DefaultTab(trans('statistics.index.tab_learning_records_dashboard')),
                learningRecords: new DefaultTab(trans('statistics.index.tab_learning_records_table_view')),
            }
        }
    },

    computed: {},

    methods: {
        trans,
    },
});
</script>

<style lang="scss" scoped>

#content {
    padding: 0;
}

.tab-wrapper {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 50px 15px 50px;
    background-color: var(--background-color-light);
    z-index: 1;

    .tabs {
        max-width: 640px;
    }
}

.tab-component {
    padding: 20px 50px 35px 50px;
}

</style>
