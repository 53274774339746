<template>


    <div class="form-group row">
        <label for="name" class="col-md-4 col-form-label text-md-right">
            {{ trans('labels.name') }}
        </label>

        <div class="col-md-6">
            <TextInput
                id="name"
                name="name"
                :model="tenantToEdit"
                property="name"
                :maxlength="255"
                required
                autofocus
            />

            <span
                v-if="validationError('name')"
                class="error-msg"
                role="alert"
            >
                {{ validationError('name') }}
            </span>
        </div>
    </div>

    <div v-if="canChangeHubspotId" class="form-group row">
        <label for="hubspot_company_id" class="col-md-4 col-form-label text-md-right">
            {{ trans('labels.hubspot_company_id') }}
        </label>

        <div class="col-md-6">
            <TextInput
                id="hubspot_company_id"
                name="hubspot_company_id"
                :model="tenantToEdit"
                property="hubspot_company_id"
                :maxlength="255"
            />

            <span
                v-if="validationError('hubspot_company_id')"
                class="error-msg"
                role="alert"
            >
                {{ validationError('hubspot_company_id') }}
            </span>
        </div>
    </div>

    <div class="form-group row">
        <label for="logo" class="col-md-4 col-form-label text-md-right">
            Logo
        </label>

        <div class="col-md-8">
            <ImageInputField
                id="logo"
                field-name="logo"
                :initial-image-url="tenantToEdit.initialLogoUrl"
                :validation-rules="{
                    minWidth: 128,
                    minHeight: 128,
                    maxWidth: 4096,
                    maxHeight: 2048,
                }"
                @file-changed="onLogoChanged"
                @validation-error="onLogoValidationError"
            />

            <span
                v-if="validationError('logo')"
                class="error-msg"
                role="alert">
                {{ validationError('logo') }}
            </span>
        </div>
    </div>

</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {trans} from "@/Utility/Helpers/trans";
import TextInput from "@/Vue/Common/TextInput.vue";
import {Permission} from "@/Models/User/Permission";
import type {TenantForm} from "@/Services/TenantService";
import ImageInputField from "@/Vue/Common/ImageInputField.vue";
import Icon from "@/Vue/Common/Icon.vue";
import ImageValidation from "@/Models/Asset/ImageValidation";

export default defineComponent({
    name: "TenantFormFields",

    components: {
        Icon,
        ImageInputField,
        TextInput,
    },

    props: {
        tenantToEdit: {
            type: Object as PropType<TenantForm>,
            required: true,
        },
        apiValidationErrors: {
            type: null as PropType<Record<string, string[]>> | null,
            required: true,
        }
    },

    computed: {
        canChangeHubspotId() {
            return this.$gate.allows(Permission.TenantsConfigureHubspot());
        },
    },

    methods: {
        trans,

        validationError(fieldName: string): string | undefined {
            return this.apiValidationErrors?.[fieldName]?.[0] ||
                this.apiValidationErrors?.['tenant.' + fieldName]?.[0];
        },

        onLogoChanged(file: File | null, changedByInitialUrl: Boolean) {
            if (!changedByInitialUrl) {
                this.tenantToEdit.logo = file;
            }
        },

        onLogoValidationError(e: Error) {
            this.$root!.showErrorDialog(e.message);
        },
    }
});
</script>

<style scoped lang="scss">

.error-msg {
    display: block;
    color: var(--color-signal);
    font-size: var(--font-size-small);
}

</style>
