import AbstractDataObject from '@/Models/AbstractDataObject';
import {updateUidReferences, uuid4} from '@/Utility/Helpers';

export default abstract class Variable<T = any> extends AbstractDataObject {

    public uid: string;
    public value: any;
    public name: string | null;
    public type: string;

    /**
     * Original unique ID from which the object was duplicated (hidden)
     */
    public readonly originalUid: string;

    constructor(attributes: any = {}, parent: AbstractDataObject | null = null) {
        super(parent);

        if (new.target === Variable) {
            throw new TypeError('Cannot construct Variable instances directly');
        }

        if (new.target && new.target.Type !== attributes.type) {
            throw new TypeError(`"${new.target.name}" cannot be instantiated with type attribute "${attributes.type}". "${new.target.Type}" expected instead.`);
        }

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        Object.defineProperty(this, 'originalUid', { enumerable: false, writable: true });

        this.uid = attributes.uid || uuid4();
        this.originalUid = this.uid;
        this.value = (attributes.value !== undefined) ? attributes.value : this.defaultValue;
        this.name = attributes.name || '';
        this.type = attributes.type;
    }

    static get Type() {
        return 'undefined';
    }

    get Type(): string {
        return (<typeof Variable>this.constructor).Type;
    }

    get defaultValue(): T {

        throw new Error('child class must implement defaultValue getter');
    }

    get isValid() {
        return this.type === this.Type &&
            typeof this.name === 'string' &&
            this.name.trim() !== '';
    }

    cleanUpData(): boolean {
        // @NOTE: Override this method on subclasses to make sure a variable only uses valid data
        let hasChanged = false;

        if (this.type !== this.Type) {
            console.info('Variable->cleanUpData(): Changing incorrect type.', this.type, this);
            this.type = this.Type;
            hasChanged = true;
        }

        if (typeof this.name !== 'string') {
            console.info('Variable->cleanUpData(): Setting default name.', this.name, this);
            this.name = '';
            hasChanged = true;
        } else if (this.name !== this.name.trim()) {
            console.info('Variable->cleanUpData(): Removing whitespace from name.', this.name, this);
            this.name = this.name.trim();
            hasChanged = true;
        }

        return hasChanged;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param updateUidMapping  Whether to update all UID references for child elements
     */
    duplicate(updateUidMapping: boolean = true): this {
        const duplicated = new this.ctor(this, this.parent);

        duplicated.uid = uuid4();

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {
            updateUidReferences(duplicated);
        }

        return duplicated;
    }

    /**
     * Typed constructor, so we can use it to create new instances without referencing VariableFactory.
     */
    private get ctor(): new (attributes: any, parent: AbstractDataObject | null) => this {
        return this.constructor as (new (attributes: any, parent: AbstractDataObject | null) => this);
    }
}
