<template>
    <span class="search-has-no-results no-items-found">
        <Icon name="icon_error" />
        <span class="caption">{{ trans('search.no_items_found') }}</span>
        <span class="description">{{ trans('search.no_items_found_text') }}</span>
    </span>
</template>

<script>
    export default {
        name: 'NoItemsFound'
    }
</script>

<style lang="scss" scoped>

</style>
