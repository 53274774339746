<template>
    <div class="artisan-command-list-item">
        <div class="artisan-command-list-item-info">

            <h3 class="artisan-command-list-item-info-title">{{ command.name }}</h3>
            <p class="artisan-command-list-item-info-description">{{ command.description }}</p>

            <ButtonPrimary
                @trigger="onClickRunCommand"
                caption="artisan_commands.run"
            />

            <div
                v-if="logs"
                class="artisan-command-list-item-command-output"
                :class="{ error: hasErrors, success: !hasErrors }"
            >
                <label>{{ trans('artisan_commands.command_output') }}</label>

                <textarea
                    :value="logs.join('\n')"
                    class="form-control logs"
                    rows="5"
                ></textarea>
            </div>
        </div>
    </div>

</template>

<script>
import ButtonSecondary from "@/Vue/Common/ButtonSecondary";
import ButtonPrimary from "@/Vue/Common/ButtonPrimary";
import {inject} from "vue";
import {manageServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

export default {
    name: "ArtisanCommandListItem",
    components: {ButtonPrimary, ButtonSecondary},
    props: {
        command: {
            type: Object,
        }
    },
    data() {
        return {
            manageService: inject(manageServiceKey),
            logs: null,
            hasErrors: false,
        };
    },
    methods: {
        onClickRunCommand() {
            this.hasErrors = false;
            this.logs = null;

            this.manageService.runCommand(this.command.name)
                .then(logs => {
                    this.logs = logs;
                })
                .catch(error => {
                    this.hasErrors = true;

                    // Handle Validation errors
                    if (error.response && error.response.data) {
                        this.logs = error.response.data.toString().split('\n');
                    } else {
                        this.$root.showErrorDialog(error);
                    }
                });
        }
    }
}
</script>

<style lang="scss" scoped>

.artisan-command-list-item {
    background: var(--color-white);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    transition: box-shadow 0.15s ease-in-out;

    padding: 20px;
}

.artisan-command-list-item + .artisan-command-list-item {
    margin-top: 20px;
}

.artisan-command-list-item-command-output {
    margin-top: 20px;

    &.error {
        textarea {
            border-color: var(--color-signal);
        }
    }

    &.success {
        textarea {
            border-color: var(--color-item-green);
        }
    }
}

textarea {
    font-family: var(--font-family-mono-regular);
    font-size: var(--font-size-small);
    white-space: pre;
    padding: 8px;
}

</style>
