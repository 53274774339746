<template>
    <li :class="{ 'is-in-managed-mode': device.isInManagedMode }" class="device-list-item">
        <span>
            <ButtonCircular
                v-tooltip="'buttons.managed_sessions.remove_device'"
                :disabled="!canDeleteSession"
                class="small"
                icon="icon_delete"
                @trigger="onRemoveClicked"
            />
        </span>
        <span class="device-name">{{ device.name }}</span>
        <span class="device-model">
            <Icon :name="device.formFactorIcon" />
            <span>{{ device.model ?? trans('labels.unknown') }}</span>
        </span>
        <span>
            <Icon v-if="device.isInManagedMode" name="icon_check" />
            <Icon v-else name="icon_remove" />
        </span>
        <span>
            <Icon v-if="device.isUnitLoaded" name="icon_check" />
            <Icon v-else name="icon_remove" />
        </span>
        <span>
            <ButtonCircular
                v-tooltip="Instruction.getDescriptionForSingleDevice('unit_start')"
                :disabled="!canStartUnit"
                class="small"
                icon="icon_play"
                @trigger="onPlayClicked"
            />
        </span>
        <span>
            <ButtonCircular
                v-tooltip="Instruction.getDescriptionForSingleDevice('unit_stop')"
                :disabled="!canStopUnit"
                class="small"
                icon="icon_stop"
                @trigger="onStopClicked"
            />
        </span>
    </li>
</template>

<script lang="ts">
import {defineComponent, type PropType} from 'vue';
import {trans} from '@/Utility/Helpers';
import type Device from '@/Models/Devices/Device';
import Icon from '@/Vue/Common/Icon.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import Instruction from '@/Models/Sessions/Instruction';

export default defineComponent({
    components: {
        ButtonCircular,
        Icon
    },

    props: {
        device: {
            type: Object as PropType<Device>,
            required: true,
        },

        /**
         * If set to false, the play button will be disabled
         */
        canStartUnit: {
            type: Boolean,
            required: true,
        },

        /**
         * If set to false, the stop button will be disabled
         */
        canStopUnit: {
            type: Boolean,
            required: true,
        },

        /**
         * If set to false, the delete button will be disabled
         */
        canDeleteSession: {
            type: Boolean,
            required: true,
        },
    },

    emits: [
        'remove-click',
        'start-unit-click',
        'stop-unit-click',
    ],

    computed: {
        Instruction() {
            return Instruction;
        }
    },

    methods: {
        trans,

        onRemoveClicked() {
            this.$emit('remove-click', this.device);
        },

        onPlayClicked() {
            this.$emit('start-unit-click', this.device);
        },

        onStopClicked() {
            this.$emit('stop-unit-click', this.device);
        },
    },
});
</script>

<style lang="scss" scoped>

.device-list-item {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    align-items: center;
    padding: 6px 10px;
    border-radius: 8px;
    transition: color 0.1s, background-color 0.1s;

    &.is-in-managed-mode {
        background-color: white;
    }

    &:not(.is-in-managed-mode) {
        background-color: var(--color-anthracite10);
    }

    span.device-name {
        font-family: var(--font-family-mono-regular);
        word-wrap: anywhere;
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    > span:has(> .icon:only-child) {
        justify-self: center;
    }

    > span:has(> .icon:not(:only-child)) > .icon {
        margin-right: 10px;
    }

    &:hover {
        background-color: var(--color-anthracite20);
    }
}

</style>
