import { trans } from '@/Utility/Helpers';
import CommandTargetType from '@/Models/UnitData/Commands/CommandTargetType';
import HelperAnimationType from '@/Models/Unity/HelperAnimationType';
import ColorConfiguration from '@/Models/UnitData/Configuration/ColorConfiguration';
import {WaypointMode, WaypointModeValues} from '@/Models/Unity/WaypointMode';
import {LearningRecordVerbs} from "@/Models/UnitData/LearningRecords/LearningRecordVerb";

/**
 * @typedef {{
 * group: string,
 * type: string,
 * title: string,
 * titleGrouped: string | null,
 * targetType: string | null,
 * defaultValue: any,
 * possibleValues: any,
 * allowTargetSelf: boolean,
 * maxCountPerTrigger: number | null,
 * confirmDelete: boolean,
 * }} CommandTypeAttributes
 */

/**
 * CommandType "groups"
 *
 * @see commands.php translation
 *
 * @var {Object<String>}
 */
export const CommandTypeGroups = {
    AI:             'ai',
    Basic:          'basic',
    Character:      'character',
    Advanced:       'advanced',
    Unit :          'unit',
    Beta :          'beta',
};

export default class CommandType
{
    /**
     * @param {CommandTypeAttributes} attributes
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || !CommandType.isValidType(attributes.type))
        {
            console.warn('CommandType->constructor(): Invalid data.', attributes);
            throw new TypeError('CommandType->constructor(): Property "type" has to be set on CommandType. Must be a valid type from CommandType class.');
        }
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('CommandType->constructor(): Invalid data.', attributes);
            throw new TypeError('CommandType->constructor(): Property "title" has to be set on CommandType.');
        }
        if (attributes.targetType !== undefined && attributes.targetType !== null && (!(attributes.targetType instanceof CommandTargetType) || typeof attributes.targetType.type !== 'string' || !CommandTargetType.isValidType(attributes.targetType.type)))
        {
            console.warn('CommandType->constructor(): Invalid data.', attributes);
            throw new TypeError('CommandType->constructor(): Property "targetType" has to be set on CommandTargetType. Must be a valid type from CommandTargetType class.');
        }

        // Populate the model:
        /** @type string */
        this.type = attributes.type;                                        // Type identifier
        this.title = attributes.title;                                      // Translated title
        this.titleGrouped = attributes.titleGrouped || attributes.title;    // Translated title when used in a group (e.g. DropdownOptionGroup)
        this.group = attributes.group || CommandTypeGroups.Beta;            // Group identifier (see translation commands.php from commands.groups)
        this.enabled = (typeof attributes.enabled === 'boolean') ? attributes.enabled : true;   // Enabled state
        this.targetType = attributes.targetType || null;                    // CommandTargetType
        this.defaultValue = Object.prototype.hasOwnProperty.call(attributes, 'defaultValue') ? attributes.defaultValue : null; // Default value
        this.possibleValues = attributes.possibleValues || null;            // List or Dictionary of possible values
        this.allowTargetSelf = (typeof attributes.allowTargetSelf === 'boolean') ? attributes.allowTargetSelf : false;  // Allow "self" as a target value
        this.maxCountPerTrigger = (typeof attributes.maxCountPerTrigger === 'number') ? attributes.maxCountPerTrigger : null;  // How many of the same command can be added to a trigger?
        this.confirmDelete = !!attributes.confirmDelete;
    }

    /**
     * CommandType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {CommandType}
     */
    static get AIKnowledge()        { return StaticCommandTypes.AIKnowledge; }
    static get AIPrompt()           { return StaticCommandTypes.AIPrompt; }
    static get Speak()              { return StaticCommandTypes.Speak; }
    static get BehaviourChange()    { return StaticCommandTypes.BehaviourChange; }
    static get Condition()          { return StaticCommandTypes.Condition; }
    static get Control3dAnimation() { return StaticCommandTypes.Control3dAnimation; }
    static get ControlCharacterAnimation() { return StaticCommandTypes.ControlCharacterAnimation; }
    static get InputStyle()         { return StaticCommandTypes.InputStyle; }
    static get Hide()               { return StaticCommandTypes.Hide; }
    static get Show()               { return StaticCommandTypes.Show; }
    static get Fade()               { return StaticCommandTypes.Fade; }
    static get Feedback()           { return StaticCommandTypes.Feedback; }
    static get HelperAnimationPlay(){ return StaticCommandTypes.HelperAnimationPlay; }
    static get HelperGlowChange()   { return StaticCommandTypes.HelperGlowChange; }
    static get HelperKnowledge()    { return StaticCommandTypes.HelperKnowledge; }
    static get HelperPrompt()       { return StaticCommandTypes.HelperPrompt; }
    static get HelperSpeak()        { return StaticCommandTypes.HelperSpeak; }
    static get HelperWaypointGoTo() { return StaticCommandTypes.HelperWaypointGoTo; }
    static get HelperTriggerInvoke() { return StaticCommandTypes.HelperTriggerInvoke; }
    static get ImageShow()          { return StaticCommandTypes.ImageShow; }
    static get LearningRecord()     { return StaticCommandTypes.LearningRecord; }
    static get ModuleActivate()     { return StaticCommandTypes.ModuleActivate; }
    static get ModuleDeactivate()   { return StaticCommandTypes.ModuleDeactivate; }
    static get OverlayHide()        { return StaticCommandTypes.OverlayHide; }
    static get OverlayNext()        { return StaticCommandTypes.OverlayNext; }
    static get OverlayPrevious()    { return StaticCommandTypes.OverlayPrevious; }
    static get OverlayReset()       { return StaticCommandTypes.OverlayReset; }
    static get SceneChange()        { return StaticCommandTypes.SceneChange; }
    static get SceneReset()         { return StaticCommandTypes.SceneReset; }
    static get Script()             { return StaticCommandTypes.Script; }
    static get SoundPlay()          { return StaticCommandTypes.SoundPlay; }
    static get TextShow()           { return StaticCommandTypes.TextShow; }
    static get TriggerCancel()      { return StaticCommandTypes.TriggerCancel; }
    static get TriggerInvoke()      { return StaticCommandTypes.TriggerInvoke; }
    static get UnitExit()           { return StaticCommandTypes.UnitExit; }
    static get UnitReset()          { return StaticCommandTypes.UnitReset; }
    static get VariableOperation()  { return StaticCommandTypes.VariableOperation; }
    static get VideoPlay()          { return StaticCommandTypes.VideoPlay; }
    static get Wait()               { return StaticCommandTypes.Wait; }
    static get WorldAnchorReset()   { return StaticCommandTypes.WorldAnchorReset; }

    /**
     * CommandType "groups"
     *
     * @var {Object<String>|string[]}
     */
    static get Groups() { return CommandTypeGroups; }
    static get GroupsAsArray() { return Object.values(CommandTypeGroups); }

    /**
     * Get all command types as an array
     *
     * @returns {CommandType[]}
     */
    static get all()
    {
        return Object.values(StaticCommandTypes);
    }

    /**
     * Get all command types as an array excluding the given type or types
     *
     * @param {CommandType|CommandType[]} commandTypeOrTypes
     * @returns {CommandType[]}
     */
    static getAllExcept(commandTypeOrTypes)
    {
        let excludeTypes = (commandTypeOrTypes instanceof CommandType) ? [commandTypeOrTypes.type] : commandTypeOrTypes.map(ct => ct.type);
        excludeTypes = excludeTypes.concat(this.SceneReset.type); // @deprecated: Excluding SceneReset for #PRDA-2484
        return CommandType.all.filter(ct => !excludeTypes.includes(ct.type));
    }

    /**
     * Get command types for assets as an array
     *
     * @returns {CommandType[]}
     */
    static get defaultCommands() {
        return [
            this.AIPrompt,
            this.AIKnowledge,
            this.Speak,
            this.BehaviourChange,
            this.Condition,
            this.Control3dAnimation,
            this.ControlCharacterAnimation,
            this.InputStyle,
            this.Hide,
            this.Show,
            this.Fade,
            this.Feedback,
            this.HelperAnimationPlay,
            this.HelperGlowChange,
            // this.HelperKnowledge,    // @deprecated: Disabled for #PRDA-14459, replaced by AIKnowledge
            // this.HelperPrompt,   // @deprecated: Disabled for #PRDA-14464, replaced by AIPrompt
            // this.HelperSpeak,    // @deprecated: Disabled for #PRDA-16168, replaced by Speak
            this.HelperWaypointGoTo,
            this.HelperTriggerInvoke,
            this.LearningRecord,
            this.ModuleActivate,
            this.ModuleDeactivate,
            this.SceneChange,
            //this.SceneReset,  // @deprecated: Disabled for #PRDA-2484
            this.Script,
            this.SoundPlay,
            this.UnitExit,
            this.UnitReset,
            this.TriggerCancel,
            this.TriggerInvoke,
            this.VariableOperation,
            this.Wait,
            this.WorldAnchorReset
        ];
    }

    /**
     * Get command types for transparent shapes as an array
     *
     * @returns {CommandType[]}
     */
    static get forTransparentShapes()
    {
        return this.defaultCommands;
    }

    /**
     * Get command types for image assets as an array
     *
     * @returns {CommandType[]}
     */
    static get forImageAssets()
    {
        return this.defaultCommands;
    }

    /**
     * Get command types for 3D model assets as an array
     *
     * @returns {CommandType[]}
     */
    static get forModel3dAssets()
    {
        return this.defaultCommands;
    }

    /**
     * Get command types for 3D character assets as an array
     *
     * @returns {CommandType[]}
     */
    static get forCharacterModel3dAssets()
    {
        return this.defaultCommands;
    }

    /**
     * Get command types for hotspots as an array
     *
     * @returns {CommandType[]}
     */
    static get forHotspots()
    {
        return this.defaultCommands
            .concat([
                CommandType.ImageShow,  // #PRDA-8454: Add support for old command types that can still exist on older units
                CommandType.TextShow,   // #PRDA-8454: Add support for old command types that can still exist on older units
                CommandType.VideoPlay   // #PRDA-8454: Add support for old command types that can still exist on older units
            ]);
    }

    /**
     * Get command types for particle emitter as an array
     *
     * @returns {Array<CommandType>}
     */
    static get forParticleEmitters()
    {
        return this.defaultCommands;
    }

    /**
     * Get command types for objectives completed as an array
     *
     * @returns {CommandType[]}
     */
    static get forObjectivesCompleted()
    {
        // For now the condition command does not make sense as we only support "objective_completed" as the condition
        return this.defaultCommands.filter(command => command.type !== CommandType.Condition.type);
    }

    /**
     * Get command types for text assets as an array
     *
     * @returns {CommandType[]}
     */
    static get forTextAssets()
    {
        return this.defaultCommands;
    }

    /**
     * Get command types for Modules as an array
     *
     * @returns {CommandType[]}
     */
    static get forModules()
    {
        return this.defaultCommands;
    }

    /**
     * Get command types for Environments as an array
     *
     * @returns {CommandType[]}
     */
    static get forEnvironments()
    {
        return this.defaultCommands;
    }

    /**
     * Get all helper-related command types
     *
     * @returns {CommandType[]}
     */
    static get helperCommands() {
        return [
            this.AIKnowledge,
            this.AIPrompt,
            this.Speak,
            this.HelperAnimationPlay,
            this.HelperGlowChange,
            // this.HelperKnowledge,   // @deprecated: Disabled for #PRDA-14459, replaced by AIKnowledge
            // this.HelperPrompt,   // @deprecated: Disabled for #PRDA-14464, replaced by AIPrompt
            // this.HelperSpeak,    // @deprecated: Disabled for #PRDA-16168, replaced by Speak
            this.HelperWaypointGoTo,
            this.HelperTriggerInvoke,
        ];
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in CommandTypes)
        {
            if (CommandTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }

    /**
     * Get CommandType by a given type name
     *
     * @param {String} type
     * @returns {CommandType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }
}

/**
 * Type definitions
 */
const StaticCommandTypes = {};
/**
 * @type {Object.<string, CommandTypeAttributes>}
 */
const CommandTypes = {

    // AI ---------------------------------------------------------------------

    AIKnowledge: {
        group: CommandTypeGroups.AI,
        type: 'ai_knowledge',
        title: trans('commands.ai.knowledge.title'),
        titleGrouped: trans('commands.ai.knowledge.title_grouped'),
        targetType: CommandTargetType.SceneObject_AI,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // @deprecated 2024.2 [12.1.0]
    HelperKnowledge: {
        group: CommandTypeGroups.Character,
        type: 'helper_knowledge',
        title: trans('commands.helper.knowledge.title'),
        titleGrouped: trans('commands.helper.knowledge.title_grouped'),
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    AIPrompt: {
        group: CommandTypeGroups.AI,
        type: 'ai_prompt',
        title: trans('commands.ai.prompt.title'),
        titleGrouped: trans('commands.ai.prompt.title_grouped'),
        targetType: CommandTargetType.SceneObject_AI,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // @deprecated 2024.2 [12.1.0]
    HelperPrompt: {
        group: CommandTypeGroups.Character,
        type: 'helper_prompt',
        title: trans('commands.helper.prompt.title'),
        titleGrouped: trans('commands.helper.prompt.title_grouped'),
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // Basic ------------------------------------------------------------------

    ModuleActivate: {
        group: CommandTypeGroups.Basic,
        type: 'widget_activate',
        title: trans('commands.module.activate.title'),
        titleGrouped: null,
        targetType: CommandTargetType.SceneObject_Module,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    ModuleDeactivate: {
        group: CommandTypeGroups.Basic,
        type: 'widget_deactivate',
        title: trans('commands.module.deactivate.title'),
        titleGrouped: null,
        targetType: CommandTargetType.SceneObject_Module,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    Show: {
        group: CommandTypeGroups.Basic,
        type: 'show',
        title: trans('commands.show.title'),
        titleGrouped: null,
        targetType: CommandTargetType.SceneObject_AssetHotspotParticles,
        defaultValue: true,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    Hide: {
        group: CommandTypeGroups.Basic,
        type: 'hide',
        title: trans('commands.hide.title'),
        titleGrouped: null,
        targetType: CommandTargetType.SceneObject_AssetHotspotParticles,
        defaultValue: false,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    TriggerInvoke: {
        group: CommandTypeGroups.Basic,
        type: 'trigger_invoke',
        title: trans('commands.trigger.invoke.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Trigger,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    TriggerCancel: {
        group: CommandTypeGroups.Basic,
        type: 'trigger_cancel',
        title: trans('commands.trigger.cancel.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Trigger,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    Control3dAnimation: {
        group: CommandTypeGroups.Basic,
        type: 'control_3d_animation',
        title: trans('commands.control_3d_animation.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Model3d,
        defaultValue: false,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    SoundPlay: {
        group: CommandTypeGroups.Basic,
        type: 'sound_play',
        title: trans('commands.sound.play.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Sound,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // @deprecated 2021.3 [4.0.0]
    VideoPlay: {
        group: CommandTypeGroups.Basic,
        type: 'video_play',
        title: trans('commands.video.play.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Video,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // @deprecated 2021.3 [4.0.0]
    ImageShow: {
        group: CommandTypeGroups.Basic,
        type: 'image_show',
        title: trans('commands.image.show.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Image,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // @deprecated 2021.3 [4.0.0]
    TextShow: {
        group: CommandTypeGroups.Basic,
        type: 'text_show',
        title: trans('commands.text.show.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Text,
        defaultValue: { headline: null, text: null },
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    Feedback: {
        group: CommandTypeGroups.Basic,
        type: 'feedback',
        title: trans('commands.feedback.play.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: 'success',
        possibleValues: {
            'success': trans('labels.success'),
            'fail': trans('labels.fail'),
            'message': trans('labels.message'),
        },
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    SceneChange: {
        group: CommandTypeGroups.Basic,
        type: 'scene_change',
        title: trans('commands.scene.change.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Scene,
        defaultValue: {
            value: null,
            fade: true
        },
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    OverlayPrevious: {
        group: CommandTypeGroups.Basic,
        type: 'overlay_previous',
        title: trans('commands.overlay.previous.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    OverlayNext: {
        group: CommandTypeGroups.Basic,
        type: 'overlay_next',
        title: trans('commands.overlay.next.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    OverlayHide: {
        group: CommandTypeGroups.Basic,
        type: 'overlay_hide',
        title: trans('commands.overlay.hide.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    OverlayReset: {
        group: CommandTypeGroups.Basic,
        type: 'overlay_reset',
        title: trans('commands.overlay.reset.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    // Character & Helper Companion -------------------------------------------

    Speak: {
        group: CommandTypeGroups.Character,
        type: 'speak',
        title: trans('commands.speak.title'),
        titleGrouped: trans('commands.speak.title_grouped'),
        targetType: CommandTargetType.SceneObject_AI,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
    },

    // @deprecated 2024.2 [12.1.0]
    HelperSpeak: {
        group: CommandTypeGroups.Character,
        type: 'helper_speak',
        title: trans('commands.helper.speak.title'),
        titleGrouped: trans('commands.helper.speak.title_grouped'),
        targetType: CommandTargetType.Sound,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    ControlCharacterAnimation: {
        group: CommandTypeGroups.Character,
        type: 'control_character_animation',
        title: trans('commands.control_character_animation.title'),
        titleGrouped: null,
        targetType: CommandTargetType.SceneObject_CharacterModel3d,
        defaultValue: false,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    HelperWaypointGoTo: {
        group: CommandTypeGroups.Character,
        type: 'helper_waypoint_goto',
        title: trans('commands.helper.waypoint_goto.title'),
        titleGrouped: trans('commands.helper.waypoint_goto.title_grouped'),
        targetType: CommandTargetType.Waypoint,
        defaultValue: WaypointMode.FollowUser,
        possibleValues: [...WaypointModeValues],  // @NOTE: UUIDs of waypoints are also allowed
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    HelperGlowChange: {
        group: CommandTypeGroups.Character,
        type: 'helper_glow_change',
        title: trans('commands.helper.glow_change.title'),
        titleGrouped: trans('commands.helper.glow_change.title_grouped'),
        targetType: null,
        defaultValue: (new ColorConfiguration()).main,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    HelperTriggerInvoke: {
        group: CommandTypeGroups.Character,
        type: 'helper_trigger_invoke',
        title: trans('commands.helper.trigger_invoke.title'),
        titleGrouped: trans('commands.helper.trigger_invoke.title_grouped'),
        targetType: CommandTargetType.Trigger,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    HelperAnimationPlay: {
        group: CommandTypeGroups.Character,
        type: 'helper_animation_play',
        title: trans('commands.helper.animation_play.title'),
        titleGrouped: trans('commands.helper.animation_play.title_grouped'),
        targetType: null,
        defaultValue: null,
        possibleValues: HelperAnimationType.all,
        allowTargetSelf: true,      // @NOTE: Only when the target is a Helper Companion module!
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // Advanced ---------------------------------------------------------------

    Condition: {
        group: CommandTypeGroups.Advanced,
        type: 'condition',
        title: trans('commands.condition.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Condition,
        defaultValue: { 'objective_completed': trans('commands.condition.values.objective_completed') },
        possibleValues: {
            'object_active': trans('commands.condition.values.object_active'),
            'objective_completed': trans('commands.condition.values.objective_completed'),
            'trigger_running': trans('commands.condition.values.trigger_running'),
            'variable': trans('commands.condition.values.variable'),
        },
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    VariableOperation: {
        group: CommandTypeGroups.Advanced,
        type: 'variable_operation',
        title: trans('commands.variable_operation.title'),
        titleGrouped: null,
        targetType: CommandTargetType.Variable,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false, // @NOTE: Disabled since it would logically make sense but is not technically implemented correctly
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    BehaviourChange: {
        group: CommandTypeGroups.Advanced,
        type: 'behaviour_change',
        title: trans('commands.behaviour_change.title'),
        titleGrouped: null,
        targetType: CommandTargetType.SceneObject_Behaviour,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    Fade: {
        group: CommandTypeGroups.Advanced,
        type: 'fade',
        title: trans('commands.fade.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: { type: 'to_color', color: '000000FF' },
        possibleValues: {
            'from_color': trans('commands.fade.values.from_color'),
            'to_color': trans('commands.fade.values.to_color'),
        },
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    Wait: {
        group: CommandTypeGroups.Advanced,
        type: 'wait',
        title: trans('commands.wait.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: 1.0,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // Unit -------------------------------------------------------------------

    LearningRecord: {
        group: CommandTypeGroups.Unit,
        type: 'learning_record',
        title: trans('commands.learning_record.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: {
            'verb': {
                'id': LearningRecordVerbs.Completed.id,
                'display': LearningRecordVerbs.Completed.display,
            },
            'object': {
                'id': "urn:3spin-learning:unit:${unit.uid}",
                'definition': {
                    'name': {
                        'en-US': '${unit.title}'
                    },
                },
            },
        },
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // @deprecated 2024.2 [12.1.0]
    SceneReset: {
        group: CommandTypeGroups.Unit,
        type: 'scene_reset',
        title: trans('commands.scene.reset.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    UnitReset: {
        group: CommandTypeGroups.Unit,
        type: 'training_reset',
        title: trans('commands.unit.reset.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    UnitExit: {
        group: CommandTypeGroups.Unit,
        type: 'training_exit',
        title: trans('commands.unit.exit.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: 1,
        confirmDelete: false,
    },

    InputStyle: {
        group: CommandTypeGroups.Unit,
        type: 'input_style',
        title: trans('commands.input_style.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: 'controllers',
        possibleValues: {
            'controllers': trans('commands.input_style.controllers.title'),
            'hands': trans('commands.input_style.hands.title'),
        },
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    WorldAnchorReset: {
        group: CommandTypeGroups.Unit,
        type: 'worldanchor_reset',
        title: trans('commands.worldanchor.reset.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: null,
        possibleValues: null,
        allowTargetSelf: false,
        maxCountPerTrigger: null,
        confirmDelete: false,
    },

    // Beta -------------------------------------------------------------------

    Script: {
        group: CommandTypeGroups.Beta,
        type: 'script',
        title: trans('commands.script.title'),
        titleGrouped: null,
        targetType: null,
        defaultValue: trans('commands.script.code_default'),
        possibleValues: null,
        allowTargetSelf: true,
        maxCountPerTrigger: null,
        confirmDelete: true,
    },
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in CommandTypes)
{
    StaticCommandTypes[i] = new CommandType(CommandTypes[i]);
}
