import ITab from "@/Utility/Tabs/ITab";

export default class DefaultTab implements ITab {

    public title: String;
    public active: Boolean = false;
    public disabled: Boolean = false;

    constructor(title: String, active = false, disabled = false) {
        this.title = title;
        this.active = active;
        this.disabled = disabled;
    }
}
