import { trans } from '@/Utility/Helpers';
import Tags from '@/Utility/Tags';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import ExecutionType from '@/Models/UnitData/Triggers/ExecutionType';
import {TriggerSubtype} from "@/Models/UnitData/Triggers/TriggerSubtype";

export default class TriggerType
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || !TriggerType.isValidType(attributes.type))
        {
            console.warn('TriggerType->constructor(): Invalid data.', attributes);
            throw new TypeError('TriggerType->constructor(): Property "type" has to be set on TriggerType. Must be a valid type from TriggerType class.');
        }
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('TriggerType->constructor(): Invalid data.', attributes);
            throw new TypeError('TriggerType->constructor(): Property "title" has to be set on TriggerType.');
        }
        if (typeof attributes.description !== 'string' || attributes.description.length === 0)
        {
            console.warn('TriggerType->constructor(): Invalid data.', attributes);
            throw new TypeError('TriggerType->constructor(): Property "description" has to be set on TriggerType.');
        }
        if (typeof attributes.icon !== 'string' || attributes.icon.length === 0)
        {
            console.warn('TriggerType->constructor(): Invalid data.', attributes);
            throw new TypeError('TriggerType->constructor(): Property "icon" has to be set on TriggerType.');
        }

        // Populate the model:
        this.enabled = (typeof attributes.enabled === 'boolean') ? attributes.enabled : true;   // Enabled state
        this.defaultEnabled = (this.enabled === true);                      // Default enabled state (must not be a reference)
        this.type = attributes.type;                                        // Type identifier
        this.icon = attributes.icon;                                        // Icon identifier
        this.title = attributes.title;                                      // Translated title
        this.description = attributes.description;                          // Translated description
        this.commands = attributes.commands || null;                        // List of supported CommandTypes on this TriggerType
        this.executionTypes = attributes.executionTypes || ExecutionType.all;    // List of supported ExecutionTypes on this TriggerType
        this.subtypes = attributes.subtypes || {};                          // List of supported trigger subtypes on this TriggerType
        this.allowTags = (typeof attributes.allowTags === 'boolean') ? attributes.allowTags : false;   // Whether a trigger of this type can have tags
        this.tags = attributes.tags || [];                                  // List of default tag suggestions for this TriggerType
        this.maxCountPerSceneObject = (typeof attributes.maxCountPerSceneObject === 'number') ? attributes.maxCountPerSceneObject : null;  // How many of the same trigger can be added to a scene object?
        this.canUserChangeType = (typeof attributes.canUserChangeType === 'boolean') ? attributes.canUserChangeType : true;    // Whether the user can change the TriggerType
        this.canUserDelete = (typeof attributes.canUserDelete === 'boolean') ? attributes.canUserDelete : true;    // Whether the user can delete the trigger
        this.canUserEditTitle = (typeof attributes.canUserEditTitle === 'boolean') ? attributes.canUserEditTitle : false;    // Whether the user can edit the trigger's title
    }

    /**
     * TriggerType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {TriggerType}
     */
    static get OnActivate()             { return StaticTriggerTypes.OnActivate; }
    static get OnClick()                { return StaticTriggerTypes.OnClick; }
    static get OnConnectionPathComplete()           { return StaticTriggerTypes.OnConnectionPathComplete; }
    static get OnConnectionPathCompleteAll()        { return StaticTriggerTypes.OnConnectionPathCompleteAll; }
    static get OnConnectionPathCompleteUnknown()    { return StaticTriggerTypes.OnConnectionPathCompleteUnknown; }
    static get OnCue()                  { return StaticTriggerTypes.OnCue; }
    static get OnDeactivate()           { return StaticTriggerTypes.OnDeactivate; }
    static get OnEnter()                { return StaticTriggerTypes.OnEnter; }
    static get OnGaze()                 { return StaticTriggerTypes.OnGaze; }
    static get OnKeyPress()             { return StaticTriggerTypes.OnKeyPress; }
    static get OnLeave()                { return StaticTriggerTypes.OnLeave; }
    static get OnSpectatorKeyPress()    { return StaticTriggerTypes.OnSpectatorKeyPress; }
    static get OnVariableEvent()        { return StaticTriggerTypes.OnVariableEvent; }

    /**
     * Get all trigger types as an array
     *
     * @returns {TriggerType[]}
     */
    static get all()
    {
        return Object.values(StaticTriggerTypes);
    }

    /**
     * Get TriggerType by a given type name
     *
     * @param {String} type
     * @returns {TriggerType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @param {String|null|undefined} subtype
     * @returns {Boolean}
     */
    static isValidType(type, subtype = undefined)
    {
        return Object.values(TriggerTypes).some(
            triggerType => (triggerType.type === type)
                && (
                    subtype === undefined
                    || (subtype === null && triggerType.subtypes.length === 0)
                    || triggerType.subtypes.includes(subtype)
                )
        );
    }
}

/**
 * Type definitions
 */
const StaticTriggerTypes = {};
const TriggerTypes = {

    OnActivate: {
        enabled: true,
        type: 'on_activate',
        icon: 'icon_trigger-activated',
        title: trans('triggers.activate'),
        description: trans('triggers.activate_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: 1,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnClick: {
        enabled: true,
        type: 'on_click',
        icon: 'icon_trigger-click',
        title: trans('triggers.click'),
        description: trans('triggers.click_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: 1,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnConnectionPathComplete: {
        enabled: false, // @NOTE: Disabled since triggers of this type are being added automatically
        type: 'on_connection_path_complete',
        icon: 'icon_check',
        title: trans('triggers.connection_path_complete'),
        description: trans('triggers.connection_path_complete_description'),
        commands: CommandType.defaultCommands,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: null,
        canUserChangeType: false,
        canUserDelete: false,
        canUserEditTitle: false,
    },
    OnConnectionPathCompleteAll: {
        enabled: true,
        type: 'on_connection_path_complete_all',
        icon: 'icon_check',
        title: trans('triggers.connection_path_complete_all'),
        description: trans('triggers.connection_path_complete_all_description'),
        commands: CommandType.defaultCommands,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: 1,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnConnectionPathCompleteUnknown: {
        enabled: true,
        type: 'on_connection_path_complete_unknown',
        icon: 'icon_close',
        title: trans('triggers.connection_path_complete_unknown'),
        description: trans('triggers.connection_path_complete_unknown_description'),
        commands: CommandType.defaultCommands,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: 1,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnCue: {
        enabled: true,
        type: 'on_cue',
        icon: 'icon_trigger-cue',
        title: trans('triggers.cue'),
        description: trans('triggers.cue_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: null,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: true,
    },
    OnDeactivate: {
        enabled: true,
        type: 'on_deactivate',
        icon: 'icon_trigger-deactivated',
        title: trans('triggers.deactivate'),
        description: trans('triggers.deactivate_description'),
        commands: CommandType.getAllExcept([
            CommandType.ImageShow,
            CommandType.SoundPlay,
            CommandType.TextShow,
            CommandType.VideoPlay,
        ]),
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: 1,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnEnter: {
        enabled: true,
        type: 'on_enter',
        icon: 'icon_trigger-enter',
        title: trans('triggers.enter'),
        description: trans('triggers.enter_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [
            TriggerSubtype.Collider,
            TriggerSubtype.Distance,
        ],
        allowTags: true,
        tags: [
            Tags.HandAny,
            Tags.HandLeft,
            Tags.HandRight,
            Tags.User,
        ],
        maxCountPerSceneObject: null,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnGaze: {
        enabled: true,
        type: 'on_gaze',
        icon: 'icon_trigger-gaze',
        title: trans('triggers.gaze'),
        description: trans('triggers.gaze_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: 1,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnKeyPress: {
        enabled: true,
        type: 'on_keypress',
        icon: 'icon_trigger-keypress',
        title: trans('triggers.keypress'),
        description: trans('triggers.keypress_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: null,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnLeave: {
        enabled: true,
        type: 'on_leave',
        icon: 'icon_trigger-leave',
        title: trans('triggers.leave'),
        description: trans('triggers.leave_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [
            TriggerSubtype.Collider,
            TriggerSubtype.Distance,
        ],
        allowTags: true,
        tags: [
            Tags.HandAny,
            Tags.HandLeft,
            Tags.HandRight,
            Tags.User,
        ],
        maxCountPerSceneObject: null,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnSpectatorKeyPress: {
        enabled: true,
        type: 'on_spectator_keypress',
        icon: 'icon_trigger-keypress',
        title: trans('triggers.spectator_keypress'),
        description: trans('triggers.spectator_keypress_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: null,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
    OnVariableEvent: {
        enabled: true,
        type: 'on_variable_event',
        icon: 'icon_variable',
        title: trans('triggers.variable_event'),
        description: trans('triggers.variable_event_description'),
        commands: CommandType.all,
        executionTypes: ExecutionType.all,
        subtypes: [
            TriggerSubtype.Changed
        ],
        allowTags: false,
        tags: [],
        maxCountPerSceneObject: null,
        canUserChangeType: true,
        canUserDelete: true,
        canUserEditTitle: false,
    },
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in TriggerTypes)
{
    StaticTriggerTypes[i] = new TriggerType(TriggerTypes[i]);
}
