export default class PageHeaderButton
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.visible = (typeof attributes.visible === 'boolean') ? attributes.visible : true;           // Visible state
        this.disabled = (typeof attributes.disabled === 'boolean') ? attributes.disabled : false;       // Disabled state
        this.caption = attributes.caption || null;                                                      // Caption text
        this.href = attributes.href || null;                                                            // Simple link
        this.tooltip = attributes.tooltip || null;                                                      // Tooltip text
        this.icon = attributes.icon || null;                                                            // Icon identifier
        this.callback = attributes.callback || null;                                                    // Callback method
        this.style = attributes.style || 'icon+text';                                                   // Style (e.g. 'button', 'icon+text')
    }
}
