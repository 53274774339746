<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('users.delete.headline') }}</div>

                    <div class="card-body">
                        <div class="form-group row mb-0">
                            <div class="buttons col-md-6 offset-md-4">
                                <ButtonPrimary
                                    class="btn-red"
                                    caption="labels.delete"
                                    @trigger="onClickDelete"
                                    :disabled="isButtonDisabled" />
                                <span class="d-xs-none d-sm-none"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalDeleteUser />
        <ModalProgress />
    </div>
</template>

<script>
import EventType from '@/Utility/EventType';
import {route, shortId, trans} from '@/Utility/Helpers';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ModalDeleteUser from '@/Vue/Modals/ModalDeleteUser.vue';
import User from '@/Models/User/User';
import {Permission} from '@/Models/User/Permission.js';

export default {
    name: 'DeleteUserForm',
    components: {
        ModalDeleteUser,
        ModalProgress,
    },
    props: {
        userJson: {
            type: Object,
            default: null
        },
        url: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            uid: shortId('delete-user-form'),
            isSubmitting: false,
            user: new User(this.userJson),
        };
    },
    mounted() {
        this.$globalEvents.on(EventType.MODAL_DELETE_USER_APPLY, this.onApplyConfirmDelete);
    },
    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_DELETE_USER_APPLY, this.onApplyConfirmDelete);
    },
    computed: {
        isButtonDisabled() {
            return this.isSubmitting || !this.canDeleteUser;
        },

        canDeleteUser() {
            return this.$gate.allows(Permission.ability(Permission.UsersDelete()), this.user);
        },
    },
    methods: {

        /**
         * Click handler for the submit button
         */
        onClickDelete() {
            if (this.isSubmitting) {
                return;
            }
            this.$globalEvents.emit(EventType.MODAL_DELETE_USER_SHOW, this.user);
            return this;
        },

        /**
         * Event handler for apply button from the overlay
         */
        onApplyConfirmDelete() {

            if (this.isSubmitting) {
                return;
            }

            this.isSubmitting = true;
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('users.delete.modals.deleting.title'));

            window.axios({
                url: this.url,
                method: 'delete',
            })
            .then(() => {
                // Force logout if the current user was deleted, otherwise redirect to users list:
                window.location = route((this.user.uid === window.currentUser.uid) ? 'logout' : 'users.index');
            })
            .catch(error => {
                this.isSubmitting = false;
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                this.$root.showErrorDialog(error);
            });
        },
    }
};
</script>

<style lang="scss" scoped>

</style>
