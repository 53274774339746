import {trans} from '@/Utility/Helpers';
import FilterCategory from '@/Filters/FilterCategory';
import MultiFilterCategory from '@/Filters/MultiFilterCategory';
import FilterSection from '@/Filters/FilterSection';
import Asset from '@/Models/Asset/Asset';
import AssetType from '@/Models/Asset/AssetType';
import TrainingScene from '@/Models/UnitData/Scenes/TrainingScene';
import UnitData from '@/Models/UnitData/UnitData';
import {
    BaseSceneObjectHotspot,
    BaseSceneObjectModule,
    SceneObjectAssetText,
    SceneObjectGroup,
    SceneObjectHotspotTransparentShape,
    SceneObjectModuleVariable,
    SceneObjectParticleEmitter
} from '@/Models/UnitData/SceneObjects/SceneObject';
import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';

/**
 * Apply max count limits
 *
 * @param {Object[]} elements
 * @param {*|Null} options
 * @returns {Object[]}
 */
const filterByMaxCount = function(elements, options = null) {
    if (options === null || !(options instanceof Object) || typeof options.constructorName !== 'string')
    {
        return elements;
    }
    // Apply limit per scene:
    if (options instanceof TrainingScene)
    {
        return elements.filter(e => {
            const limit = e.isGlobalOnly ? e.maxCountGlobal : e.maxCountPerScene;
            const target = e.isGlobalOnly ? options.parent : options;
            return (typeof limit === 'number') ? (target.allSceneObjects.filter(o => o.type === e.type && o.subtype === e.subtype).length < limit) : true;
        });
    }
    // Apply limit per unit:
    else if (options instanceof UnitData)
    {
        return elements.filter(e => {
            return (typeof e.maxCountGlobal === 'number') ? (options.allSceneObjects.filter(o => o.type === e.type && o.subtype === e.subtype).length < e.maxCountGlobal) : true;
        });
    }
    return elements;
};

/**
 * Filter definitions
 */
const FilterDefinitions = {

    All: {
        title: trans('labels.all'),
        callback(elements, options = null) {

            // Filter for assets that are in the library, and all non-assets:
            elements = elements.filter(e =>
                (e instanceof Asset) ? e.isInUserLibrary : true
            );

            // Apply limit per scene and unit:
            elements = filterByMaxCount(elements, options);

            // Return the filtered list:
            return elements;
        }
    },

    Assets: {
        title: trans('labels.assets'),
        callback(elements, options = null) {

            // Exclude anything that isn't an asset available in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },

    AssetsArchived: {
        title: trans('labels.archive'),
        callback(elements, options = null) {

            // Exclude anything that isn't an asset and is not archived:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.isArchived
            );

            // Return the filtered list:
            return elements;
        }
    },

    AssetsOfSameType: {
        title: trans('labels.assets'),
        callback(elements, options = null) {

            // Exclude anything that isn't an asset of the same type or not available in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                (options === null || options.subtype === e.type) &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },

    AssetsFromMyLibrary: {
        title: trans('labels.my_library'),
        callback(elements, options = null) {

            // Exclude anything that isn't an asset available in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },

    AssetsForAuthoring: {
        title: trans('labels.my_assets'),
        callback(elements, options = null) {

            // Only use elements that are of type 'Asset', are available in the user's library
            // and have the AssetPolicyStandard or are from the free library
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.isInUserLibrary &&
                (
                    e.isStandardAsset ||
                    e.isFromFreeLibrary
                )
            );

            // Return the filtered list:
            return elements;
        }
    },

    AssetsFromFreeLibrary: {
        title: trans('labels.assets_from_free_library'),
        callback(elements, options = null) {

            // Exclude anything that is not a store asset:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.isFromFreeLibrary &&
                !e.archived
            );

            // Return the filtered list:
            return elements;
        }
    },

    Hotspot: {
        title: trans('labels.hotspots'),
        callback(elements, options = null) {

            // Exclude anything that isn't a hotspot:
            elements = elements.filter(e =>
                (
                    e instanceof SceneObjectType &&
                    e.type === SceneObjectType.TypeOfHotspot &&
                    !e.typeOf(SceneObjectType.Hotspots.Transparent)
                ) ||
                (
                    e instanceof BaseSceneObjectHotspot &&
                    !(e instanceof SceneObjectHotspotTransparentShape)
                )
            );

            // Apply limit per scene and unit:
            elements = filterByMaxCount(elements, options);

            // Return the filtered list:
            return elements;
        }
    },

    Hotspots: {
        title: trans('labels.hotspots'),
        callback(elements, options = null) {

            // Exclude anything that isn't a hotspot:
            // @NOTE: Including Text Asset here as a workaround
            elements = elements.filter(e =>
                (
                    e instanceof SceneObjectType &&
                    (
                        e.type === SceneObjectType.TypeOfHotspot ||
                        e.typeOf(SceneObjectType.Assets.Text)
                    )
                ) ||
                e instanceof BaseSceneObjectHotspot ||
                e instanceof SceneObjectAssetText
            );

            // Apply limit per scene and unit:
            elements = filterByMaxCount(elements, options);

            // Return the filtered list:
            return elements;
        }
    },

    GenericAssets: {
        title: trans('labels.standard_assets'),
        callback(elements, options = null) {

            // Only include hotspots, groups and text-assets
            elements = elements.filter(e =>
                (
                    e instanceof SceneObjectType &&
                    (
                        e.type === SceneObjectType.TypeOfHotspot ||
                        e.typeOf(SceneObjectType.Group) ||
                        e.typeOf(SceneObjectType.Assets.Text) ||
                        e.typeOf(SceneObjectType.ParticleEmitter)
                    )
                ) ||
                e instanceof BaseSceneObjectHotspot ||
                e instanceof SceneObjectGroup ||
                e instanceof SceneObjectAssetText ||
                e instanceof SceneObjectParticleEmitter
            );

            // Return the filtered list:
            return elements;
        }
    },

    Images: {
        title: trans('labels.images'),
        callback(elements, options = null) {

            // Exclude anything that isn't an image asset and anything that is not in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.type === AssetType.Image.type &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },

    Model3d: {
        title: trans('labels.models3d'),
        callback(elements, options = null) {

            // Exclude anything that isn't a 3D model asset and anything that is not in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                (e.type === AssetType.Model3D.type || e.type === AssetType.EnvironmentModel3D.type) &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },

    Modules: {
        title: trans('labels.modules'),
        callback(elements, options = null) {

            // Exclude anything that isn't a module:
            elements = elements.filter(e =>
                (
                    e instanceof SceneObjectType &&
                    e.type === SceneObjectType.TypeOfModule
                ) ||
                e instanceof BaseSceneObjectModule
            );

            // Apply limit per scene and unit:
            elements = filterByMaxCount(elements, options);

            // Return the filtered list:
            return elements;
        }
    },

    ParticleEmitters: {
        title: trans('labels.particle_emitters'),
        callback(elements, options = null) {
            // Exclude anything that isn't a particle emitter:
            elements = elements.filter(e =>
                (
                    e instanceof SceneObjectType &&
                    e.type === SceneObjectType.TypeOfParticleEmitter
                ) ||
                e instanceof SceneObjectParticleEmitter
            );

            // Apply limit per scene and unit:
            elements = filterByMaxCount(elements, options);

            // Return the filtered list:
            return elements;
        }
    },

    Samples: {
        title: trans('labels.sample_assets'),
        callback(elements, options = null) {

            // Only use elements that are of type 'Asset', are available in the user's library
            // and have the AssetPolicySample
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.isInUserLibrary &&
                e.isSampleAsset
            );

            // Return the filtered list:
            return elements;
        }
    },

    Templates: {
        title: trans('labels.template_assets'),
        callback(elements, options = null) {

            // Only use elements that are of type 'Asset', are available in the user's library
            // and have the AssetPolicyTemplate
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.isInUserLibrary &&
                e.isTemplateAsset
            );

            // Return the filtered list:
            return elements;
        }
    },

    Sounds: {
        title: trans('labels.sounds'),
        callback(elements, options = null) {

            // Exclude anything that isn't a sound asset and anything that is not in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.type === AssetType.Sound.type &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },

    TextToSpeechSounds: {
        title: trans('labels.text_to_speech_sounds'),
        callback(elements, options = null) {

            // Exclude anything that isn't a tts sound asset and anything that is not in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.type === AssetType.SoundTts.type &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },

    TransparentShape: {
        title: trans('hotspots.transparent.title'),
        callback(elements, options = null) {

            // Exclude anything that isn't a hotspot:
            elements = elements.filter(e =>
                (
                    e instanceof SceneObjectType &&
                    e.typeOf(SceneObjectType.Hotspots.Transparent)
                ) ||
                e instanceof SceneObjectHotspotTransparentShape
            );

            // Apply limit per scene and unit:
            elements = filterByMaxCount(elements, options);

            // Return the filtered list:
            return elements;
        }
    },

    Variables: {
        title: trans('labels.variables'),
        callback(elements, options = null) {

            // Exclude anything that isn't a variable module:
            elements = elements.filter(e =>
                (
                    e instanceof SceneObjectType &&
                    e.typeOf(SceneObjectType.Modules.Variable)
                ) ||
                e instanceof SceneObjectModuleVariable
            );

            // Apply limit per scene and unit:
            elements = filterByMaxCount(elements, options);

            // Return the filtered list:
            return elements;
        }
    },

    Videos: {
        title: trans('labels.videos'),
        callback(elements, options = null) {

            // Exclude anything that isn't a video asset and anything that is not in the user's library:
            elements = elements.filter(e =>
                e instanceof Asset &&
                e.type === AssetType.Video.type &&
                e.isInUserLibrary
            );

            // Return the filtered list:
            return elements;
        }
    },
};

export default class SceneObjectFilters
{
    /**
     * Default filter category for the side panel showing all items
     */
    static get All()                    { return new FilterCategory(FilterDefinitions.All); }

    /**
     * Asset filter category for the side panel
     */
    static get Assets()                 { return new FilterCategory(FilterDefinitions.Assets); }

    /**
     * Only assets that are archived
     */
    static get AssetsArchived()         { return new FilterCategory(FilterDefinitions.AssetsArchived); }

    /**
     * Asset filter category for the side panel with only the same type as a given SceneObject passed as the filter options
     */
    static get AssetsOfSameType()       { return new FilterCategory(FilterDefinitions.AssetsOfSameType); }

    /**
     * All assets uploaded by user, all standard assets and all added assets from store
     */
    static get AssetsForAuthoring()     { return new FilterCategory(FilterDefinitions.AssetsForAuthoring); }

    /**
     * All assets uploaded by user, all standard assets and all added assets from store
     */
    static get AssetsFromMyLibrary()    { return new FilterCategory(FilterDefinitions.AssetsFromMyLibrary); }

    /**
     * Only assets from store
     */
    static get AssetsFromFreeLibrary()  { return new FilterCategory(FilterDefinitions.AssetsFromFreeLibrary); }

    /**
     * List of all available objects in authoring
     */
    static get AuthoringAll() {
        return new MultiFilterCategory(
            trans('labels.all'),
            [
                new FilterSection(
                    '',
                    FilterDefinitions.GenericAssets
                ),
                new FilterSection(
                    trans('labels.modules'),
                    FilterDefinitions.Modules
                ),
                new FilterSection(
                    trans('labels.my_assets'),
                    FilterDefinitions.AssetsForAuthoring
                ),
                new FilterSection(
                    trans('labels.free_library'),
                    FilterDefinitions.AssetsFromFreeLibrary
                ),
                new FilterSection(
                    trans('labels.template_assets'),
                    FilterDefinitions.Templates
                ),
                new FilterSection(
                    trans('labels.sample_assets'),
                    FilterDefinitions.Samples
                ),
            ]
        );
    }

    /**
     * List of assets from the user's library and sample assets
     */
    static get AuthoringMyLibrary() {
        return new MultiFilterCategory(
            trans('labels.my_library'),
            [
                new FilterSection(
                    trans('labels.my_assets'),
                    FilterDefinitions.AssetsForAuthoring
                ),
                new FilterSection(
                    trans('labels.template_assets'),
                    FilterDefinitions.Templates
                ),
                new FilterSection(
                    trans('labels.sample_assets'),
                    FilterDefinitions.Samples
                ),
            ]
        );
    }

    /**
     * Hotspot filter category for the side panel
     */
    static get Hotspot()            { return new FilterCategory(FilterDefinitions.Hotspot); }

    /**
     * Hotspot filter category for the side panel
     */
    static get Hotspots()           { return new FilterCategory(FilterDefinitions.Hotspots); }

    /**
     * Images filter category for the side panel
     */
    static get Images()             { return new FilterCategory(FilterDefinitions.Images); }

    /**
     * Model3d filter category for the side panel
     */
    static get Model3d()            { return new FilterCategory(FilterDefinitions.Model3d); }

    /**
     * Modules filter category for the side panel
     */
    static get Modules()            { return new FilterCategory(FilterDefinitions.Modules); }

    /**
     * Particle emitter filter category for the side panel
     */
    static get ParticleEmitters()   { return new FilterCategory(FilterDefinitions.ParticleEmitters); }

    /**
     * Sounds filter category for the side panel
     */
    static get Sounds()             { return new FilterCategory(FilterDefinitions.Sounds); }

    /**
     * Text to speech sounds filter category for the side panel
     */
    static get TextToSpeechSounds() { return new FilterCategory(FilterDefinitions.TextToSpeechSounds); }

    /**
     * Transparent Shape filter category for the side panel
     */
    static get TransparentShape()   { return new FilterCategory(FilterDefinitions.TransparentShape); }

    /**
     * Variable Module Scene Object filter category for the side panel
     */
    static get VariableModules()    { return new FilterCategory(FilterDefinitions.Variables); }

    /**
     * Videos filter category for the side panel
     */
    static get Videos()             { return new FilterCategory(FilterDefinitions.Videos); }

    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === SceneObjectFilters)
        {
            throw new TypeError('Static class "SceneObjectFilters" cannot be instantiated directly.');
        }
    }
}
