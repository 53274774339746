import {trans} from '@/Utility/Helpers';
import FilterCategory from '@/Filters/FilterCategory';
import type Device from '@/Models/Devices/Device';

/**
 * Filter definitions
 */
const FilterDefinitions = {

    All: {
        title: trans('labels.all'),
        callback(devices: Device[]) {
            return devices;
        }
    },

    InManagedMode: {
        title: trans('labels.managed_mode'),
        callback(devices: Device[]) {
            return devices.filter(device => device.isInManagedMode);
        }
    }

};

export default abstract class DeviceFilters {
    static All = new FilterCategory(FilterDefinitions.All);
    static InManagedMode = new FilterCategory(FilterDefinitions.InManagedMode);
}
