<template>
    <div
        :data-loading="isLoading"
        class="container"
    >
        <div v-if="Object.keys(errors).length" class="invalid-packages page-import-list-item">
            <h2>
                <Icon name="icon_error" />
                {{ trans('labels.invalid_packages') }}
            </h2>
            <p v-for="packageName in Object.keys(errors)">
                <b>{{ packageName }}</b> ({{ errors[packageName] }})
            </p>
        </div>

        <NoItemsAvailable v-if="Object.keys(packages).length === 0" />
        <PageImportListItem
            v-for="(content, packageName) in packages"
            v-else
            :content="content"
            :package="packageName"
        />

        <ModalProgress>
            <p class="description" v-html="trans('units.import.importing_text')"></p>
        </ModalProgress>
        <ModalNotification />
    </div>
</template>

<script>
import {shortId, trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import PageImportListItem from '@/Vue/Import/PageImportListItem.vue';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import {inject} from 'vue';
import {unitServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';

export default {
    name: 'PageImportList',
    components: {
        NoItemsAvailable,
        ModalProgress,
        ModalNotification,
        PageImportListItem,
    },

    props: {
        packages: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: {},
        },
        url: {
            type: String,
            default: '/api/units/import/',
        },
    },

    data() {
        return {
            uid: shortId('page-import-list'),
            unitService: inject(unitServiceKey),
        };
    },

    computed: {
        isLoading() {
            if (this.unitService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('units.import.importing'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        }
    },

    methods: {
        trans
    },
};
</script>

<style lang="scss" scoped>

.invalid-packages {
    h2 svg {
        color: var(--color-signal);
        margin: -2px 10px 0 0;
        width: 24px;
        height: 24px;
    }
}

</style>
