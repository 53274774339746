<template>
    <Modal
        ref="modal"
        :event-type="eventType"
        :show-close-button="false"
        :title="title"
        :user-closable="Boolean(cancelText)"
        @hide="onHide"
        @show="onShow"
    >
        <p class="description" v-html="description"></p>

        <template #buttons>
            <ButtonSecondary
                v-if="cancelText"
                :caption="cancelText"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :caption="applyText"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import {defineComponent} from 'vue';
import EventType from '@/Utility/EventType';
import {trans} from '@/Utility/Helpers';
import Modal from '@/Vue/Modals/Modal.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Modal,
    },
    props: {
        title: String,

        description: String,

        /**
         * Must be a string from EventType class
         */
        eventType: {
            type: String,
            default: 'MODAL_APPLY_CANCEL'    //
        },

        applyText: {
            type: String,
            default() {
                return trans('labels.okay')
            }
        },

        /**
         * Setting this to null disables the button (e.g. used for incompatible unit dialog)
         */
        cancelText: {
            type: String,
            default() {
                return trans('labels.cancel')
            }
        },
    },
    data() {
        return {
            /**
             * Reference to the model object for which this dialog is being shown
             */
            model: null as any,
        }
    },
    created() {
        // Make sure the given type's events exist:
        ['SHOW', 'HIDE', 'CANCEL', 'APPLY'].forEach((suffix) => {
            const eventName = this.eventType + '_' + suffix;
            if (typeof EventType[eventName] !== 'string') {
                throw new TypeError(`ModalApplyCancel->created(): EventType ${eventName} doesn't exist.`);
            }
        });
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {

        onShow(model: any = null): void {
            this.model = model;
        },

        onHide(): void {
            this.model = null;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            this.$globalEvents.emit(EventType[this.eventType + '_APPLY'], this.model);
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
