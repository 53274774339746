import { parseColor } from '@/Utility/Helpers';

export default class ColorConfiguration
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Populate the model:
        this.main = parseColor(attributes.main || window.appData.THEME.app.colors.main || null);                  // Main color
        this.highlight = parseColor(attributes.highlight || window.appData.THEME.app.colors.highlight || null);   // Highlight color
    }

    /**
     * Duplicate
     *
     * @returns {ColorConfiguration}
     */
    duplicate() {
        return new ColorConfiguration(this);
    }
}
