export default class HintSceneAssignment
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.scene_uid = attributes.scene_uid || null;                                  // UID of the scene that should use the cue trigger
        this.cue = attributes.cue || null;                                              // UID of the cue trigger that executes the commands to give the hint to the user
    }

    /**
     * Duplicate
     *
     * @returns {HintSceneAssignment}
     */
    duplicate()
    {
        return new HintSceneAssignment(this);
    }
}
