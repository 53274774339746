import NetworkControlMode from '@/Models/UnitData/Configuration/NetworkControlMode';

export default class NetworkConfiguration
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Populate the model:
        this.avatars_visible = (typeof attributes.avatars_visible === 'boolean') ? attributes.avatars_visible : true;
        this.control_mode = (typeof attributes.control_mode === 'string') ? attributes.control_mode : NetworkControlMode.SingleUser.type;
        this.users_join_muted = (typeof attributes.users_join_muted === 'boolean') ? attributes.users_join_muted : false;
    }

    /**
     * Duplicate
     *
     * @returns {NetworkConfiguration}
     */
    duplicate() {
        return new NetworkConfiguration(this);
    }
}
