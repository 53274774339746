import type InstructionPayload from '@/Models/Sessions/InstructionPayload';
import InstructionPayloadUnitStart from '@/Models/Sessions/InstructionPayloadUnitStart';
import type {InstructionPayloadClass} from '@/Models/Sessions/InstructionPayloadClass';
import InstructionPayloadUnitStop from '@/Models/Sessions/InstructionPayloadUnitStop';

export default class InstructionPayloadFactory {

    // Add all upcoming payload types here:
    static payloadClasses: InstructionPayloadClass[] = [
        InstructionPayloadUnitStart,
        InstructionPayloadUnitStop,
    ];

    static createFromAttributes(attributes: any): InstructionPayload {
        const payloadClass = this.payloadClasses.find(payloadClass => payloadClass.type === attributes.type);

        if (!payloadClass) {
            throw Error(`Unknown instruction payload type '${attributes.type}'`);
        }

        return new payloadClass(attributes);
    }
}
