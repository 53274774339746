<template>
    <li class="section-headline">
        <h4>{{ text }}</h4>
    </li>
</template>

<script>
export default {
    name: 'SidepanelSectionHeadline',
    props: {
        text: {
            type: String,
            default() {
                return '';
            }
        }
    },
}
</script>

<style lang="scss" scoped>

li {
    background-color: var(--color-anthracite05);
    color: var(--color-anthracite40);
    cursor: default;
    pointer-events: none;
    position: sticky;
    top: 0;
}

</style>
