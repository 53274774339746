<template>
    <header class="inspector-header">
        <Icon :name="iconName" class="inspector-panel-header-icon" />

        {{ title }}

        <div
            v-if="uid"
            ref="overflowMenuWrapper"
            class="overflow-menu-wrapper"
            @keydown.esc="closeOverflowMenu"
        >
            <a :title="trans('labels.overflow_menu')" href="#" @mousedown="onOverflowMenuIconMouseDown">
                <Icon name="icon_more_horizontal" />
            </a>

            <menu class="overflow-menu">
                <li>
                    <a
                        :title="trans('labels.copy')"
                        class="menu-item"
                        href="#"
                        @click="copyUid"
                    >
                        <Icon name="icon_copy" />
                        {{ trans('labels.copy_uid') }}
                    </a>
                </li>
            </menu>
        </div>

    </header>
</template>

<script lang="ts">
import {trans} from '@/Utility/Helpers';
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import Icon from '@/Vue/Common/Icon.vue';
import Clipboard from '@/Utility/Clipboard';

export default defineComponent({
    components: {
        Icon
    },

    props: {
        iconName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        uid: {
            type: String as PropType<string | null>,
            default: null,
        }
    },

    computed: {
        overflowMenuWrapper() {
            return this.$refs.overflowMenuWrapper as HTMLElement;
        },
    },

    methods: {
        trans,

        copyUid() {
            if (!this.uid) {
                return;
            }

            Clipboard.setClipboardStringAsync(this.uid);
        },

        isOverflowMenuOpen() {
            return this.overflowMenuWrapper.contains(document.activeElement);
        },

        closeOverflowMenu() {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        },

        onOverflowMenuIconMouseDown(e: Event) {
            // When clicking on menu icon while the menu is open,
            // prevent focus on the button and instead remove the focus.
            if (this.isOverflowMenuOpen()) {
                e.preventDefault();
                this.closeOverflowMenu();
            }
        },
    }
});
</script>

<style lang="scss" scoped>

.inspector-header {
    --inspector-header-color-bg: var(--background-color-white);
    --inspector-header-color-fg: var(--font-color-dark);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px var(--sidepanel-padding);
    line-height: var(--line-height-small);
    font-family: var(--font-family-condensed-demibold);
    color: var(--inspector-header-color-fg);
    background-color: var(--inspector-header-color-bg);

    &.asset {
        --inspector-header-color-fg: var(--font-color-light);
        --inspector-header-color-bg: var(--color-item-green);
    }

    &.asset[class*='asset-environment'] {
        --inspector-header-color-bg: var(--color-item-blue);
    }

    &.hotspot {
        --inspector-header-color-fg: var(--font-color-light);
        --inspector-header-color-bg: var(--color-item-yellow);
    }

    &.particle_emitter {
        --inspector-header-color-fg: var(--font-color-light);
        --inspector-header-color-bg: var(--color-item-yellow);
    }

    &.module {
        --inspector-header-color-fg: var(--font-color-light);
        --inspector-header-color-bg: var(--color-item-grey);
    }

    &.objectives {
        --inspector-header-color-fg: var(--font-color-light);
        --inspector-header-color-bg: var(--color-anthracite);
    }

    &.sceneobject-group {
        --inspector-header-color-bg: var(--color-item-lightgrey);
    }

    .inspector-panel-header-icon {
        width: 18px;
        height: 18px;
        margin: 0 5px 0 -23px;
    }

    .overflow-menu-wrapper {
        position: absolute;
        right: 12px;

        &:focus-within {
            .overflow-menu {
                display: block;
            }
        }

        .icon {
            color: var(--inspector-header-color-fg);
            width: 14px;
            height: 14px;
            margin-top: -2px;
        }

        .overflow-menu {
            display: none;
            position: absolute;
            right: -8px;
            top: 14px;
            padding: 10px 16px;
            list-style-type: none;
            z-index: 100;
            background-color: var(--background-color-white);
            border-radius: var(--dropdown-border-radius);
            box-shadow: var(--card-box-shadow);

            .menu-item {
                display: block;
                padding: 4px 0;
                transition: color 0.2s;
                color: var(--font-color-dark);
                font-family: var(--font-family-condensed);
                white-space: nowrap;

                &:hover,
                &:focus-visible {
                    color: var(--color-primary-hover);

                    .icon {
                        color: var(--color-primary-hover);
                    }
                }

                .icon {
                    margin-right: 4px;
                    color: var(--font-color-dark);
                }
            }
        }
    }
}

</style>
