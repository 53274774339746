import AxiosRequest from "@/Services/AxiosRequest";
import {route} from "@/Utility/Helpers";

interface TourEvent {
    tour_id: string;
    tour_name: string;
    event_type: 'tour_completed';
}

export default class TrackingService {

    /**
     * Tracks a new tour event. Errors will be swallowed and logged to
     * the console.
     */
    async trackTourEvent(tourEvent: TourEvent) {
        const request = new AxiosRequest();
        const url = route('api.tracking.tour-events.create');

        try {
            await request.post(url, tourEvent);
        } catch (e) {
            this.logError(tourEvent, e);
        }
    }

    private logError(tourEvent: TourEvent, error: any) {
        const errorOutput = error instanceof Error ? error.message : error;
        console.error(`"${tourEvent.event_type}" for tour "${tourEvent.tour_id}" could not be tracked:`, errorOutput);
    }
}
