<template>
    <section class="session-list-item" @dblclick="openSession">

        <main class="session-list-item-content">
            <h3>{{ session.title }}</h3>
            <p v-if="session.description">
                {{ session.description }}
            </p>
        </main>

        <footer>
            <template v-if="hasActions">
                <div class="session-list-item-actions">

                    <!-- Btn: Edit -->
                    <ButtonCircular
                        v-tooltip="'buttons.managed_sessions.edit'"
                        :disabled="!isEditable"
                        :href="route('sessions.edit', {'managed_session': session.uid})"
                        icon="icon_arrow_right"
                    />

                    <!-- Btn: Delete -->
                    <ButtonCircular
                        v-tooltip="'buttons.managed_sessions.delete'"
                        :disabled="!isDeletable"
                        icon="icon_delete"
                        @trigger="onDeleteClick"
                    />
                </div>
                <Icon class="icon-more" name="icon_more_horizontal" />
            </template>
        </footer>

    </section>
</template>

<script lang="ts">
import {defineComponent, type PropType} from 'vue';
import type ManagedSession from '@/Models/Sessions/ManagedSession';
import Icon from '@/Vue/Common/Icon.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {route} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        ButtonCircular,
        Icon,
    },

    props: {
        session: {
            type: Object as PropType<ManagedSession>,
            required: true,
        },
    },

    emits: [
        'delete-click'
    ],

    computed: {

        hasActions() {
            return this.isEditable || this.isDeletable;
        },

        isEditable() {
            return this.$gate.allows(Permission.ability(Permission.ManagedSessionsUpdate()), this.session);
        },

        isDeletable() {
            return this.$gate.allows(Permission.ability(Permission.ManagedSessionsDelete()), this.session);
        },

    },

    methods: {
        route,

        onDeleteClick() {
            this.$emit('delete-click', this.session);
        },

        openSession() {
            location.href = route('sessions.edit', { managed_session: this.session.uid });
        },

    },
});
</script>

<style lang="scss" scoped>

.session-list-item {
    display: grid;
    grid-template-columns: auto 100px;
    grid-column-gap: 16px;

    background: var(--color-white);
    border-radius: var(--card-border-radius);
    padding: 16px 32px;

    transition: box-shadow 0.15s ease-in-out;

    &:first-child {
        margin-top: 15px; // To fix box-shadow being cut-off at the top
    }

    &.selected,
    &:focus,
    &:focus-within,
    &:hover {
        box-shadow: var(--card-box-shadow-hover);

        footer .icon-more {
            display: none;
        }

        footer .session-list-item-actions {
            display: flex;
        }
    }

    .session-list-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .icon-more {
            flex-basis: 12px;
            margin-right: 14px;
            width: 40px;
            height: 40px;
        }

        .session-list-item-actions {
            display: none;
            transition: opacity .1s ease;
        }
    }
}

</style>
