<template :data-loading="isLoading">
    <div class="component-collection">
        <StatisticsCardCount
            class="course-count"
            icon="icon_courses"
            :title="trans('navigation.courses')"
            :count="statisticsService.statistics?.courseCount || 0"
            :is-loading="isLoading"
        />
        <StatisticsCardCount
            class="unit-count"
            icon="icon_units"
            :title="trans('navigation.units')"
            :count="statisticsService.statistics?.unitCount || 0"
            :is-loading="isLoading"
        />
        <StatisticsCardMostUsedUnits
            class="most-used-units"
            :is-loading="isLoading"
            :units="statisticsService.statistics?.mostUsedUnitsInCourses || []"
        />
        <StatisticsCardActivities
            class="activity-chart"
            :is-loading="isLoading"
            :activities="statisticsService.statistics?.activities || []"
        />
        <StatisticsCardUsers
            class="users"
            :is-loading="isLoading"
            :users="statisticsService.statistics?.usersByRole || []"
        />
        <StatisticsCardAssetTypes
            class="asset-types"
            :is-loading="isLoading"
            :assets="statisticsService.statistics?.assetTypes || []"
        />
    </div>
</template>

<script lang="ts">

import {defineComponent, inject} from "vue";
import StatisticsCardCount from "@/Vue/Statistics/General/Cards/StatisticsCardCount.vue";
import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
import {trans} from "@/Utility/Helpers";
import {statisticsServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import StatisticsService from "@/Services/StatisticsService";
import StatisticsCardMostUsedUnits from "@/Vue/Statistics/General/Cards/StatisticsCardMostUsedUnits.vue";
import StatisticsCardAssetTypes from "@/Vue/Statistics/General/Cards/StatisticsCardAssetTypes.vue";
import StatisticsCardUsers from "@/Vue/Statistics/General/Cards/StatisticsCardUsers.vue";
import StatisticsCardActivities from "@/Vue/Statistics/General/Cards/StatisticsCardActivities.vue";

export default defineComponent({
    name: "StatisticsCards",

    components: {
        StatisticsCardActivities,
        StatisticsCardUsers,
        StatisticsCardAssetTypes,
        StatisticsCardMostUsedUnits,
        StatisticsCard,
        StatisticsCardCount
    },

    data() {
        return {
            statisticsService: inject(statisticsServiceKey) as StatisticsService,
            fetchStatisticsAbortController: null as AbortController | null,
        }
    },

    mounted() {
        if (this.statisticsService.statistics === null) {
            this.fetchStatistics();
        }
    },

    unmounted() {
        this.fetchStatisticsAbortController?.abort();
    },

    computed: {
        isLoading() {
            return this.statisticsService.isLoading;
        }
    },

    methods: {
        trans,

        fetchStatistics() {
            this.fetchStatisticsAbortController = new AbortController();
            this.statisticsService
                .index(this.fetchStatisticsAbortController.signal)
                .catch(() => {
                    if (this.fetchStatisticsAbortController?.signal?.aborted === true) {
                        return;
                    }

                    this.$root?.showErrorDialog(trans('errors.server_communication_failed'));
                });
        },
    },
});
</script>

<style lang="scss" scoped>

/* small screens */
@media (max-width: 1300px) {
    .component-collection {
        grid-template-columns: minmax(180px, 200px) minmax(180px, 200px) minmax(360px, 400px);
        grid-template-rows: 136px 192px 360px 400px;

        grid-template-areas:
            "course-count    unit-count      users"
            "most-used-units most-used-units users"
            "activity-chart  activity-chart  activity-chart"
            "asset-types asset-types asset-types";
    }
}

/* large screens */
@media (min-width: 1300px) {
    .component-collection {
        grid-template-columns: minmax(180px, 200px) minmax(180px, 200px) minmax(700px, 882px);
        grid-template-rows: 136px 192px 400px;

        grid-template-areas:
        "course-count    unit-count      activity-chart"
        "most-used-units most-used-units activity-chart"
        "users           users           asset-types";
    }
}

.component-collection {
    display: grid;
    justify-items: stretch;
    row-gap: 32px;
    column-gap: 32px;
    padding-top: 64px;

    transition: 250ms;

    .course-count {
        grid-area: course-count;
    }

    .unit-count {
        grid-area: unit-count;
    }

    .most-used-units {
        grid-area: most-used-units;
    }

    .activity-chart {
        grid-area: activity-chart;
    }

    .asset-types {
        grid-area: asset-types;
    }

    .users {
        grid-area: users;
    }
}

</style>
