import {parseDate, uuid4} from '@/Utility/Helpers';

export default class Token {

    constructor(attributes = {}) {
        this.id = attributes.id || uuid4();
        this.name = attributes.name || null;
        this.user_id = attributes.user_id || null;
        this.client_id = attributes.client_id || null;
        this.scopes = Array.from(attributes.scopes || []);
        this.revoked = (typeof attributes.revoked === 'boolean') ? attributes.revoked : false;
        this.created_at = parseDate(attributes.created_at || null);
        this.updated_at = parseDate(attributes.updated_at || null);
        this.expires_at = parseDate(attributes.expires_at || null);
        this.access_token = attributes.access_token || null;
    }

    /**
     * @returns {boolean}
     */
    get isExpired() {
        return this.expires_at.valueOf() <= Date.now().valueOf();
    }

    /**
     * @returns {boolean} True if this token has just been created and its access_token property is available.
     * Tokens fetched from the API will never have their access_token set. They must be recreated to obtain a new
     * access token.
     */
    get hasAccessToken() {
        return this.access_token !== null;
    }
}
