import {trans} from "@/Utility/Helpers/trans";
import ImageMaxDimensionsError from "@/Errors/Validation/ImageMaxDimensionsError";

type Type = 'JPG' | 'PNG';
export type ImageValidationRules = {
    maxWidth?: number,
    maxHeight?: number,
    minWidth?: number,
    minHeight?: number,
    aspectRatio?: number,
    allowedTypes?: Type[],
};

export default class ImageValidation {

    public readonly humanReadableAspectRatio?: string;
    public readonly humanReadableRequirements: string;

    constructor(private rules: ImageValidationRules) {
        if (rules.allowedTypes === undefined) {
            rules.allowedTypes = ['JPG', 'PNG'];
        }

        if (rules.aspectRatio !== undefined) {
            for (let height = 1; height < 20; ++height) {
                const width = Math.round(rules.aspectRatio * height);

                if (Math.abs(rules.aspectRatio - width / height) < 0.01) {
                    this.humanReadableAspectRatio = `${width}:${height}`;
                    break;
                }
            }
        }

        this.humanReadableRequirements = this.getHumanReadableRules();
    }

    get acceptTypes() {
        const types: string[] = [];

        if (this.rules.allowedTypes?.includes('JPG')) {
            types.push('.jpeg', '.jpg');
        }

        if (this.rules.allowedTypes?.includes('PNG')) {
            types.push('.png');
        }

        return types.join(',');
    }

    /**
     * @param {String} imageSrc url or image data to validate
     */
    async validate(imageSrc: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.addEventListener('error', error => {
                reject(error);
            });

            image.addEventListener('load', () => {

                if (
                    this.rules.maxWidth !== undefined
                    && this.rules.maxHeight !== undefined
                    && (
                        image.naturalWidth > this.rules.maxWidth
                        || image.naturalHeight > this.rules.maxHeight
                    )) {
                    reject(
                        new ImageMaxDimensionsError(
                            trans(`validation.custom.dimensions.max`, {max: `${this.rules.maxWidth}x${this.rules.maxHeight}`})
                        )
                    )
                }

                if (
                    this.rules.minWidth !== undefined
                    && this.rules.minHeight !== undefined
                    && (
                        image.naturalWidth < this.rules.minWidth
                        || image.naturalHeight < this.rules.minHeight
                    )) {
                    reject(this.getValidationError('min', `${this.rules.minWidth}x${this.rules.minHeight}`));
                }

                if (this.rules.maxWidth !== undefined && image.naturalWidth > this.rules.maxWidth) {
                    reject(this.getValidationError('max_width', this.rules.maxWidth));
                }
                if (this.rules.maxHeight !== undefined && image.naturalHeight > this.rules.maxHeight) {
                    reject(this.getValidationError('max_height', this.rules.maxHeight));
                }
                if (this.rules.minWidth !== undefined && image.naturalWidth < this.rules.minWidth) {
                    reject(this.getValidationError('min_width', this.rules.minWidth));
                }
                if (this.rules.minHeight !== undefined && image.naturalHeight < this.rules.minHeight) {
                    reject(this.getValidationError('min_height', this.rules.minHeight));
                }
                if (this.rules.aspectRatio !== undefined && Math.round(image.naturalHeight * this.rules.aspectRatio) !== image.naturalWidth) {
                    reject(this.getValidationError('ratio', this.humanReadableAspectRatio));
                }

                resolve();
            });

            image.src = imageSrc;
        });
    }

    private getValidationError(key: string, value?: number | string): Error {
        let replacements = {};

        if (value !== undefined) {
            replacements[key] = value;
        }

        if (['max', 'max_width', 'max_height'].includes(key)) {
            return new ImageMaxDimensionsError(trans(`validation.custom.dimensions.${key}`, replacements));
        }

        return new Error(trans(`validation.custom.dimensions.${key}`, replacements));
    }

    private getHumanReadableRules(): string {
        let rules = this.rules.allowedTypes!.join(', ');

        if (this.humanReadableAspectRatio !== undefined) {
            rules += ` – ${this.humanReadableAspectRatio}`;
        }

        if (this.rules.minWidth !== undefined || this.rules.minHeight !== undefined) {
            rules += ` – min. ${this.rules.minWidth || '?'}x${this.rules.minHeight || '?'}px`;
        }

        if (this.rules.maxWidth !== undefined || this.rules.maxHeight !== undefined) {
            rules += ` – max. ${this.rules.maxWidth || '?'}x${this.rules.maxHeight || '?'}px`;
        }

        return rules;
    }
}
