<template>

    <div v-if="course.auto_enrollment" class="property property-users">
        <header>{{ trans('labels.enrolled_for_learning') }}</header>
        <p class="info-changes-visible-immediately">
            {{ trans('courses.index.enrolled_users_auto') }}
        </p>

        <ButtonSecondary
            v-if="canChangeAutoEnrollment"
            caption="labels.auto_enrollment_disable"
            class="auto-enrollment-enable"
            @trigger="onDisableAutoEnrollment"
        />
    </div>

    <div v-else class="property property-users">
        <header>{{ trans('labels.enrolled_for_learning') }}</header>

        <ul v-if="enrolledUsers && enrolledUsers.length >= 1">
            <li
                v-for="(user, index) in enrolledUsers"
                :key="'enrolledUsers'+index+'_'+course.uid+'_'+user.uid"
                class="assigned-user"
            >
                <span class="image">
                    <img :src="user.image" alt="">
                </span>
                <section class="info">
                    <h4 class="name">
                        {{ user.firstname }} {{ user.lastname }}
                    </h4>
                    <p class="email">
                        {{ user.email }}
                    </p>
                </section>
                <span v-if="canDisenrollUsers" class="remove-btn">
                    <Icon class="icon-delete" name="icon_delete" @click="onClickRemoveUser(user)" />
                </span>

                <!-- Delete confirm overlay -->
                <OverlayConfirmRemove
                    v-if="isRemoveConfirmDialogVisible[user.uid] === true"
                    @cancel="onCancelUserRemove(user)"
                    @confirm="onConfirmUserRemove(user)"
                />
            </li>
        </ul>

        <p v-else-if="!canEnrollOrUnenrollUsers" class="no-users">
            {{ trans('courses.index.no_users_to_enroll') }}
        </p>

        <div
            v-if="canEnrollOrUnenrollUsers"
            class="buttons"
        >
            <ButtonPrimary
                v-if="shouldShowEnrollUsersButton"
                caption="labels.enroll_users"
                class="assign-users"
                icon="icon_add"
                @trigger="onClickBtnEnrollUser"
            />

            <ButtonSecondary
                v-if="shouldShowDisenrollUsersButton"
                caption="labels.remove_all"
                class="assign-users"
                @trigger="onClickBtnRemoveAllUsers"
            />
        </div>

        <ButtonSecondary
            v-if="canChangeAutoEnrollment"
            caption="labels.auto_enrollment_enable"
            class="auto-enrollment-enable"
            @trigger="onEnableAutoEnrollment"
        />
    </div>
</template>

<script lang="ts">

// Import VueJS components:
import OverlayConfirmRemove from '@/Vue/Inspector/OverlayConfirmRemove.vue';
import Icon from '@/Vue/Common/Icon.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';

// Import classes:
import {Permission} from '@/Models/User/Permission';
import EventType from '@/Utility/EventType';
import Course from '@/Models/Course/Course';
import {defineComponent, inject} from 'vue';
import {courseServiceKey, userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import {trans} from '@/Utility/Helpers';
import type User from '@/Models/User/User';

export default defineComponent({

    components: {
        ButtonSecondary,
        ButtonPrimary,
        Icon,
        OverlayConfirmRemove,
    },

    props: {
        course: {
            type: Course,
            required: true
        },
    },

    data() {
        return {
            // Global CourseService instance
            courseService: inject(courseServiceKey)!,

            // Global UserService instance
            userService: inject(userServiceKey)!,

            // Whether the remove confirmation dialog is visible for a specific user
            isRemoveConfirmDialogVisible: {},
        };
    },

    computed: {

        canChangeAutoEnrollment() {
            return this.isAllowedToEnrollUsers && !this.course.parsedPolicy?.requiresAutoEnrollment;
        },

        isAllowedToEnrollUsers() {
            return this.$gate.allows(Permission.ability(Permission.CoursesEnrollUsers()), this.course);
        },

        canDisenrollUsers() {
            return this.isAllowedToEnrollUsers;
        },

        canEnrollOrUnenrollUsers() {
            return this.shouldShowEnrollUsersButton || this.shouldShowDisenrollUsersButton;
        },

        shouldShowEnrollUsersButton() {
            return this.isAllowedToEnrollUsers && this.unenrolledUsers.length >= 1;
        },

        shouldShowDisenrollUsersButton() {
            return this.isAllowedToEnrollUsers && this.hasEnrolledUsers;
        },

        /**
         * Does the training have any assigned users?
         */
        hasEnrolledUsers(): boolean {
            return this.course.hasEnrolledUsers;
        },

        /**
         * Get assigned users for the training
         */
        enrolledUsers(): User[] {
            if (this.course !== null) {
                return this.userService.getEnrolledUsersForCourse(this.course);
            }
            return [];
        },

        /**
         * Get unenrolled users for the course
         */
        unenrolledUsers(): User[] {
            return this.userService.getUnenrolledUsersForCourse(this.course);
        },

    },

    mounted() {
        this.$globalEvents.on(
            EventType.MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_APPLY,
            this.onApplyRemoveEnrollmentsFromCourse
        );
        this.$globalEvents.on(EventType.SIDEPANEL_USERS_ASSIGN, this.onEnrollUsersToCourse);
    },

    beforeUnmount() {
        this.$globalEvents.off(
            EventType.MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_APPLY,
            this.onApplyRemoveEnrollmentsFromCourse
        );
        this.$globalEvents.off(EventType.SIDEPANEL_USERS_ASSIGN, this.onEnrollUsersToCourse);
    },

    methods: {
        trans,

        /**
         * Click handler for assigning users button
         */
        onClickBtnEnrollUser(_: MouseEvent) {
            // Hide any remove confirm dialogs:
            this.isRemoveConfirmDialogVisible = {};
            // Clear the selected state:
            this.userService.users.forEach(u => u.selected = false);
            // Show the sidepanel:
            this.$globalEvents.emit(EventType.SIDEPANEL_USERS_SHOW);
            return this;
        },

        /**
         * Click handler for remove all users button
         */
        onClickBtnRemoveAllUsers(_: MouseEvent) {
            // Hide any remove confirm dialogs:
            this.isRemoveConfirmDialogVisible = {};
            // Show the modal dialog:
            this.$globalEvents.emit(EventType.MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_SHOW);
            return this;
        },

        /**
         * Click handler for user remove buttons
         */
        onClickRemoveUser(user: User) {
            this.isRemoveConfirmDialogVisible = {};    // Only allow one open dialog at the same time
            this.isRemoveConfirmDialogVisible[user.uid] = true;
            return this;
        },

        /**
         * Confirm handler for user remove confirmation dialog
         */
        onConfirmUserRemove(user: User) {
            delete this.isRemoveConfirmDialogVisible[user.uid];

            this.sendEnrolledUsersToApi(null, [user]);
        },

        /**
         * Cancel handler for asset remove confirmation dialog
         */
        onCancelUserRemove(user: User) {
            delete this.isRemoveConfirmDialogVisible[user.uid];
            return this;
        },

        /**
         * Click handler for remove all users dialog apply button
         */
        onApplyRemoveEnrollmentsFromCourse() {
            this.sendEnrolledUsersToApi(null, this.enrolledUsers);
        },

        /**
         * Assign users to selected training
         */
        onEnrollUsersToCourse(users: User[]) {
            this.sendEnrolledUsersToApi(users);
        },

        onEnableAutoEnrollment() {
            this.saveAutoEnrollment(true);
        },

        onDisableAutoEnrollment() {
            this.saveAutoEnrollment(false);
        },

        async saveAutoEnrollment(autoEnrollment: boolean) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

            return this.courseService
                .changeUserEnrollments(this.course, [], [], autoEnrollment)
                .catch(error => {
                    this.$root!.showErrorDialog(error);
                })
                .finally(() => {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                });
        },

        /**
         * Syncs the given user assignments with the API and handles errors in the process.
         * User enrollments will be synced to the course property and propagated on success.
         */
        async sendEnrolledUsersToApi(usersToEnroll: User[] | null = null, usersToDisenroll: User[] | null = null) {
            usersToEnroll = usersToEnroll || [];
            usersToDisenroll = usersToDisenroll || [];

            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

            return this.courseService
                .changeUserEnrollments(this.course, usersToEnroll, usersToDisenroll)
                .then(() => {
                    this.$globalEvents.emit(EventType.USER_ASSIGNMENT_USERS_CHANGED, this.course);
                })
                .catch(error => {
                    this.$root!.showErrorDialog(error);
                })
                .finally(() => {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                });
        }
    }
});

</script>


<style lang="scss" scoped>

</style>
