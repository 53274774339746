<template>
    <Modal
        ref="modal"
        event-type="MODAL_CONFIRM_MODEL_CONVERSION_ISSUES"
        :title="trans('modals.confirm_model_conversion_issues.title')"
        :show-close-button="false"
        :user-closable="false"
        @show="onShow"
    >
        <p class="description" v-html="trans('modals.confirm_model_conversion_issues.description')"></p>
        <Checkbox
            layout="checkbox-right"
            :caption="trans('modals.confirm_model_conversion_issues.remember')"
            :model="this"
            property="remember"
        />
        <template #buttons>
            <ButtonPrimary
                class="btn-apply"
                @trigger="onClickApply"
                caption="modals.confirm_model_conversion_issues.apply"/>
        </template>
    </Modal>
</template>

<script lang="ts">

import Checkbox from '@/Vue/Common/Checkbox.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {getCookie, setCookie} from 'typescript-cookie';
import {trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent, nextTick} from 'vue';

const cookieNameIncompatibilityNotification = 'converter3d_dont_show_incompatibility_notification';

const getsDoNotShowAgainFromUserCookie = () => {
    if (!window.currentUser) {
        throw new Error('window.currentUser is not set');
    }

    const cookie = JSON.parse(getCookie(cookieNameIncompatibilityNotification) || '[]');
    return (cookie instanceof Array && cookie.includes(window.currentUser.uid));
};

const saveDoNotShowAgainInUserCookie = () => {
    if (!window.currentUser) {
        throw new Error('window.currentUser is not set');
    }

    let cookie = JSON.parse(getCookie(cookieNameIncompatibilityNotification) || '[]');
    if (!(cookie instanceof Array)) {
        cookie = [window.currentUser.uid];
    } else if (!cookie.includes(window.currentUser.uid)) {
        cookie.push(window.currentUser.uid);
    }
    setCookie(cookieNameIncompatibilityNotification, JSON.stringify(cookie), {sameSite: 'strict'});
};

export default defineComponent({
    components: {
        Modal,
        ButtonPrimary,
        Checkbox
    },

    data() {
        return {
            remember: getsDoNotShowAgainFromUserCookie(),
        }
    },

    methods: {
        trans,

        onShow() {
            // Don't show the dialog if the checkbox is checked already:
            if (this.remember) {
                nextTick(() => this.modal.hide());
            }
        },

        onClickApply() {
            // Save remember state in session:
            if (this.remember) {
                saveDoNotShowAgainInUserCookie();
            }

            this.$globalEvents.emit(EventType.MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_APPLY);
            this.modal.hide();
        },
    },

    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    }
});
</script>

<style lang="scss" scoped>

dialog {
    width: 500px;

    .description {
        margin-bottom: 30px;
    }
}

</style>
