import InstructionPayload from '@/Models/Sessions/InstructionPayload';
import type {InstructionType} from '@/Models/Sessions/InstructionType';

export default class InstructionPayloadUnitStop extends InstructionPayload {

    static type: InstructionType = 'unit_stop';

    constructor(attributes: any = {}) {
        super(attributes);
    }
}
