<template>
    <main id="layout-main" :data-loading="isLoading">

        <PageHeader
            :page-title="trans('users.index.headline')"
            :buttons="headerButtons"
            @button-click="onHeaderButtonClick"
        />

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <div class="container user-list-filters-wrapper">
                    <ButtonPrimary
                        v-if="canCreateUsers"
                        :href="route('users.createForm')"
                        caption="labels.create_user"
                        class="btn-add-new"
                        icon="icon_add"
                    />
                </div>
                <div class="container">
                    <div class="user-list">
                        <UserListItem
                            v-for="user in users"
                            :key="user.uid"
                            :user="user"
                            :tenant="currentUser.tenant!"
                            v-focusable
                            @click-remove="onClickRemoveUser"
                            @click-delete="onClickDeleteUser"
                        />
                    </div>
                </div>
                <div
                    v-if="canAddUsersToTeam"
                    class="container"
                >
                    <AddUserToTenant :tenant-roles="tenantRoles" />
                </div>
            </div>

            <ModalProgress/>
            <ModalNotification/>

            <ModalApplyCancel
                event-type="MODAL_REMOVE_USER"
                :title="trans('users.remove.modals.confirm.title')"
                :description="removeUserModalDescription"
                apply-text="users.remove.modals.confirm.apply"
            />
            <ModalDeleteUser />

            <ModalImportUserCsv/>
            <ModalImportUsers/>

        </div>
    </main>
</template>

<script lang="ts">

    import EventType from '@/Utility/EventType';
    import AuthorizationError from '@/Errors/AuthorizationError';
    import {permission, route, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import {defineComponent, inject} from 'vue';
    import {featureRepositoryKey, tenantServiceKey, userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
    import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
    import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
    import PageHeader from '@/Vue/Common/PageHeader.vue';
    import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
    import UserListItem from '@/Vue/Users/UserListItem.vue';
    import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
    import PageHeaderButton from '@/Utility/PageHeaderButton';
    import ModalImportUserCsv from '@/Vue/Modals/ModalSelectImportUserCsv.vue';
    import ModalImportUsers from '@/Vue/Modals/ModalImportUsers.vue';
    import AddUserToTenant from '@/Vue/Users/AddUserToTenant.vue';
    import {Feature} from '@/Models/Features/Feature';
    import User from '@/Models/User/User';
    import ModalDeleteUser from '@/Vue/Modals/ModalDeleteUser.vue';

    export default defineComponent({

        components: {
            ModalDeleteUser,
            AddUserToTenant,
            ModalImportUsers,
            ModalImportUserCsv,
            ModalProgress,
            UserListItem,
            ButtonPrimary,
            PageHeader,
            ModalNotification,
            ModalApplyCancel,
        },

        props: {
            tenantRoles: {
                type: Array,
                default: [],
            },
        },

        data() {
            return {
                userService: inject(userServiceKey)!,
                tenantService: inject(tenantServiceKey)!,
                featureRepository: inject(featureRepositoryKey)!,
                isSubmitting: false,
                removeUserModalDescription: "",
                events: new Map([
                    [EventType.MODAL_REMOVE_USER_APPLY, this.onApplyConfirmRemove],
                    [EventType.MODAL_DELETE_USER_APPLY, this.onApplyConfirmDelete],
                ]),
            }
        },

        mounted() {
            this.fetchUsers();
            this.events.forEach((value, key) => {
                this.$globalEvents.on(key, value);
            });
        },

        beforeUnmount() {
            this.events.forEach((value, key) => {
                this.$globalEvents.off(key, value);
            });
        },

        computed: {

            currentUser() {
                return window.currentUser!;
            },

            canAddUsersToTeam() {
                return (
                    this.featureRepository.active(Feature.FeatureInviteUsersToTeam)
                    && (
                        permission(Permission.TenantsAddUsers())
                        || permission(Permission.TenantInvitationsCreate())
                    )
                );
            },

            canCreateUsers() {
                return permission(Permission.UsersCreate());
            },

            canExportUsers() {
                return permission(Permission.UsersExport());
            },

            canImportUsers() {
                return permission(Permission.UsersImport());
            },

            users() {
                return this.userService.users;
            },

            isLoading(): boolean {
                if (this.userService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * List of button configurations for the page header
             */
            headerButtons() {
                return {
                    importUsers: new PageHeaderButton({
                        disabled: this.isLoading,
                        visible: this.canImportUsers,
                        caption: trans('labels.import'),
                        icon: 'icon_import',
                        style: 'icon+text',
                        callback: this.importUserCSV,
                    }),
                    exportUsers: new PageHeaderButton({
                        disabled: this.isLoading,
                        visible: this.canExportUsers,
                        caption: trans('labels.export'),
                        icon: 'icon_export',
                        style: 'icon+text',
                        callback: this.downloadExportedUsersCSV,
                    }),
                };
            },
        },

        methods: {
            trans,
            route,

            fetchUsers() {
                this.userService
                    .fetchUsers()
                    .catch(this.onErrorApi);
            },

            onErrorApi(error: any) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root?.forceLogout;
                }

                this.$root?.showErrorDialog(error);
            },

            onHeaderButtonClick(buttonConfig: PageHeaderButton) {
                buttonConfig.callback?.call(this, buttonConfig);
            },

            importUserCSV() {
                this.$globalEvents.emit(EventType.MODAL_SELECT_IMPORT_USER_CSV_SHOW);
            },

            downloadExportedUsersCSV() {
                if (this.canExportUsers) {
                    this.userService
                        .downloadExportedUsersCSV()
                        .catch(this.onErrorApi);
                }
            },

            onClickRemoveUser(user: User) {
                if (this.isSubmitting) {
                    return;
                }

                // Update description
                if (user.belongsToMultipleTenants()) {
                    this.removeUserModalDescription = trans('users.remove.modals.confirm.description', {'firstname': user!.firstname!, 'lastname': user!.lastname!, 'email': user.email});
                } else {
                    this.removeUserModalDescription = trans('users.remove.modals.confirm.description_last_team', {'firstname': user!.firstname!, 'lastname': user!.lastname!, 'email': user.email});
                }

                this.$globalEvents.emit(EventType.MODAL_REMOVE_USER_SHOW, user);
                return this;
            },

            onClickDeleteUser(user: User) {
                if (this.isSubmitting) {
                    return;
                }

                this.$globalEvents.emit(EventType.MODAL_DELETE_USER_SHOW, user);
                return this;
            },

            onApplyConfirmRemove(user: User) {
                if (this.tenantService.isDeleting || this.tenantService.isLoading || this.tenantService.isSaving) {
                    return;
                }

                this.isSubmitting = true;
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('users.remove.modals.removing.title'));

                this.tenantService.removeUser(window.currentUser!.tenant!, user)
                    .then(() => {
                        // Force logout if the current user was removed, otherwise redirect to users list:
                        window.location.href = route((user.uid === window.currentUser?.uid) ? 'logout' : 'users.index');
                    })
                    .catch((error) => {
                        this.isSubmitting = false;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.$root!.showErrorDialog(error);
                    });
            },

            onApplyConfirmDelete(user: User) {
                if (this.tenantService.isDeleting || this.tenantService.isLoading || this.tenantService.isSaving) {
                    return;
                }

                this.isSubmitting = true;
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('users.delete.modals.deleting.title'));

                this.userService.deleteUser(user.uid)
                    .then(() => {
                        // Force logout if the current user was deleted, otherwise redirect to users list:
                        window.location.href = route((user.uid === window.currentUser?.uid) ? 'logout' : 'users.index');
                    })
                    .catch((error) => {
                        this.isSubmitting = false;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.$root!.showErrorDialog(error);
                    });
            },
        }
    });
</script>

<style lang="scss" scoped>
    .user-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 30px;
    }

    #layout-main {
        :deep(.add-user-to-tenant) {
            max-width: 450px;
        }
    }
</style>
