import {isEqual} from 'lodash';
import DropdownOption from '@/Utility/DropdownOption';
import {isUUID} from "@/Utility/Helpers";

export default class DropdownOptionGroup
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.value = attributes.value || null;                                              // Group value
        this.caption = attributes.caption || null;                                          // Group caption text
        this.disabled = (typeof attributes.disabled === 'boolean') ? attributes.disabled : false; // Disabled state
        this.icon = attributes.icon || null;                                                // Icon identifier
        this.options = (attributes.options || []).map(o => new DropdownOption(o));          // List of DropdownOptions
        this.collapsible = (typeof attributes.collapsible === 'boolean') ? attributes.collapsible : false; // Whether the group can be collapsed
        this.autoCollapseLimit = (typeof attributes.autoCollapseLimit === 'number' && attributes.autoCollapseLimit >= 0) ? attributes.autoCollapseLimit : 10;   // Number of options before the group will be collapsed automatically
        this.showGroupNameInCaption = (typeof attributes.showGroupNameInCaption === 'boolean') ? attributes.showGroupNameInCaption : true; // Whether add the group name to the caption of the selected dropdown value
        this.title = attributes.title !== undefined ? attributes.title : null;              // Title for option tooltip
        this.isSeparator = (typeof attributes.isSeparator === 'boolean') ? attributes.isSeparator : true;   // Whether the group title should be displayed as a separator item
        this.referenceUid = attributes.referenceUid || null;                                // Optional UID of a referenced object
    }

    get referencedObjectUid() {
        return this.referenceUid || this.value?.uid || (isUUID(this.value) ? this.value : null) || null;
    }

    /**
     * Check if one of the options has a specific value
     *
     * @param {String} value
     * @returns {DropdownOption|Null}
     */
    findOptionByValue(value) {

       if (typeof value === 'object') {
           return (this.options.length >= 1) ? this.options.find(o => isEqual(o.value, value)) || null : null;
       }
       return (this.options.length >= 1) ? this.options.find(o => o.value === value) || null : null;
    }
}
