export default class PagingMetadata {

    /**
     * Current page. Index is based on 1.
     */
    public readonly current_page: number

    /**
     * Last page available. Index is based on 1.
     */
    public readonly last_page: number

    constructor(attributes: any = {}) {
        this.current_page = attributes.current_page || 1;
        this.last_page = attributes.last_page || 1;
    }

}
