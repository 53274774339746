<template>
    <div class="variable-property-dropdown">
        <Dropdown
            :label="trans('variables.values.value')"
            :model="variable"
            property="value"
            @select="onChangeVariable"
            :options="targetOptionsForBoolVariable"
            :deselected-caption="trans('variables.select_value')"
            class="no-wrap"
        />
    </div>
</template>

<script>
import Variable                                from '@/Models/UnitData/Variables/Variables/Variable';
import { getTargetOptionsForBoolVariable }     from '@/Models/UnitData/Variables/VariableHelpers';

export default {
    name: 'PanelVariableBool',
    emits: [
        'change',
    ],
    props: {
        variable: {                      // The Variable object to be edited
            type: Variable,
            default: null
        }
    },
    computed: {

        targetOptionsForBoolVariable() {
            return getTargetOptionsForBoolVariable();
        },
    },
    methods: {

        /**
         *  Change handler for the variable
         */
        onChangeVariable() {
            this.$emit('change', this.variable);

            return this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
