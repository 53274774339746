<template>
    <tr
        :class="selected ? 'selected' : ''"
        @click="selectItem"
    >

        <td class="preview-cell">
            <span v-if="supportsAssetPreviewModal" class="preview preview-image-pointer preview-cell-image-wrapper" @click.stop.prevent="showAssetPreview">
                <Icon :name="asset.assetType.previewIcon" />
                <img :src="asset.thumbnail" :alt="asset.title" />
            </span>
            <span v-else class="preview preview-cell-image-wrapper">
                <img :src="asset.thumbnail" :alt="asset.title">
            </span>
        </td>

        <td class="asset-info-cell">
            <div class="asset-info">
                <h4 class="asset-info-title" :title="formatTitleForTitleAttribute(asset.title)">{{ asset.title }}</h4>
                <p class="asset-info-description" :title="formatDescriptionForTitleAttribute(asset.description)">{{ asset.description }}</p>
            </div>
        </td>

        <td>
            <span class="cell-title">Last Updated</span>
            <span class="cell-description">{{ moment(asset.updated_at).format('DD.MM.YYYY') }}</span>
        </td>

        <td class="asset-type-cell">
            <span class="cell-title">Asset Type</span>
            <span class="cell-description">{{ trans('assets.types.' + asset.type) }}</span>
        </td>

        <td v-if="showAddToLibraryButton" class="asset-add-to-library-button-cell">
            <ButtonPrimary
                v-if="asset.isFromFreeLibrary"
                class="small fullwidth"
                :caption="asset.isInUserLibrary ? trans('labels.added') : trans('labels.add_to_library')"
                :disabled="!canAssetBeAddedToLibrary"
                @trigger="onClickAddToLibraryButton" />
        </td>

    </tr>
</template>

<script>
    import Asset                    from '@/Models/Asset/Asset';
    import EventType                from '@/Utility/EventType';
    import {Permission}             from "@/Models/User/Permission";

    export default {
        name: 'AssetListItem',

        emits: [
            'click',
            'addToLibrary'
        ],

        props: {
            asset: {              // Caption string that should be translated
                type: Asset,
                default: null
            },
            selected: {
                type: Boolean,
                default: false,
            },
            showAddToLibraryButton: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            /**
             * Check if the asset itself can be previewed or if it has a preview image
             */
            supportsAssetPreviewModal() {
                return this.asset.supportsPreview || this.asset.hasPreviewImage;
            },

            canAssetBeAddedToLibrary() {
                return (
                    !this.asset.isInUserLibrary
                    && this.$gate.allows(Permission.ability(Permission.AssetsAddToLibrary()), this.asset)
                );
            },
        },

        methods: {

            /**
             * Format description text
             *
             * @param {String} description
             * @returns {String}
             */
            formatDescription(description) {
                if (typeof description !== 'string') {
                    return '';
                }
                return (description.length > 50) ? description.substr(0, 50) + '...' : description;
            },

            /**
             * Format description text for title attribute
             *
             * @param {String} description
             * @returns {String|null}
             */
            formatDescriptionForTitleAttribute(description) {
                return (typeof description === 'string' && description.length > 40) ? description : null;
            },

            /**
             * Format title text for title attribute
             *
             * @param {String} title
             * @returns {String|null}
             */
            formatTitleForTitleAttribute(title) {
                return (typeof title === 'string' && title.length > 40) ? title : null;
            },

            /**
             * Click handler
             */
            selectItem() {
                this.$emit('click', this.asset);
                return this;
            },

            showAssetPreview() {
                if (this.supportsAssetPreviewModal)
                {
                    this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_SHOW, this.asset);
                }
                return this;
            },

            onClickAddToLibraryButton() {
                this.$emit('addToLibrary', this.asset);
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
