import { trans } from '@/Utility/Helpers';
import FilterCategory from "@/Filters/FilterCategory";

export default class UnitType
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('UnitType->constructor(): Invalid data.', attributes);
            throw new TypeError('UnitType->constructor(): Property "title" has to be set on UnitType.');
        }
        if (typeof attributes.type !== 'string' || UnitType.isValidType(attributes.type) === false)
        {
            console.warn('UnitType->constructor(): Invalid data.', attributes);
            throw new TypeError('UnitType->constructor(): Property "type" has to be set on UnitType. Must be a valid type from UnitType class.');
        }
        if (attributes.description === null || typeof attributes.description !== 'string' || attributes.description.length === 0)
        {
            console.warn('UnitType->constructor(): Invalid data.', attributes);
            throw new TypeError('UnitType->constructor(): Property "description" has to be set on UnitType.');
        }
        if (typeof attributes.icon !== 'string' || attributes.icon.length === 0)
        {
            console.warn('UnitType->constructor(): Invalid data.', attributes);
            throw new TypeError('UnitType->constructor(): Property "icon" has to be set on UnitType.');
        }

        // Populate the model:
        this.type = attributes.type;                                        // Type identifier
        this.icon = attributes.icon;                                        // Icon identifier
        this.title = attributes.title;                                      // Translated title of this UnitType (e.g. 'Virtual Reality')
        this.description = attributes.description || null;                  // Translated description of this UnitType
    }

    /**
     * @returns {FilterCategory}
     */
    get filterCategory()
    {
        return new FilterCategory({
            title: this.title,
            callback: (elements) => elements.filter(e => e?.type === this.type),
            paramName: this.type,
        });
    }

    /**
     * UnitType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {UnitType}
     */
    static get AR()                { return StaticUnitTypes.AR; }
    static get ThreeSixty()        { return StaticUnitTypes.ThreeSixty; }
    static get VR()                { return StaticUnitTypes.VR; }

    /**
     * Get all types as an array
     *
     * @returns {UnitType[]}
     */
    static get all()
    {
        return Object.values(StaticUnitTypes);
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in UnitTypes)
        {
            if (UnitTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }

    /**
     * Get UnitType by a given type name
     *
     * @param {String} type
     * @returns {UnitType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }
}

/**
 * Type definitions
 */
const StaticUnitTypes = {};
const UnitTypes = {

    AR: {
        type: 'ar',
        icon: 'icon_ar',
        title: trans('unittypes.ar'),
        description: trans('unittypes.ar_description'),
    },

    ThreeSixty: {
        type: '360',
        icon: 'icon_360deg',
        title: trans('unittypes.360'),
        description: trans('unittypes.360_description'),
    },

    VR: {
        type: 'vr',
        icon: 'icon_vr',
        title: trans('unittypes.vr'),
        description: trans('unittypes.vr_description'),
    }
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in UnitTypes)
{
    StaticUnitTypes[i] = new UnitType(UnitTypes[i]);
}
