import {permission} from '@/Utility/Helpers';
import User from "@/Models/User/User";

export default class Gate {

    private readonly user: User|null;
    private readonly policies: { [key: string]: object } = {};

    /**
     * The Gate class can be used to check simple permissions a user has or more complex permissions that are implemented
     * via a policy. Policies need to be registered by mapping a Policy class and a corresponding model.
     *
     * @example
     * const gate = new Gate('currentUser'); // will map to window.currentUser
     *
     * @param user Name of the property on the window object that holds the object
     */
    constructor(user: string = 'currentUser') {

        // @NOTE: There is no user object when the user is logged out (e.g. guest on the login page)
        this.user = window[user] || null;
    }

    /**
     * Register a policy
     *
     * @example
     * window.gate.policy('Unit', new UnitPolicy);
     */
    policy(modelName: string, policy: object): void {
        this.policies[modelName] = policy;
    }

    /**
     * Determine whether the user can perform the action on the model.
     *
     * @example
     * // Check if a user is allowed to perform an action. Can be a simple check for a user permission or a complex check
     * // implemented via a policy.
     * // To be able to determine which Policy class should be used, a model that is mapped the policy needs to be
     * // passed as the second argument. The model can be a string if the policy does not need a model instance to
     * // determine a result.
     * this.$gate.allows(Permission.ability(Permission.UnitsRead()), unit);
     *
     * @param action A permission string or the name of an ability that exists in a policy
     * @param model A model instance or the name of a model that is mapped to a policy
     * @param args Additional arguments that will be passed to the policy method
     */
    allows(action: string, model: object | string | undefined | null = undefined, ...args: any[]): boolean {

        if (this.user === null || model === null) {
            return false;
        }

        if (model === undefined) {
            return permission(action);
        }

        const type = (typeof model === 'object') ? model.constructorName : model;
        const userAndPolicyExist = this.user && this.policies.hasOwnProperty(type);

        if (!type) {
            console.error('Gate->allows(): Invalid model.', action, model);
            throw new Error("Type of the given model could not be determined. " +
                "Did you forget to define `constructorName` on your model?");
        }

        if (userAndPolicyExist && typeof this.policies[type][action] === 'function') {
            // If the model is not an object it is only used to map the permission to a specific policy.
            // In that case we do not pass the model to the policy. This is the same way laravel handles this.
            if (typeof model === 'object') {
                return this.policies[type][action](this.user, model, ...args);
            }
            return this.policies[type][action](this.user, ...args);
        }

        return false;
    }

    /**
     * Determine whether the user can't perform the action on the model.
     *
     * @example
     * this.$gate.denies(Permission.ability(Permission.UnitsRead()), unit);
     *
     * @param action A permission string or the name of an ability that exists in a policy
     * @param model A model instance or the name of a model that is mapped to a policy
     * @param args Additional arguments that will be passed to the policy method
     */
    denies(action: string, model: object | string | undefined | null = undefined, ...args: any[]): boolean {
        return !this.allows(action, model, ...args);
    }
}
