import type Asset from '@/Models/Asset/Asset';
import AssetType from '@/Models/Asset/AssetType';
import {cloneDeep} from 'lodash';
import {SoundAsset} from '@/Models/Asset/Assets/SoundAsset';
import {TtsSoundAsset} from '@/Models/Asset/Assets/TtsSoundAsset';
import {EnvironmentModel3DAsset} from '@/Models/Asset/Assets/EnvironmentModel3DAsset';
import {CharacterModel3DAsset} from '@/Models/Asset/Assets/CharacterModel3DAsset';
import {TextAsset} from '@/Models/Asset/Assets/TextAsset';
import {UnknownAsset} from '@/Models/Asset/Assets/UnknownAsset';
import {ImageAsset} from '@/Models/Asset/Assets/ImageAsset';
import {EnvironmentVideoAsset} from '@/Models/Asset/Assets/EnvironmentVideoAsset';
import {EnvironmentImageAsset} from '@/Models/Asset/Assets/EnvironmentImageAsset';
import {VideoAsset} from '@/Models/Asset/Assets/VideoAsset';
import {Model3DAsset} from '@/Models/Asset/Assets/Model3DAsset';

export default class AssetFactory {

    /**
     * AssetType to Asset subclass mapping.
     */
    private static getAssetClassFromType(type: string): (new(attributes: any) => Asset) | undefined {
        const assetMapping = new Map([
            [AssetType.Unknown.type, UnknownAsset],
            [AssetType.CharacterModel3D.type, CharacterModel3DAsset],
            [AssetType.Image.type, ImageAsset],
            [AssetType.EnvironmentImage.type, EnvironmentImageAsset],
            [AssetType.Sound.type, SoundAsset],
            [AssetType.SoundTts.type, TtsSoundAsset],
            [AssetType.Video.type, VideoAsset],
            [AssetType.EnvironmentVideo.type, EnvironmentVideoAsset],
            [AssetType.Model3D.type, Model3DAsset],
            [AssetType.EnvironmentModel3D.type, EnvironmentModel3DAsset],
            [AssetType.Text.type, TextAsset],
        ]);

        return assetMapping.get(type);
    }

    /**
     * Create a new asset from the given attributes
     */
    static createFromAttributes(attributes: any): Asset {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        const className = this.getAssetClassFromType(clonedAttributes.type);

        if (className === undefined) {
            throw new Error(`Unknown asset type ${clonedAttributes.type}. Cannot create from attributes.`);
        }

        return new className(clonedAttributes);
    }
}
