import OverlayContent from '@/Models/Unity/OverlayContent';

export default class OverlayContentUtility
{
    /**
     * Cast an overlay content element
     *
     * @param {Object} contentData
     * @param {Object} parent               // Parent object reference
     * @returns {OverlayContent|Object}
     */
    static castContent(contentData = null, parent = null)
    {
        if (contentData instanceof Object && typeof contentData.type === 'string')
        {
            if (OverlayContent.isValidType(contentData.type) === true)
            {
                return new OverlayContent(contentData, parent);
            }
            console.warn('OverlayContentUtility->castContent(): Unknown content type "' + contentData.type + '"');
            return contentData;  // Leaving unknown content data as is
        }
        console.warn('OverlayContentUtility->castContent(): Invalid content data', contentData);
        return contentData;   // Leaving unknown content data as is
    }
}
