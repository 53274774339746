import AppPanelConfiguration from '@/Models/UnitData/Configuration/AppPanelConfiguration';
import ColorConfiguration from '@/Models/UnitData/Configuration/ColorConfiguration';
import InputConfiguration from '@/Models/UnitData/Configuration/InputConfiguration';
import NetworkConfiguration from '@/Models/UnitData/Configuration/NetworkConfiguration';

export default class TrainingConfiguration
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Make sure attributes is always an object:
        if (attributes instanceof Object === false || attributes instanceof Array)
        {
            attributes = {};
        }

        // Populate the model:
        this.app_panel = new AppPanelConfiguration(attributes.app_panel || null);   // AppPanelConfiguration
        this.colors = new ColorConfiguration(attributes.colors || null);            // ColorConfiguration
        this.input = new InputConfiguration(attributes.input || null);              // InputConfiguration
        this.network = new NetworkConfiguration(attributes.network || null);        // NetworkConfiguration
    }

    /**
     * Duplicate
     *
     * @returns {TrainingConfiguration}
     */
    duplicate() {
        const duplicated = new TrainingConfiguration(this);

        // Create new instances for child objects:
        duplicated.app_panel = (this.app_panel !== null) ? this.app_panel.duplicate() : null;
        duplicated.colors = (this.colors !== null) ? this.colors.duplicate() : null;
        duplicated.input = (this.input !== null) ? this.input.duplicate() : null;
        duplicated.network = (this.network !== null) ? this.network.duplicate() : null;

        return duplicated;
    }
}
