<template>
    <div class="list-filters">

        <!-- Optional buttons -->
        <slot name="buttons" />

        <!-- Categories tabs -->
        <Tabs
            v-if="categories.length"
            class="list-filters-categories"
            :tabs="categories"
            @change="onTabChanged"
        />

        <!-- Second row below with filter inputs -->
        <div class="list-filters-inputs">

            <!-- Filters -->
            <RevealButton
                v-for="multiFilter in filters"
                :caption="multiFilter.title"
                :icon="multiFilter.icon"
                :active="multiFilter.sections.some(s => s.category.active !== s.category.defaultActive)"
            >
                <template #body>
                    <Checkbox
                        v-for="filter in multiFilter.sections"
                        :key="`checkbox-filter-${multiFilter.title}-${filter.title}-${forceRenderKey}`"
                        class="no-wrap"
                        layout="checkbox-left"
                        :caption="filter.title"
                        :disabled="filter.category.disabled"
                        :model="filter.category"
                        property="active"
                        @change="onTabChanged(filter.category)"
                    />

                    <ButtonSecondary
                        :caption="getCaptionForSelectBtn(multiFilter)"
                        @trigger="onTriggerBtnSelect(multiFilter)"
                    />
                </template>
            </RevealButton>

            <ButtonSecondary
                v-if="filters.length >= 1"
                :caption="trans('labels.reset_filters')"
                @trigger="onTriggerResetFilters"
            />

            <!-- Sorting -->
            <RevealButton
                v-if="sortingFilters.length >= 1"
                :caption="trans('labels.sorting')"
                class="align-right"
                :active="sortingFilters.some(s => s.active !== s.defaultActive)"
            >
                <template #body>
                    <Dropdown
                        :key="`dropdown-filter-sortby-${forceRenderKey}`"
                        :label="trans('labels.sort_by')"
                        :options="sortingOptions"
                        :initial-value="sortingFilters.find(sf => sf.isActive) || null"
                        class="no-wrap"
                        @select="onSelectSorting"
                    />
                    <Dropdown
                        :key="`dropdown-filter-orderby-${forceRenderKey}-${sortDescending}`"
                        :label="trans('labels.order')"
                        :options="sortDescendingOptions"
                        :initial-value="sortDescending"
                        class="no-wrap"
                        @select="onSelectSortDescending"
                    />
                </template>
            </RevealButton>

            <!-- Search input -->
            <SearchInput
                ref="searchInput"
                class="list-filters-searchbar align-right"
                :filter-words="filterWords"
                :show-search-button="showSearchButton"
                @change="onChangeWordFilter"
                @cancel="onCancelSearchInput"
                @trigger-search="onTriggerSearch"
            />
        </div>

    </div>
</template>

<script>
import {shortId, trans} from "@/Utility/Helpers";
import Tabs from "@/Vue/Common/Tabs.vue";
import SearchInput from "@/Vue/Common/SearchInput.vue";
import RevealButton from "@/Vue/Common/RevealButton.vue";
import Checkbox from "@/Vue/Common/Checkbox.vue";
import DropdownOption from "@/Utility/DropdownOption";
import ButtonSecondary from "@/Vue/Common/ButtonSecondary.vue";

export default {
    name: 'ListFiltersBar',
    components: {
        ButtonSecondary,
        Checkbox,
        RevealButton,
        SearchInput,
        Tabs
    },
    emits: [
        'change',
        'cancel',
        'reset',
        'trigger-search',
    ],
    props: {
        /**
         * @type FilterCategory[]
         */
        categories: {
            type: Array,
            default: []
        },
        /**
         * @type MultiFilterCategory[]
         */
        filters: {
            type: Array,
            default: []
        },
        /**
         * @type FilterCategory[]
         */
        sortingFilters: {
          type: Array,
          default: []
        },
        filterWords: {                  // Text input words for the filter
            type: [String, null, undefined],
            default: null,
        },
        showSearchButton: {
            type: Boolean,
            default: false
        },
        sortDescending: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            forceRenderKey: shortId(),
        }
    },
    computed: {

        sortingOptions() {
            return this.sortingFilters.map(sf => new DropdownOption({
                value: sf,
                caption: sf.title,
                disabled: sf.isDisabled
            }));
        },

        sortDescendingOptions() {
            return [
                new DropdownOption({
                    value: false,
                    caption: trans('labels.ascending'),
                }),
                new DropdownOption({
                    value: true,
                    caption: trans('labels.descending'),
                }),
            ];
        }
    },
    methods: {

        getCaptionForSelectBtn(multiFilterCategory) {
            return trans(multiFilterCategory.hasInactiveSection ? 'labels.select_all' : 'labels.deselect_all');
        },

        onTriggerBtnSelect(multiFilterCategory) {
            // Select all
            if (multiFilterCategory.hasInactiveSection) {
                multiFilterCategory.setActive(true).sections.forEach(fs => fs.category.setActive(true));
            // Deselect all
            }else{
                multiFilterCategory.setActive(false).sections.forEach(fs => fs.category.setActive(false));
            }
            this.forceRenderKey = shortId();
            this.$emit('change', multiFilterCategory);
        },

        onTriggerResetFilters() {
            this.filters.forEach(f => f.reset());
            this.sortingFilters.forEach(f => f.reset());
            this.forceRenderKey = shortId();
            this.$emit('reset', this.filters);
        },

        /**
         * @param {FilterCategory} category
         */
        onTabChanged(category) {

            // Update active state on the parent multi filter category
            for (const filter of this.filters) {
                filter.setActive(filter.sections.some(fs => fs.category.isActive));
            }

            this.$emit('change', category);
        },

        /**
         * Change handler for the word filter
         *
         * @param {String} text
         */
        onChangeWordFilter(text) {
            this.$emit('change', text);
            return this;
        },

        /**
         * Cancel handler for the search input
         *
         * @param {KeyboardEvent|MouseEvent} e
         */
        onCancelSearchInput(e) {
            this.$emit('cancel', e);
            return this;
        },

        /**
         * Select handler for sorting dropdown
         *
         * @param {FilterCategory} sortingFilterCategory
         */
        onSelectSorting(sortingFilterCategory) {
            this.sortingFilters.forEach(sf => sf.setActive(false));
            sortingFilterCategory.setActive(true);
            this.$emit('change', sortingFilterCategory);
            return this;
        },

        /**
         * Select handler for descending order
         *
         * @param {Boolean} descending
         */
        onSelectSortDescending(descending) {
            this.$emit('change', {descending});
            return this;
        },

        /**
         * Trigger search by [ENTER] key
         *
         * @param {KeyboardEvent} e
         * @param {String} keywords
         */
        onTriggerSearch(e, keywords) {
            this.$emit('trigger-search', e, keywords);
            return this;
        },

        /**
         * Set focus on the filter input
         */
        setFocus() {
            this.$refs.searchInput?.setFocus();
            return this;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
