import {cloneDeep} from 'lodash';
import { updateUidReferences, uuid4 } from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';
import OverlayButton from '@/Models/Unity/OverlayButton';

export default class OverlayContent extends AbstractDataObject // @TODO: Inherit from a generic Content class that has uid, type and value?
{
    static get constructorName() { return 'OverlayContent'; }

    /**
     * Types
     *
     * @var {String}
     */
    static get Type() {
        const types = {
            ObjectivesCounter:      'objectives_counter',       // String: 'training', 'scene'
            Text:                   'text',                     // Object: { headline: '', text: ''}
            ImageReference:         'image_reference',          // String: Image asset reference identifier
            SoundReference:         'sound_reference',          // String: Sound asset reference identifier
            VideoReference:         'video_reference',          // String: Video asset reference identifier
            TrainingCompleted:      'training_completed',       // Object: { headline: '', text: ''}
            TrainingInfo:           'training_info',            // Use unit title as headline and description as text
            TrainingSceneCompleted: 'trainingscene_completed',  // Object: { headline: '', text: ''}
            TrainingSceneInfo:      'trainingscene_info',       // Use scene title as headline and description as text
        };
        types.All = Object.values(types);                       // Helper for getting all types as an string[]
        return types;
    }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || OverlayContent.isValidType(attributes.type) === false)
        {
            console.warn('OverlayContent->constructor(): Invalid data.', attributes);
            throw new TypeError('OverlayContent->constructor(): Property "type" has to be set on OverlayContent.');
        }

        // Populate the model:
        this.uid = attributes.uid || uuid4();                               // Unique ID
        this.originalUid = this.uid;                                        // Original unique ID from which the object was duplicated (hidden)
        this.type = attributes.type;                                        // Type identifier
        this.value = cloneDeep(attributes.value || null);                 // Value of mixed type
        this.buttons = (attributes.buttons || []).map(b => new OverlayButton(b, this)); // List of OverlayButtons
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // Headline or text must contain values if present:
        if (this.type === OverlayContent.Type.Text && this.value instanceof Object && (this.value.headline === null || this.value.headline.trim() === '') && (this.value.text === null || this.value.text.trim() === ''))
        {
            return false;
        }
        const dontCheckValue = [
            OverlayContent.Type.TrainingCompleted,
            OverlayContent.Type.TrainingInfo,
            OverlayContent.Type.TrainingSceneCompleted,
            OverlayContent.Type.TrainingSceneInfo
        ].includes(this.type);
        // Value and all buttons must be valid:
        return (dontCheckValue || this.value !== null) && this.buttons.every(c => c.isValid);
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {OverlayContent}
     */
    duplicate(updateUidMapping = true)
    {
        const duplicated = new OverlayContent(this, this.parent);
        duplicated.uid = uuid4();

        // Create new instances for child objects:
        duplicated.value = cloneDeep(this.value);
        duplicated.buttons = duplicated.buttons.map(b => b.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        if (type === null || typeof type !== 'string' || type.length === 0)
        {
            return false;
        }
        for (let i in OverlayContent.Type)
        {
            if (OverlayContent.Type[i] === type)
            {
                return true;
            }
        }
        return false;
    }

    /**
     * Create a new overlay content for intro modules
     *
     * @returns {OverlayContent}
     */
    static get newIntroContent()
    {
        return new OverlayContent({
            type: OverlayContent.Type.TrainingSceneInfo
        });
    }

    /**
     * Create a new overlay content for outro modules
     *
     * @returns {OverlayContent}
     */
    static get newOutroContent()
    {
        return new OverlayContent({
            type: OverlayContent.Type.TrainingSceneCompleted
        });
    }
}
