import {SpeechSynthesisOutputFormat, SpeechSynthesisResult} from "microsoft-cognitiveservices-speech-sdk";

export default class TextToSpeechResult {

    /**
     * @param {SpeechSynthesisResult} speechSynthesisResult
     * @param {string} plainTextInput
     * @param {TextToSpeechVoiceConfig} voiceConfig
     * @param {SpeechSynthesisOutputFormat} speechSynthesisOutputFormat
     */
    constructor(
        speechSynthesisResult,
        plainTextInput,
        voiceConfig,
        speechSynthesisOutputFormat
    ) {
        this.speechSynthesisResult = speechSynthesisResult;
        this.plainTextInput = plainTextInput;
        this.voiceConfig = voiceConfig;
        this.speechSynthesisOutputFormat = speechSynthesisOutputFormat;

        /**
         * @type Blob
         */
        this.blob = this._toBlob();

        this.filename = `text-to-speech.${this.fileExtension}`;
    }

    get fileExtension() {
        switch (this.mimetype) {
            case 'audio/mp3':
                return 'mp3';
            case 'audio/ogg':
                return 'ogg';
            default:
                throw `mimetype ${this.mimetype} not supported`;
        }
    }

    /**
     * @return {string}
     */
    get mimetype() {
        switch (this.speechSynthesisOutputFormat) {
            case SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3:
            case SpeechSynthesisOutputFormat.Audio16Khz128KBitRateMonoMp3:
            case SpeechSynthesisOutputFormat.Audio16Khz64KBitRateMonoMp3:
            case SpeechSynthesisOutputFormat.Audio24Khz48KBitRateMonoMp3:
            case SpeechSynthesisOutputFormat.Audio24Khz96KBitRateMonoMp3:
            case SpeechSynthesisOutputFormat.Audio24Khz160KBitRateMonoMp3:
            case SpeechSynthesisOutputFormat.Audio48Khz96KBitRateMonoMp3:
            case SpeechSynthesisOutputFormat.Audio48Khz192KBitRateMonoMp3:
                return 'audio/mp3';
            case SpeechSynthesisOutputFormat.Ogg16Khz16BitMonoOpus:
            case SpeechSynthesisOutputFormat.Ogg24Khz16BitMonoOpus:
            case SpeechSynthesisOutputFormat.Ogg48Khz16BitMonoOpus:
                return 'audio/ogg';
            default:
                throw `audio format ${this.speechSynthesisOutputFormat} not supported`;
        }
    }

    /**
     * Applies this model's properties to the given form data,
     * which may be used to create a new tts asset.
     *
     * @param {FormData} formData
     */
    applyToCreateAssetFormData(formData) {
        formData.set('files[]', this.blob, this.filename);
        formData.set('tts_voice', this.voiceConfig.voiceName);
        formData.set('tts_language_code', this.voiceConfig.languageCode);
        formData.set('tts_output_format', this.speechSynthesisOutputFormat.toString());
        formData.set('tts_ssml_text', this.voiceConfig.getSsmlText(this.plainTextInput));
    }

    /**
     * @return {Blob}
     * @private
     */
    _toBlob() {
        return new Blob([this.speechSynthesisResult.audioData], {type: this.mimetype});
    }
}
