<template>
    <Modal
        ref="modal"
        event-type="MODAL_NOTIFICATION"
        :title="title || undefined"
        :show-close-button="false"
        :user-closable="true"
        @show="onShow"
    >
        <p class="description" v-html="description"></p>

        <template #buttons>
            <ButtonPrimary
                class="btn-apply"
                @trigger="onClickApply"
                caption="labels.okay"/>
        </template>
    </Modal>
</template>

<script lang="ts">

import {nl2br} from '@/Utility/Helpers';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

export default defineComponent({
    components: {
        ButtonPrimary,
        Modal
    },
    data() {
        return {
            title: null as string | null,
            description: null as string | null,
        }
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {

        onShow(title: string, description: string) {
            this.title = title;
            this.description = nl2br(description);
        },

        onClickApply() {
            this.modal.hide();
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
