import {parseDate} from '@/Utility/Helpers';

export default class ManagedSession {

    readonly constructorName = 'ManagedSession';

    readonly uid: string;
    title: string;
    description: string | null;
    unit_uid: string | null;

    readonly created_at: Date;
    readonly updated_at: Date;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.title = attributes.title;
        this.description = attributes.description;
        this.unit_uid = attributes.unit_uid;

        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }

    get hasUnit() {
        return this.unit_uid !== null;
    }
}
