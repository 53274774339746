import Variable from '@/Models/UnitData/Variables/Variables/Variable';

export default class BoolVariable extends Variable<boolean> {

    static get Type() {
        return 'bool';
    }

    get defaultValue() {
        return false;
    }

    get isValid() {
        return typeof this.value === 'boolean' && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        if (typeof this.value !== 'boolean') {
            console.info('BoolVariable->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = this.defaultValue;
            hasChanged = true;
        }

        return hasChanged;
    }
}
