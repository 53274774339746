<template>
    <li
        v-if="isVisible"
        :class="{ active: isActive }"
        :data-ug-id="`sidemenu-item-${id}`"
    >
        <a :href="url" :title="tooltip">
            <Icon :name="iconName" />
            <span class="sidemenu-item-text">
                {{ label }}
            </span>
        </a>
    </li>
</template>

<script>
import {permission, route, trans} from '@/Utility/Helpers';
import Icon from '@/Vue/Common/Icon.vue';

export default {
    name: 'SidemenuItem',

    components: {
        Icon
    },

    props: {
        id: {
            type: String,
            required: true,
        },
        routeId: {
            type: String,
            required: true,
        },
        iconName: {
            type: String,
            required: true,
        },
        permission: {
            type: String,
            default: null,
        }
    },

    computed: {

        label() {
            return trans(`navigation.${this.id}`);
        },

        tooltip() {
            return trans(`tooltips.navigation.${this.id}`);
        },

        url() {
            return route(this.routeId);
        },

        isVisible() {
            return permission(this.permission);
        },

        isActive() {
            return window.location.href.startsWith(this.url);
        }

    },

    methods: {
        route,
    }
};
</script>

<style lang="scss" scoped>

</style>
