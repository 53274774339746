<template>
    <Modal
        ref="modal"
        :animated="true"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        event-type="MODAL_EDIT_MANAGED_SESSION_PROPERTIES"
        @show="onShow"
    >
        <!-- Title -->
        <TextInput
            ref="session-title-input"
            :error-msg="trans('errors.managed_session.title')"
            :label="trans('sessions.create.session_title')"
            :maxlength="50"
            :model="$data"
            :placeholder="trans('sessions.create.session_title_placeholder')"
            :required="true"
            class="panel-row session-name-input"
            property="title"
        />

        <!-- Description -->
        <TextInput
            :label="trans('sessions.create.session_description')"
            :maxlength="600"
            :model="$data"
            :placeholder="trans('sessions.create.session_description_placeholder')"
            :required="false"
            class="panel-row session-description-input"
            property="description"
            type="textarea"
        />

        <template #buttons>
            <ButtonSecondary
                caption="modals.duplicate_unit.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                caption="modals.duplicate_unit.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, nextTick} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers/trans';
import type ManagedSession from '@/Models/Sessions/ManagedSession';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import EventType from '@/Utility/EventType';
import TextInput from '@/Vue/Common/TextInput.vue';

export default defineComponent({
    components: {
        TextInput,
        ButtonSecondary,
        ButtonPrimary,
        Modal,
    },

    data() {
        return {
            title: null as string | null,
            description: null as string | null,
        };
    },

    methods: {
        trans,

        onShow(session: ManagedSession) {
            this.description = session.description;
            this.title = session.title;

            nextTick(() => {
                this.$refs['session-title-input'].focus();
            });
        },

        onClickCancel() {
            this.$refs.modal.cancel();
        },

        onClickApply() {
            this.$refs.modal.hide();

            this.$globalEvents.emit(
                EventType.MODAL_EDIT_MANAGED_SESSION_PROPERTIES_APPLY,
                this.title,
                this.description || null,
            );
        },
    },
});
</script>

<style lang="scss" scoped>

dialog {
    width: 450px;

    .panel-row {
        &:deep(label) {
            font-family: var(--font-family-condensed-demibold);
            display: block;
            padding-bottom: 8px;
        }

        :deep(textarea) {
            min-height: 120px;
        }
    }
}


</style>
