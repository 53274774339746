import {trans} from '@/Utility/Helpers/trans';

type ParticleEmitterStyleType = {
    type: 'flow_jet' | 'ground_fog' | 'campfire_glow' | 'campfire_large' | 'candlelight' | 'crackling_fireplace' | 'fire_ball' | 'fireplace_flame' | 'flame_jet' | 'flame_stream' | 'medium_flames' | 'rising_sparks' | 'small_flame' | 'tiny_flames' | 'wild_fire' | 'candle_smoke' | 'campfire_smoke' | 'exhaust_fumes' | 'fireplace_smoke' | 'puffing_smoke' | 'thick_rising_smoke_dark' | 'thick_rising_smoke_light' | 'wildfire_smoke',
    title: string,
    preview: string,
};

const ParticleEmitterStyleTypes: Record<string, ParticleEmitterStyleType> = {
    fog_flow_jet: {
        type: 'flow_jet',
        title: trans('particle_emitters.style_types.fog_flow_jet.title'),
        preview: '/images/preview/particle_emitter_fog_flow_jet.avif',
    },
    fog_ground_fog: {
        type: 'ground_fog',
        title: trans('particle_emitters.style_types.fog_ground_fog.title'),
        preview: '/images/preview/particle_emitter_fog_ground_fog.avif',
    },
    fire_campfire_glow: {
        type: 'campfire_glow',
        title: trans('particle_emitters.style_types.fire_campfire_glow.title'),
        preview: '/images/preview/particle_emitter_fire_campfire_glow.avif',
    },
    fire_campfire_large: {
        type: 'campfire_large',
        title: trans('particle_emitters.style_types.fire_campfire_large.title'),
        preview: '/images/preview/particle_emitter_fire_campfire_large.avif',
    },
    fire_candlelight: {
        type: 'candlelight',
        title: trans('particle_emitters.style_types.fire_candlelight.title'),
        preview: '/images/preview/particle_emitter_fire_candlelight.avif',
    },
    fire_crackling_fireplace: {
        type: 'crackling_fireplace',
        title: trans('particle_emitters.style_types.fire_crackling_fireplace.title'),
        preview: '/images/preview/particle_emitter_fire_crackling_fireplace.avif',
    },
    fire_fire_ball: {
        type: 'fire_ball',
        title: trans('particle_emitters.style_types.fire_fire_ball.title'),
        preview: '/images/preview/particle_emitter_fire_fire_ball.avif',
    },
    fire_fireplace_flame: {
        type: 'fireplace_flame',
        title: trans('particle_emitters.style_types.fire_fireplace_flame.title'),
        preview: '/images/preview/particle_emitter_fire_fireplace_flame.avif',
    },
    fire_flame_jet: {
        type: 'flame_jet',
        title: trans('particle_emitters.style_types.fire_flame_jet.title'),
        preview: '/images/preview/particle_emitter_fire_flame_jet.avif',
    },
    fire_flame_stream: {
        type: 'flame_stream',
        title: trans('particle_emitters.style_types.fire_flame_stream.title'),
        preview: '/images/preview/particle_emitter_fire_flame_stream.avif',
    },
    fire_medium_flames: {
        type: 'medium_flames',
        title: trans('particle_emitters.style_types.fire_medium_flames.title'),
        preview: '/images/preview/particle_emitter_fire_medium_flames.avif',
    },
    fire_rising_sparks: {
        type: 'rising_sparks',
        title: trans('particle_emitters.style_types.fire_rising_sparks.title'),
        preview: '/images/preview/particle_emitter_fire_rising_sparks.avif',
    },
    fire_small_flame: {
        type: 'small_flame',
        title: trans('particle_emitters.style_types.fire_small_flame.title'),
        preview: '/images/preview/particle_emitter_fire_small_flame.avif',
    },
    fire_tiny_flames: {
        type: 'tiny_flames',
        title: trans('particle_emitters.style_types.fire_tiny_flames.title'),
        preview: '/images/preview/particle_emitter_fire_tiny_flames.avif',
    },
    fire_wild_fire: {
        type: 'wild_fire',
        title: trans('particle_emitters.style_types.fire_wild_fire.title'),
        preview: '/images/preview/particle_emitter_fire_wild_fire.avif',
    },
    smoke_candle_smoke: {
        type: 'candle_smoke',
        title: trans('particle_emitters.style_types.smoke_candle_smoke.title'),
        preview: '/images/preview/particle_emitter_smoke_candle_smoke.avif',
    },
    smoke_campfire_smoke: {
        type: 'campfire_smoke',
        title: trans('particle_emitters.style_types.smoke_campfire_smoke.title'),
        preview: '/images/preview/particle_emitter_smoke_campfire_smoke.avif',
    },
    smoke_exhaust_fumes: {
        type: 'exhaust_fumes',
        title: trans('particle_emitters.style_types.smoke_exhaust_fumes.title'),
        preview: '/images/preview/particle_emitter_smoke_exhaust_fumes.avif',
    },
    smoke_fireplace_smoke: {
        type: 'fireplace_smoke',
        title: trans('particle_emitters.style_types.smoke_fireplace_smoke.title'),
        preview: '/images/preview/particle_emitter_smoke_fireplace_smoke.avif',
    },
    smoke_puffing_smoke: {
        type: 'puffing_smoke',
        title: trans('particle_emitters.style_types.smoke_puffing_smoke.title'),
        preview: '/images/preview/particle_emitter_smoke_puffing_smoke.avif',
    },
    smoke_thick_rising_smoke_dark: {
        type: 'thick_rising_smoke_dark',
        title: trans('particle_emitters.style_types.smoke_thick_rising_smoke_dark.title'),
        preview: '/images/preview/particle_emitter_smoke_thick_rising_smoke_dark.avif',
    },
    smoke_thick_rising_smoke_light: {
        type: 'thick_rising_smoke_light',
        title: trans('particle_emitters.style_types.smoke_thick_rising_smoke_light.title'),
        preview: '/images/preview/particle_emitter_smoke_thick_rising_smoke_light.avif',
    },
    smoke_wildfire_smoke: {
        type: 'wildfire_smoke',
        title: trans('particle_emitters.style_types.smoke_wildfire_smoke.title'),
        preview: '/images/preview/particle_emitter_smoke_wildfire_smoke.avif',
    },
};

export default ParticleEmitterStyleTypes;
