<template>

    <main id="layout-main">

        <PageHeader
            :page-title="trans('tenants.create.headline')"
        />

        <TenantCreateForm/>

        <ModalProgress/>
        <ModalNotification/>
    </main>

</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {trans} from "@/Utility/Helpers/trans";
import PageHeader from "@/Vue/Common/PageHeader.vue";
import ModalNotification from "@/Vue/Modals/ModalNotification.vue";
import TenantUpdateForm from "@/Vue/Tenants/TenantUpdateForm.vue";
import TenantCreateForm from "@/Vue/Tenants/TenantCreateForm.vue";
import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

export default defineComponent({
    name: "PageTenantCreate",

    components: {
        ModalProgress,
        TenantCreateForm,
        TenantUpdateForm,
        ModalNotification,
        PageHeader,
    },

    methods: {
        trans,
    }
});
</script>

<style scoped lang="scss">

</style>
