<template>
    <section class="session-devices">
        <h2>
            {{ trans('sessions.devices.headline') }}
            <LoadingIndicator v-if="isFetchingDevices" />
        </h2>

        <ButtonSecondary
            v-if="canCreateInstruction"
            v-tooltip="Instruction.getDescriptionForAllDevices('unit_stop')"
            :caption="Instruction.getButtonLabelForAllDevices('unit_stop')"
            :disabled="!isStopAllButtonEnabled"
            class="stop-all-units-button"
            @trigger="onStopAllUnitsClicked"
        />

        <ul>
            <li class="header">
                <span />
                <span>{{ trans('sessions.devices.device_name_headline') }}</span>
                <span>{{ trans('sessions.devices.model') }}</span>
                <span>{{ trans('sessions.devices.is_in_managed_mode_headline') }}</span>
                <span>{{ trans('sessions.devices.is_unit_loaded_headline') }}</span>
            </li>
            <session-devices-list-item
                v-for="device in devices"
                :key="device.uid"
                :can-delete-session="!sessionService.isSaving"
                :can-start-unit="canStartUnit && !sessionService.isSaving"
                :can-stop-unit="!sessionService.isSaving"
                :device="device"
                @remove-click="onRemoveClicked"
                @start-unit-click="onStartUnitClicked"
                @stop-unit-click="onStopUnitClicked"
            />
            <NoItemsAvailable v-if="devices.length === 0 && hasFetchedDevices" />
        </ul>

        <ButtonSecondary
            caption="Add Devices"
            icon="icon_add"
            @trigger="onAddDevicesClicked"
        />
    </section>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers';
import {sessionServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import type Device from '@/Models/Devices/Device';
import AuthorizationError from '@/Errors/AuthorizationError';
import SessionDevicesListItem from '@/Vue/Sessions/SessionDevicesListItem.vue';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';
import ManagedSession from '@/Models/Sessions/ManagedSession';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import Instruction from '@/Models/Sessions/Instruction';

export default defineComponent({

    components: {
        ButtonSecondary,
        NoItemsAvailable,
        LoadingIndicator,
        SessionDevicesListItem
    },

    props: {
        session: {
            type: ManagedSession,
            required: true,
        },

        /**
         * If set to false, the play button will be disabled
         */
        canStartUnit: {
            type: Boolean,
            required: true,
        },

        canCreateInstruction: {
            type: Boolean,
            required: true,
        },
    },

    emits: [
        'device-count-changed',
        'add-device-click',
        'start-unit-click',
        'stop-unit-click',
    ],

    data() {
        return {
            sessionService: inject(sessionServiceKey)!,
            devices: [] as Device[],
            reloadInterval: 0,
            isFetchingDevices: false,
            hasFetchedDevices: false,
        };
    },

    computed: {
        Instruction() {
            return Instruction;
        },

        isStopAllButtonEnabled() {
            return this.devices.length > 0 && !this.sessionService.isSaving;
        }
    },

    watch: {
        devices(devices: Device[], oldDevices: Device[]) {
            if (devices.length !== oldDevices.length) {
                this.$emit('device-count-changed', devices.length);
            }
        }
    },

    mounted() {
        this.fetchDevices();
        this.reloadInterval = window.setInterval(() => this.fetchDevices(), 10_000);
    },

    beforeUnmount() {
        clearInterval(this.reloadInterval);
    },

    methods: {
        trans,

        async fetchDevices() {
            this.isFetchingDevices = true;

            return this.sessionService
                .fetchSessionDevices(this.session.uid)
                .then((devices) => {
                    this.devices = devices;
                })
                .catch(this.onErrorApi)
                .finally(() => {
                    this.isFetchingDevices = false;
                    this.hasFetchedDevices = true;
                });
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }
            this.$root?.showErrorDialog(error);
        },

        onAddDevicesClicked() {
            this.$emit('add-device-click');
        },

        onStartUnitClicked(device: Device) {
            this.$emit('start-unit-click', device);
        },

        onStopUnitClicked(device: Device) {
            this.$emit('stop-unit-click', device);
        },

        onStopAllUnitsClicked() {
            this.$emit('stop-unit-click');
        },

        onRemoveClicked(device: Device) {
            this.sessionService
                .updateSessionDevices(this.session.uid, [device.uid])
                .then((devices) => {
                    this.devices = devices;
                })
                .catch(this.onErrorApi);
        },
    }
});
</script>

<style lang="scss" scoped>

.session-devices {
    margin-top: 24px;

    h2 > .loading-indicator {
        margin: 0 0 -2px 4px;
        width: 20px;
        height: 20px;
    }

    .stop-all-units-button {
        margin-bottom: 16px;
    }

    ul {
        display: grid;
        grid-template-columns: max-content minmax(150px, max-content) minmax(auto, 150px) repeat(4, max-content);
        gap: 4px 16px;
        margin-bottom: 16px;
        list-style-type: none;

        li.header {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: subgrid;

            padding: 10px;
            background-color: var(--color-anthracite);
            color: white;
            border-radius: 8px;
        }

        .no-items-available {
            grid-column: 1 / -1;
            margin: 8px 0 0 0;
        }
    }
}

</style>
