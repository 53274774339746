<template>
    <div class="launch-unit-card">
        <a
            v-if="unitIsLaunchable"
            v-tooltip="'buttons.launch_page.learn_in_web_browser'"
            class="launch-unit-card-preview"
            @click.stop.prevent="$emit('click-launch')"
        >
            <Icon name="icon_play" />
            <img :src="imageUrl" alt="">
        </a>
        <img
            v-else
            :src="imageUrl"
            alt=""
            class=""
        >
        <div class="launch-unit-card-body">
            <h3>{{ headline }}</h3>
            <p v-if="description">
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Icon from '@/Vue/Common/Icon.vue';
import {route} from '@/Utility/Helpers';

export default defineComponent({
    components: {
        Icon
    },

    props: {
        imageUrl: {
            type: String,
            required: true,
        },
        headline: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        unitIsLaunchable: {
            type: Boolean,
            default: false
        },
    },

    emits: [
        'click-launch'
    ],

    methods: {
        route
    },
});
</script>

<style lang="scss" scoped>

.launch-unit-card {
    display: flex;
    flex-direction: column;
    flex: 1 1 500px;
    border-radius: var(--card-border-radius);
    background-color: var(--background-color-white);
    box-shadow: var(--card-box-shadow);
    padding: 12px 12px 16px 12px;

    .launch-unit-card-preview {
        position: relative;
        border-radius: var(--card-border-radius-small);
        overflow: hidden;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            background-color: var(--color-primary-transparent);
            pointer-events: all;
            opacity: 0;
            transition: background-color 0.1s, opacity 0.1s;
        }

        &:hover:before,
        &:focus-visible:before {
            background-color: var(--color-primary-hover-transparent);
            opacity: 1;
        }

        svg {
            position: absolute;
            top: calc(50% - 72px);
            left: calc(50% - 72px);
            height: 144px;
            width: 144px;
            opacity: 1;
            color: var(--color-white);
            transition: background-color 0.1s, opacity 0.1s;
            cursor: pointer;
        }

        &:hover {
            svg {
                color: var(--color-white);
                opacity: 1;
            }
        }
    }

    img {
        border-radius: var(--card-border-radius-small);
        width: 100%;

        &:only-child {
            margin-bottom: -4px;
        }
    }

    .launch-unit-card-body {
        display: flex;
        flex-direction: column;
        margin: 0 11px;
        padding-top: 16px;

        h3 {
            margin-bottom: 4px;
        }

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0;
        }
    }
}
</style>
