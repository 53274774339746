<template>
    <div v-shortcuts.stop class="variable-property variable-property-input number-input-wrapper">
        <label>{{ trans('variables.values.value') }}</label>
        <NumberInput
            :maxValue="maxValue"
            :minValue="minValue"
            :model="variable"
            property="value"
            :placeholder="trans('labels.number')"
            :precision="0"
            :step="1"
            @change="onChangeNumber"
        />
    </div>
</template>

<script>

import NumberVariable from '@/Models/UnitData/Variables/Variables/NumberVariable';

    export default {
        name: 'PanelVariableNumber',
        emits: [
            'change',
        ],
        props: {
            variable: {                      // The Variable object to be edited
                type: NumberVariable,
                default: null
            },
            minValue: {
                type: Number,
                default: NumberVariable.MinValue
            },
            maxValue: {
                type: Number,
                default: NumberVariable.MaxValue
            }
        },
        data() {
            return {
                shortcuts: new Map([
                    ['Duplicate.prevent', null],            // Prevent browser behaviour
                    ['Save.prevent', null],                 // Prevent browser behaviour
                    ['Any', null]                           // Allow any other shortcut but stop propagation
                ])
            }
        },
        methods: {
            /**
             *  Change handler for the variable
             */
            onChangeNumber() {
                this.$emit('change', this.variable);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
