import { updateUidReferences, uuid4 } from '@/Utility/Helpers';
import Component from '@/Models/Unity/Components/Component';
import ConnectionPoint from '@/Models/Unity/ConnectionPoint';

export default class ConnectableComponent extends Component
{
    /**
     * Type for this component
     *
     * @var {String}
     */
    static get type() { return 'connectable'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Set type for this component:
        super({...attributes, ...{type: attributes.type || ConnectableComponent.type}});

        // Populate the model:
        this.connection_points = (attributes.connection_points || []).map(c => new ConnectionPoint(c)); // List of Unity ConnectionPoints
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {ConnectableComponent}
     */
    duplicate(updateUidMapping = true)
    {
        const duplicated = new ConnectableComponent(this);
        duplicated.uid = uuid4();
        duplicated.connection_points = duplicated.connection_points.map(c => c.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        return duplicated;
    }
}
