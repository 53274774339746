import {parseDate} from '@/Utility/Helpers';

export default class StatusLabelConfig
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.type = attributes.type || null;                    // Optional type (e.g. 'policy', 'status', 'notification')
        this.caption = attributes.caption || null;              // Optional custom caption text identifier (e.g. 'statuslabels.unreleased_changes')
        this.date = parseDate(attributes.date || null);         // Optional date suffix
        this.icon = attributes.icon || null;                    // Optional icon name (e.g. 'icon_history')
        this.tooltip = attributes.tooltip || null;              // Optional tooltip text (e.g. 'statuslabels.unreleased_changes_tooltip')
    }
}
