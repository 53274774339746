<template>
    <div class="image-input">
        <div class="image-upload">
            <img
                v-if="imageFile"
                :src="imageSrc"
                class="image-upload-preview"
                :alt="imageInfo?.name"
                @load="onImageLoaded"
            />
            <template v-else-if="!isLoading">
                <Icon name="icon_add" class="icon-add"/>
            </template>
            <LoadingIndicator v-else/>
            <input
                :id="fieldId"
                :name="fieldName"
                ref="fileInput"
                class="image-upload-input"
                type="file"
                @change="onChangePreviewImage"
                :accept="imageValidation.acceptTypes"
                :placeholder="trans('courses.create.preview_image_placeholder')"
            />
        </div>
        <div class="image-info">
            <template v-if="imageInfo">
                <p class="title">{{ imageInfo.name }}</p>
                <p class="dimensions">{{ imageInfo.width }}x{{ imageInfo.height }}px {{ imageInfo.resized ? `(${trans('labels.resized')})` : ''}}</p>
                <p class="filesize">{{ imageInfo.filesize }}</p>
            </template>
            <p v-else-if="imageValidation" class="image-requirements">
                <Icon name="icon_info"/>
                {{ imageValidation.humanReadableRequirements }}
            </p>
        </div>
    </div>
</template>

<script lang="ts">

import Compressor from 'compressorjs';
import {trans} from "@/Utility/Helpers/trans";
import {formatBytes} from "@/Utility/Helpers";
import {defineComponent, PropType, ref} from "vue";
import Icon from "@/Vue/Common/Icon.vue";
import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";
import ImageValidation, {ImageValidationRules} from "@/Models/Asset/ImageValidation";
import ImageMaxDimensionsError from '@/Errors/Validation/ImageMaxDimensionsError';

export default defineComponent({

    name: 'ImageInputField',

    components: {
        LoadingIndicator,
        Icon
    },

    emits: {
        'validation-error': (_: Error) => true,
        'file-changed': (_: File | null, changedByInitialUrl: Boolean) => true,
    },

    props: {

        /**
         * When set, this url will be used to fetch the image
         * on mount. Make sure, the cors headers are set correctly
         * when loading from remote hosts.
         * The input field will be set correctly and can be used in forms.
         */
        initialImageUrl: {
            type: String as PropType<string | null | undefined>,
            required: false,
        },

        /**
         * Rules about which image type and dimensions are allowed.
         */
        validationRules: {
            type: Object as PropType<ImageValidationRules>,
            required: true,
        },

        /**
         * Name of the file input field for usage in html forms.
         */
        fieldName: {
            type: String,
            required: true,
        },

        /**
         * ID of the file input field for label relations.
         */
        fieldId: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            /**
             * Can be used to obtain the currently set
             * image file. Will be filled from initialImageUrl
             * on mount.
             */
            imageFile: null as File | null,

            fileReader: new FileReader(),
            imageSrc: null as string | null,
            fileToLoadAndValidate: null as File | null,
            isLoading: false,
            imageFileIsFromInitialUrl: false,
            imageFetchAbortController: null as AbortController | null,
            imageInfo: null,
        }
    },

    setup() {
        return {
            fileInput: ref<HTMLInputElement>(),
        }
    },

    mounted() {
        this.fileReader.onloadstart = this.fileReaderOnloadStart;
        this.fileReader.onloadend = this.fileReaderOnloadEnd;
        this.fileReader.onerror = this.fileReaderOnloadError;
        this.reset();
    },

    beforeUnmount() {
        this.fileReader.onload = null;
        this.fileReader.onloadstart = null;
        this.fileReader.onloadend = null;
        this.fileReader.onerror = null;
    },

    computed: {
        imageValidation() {
            return new ImageValidation(this.validationRules);
        },
    },

    methods: {
        trans,

        /**
         * Resets this component to its initial state.
         * If initialImageUrl has been provided, it will be re-loaded.
         *
         * @public
         */
        reset() {
            this.imageFile = null;
            this.imageSrc = null;
            this.imageInfo = null;

            this.abortLoading();
            this.loadInitialImageUrl();
        },

        async loadInitialImageUrl() {
            if (!this.initialImageUrl) {
                return;
            }

            this.isLoading = true;

            try {
                this.imageFileIsFromInitialUrl = true;

                this.imageFetchAbortController = new AbortController();
                const response = await fetch(this.initialImageUrl, {
                    signal: this.imageFetchAbortController.signal
                });
                const blob = await response.blob()
                const fileName = this.getFileNameFromUrl(this.initialImageUrl, blob.type);
                const file = new File([blob], fileName);

                this.changeFile(file, true);

            } catch (_) {
                this.isLoading = false;
            }
        },

        onChangePreviewImage(e: Event) {
            const target = e.currentTarget as HTMLInputElement;

            if (target.files === null || target.files.length === 0) {
                // Fix Chromium-based browsers resetting inputs on cancel
                if (this.imageFile !== null) {
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(this.imageFile);
                    this.fileInput!.files = dataTransfer.files;
                }

                return;
            }

            this.abortLoading();

            this.imageFile = null;
            this.imageSrc = null;
            this.imageInfo = null;

            this.imageFileIsFromInitialUrl = false;
            this.changeFile(target.files[0]);
        },

        /**
         * Triggers the validation and preview loading process
         * for the given file.
         *
         * @param file
         * @param updateFileInInputField If set, the given file will be set
         * as the file input fields value, so forms are continuing to work,
         * even when the file was downloaded from a remote url.
         */
        changeFile(file: File, updateFileInInputField = false) {
            this.fileToLoadAndValidate = file;
            this.fileReader.readAsDataURL(this.fileToLoadAndValidate);

            if (this.fileInput && updateFileInInputField) {
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                this.fileInput.files = dataTransfer.files;
            }
        },

        fileReaderOnloadStart(_: ProgressEvent) {
            this.isLoading = true;
        },

        async fileReaderOnloadEnd(_: ProgressEvent) {
            this.isLoading = false;

            const image = this.fileReader.result as string;

            try {
                await this.imageValidation.validate(image);
                this.imageSrc = image;
                this.imageFile = this.fileToLoadAndValidate;
            } catch (e: unknown) {
                if (e instanceof ImageMaxDimensionsError) {
                    await this.onValidationImageMaxDimensionsError(e);
                } else if (e instanceof Error) {
                    this.onValidationError(e);
                } else {
                    this.onValidationError(new Error('Unexpected error during image validation.'));
                }
            }
        },

        async fileReaderOnloadError(_: ProgressEvent) {
            this.isLoading = false;
            this.onValidationError(new Error('Loading image preview failed.'));
        },

        onValidationError(e: Error) {
            this.imageSrc = null;
            this.imageFile = null;
            this.imageInfo = null;
            this.$emit('validation-error', e);

            this.loadInitialImageUrl();
        },

        async onValidationImageMaxDimensionsError(e: ImageMaxDimensionsError) {
            this.isLoading = true;
            new Compressor(this.fileToLoadAndValidate, {
                retainExif: true,
                maxWidth: this.validationRules.maxWidth || 8192,
                maxHeight: this.validationRules.maxHeight || 8192,
                success: (imageBlob: Blob) => {
                    const compressedImageFile = new File([imageBlob], this.fileToLoadAndValidate.name);
                    this.fileToLoadAndValidate = compressedImageFile;
                    this.imageFile = compressedImageFile;
                    this.imageSrc = window.URL.createObjectURL(imageBlob);
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(compressedImageFile);
                    this.fileInput.files = dataTransfer.files;
                    this.imageFile.resized = true;
                    this.isLoading = false;
                    //console.info(`ImageInputField: Image has been resized to fit maximum dimensions.`)
                },
                error: (err) => {
                    this.isLoading = false;
                    console.error(err);
                    this.onValidationError(e);
                }
            });
        },

        abortLoading() {
            this.fileReader.abort();
            this.imageFetchAbortController?.abort();
            this.isLoading = false;
        },

        onImageLoaded(e) {
            this.imageInfo = {
                name: this.imageFile.name,
                filesize: formatBytes(this.imageFile.size),
                width: e.target.naturalWidth,
                height: e.target.naturalHeight,
                resized: this.imageFile.resized,
            }
        },

        getFileNameFromUrl(url: string, mimetype: string | null): string {
            let extension;
            switch (mimetype) {
                case 'image/png':
                    extension = 'png';
                    break;
                default:
                    extension = 'jpg';
                    break;
            }

            const fallbackName = `image.${extension}`;

            if (url.startsWith('data')) {
                return fallbackName;
            } else {
                return new URL(url).pathname.split('/').pop() || fallbackName;
            }
        }
    },

    watch: {

        imageFile(file: File | null) {
            if (file === null && this.fileInput) {
                this.fileInput.files = null;
            }

            this.$emit('file-changed', file, this.imageFileIsFromInitialUrl);
        }

    }
});

</script>

<style lang="scss" scoped>

.image-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    column-gap: var(--forminput-spacing);
}

.image-upload {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    max-width: 100%;
    flex-basis: 240px;
    flex-shrink: 0;
    width: 240px;
    height: 135px;
    box-sizing: content-box;
    background-color: var(--background-color-light);
    border-radius: var(--card-border-radius-small);

    border: var(--forminput-border);
    transition: border-color .1s;

    overflow: hidden;
    cursor: pointer;

    &:hover,
    &:focus-within {
        border-color: var(--color-primary-hover);
    }

    .image-upload-preview {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .icon {
        width: 24px;
        height: 24px;

        color: var(--color-anthracite40);
        transition: color .1s;
        pointer-events: none;
    }

    &:hover .icon {
        color: var(--color-primary-hover);
    }

    .image-upload-input {
        position: absolute;
        width: 100%;
        opacity: 0;
        left: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
    }
}

.image-info {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: center;
    margin-top: 5px;
    padding-left: 15px;
    padding-right: 15px;

    p {
        font-size: var(--font-size-small);
        line-height: var(--line-height-small);
        margin-bottom: 0;
    }

    .title {
        word-break: break-all;
    }
}

.image-requirements {
    font-size: var(--font-size-small);
    color: var(--color-anthracite80);
    margin-bottom: 0;

    .icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        margin-bottom: 2px;
    }
}

</style>
