import type PagingMetadata from "@/Models/PagingMetadata";
import type Unit from "@/Models/Unit/Unit";

export default class UnitPage {

    constructor(
        public readonly unitList: Unit[],
        public readonly pagingMetadata: PagingMetadata
    ) {
    }

}
