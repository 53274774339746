<template>
    <Modal
        :animated="true"
        :show-close-button="true"
        :title="model?.name"
        event-type="MODAL_SKETCHFAB_MODEL_PREVIEW"
        @close="onClose"
        @show="onShow"
    >

        <iframe
            v-show="viewerLoaded"
            ref="viewerIframe"
            allow="autoplay; fullscreen; accelerometer; gyroscope; xr-spatial-tracking"
            class="preview-wrapper">
        </iframe>

        <div v-if="hasError" class="error">
            <Icon class="icon-info" name="icon_info"/>
            <p>{{ error }}</p>
        </div>
    </Modal>
</template>

<script lang="ts">

import Sketchfab from '@sketchfab/viewer-api';
import Icon from '@/Vue/Common/Icon.vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import type SketchfabModel from '@/Models/Asset/Sketchfab/SketchfabModel';


export default defineComponent({
    components: {
        Modal,
        Icon
    },

    data() {
        return {
            model: null as SketchfabModel | null,
            error: null as string | null,
            viewerLoaded: false,
        }
    },

    computed: {

        hasError() {
            return this.error !== null;
        }

    },

    methods: {

        onShow(sketchfabModel: SketchfabModel) {
            this.model = sketchfabModel;

            this.$nextTick(() => {
                // delay, because we need dom initialized
                this.initSketchfabViewer();
            });
        },

        onClose() {
            this.model = null;
            this.error = null;
            this.viewerLoaded = false;
        },

        initSketchfabViewer() {
            const client = new Sketchfab('1.12.1', this.$refs.viewerIframe);

            client.init(this.model!.uid, {
                error: this.onLoadViewerError,
                success: this.onLoadViewerSuccess,
                autospin: 0.2,
                autostart: 1,
                dnt: 1,
                ui_stop: 0,
            });
        },

        onLoadViewerSuccess() {
            this.viewerLoaded = true;
        },

        onLoadViewerError(error: string) {
            this.error = error;
        },
    }
});
</script>

<style lang="scss" scoped>

dialog[open] {
    aspect-ratio: 16/9;
    width: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;

    .preview-wrapper {
        border: none;
        width: 100%;
        height: 100%;
        border-radius: var(--card-border-radius);
    }

    .error {
        margin: auto;
        text-align: center;

        .icon-info {
            width: 24px;
            margin-bottom: 4px;
        }
    }
}

</style>
