<template>
    <div class="launch-page">

        <PageLmsXApiLaunchHeader
            :help-url="helpUrl"/>

        <PageLmsXApiLaunchStage
            :teaser-headline="trans('lms_xapi.errors.error_headline')"
            :teaser-copy="message"
            card-image-url="/images/preview/training.jpg"
        />

        <section class="launch-page-container">
            <p>{{ trans('lms_xapi.errors.message_instructions') }}</p>
        </section>
    </div>
</template>

<script>
    import Unit from "@/Models/Unit/Unit";
    import PageLmsXApiLaunchHeader from "@/Vue/Lms/xapi/PageLmsXApiLaunchHeader";
    import PageLmsXApiLaunchStage from "@/Vue/Lms/xapi/PageLmsXApiLaunchStage";

    export default {
        name: "PageLmsXApiLaunchError",
        components: {
            PageLmsXApiLaunchHeader,
            PageLmsXApiLaunchStage,
        },
        props: {
            helpUrl: {
                type: String,
            },
            message: {
                type: String,
            },
        },

        computed: {
            unit() {
                return Unit.createFromAttributes(this.unitData);
            },
        },
    }
</script>

<style lang="scss" scoped>

    .launch-page {
        width: 100%;
        background-color: var(--background-color-white);
        overflow: auto;
    }

    .launch-page-container {
        margin-top: 30px;
    }

</style>
