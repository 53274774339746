<template>
    <Modal
        ref="modal"
        v-shortcuts
        :show-close-button="false"
        :title="modalTitle"
        :user-closable="false"
        event-type="MODAL_PROGRESS"
        @hide="onHide"
        @show="onShow"
    >
        <slot>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="description" v-html="modalDescription" />
        </slot>

        <div v-if="progressPercent" class="progress-text">
            {{ progressFormatted }}
        </div>
        <progress
            v-if="progressPercent"
            :aria-label="title || undefined"
            :value="progressPercent"
        />

    </Modal>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers/trans';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';

export default defineComponent({

    components: {
        Modal,
    },

    data() {
        return {
            title: null as string | null,
            progressPercent: null as number | null,
            shortcuts: new Map([
                ['Save.prevent.stop', null],
                ['Reload.prevent.stop', null],
                ['Publish.prevent.stop', null],
            ])
        };
    },

    computed: {

        modalTitle(): string {
            return this.title || trans('modals.progress.processing');
        },

        modalDescription(): string {
            return trans('modals.progress.please_wait');
        },

        progressFormatted(): string {
            return `${((this.progressPercent || 0) * 100).toFixed(2)} %`;
        },
    },

    methods: {

        onShow(title?: any, progressPercent?: any) {
            this.title = (typeof title === 'string') ? title : null;
            this.progressPercent = (typeof progressPercent === 'number') ? progressPercent : null;
        },

        onHide() {
            this.progressPercent = null;
        }
    }
});
</script>

<style lang="scss" scoped>

@keyframes busy-dots {
    from {
        width: 0;
        margin-right: 30px;
    }
    to {
        width: 30px;
        margin-right: 0;
    }
}

dialog {

    .description:after,
    :deep(.description:after) {
        content: '...';
        position: absolute; // For positioning at the end of the text without taking up space
        display: inline-block;
        width: 0;
        margin: 0 30px 0 0;
        vertical-align: bottom;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        animation: busy-dots 1s infinite .5s;
    }

    :deep(.title),
    .description,
    :deep(.description) {
        text-align: center;
    }

    .progress-text {
        text-align: center;
        margin-left: 10px;
    }

    progress {
        width: 100%;
    }
}
</style>
