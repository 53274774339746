import UserImportEntry from '@/Models/User/Import/UserImportEntry';
import type {UserToImport} from '@/Services/UserService';
import Papa from 'papaparse';

export default class UserImport {

    /**
     * @param csvText Semicolon separated CSV text with header and following columns:
     * Lastname;Firstname;Email;Team Role;Role
     */
    public static fromCsvText(csvText: string): UserImport {
        const csvObject = Papa.parse(csvText, {
            header: false,
            skipEmptyLines: 'greedy',
        });

        const data = csvObject.data;

        // remove header column
        data.shift();

        return new UserImport(data);
    }

    public readonly entries: UserImportEntry[];

    private constructor(csvData: Array<Array<string>>) {
        this.entries = csvData.map(csvDataRow => UserImportEntry.fromCsvRow(csvDataRow));
    }

    get isValid() {
        return this.entries.length > 0 && this.entries.every(entry => entry.isValid);
    }

    get validatedUserList(): UserToImport[] {
        if (!this.isValid) {
            throw new Error('Cannot create a valid user list with invalid data.');
        }

        return this.entries.map(entry => entry.toUserToImport());
    }

    [Symbol.iterator](): Iterator<UserImportEntry> {
        return this.entries[Symbol.iterator]();
    }
}
