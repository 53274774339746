<template>
    <Modal
        id="dialog-save-asset-changes"
        ref="modal"
        :show-close-button="false"
        :title="trans('modals.save_asset_changes.title')"
        :user-closable="true"
        event-type="MODAL_SAVE_ASSET_CHANGES"
        @hide="onHide"
        @show="onShow"
    >
        <p class="description" v-html="trans('modals.save_asset_changes.description')"></p>

        <template #buttons>
            <ButtonSecondary
                caption="modals.save_asset_changes.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonSecondary
                caption="modals.save_asset_changes.dismiss"
                class="btn-dismiss"
                @trigger="onClickDismiss"
            />
            <ButtonPrimary
                caption="modals.save_asset_changes.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import Asset from '@/Models/Asset/Asset';
import {trans} from '@/Utility/Helpers';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

type ModalData = {
    asset: Asset | null,
};

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },
    data(): ModalData {
        return {
            /**
             * Reference to the asset for which this dialog is being shown
             */
            asset: null as Asset | null,
        }
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {
        trans,

        hideModal() {
            this.modal.hide();
        },

        onShow(asset: Asset) {
            this.asset = asset;
        },

        onHide() {
            this.asset = null;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_APPLY, this.asset);
        },

        onClickDismiss() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_DISMISS, this.asset);
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
