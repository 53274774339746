export default class RadioButtonConfig
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Populate the model:
        this.value = attributes.value || null;                                                      // Option value
        this.caption = attributes.caption || null;                                                  // Option caption text
        this.helpText = attributes.helpText || null;                                                // Optional title help text
        this.disabled = (typeof attributes.disabled === 'boolean') ? attributes.disabled : false;   // Disabled state
    }
}
