<template>

    <div class="form-group row" :title="getTitleAttributeFromText" v-shortcuts.stop>
        <!-- Label -->
        <label
            v-if="label"
            :for="uid"
            :class="labelClasses"
            class="col-form-label"
            :title="labelHint">
            {{ label }}
            <Icon v-if="labelHint !== null" name="icon_info"/>
        </label>

        <!-- Type: Text Input -->
        <div :class="inputSizeClasses">
            <input
                v-bind="attributes"
                ref="domElement"
            />
            <span v-if="validationErrors && validationErrors.length > 0" class="invalid-feedback" role="alert">
                <strong v-html="validationErrors[0]"></strong>
            </span>
        </div>

        <!-- Error messages -->
        <span v-if="errorMsg" v-html="errorMsg" class="error-msg"></span>
    </div>
</template>

<script>
// Import classes:
import {mergeProps} from 'vue';
import {shortId} from '@/Utility/Helpers';
import KeyboardKey from '@/Utility/KeyboardKey';

export default {
    name: 'UserFormTextInput',
    inheritAttrs: false,
    emits: [
        'focus',
        'blur',
        'change',
        'cancel',
    ],
    props: {
        initialValue: {         // Initial text (either use this or model+property!)
            type: String,
            default: ''
        },
        model: {                // Associated model reference
            type: Object,
            default: null
        },
        property: {             // Property name from the associated model that should be modified
            type: String,
            default: null
        },
        disabled: {             // Disabled state
            type: Boolean,
            default: false
        },
        required: {             // Required state
            type: Boolean,
            default: false
        },
        label: {                // Optional label text
            type: String,
            default: null
        },
        type: {                 // Type (e.g. 'text', 'password', 'email', 'url')
            type: String,
            default: 'text'
        },
        maxlength: {            // Maximum string length
            type: Number,
            default: null
        },
        minlength: {            // Minimum string length
            type: Number,
            default: null
        },
        placeholder: {          // Placeholder text
            type: String,
            default: null
        },
        errorMsg: {             // Error message text
            type: String,
            default: null
        },
        restrictKeys: {         // List of KeyboardKeys to restrict input
            type: Array,
            default() {
                return [];
            }
        },
        minLengthForAddingTooltip: {    // Minimum number of characters before showing a tooltip on the component
            type: Number,
            default: 28
        },
        validationErrors: {
            type: Array,
            default() {
                return [];
            }
        },
        labelClasses: {
            type: String,
            default: 'col-md-4 text-md-right',
        },
        inputSizeClasses: {
            type: String,
            default: 'col-md-6',
        },
        name: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        labelHint: { // hint that can be displayed next to the label (e.g. for restricted characters, etc.)
            type: String,
            default: null,
        }
    },
    data() {
        return {
            uid: shortId('textinput'),                  // A unique identifier for HTML id="" attribute
            text: '',                                   // The edited text
            previousValue: null,                        // Previous text value when focusing on the field
            errors: {},                                 // Validation errors
            keyUpTimer: null,                           // Timeout helper for triggering validation on keyup events
            hadFocusOnce: false,                        // Will be set to true onChange / onBlur so we can ignore validation before the element was focused at least once
            shortcuts: new Map([
                ['Duplicate.prevent', null],            // Prevent browser behaviour
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Any', null]                           // Allow any other shortcut but stop propagation
            ])
        }
    },
    computed: {

        inputClassAttribute() {
            return this.hasErrors ? 'form-control is-invalid' : 'form-control';
        },

        /**
         * @return {boolean}
         */
        isTextarea() {
            return (this.type === 'textarea');
        },

        /**
         * Additional attributes (e.g. for validation)
         *
         * @returns {Object}
         */
        attributes() {

            const attrs = {
                id: this.uid,
                type: this.type,
            };

            // Disabled
            if (this.disabled) {
                attrs.disabled = true;
            }else{
                attrs.onChange = this.onChange;
                attrs.onKeydown = this.onKeyDown;
                attrs.onKeyup = this.onKeyUp;
                attrs.onBlur = this.onBlur;
                attrs.onFocus = this.onFocus;
            }

            // Input-specific
            if (!this.isTextarea) {
                attrs.value = this.text;
            }

            // Maxlength
            if (this.maxlength !== null) {
                attrs.maxlength = this.maxlength;
            }

            // Minlength
            if (this.minlength !== null) {
                attrs.minlength = this.minlength;
            }

            // Name
            if (this.name !== null) {
                attrs.name = this.name;
            }

            // Placeholder
            if (this.placeholder !== null) {
                attrs.placeholder = this.placeholder;
            }

            // Required
            if (this.required) {
                attrs.required = 'required';
            }

            const mergedProps = mergeProps(attrs, this.$attrs);

            // Override CSS classes
            mergedProps.class = this.inputClassAttribute;

            return mergedProps;
        },

        /**
         * CSS classes for the checkbox
         *
         * @returns {String}
         */
        cssClasses() {
            const classes = [
                'textinput',
                'type-' + this.type
            ];

            // From attributes:
            if (this.$attrs.class) {
                classes.push(this.$attrs.class);
            }

            // Has label:
            if (this.label) {
                classes.push('has-label');
            }

            // Enabled/disabled state:
            classes.push((this.disabled === true) ? 'disabled' : 'enabled');

            // Required state:
            if (this.required === true)
            {
                classes.push('required');
            }

            // Empty state:
            if (this.text === null || this.text.trim() === '')
            {
                classes.push('is-empty');
            }

            // Error state:
            if (this.hasErrors === true)
            {
                classes.push('error');
            }

            return classes.join(' ');
        },

        /**
         * Get the text for the title attribute
         *
         * @returns {String|null}
         */
        getTitleAttributeFromText() {
            if (this.text !== null && this.text.length > this.minLengthForAddingTooltip) {
                return this.text;
            }else if (this.$attrs.title) {
                return this.$attrs.title;
            }
            return null;
        },

        /**
         * Validation
         *
         * @returns {Boolean}
         */
        hasErrors() {
            // Only trigger this after the component is being mounted:
            if (this.$refs.domElement === null)
            {
                return false;
            }

            const trimmedValue = this.text.trim();

            // Required?
            if (this.required === true && trimmedValue === '' && this.hadFocusOnce === true)
            {
                this.errors.required = true;
            }
            else
            {
                delete this.errors.required;
            }

            // Maxlength?
            if (this.maxlength !== null && trimmedValue.length > this.maxlength)
            {
                this.errors.maxlength = true;
            }
            else
            {
                delete this.errors.maxlength;
            }

            // Minlength?
            if (this.minlength !== null && trimmedValue.length < this.minlength && this.hadFocusOnce === true)
            {
                this.errors.minlength = true;
            }
            else
            {
                delete this.errors.minlength;
            }

            return (Object.keys(this.errors).length > 0) || this.validationErrors.length > 0;
        }
    },
    mounted() {
        // Check properties
        if (this.model !== null && this.property === null) {
            console.warn('UserFormTextInput->mounted(): Property :model="" is set but no property="" name is given.', this);
        }
        if (this.model !== null && this.initialValue !== '') {
            console.warn('UserFormTextInput->mounted(): Both :model="" and :initial-value="" are set. You should use just one of them.', this);
        }

        // Set initial internal text value
        this.resetValue();
    },
    methods: {

        /**
         * Focus handler
         *
         * @param {FocusEvent} e
         */
        onFocus(e) {
            this.previousValue = this.text;
            this.$emit('focus', e, this);
            return this;
        },

        /**
         * Blur handler
         *
         * @param {FocusEvent} e
         */
        onBlur(e) {
            this.hadFocusOnce = true;

            // Remove whitespaces
            const trimmed = e.target.value.trim();
            if (e.target.value !== trimmed || this.text !== trimmed) {
                this.text = trimmed;
            }

            // Reset to previous value if an input is required
            if (this.required && trimmed === '' && this.previousValue !== null) {
                this.text = this.previousValue;
                this.applyValue(e);
            }

            // Reset caret and scroll position
            this.resetCaretPosition();

            // Pass the event, the value and the component to the parent
            this.$emit('blur', e, this);

            return this;
        },

        /**
         * Keydown handler
         *
         * @param {KeyboardEvent} e
         */
        onKeyDown(e) {
            if (this.disabled) {
                return this;
            }

            const key = KeyboardKey.findByEvent(e);

            // Prevent incorrect input
            if (this.restrictKeys.length >= 1)
            {
                const allowedKeyCodes = this.restrictKeys.map(k => k.code);
                if (key === null || (allowedKeyCodes.indexOf(key.code) === -1 && !e.metaKey && !e.ctrlKey && !e.altKey))
                {
                    e.preventDefault();
                }
            }

            // Handle [Enter] key
            if (key === KeyboardKey.Enter && (!this.isTextarea || e.ctrlKey || e.metaKey))
            {
                e.preventDefault();
                e.stopPropagation();
                this.$refs.domElement.blur();
            }

            // Handle [Escape] key
            if (key === KeyboardKey.Escape)
            {
                e.preventDefault();
                e.stopPropagation();
                e.target.value = this.previousValue;
                this.onChange(e);
                this.$refs.domElement.blur();
                this.$emit('cancel', e, this);
            }

            return this;
        },

        /**
         * Keyup handler
         *
         * @param {KeyboardEvent} e
         */
        onKeyUp(e) {
            if (this.disabled) {
                return this;
            }

            // Use a timer to prevent VueJS from recomputing on every key stroke
            if (this.keyUpTimer !== null) {
                window.clearTimeout(this.keyUpTimer);
            }
            this.keyUpTimer = window.setTimeout(() => {
                this.onChange(e);
            }, 10);

            return this;
        },

        /**
         * Change handler
         *
         * @param {Event|KeyboardEvent} e
         */
        onChange(e) {
            if (this.disabled) {
                return this;
            }

            this.hadFocusOnce = true;

            // Clear the keyup timer
            if (this.keyUpTimer !== null) {
                window.clearTimeout(this.keyUpTimer);
            }

            // Update internal value with the input/textarea value
            if (e.target.value.trim() === '') {
                e.target.value = '';
            }else if (!this.isTextarea && /^\s+/.test(e.target.value)) {
                // ltrim() while keeping the current caret position
                let caret = e.target.selectionStart;
                const length = e.target.value.length;
                e.target.value = e.target.value.replace(/^\s+/, '');
                caret = Math.max(0, caret - (length - e.target.value.length))
                e.target.setSelectionRange(caret, caret);
            }
            this.text = e.target.value; // @NOTE: This causes performance issues when called on keyup events because it triggers VueJS recomputing!
            this.applyValue(e);
            return this;
        },

        /**
         * Apply changed value
         *
         * @param {Event|KeyboardEvent|FocusEvent} e
         */
        applyValue(e) {
            let hasChanged = false;
            const trimmed = this.text.trim();
            // Update the model value
            if (this.model !== null && this.property !== null) {
                if (this.model[this.property] !== trimmed) {
                    this.model[this.property] = trimmed;
                    hasChanged = true;
                }
            }else if (this.initialValue !== trimmed) {
                hasChanged = true;
            }
            // Trigger change event (only if the value is different from the initial value)
            if (hasChanged === true) {
                this.$emit('change', trimmed, e, this);
            }
            return this;
        },

        /**
         * Reset to initial value
         */
        resetValue() {
            // Reset to initial value
            if (this.model !== null && this.property !== null && typeof this.model[this.property] === 'string') {
                this.text = this.model[this.property].trim();
            } else {
                this.text = (typeof this.initialValue === 'string') ? this.initialValue.trim() : '';
            }
            if (this.text === '' && this.required && this.previousValue !== null) {
                this.text = this.previousValue;
            }
            // Reset caret and scroll position
            this.resetCaretPosition();
            return this;
        },

        /**
         * Reset caret and scroll position
         */
        resetCaretPosition() {
            // @NOTE: setSelectionRange() is not supported on email inputs
            if (this.type === 'email') {
                return this;
            }

            if (this.$refs.domElement) {

                // Reset caret position:
                this.$refs.domElement.setSelectionRange(0, 0);

                // Reset scroll position for textarea type
                if (this.isTextarea) {
                    this.$refs.domElement.scrollTop = 0;
                }
            }
            return this;
        }
    },
    watch: {

        initialValue()
        {
            // Update internal value when the initial value changes (but only if not using model+property)
            if (this.model === null) {
                this.text = (typeof this.initialValue === 'string') ? this.initialValue.trim() : '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
