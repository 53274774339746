import { uuid4 } from '@/Utility/Helpers';
import TransformComponent from '@/Models/Unity/Components/TransformComponent';

export default class TextComponent extends TransformComponent
{
    /**
     * Type for this component
     *
     * @var {String}
     */
    static get type() { return 'text'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Set type for this component:
        super({...attributes, ...{type: attributes.type || TextComponent.type}});

        // Populate the model:
        // no additional attributes yet
    }

    /**
     * Duplicate
     *
     * @returns {TextComponent}
     */
    duplicate()
    {
        const duplicated = new TextComponent(this);
        duplicated.uid = uuid4();

        return duplicated;
    }
}
