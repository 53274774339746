import { uuid4 } from '@/Utility/Helpers';
import TransformComponent from '@/Models/Unity/Components/TransformComponent';

export default class SoundComponent extends TransformComponent
{
    /**
     * Type for this component
     *
     * @var {String}
     */
    static get type() { return 'sound'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Set type for this component:
        super({...attributes, ...{type: attributes.type || SoundComponent.type}});

        // Populate the model:
        // no additional attributes yet
    }

    /**
     * Duplicate
     *
     * @returns {SoundComponent}
     */
    duplicate()
    {
        const duplicated = new SoundComponent(this);
        duplicated.uid = uuid4();

        return duplicated;
    }
}
