import AssetFile from '@/Models/Asset/AssetFile';
import Asset from '@/Models/Asset/Asset';

export abstract class AbstractImageAsset extends Asset {

    public readonly images: AssetFile[];

    constructor(attributes: any) {
        super(attributes);

        this.images = attributes.images.map(image => new AssetFile(image));
    }

    get fileList() {
        return this.images;
    }
}

