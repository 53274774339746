import { uuid4 } from '@/Utility/Helpers';

export default class Component
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || attributes.type.length === 0)
        {
            console.warn('Component->constructor(): Invalid data.', attributes);
            throw new TypeError('Component->constructor(): Property "type" has to be set on Component.');
        }

        // Populate the model:
        this.uid = attributes.uid || uuid4();                               // Unique ID
        this.originalUid = this.uid;                                        // Original unique ID from which the object was duplicated (hidden)
        this.type = attributes.type;                                        // Type identifier
    }

    /**
     * Duplicate
     *
     * @returns {Component}
     */
    duplicate()
    {
        const duplicated = new Component(this);
        duplicated.uid = uuid4();

        return duplicated;
    }
}
