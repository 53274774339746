export default class HelperAnimationType
{
    /**
     * HelperAnimationType "constants"
     *
     * @NOTE: Make sure to also update the language file "commands.php"!
     *
     * @returns {String}
     */
    static get Blinking()           { return 'blinking'; }
    static get DirectionalDown()    { return 'directional_down'; }
    static get DirectionalLeft()    { return 'directional_left'; }
    static get DirectionalRight()   { return 'directional_right'; }
    static get DirectionalUp()      { return 'directional_up'; }
    static get Disappointed()       { return 'disappointed'; }
    static get Frightened()         { return 'frightened'; }
    static get Happy()              { return 'happy'; }
    static get Neutral()            { return 'neutral'; }
    static get Nodding()            { return 'nodding'; }
    static get Snooze()             { return 'snooze'; }
    static get Surprised()          { return 'surprised'; }
    static get VeryHappy()          { return 'very_happy'; }
    static get Waiting()            { return 'waiting'; }

    /**
     * Get all types as an array
     *
     * @returns {string[]}
     */
    static get all()
    {
        return Object.getOwnPropertyNames(this).filter(prop => ['all', 'prototype', 'length', 'name'].indexOf(prop) === -1).map(k => HelperAnimationType[k]);
    }
}
