import { trans } from '@/Utility/Helpers';
import FilterCategory from '@/Filters/FilterCategory';

/**
 * Filter definitions
 */
const FilterDefinitions = {

    All: {
        title: trans('labels.all'),
        callback(elements, options = null) {
            // Return the filtered list:
            return elements;
        }
    }
};

export default class UserFilters
{
    /**
     * Assigned users filter category
     */
    static get All()           { return new FilterCategory(FilterDefinitions.All); }

    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === UserFilters)
        {
            throw new TypeError('Static class "UserFilters" cannot be instantiated directly.');
        }
    }
}
