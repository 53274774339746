<template>

    <div class="container" :data-saving="isSaving">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('tenants.update.headline') }}</div>

                    <div class="card-body">

                        <form method="post" @submit.prevent="onSubmit">

                            <TenantFormFields
                                :api-validation-errors="apiValidationErrors"
                                :tenant-to-edit="tenantToEdit"
                            />

                            <div class="form-group row mb-0">
                                <div class="col-md-6 offset-md-4 buttons">
                                    <button type="submit" class="btn btn-primary">
                                        {{ trans('labels.create') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import {defineComponent, inject} from 'vue'
import {trans} from "@/Utility/Helpers/trans";
import {route} from "@/Utility/Helpers";
import TextInput from "@/Vue/Common/TextInput.vue";
import {tenantServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import RequestError from "@/Errors/RequestError";
import EventType from "@/Utility/EventType";
import {Permission} from "@/Models/User/Permission";
import type {TenantForm} from "@/Services/TenantService";
import TenantFormFields from "@/Vue/Tenants/TenantFormFields.vue";

export default defineComponent({
    name: "TenantCreateForm",

    components: {
        TenantFormFields,
        TextInput,
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
            tenantToEdit: {} as TenantForm,
            apiValidationErrors: null as Record<string, string[]> | null,
            isLoadingNextPage: false,
        }
    },

    mounted() {
        window.addEventListener('beforeunload', this.onBeforeUnload);
    },

    beforeUnmount() {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    },

    computed: {

        isSaving() {
            if (this.tenantService.isSaving || this.isLoadingNextPage) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
            } else {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            }
        },

        canChangeHubspotId() {
            return this.$gate.allows(Permission.TenantsConfigureHubspot());
        },
    },

    methods: {
        route,
        trans,

        onBeforeUnload() {
            this.isLoadingNextPage = true;
        },

        async onSubmit(_: SubmitEvent) {
            try {
                // create new tenant
                await this.tenantService.createTenant(this.tenantToEdit);

                // redirect to tenant list
                window.location.href = route('tenants.index');

            } catch (error: any) {
                if (error instanceof RequestError && error.isValidationError) {
                    this.apiValidationErrors = error.validationErrors;
                } else {
                    this.$root!.showErrorDialog(trans('errors.server_communication_failed'));
                }
            }
        },
    }
});
</script>

<style scoped lang="scss">

</style>
