import {cloneDeep} from 'lodash';
import { updateUidReferences, uuid4 } from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';
import PhysicsMaterialProperties from '@/Models/Unity/PhysicsMaterialProperties';

/**
 * BehaviourType to Behaviour subclass mapping
 *
 * @param {String} type
 * @returns {*|null}
 */
export function getBehaviourClassFromType(type) {
    const behaviourMapping = {
        [CollidableBehaviour.Type]:        CollidableBehaviour,
        [MovableBehaviour.Type]:           MovableBehaviour,
        [PhysicsBehaviour.Type]:           PhysicsBehaviour,
        [TeleportableBehaviour.Type]:      TeleportableBehaviour,
    };

    return behaviourMapping.hasOwnProperty(type) ? behaviourMapping[type] : null;
}

export default class Behaviour extends AbstractDataObject
{
    static get constructorName() { return 'Behaviour'; }
    static get Type() { return 'Behaviour'; }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        if (new.target === Behaviour) {
            throw new TypeError(`Cannot construct Behaviour instances directly`);
        }

        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Populate the model:
        this.uid = attributes.uid || uuid4();                               // Unique ID
        this.originalUid = this.uid;                                        // Original unique ID from which the object was duplicated (hidden)
        this.type = attributes.type;
        this.enabled = (typeof attributes.enabled === 'boolean') ? attributes.enabled : true;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {Behaviour}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = Behaviour.createFromAttributes(this, this.parent);
        duplicated.uid = uuid4();

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Create a new behaviour with the given BehaviourType or type string
     *
     * @param {String} behaviourType
     * @param {Object} attributes
     * @param {Object} parent               // Parent object reference
     * @returns {Behaviour}
     */
    static createWithType(behaviourType, attributes = null, parent = null) {
        if (!(attributes instanceof Object)) {attributes = {};}
        const behaviourClass = getBehaviourClassFromType(behaviourType);

        // Merge default attributes:
        if (behaviourClass !== null && behaviourClass.defaultAttributes instanceof Object) {
            attributes = {
                ...behaviourClass.defaultAttributes, ...attributes
            };
        }

        // Enforce the 'type' that is provided by behaviourType:
        attributes = {
            ...attributes,
            ...{
                type: behaviourType,
            }
        };

        return Behaviour.createFromAttributes(attributes, parent);
    }

    /**
     * Create a new behaviour from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent               // Parent object reference
     * @returns {Behaviour}
     */
    static createFromAttributes(attributes = {}, parent = null) {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        const className = getBehaviourClassFromType(clonedAttributes.type) || Behaviour;
        return new className(clonedAttributes, parent);
    }

    /**
     * Check if the behaviour is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // @NOTE: Override this method on subclasses to make sure a behaviour only uses valid data
        return this.type === this.constructor.Type;
    }

    /**
     * Clean up data (e.g. fix invalid values)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        // @NOTE: Override this method on subclasses to make sure a behaviour only uses valid data
        return false;
    }
}

export class CollidableBehaviour extends Behaviour
{
    static get Type() { return 'collidable'; }
}

export class MovableBehaviour extends Behaviour
{
    static get Type() { return 'movable'; }
}

export class PhysicsBehaviour extends Behaviour
{
    static get Type() { return 'physics'; }

    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        this.mass = (typeof attributes.mass === 'number') ? attributes.mass : PhysicsBehaviour.propertyDefaults.mass;
        this.material_characteristics = attributes.material_characteristics || PhysicsBehaviour.propertyDefaults.material_characteristics;

        // Material characteristics
        this.angular_drag       = !isNaN(attributes.angular_drag) ? attributes.angular_drag : PhysicsBehaviour.propertyDefaults.angular_drag;
        this.bounciness         = !isNaN(attributes.bounciness) ? attributes.bounciness : PhysicsBehaviour.propertyDefaults.bounciness;
        this.drag               = !isNaN(attributes.drag) ? attributes.drag : PhysicsBehaviour.propertyDefaults.drag;
        this.friction           = !isNaN(attributes.friction) ? attributes.friction : PhysicsBehaviour.propertyDefaults.friction;
        this.static_friction    = !isNaN(attributes.static_friction) ? attributes.static_friction : PhysicsBehaviour.propertyDefaults.static_friction;
    }

    static get propertyDefaults() {
        return Object.assign({
            enabled: true,
            mass: 1.0,
            material_characteristics: 'plastic',
        }, PhysicsMaterialProperties.plastic);
    }

    /**
     * Check if the behaviour is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        return !isNaN(this.mass) && this.mass >= 0.001
            && typeof this.material_characteristics === 'string' && PhysicsMaterialProperties.hasOwnProperty(this.material_characteristics)
            && !Object.keys(PhysicsMaterialProperties[this.material_characteristics]).some(requiredProperty => (
                !this.hasOwnProperty(requiredProperty)
                || isNaN(this[requiredProperty])
                // @TODO: Allow values to differ from default material properties? (e.g. remove this condition)
                || this[requiredProperty] != PhysicsMaterialProperties[this.material_characteristics][requiredProperty]
                ))
            && super.isValid;
    }

    /**
     * Clean up data (e.g. fix invalid values)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();

        // Reset mass:
        if (isNaN(this.mass) || this.mass < 0.001)
        {
            console.info('PhysicsBehaviour->cleanUpData(): Resetting invalid mass to default value.', this.mass, this);
            this.mass = PhysicsBehaviour.propertyDefaults.mass;
            hasChanged = true;
        }

        // Reset unknown values to default material:
        if (typeof this.material_characteristics !== 'string' || !PhysicsMaterialProperties.hasOwnProperty(this.material_characteristics))
        {
            console.info('PhysicsBehaviour->cleanUpData(): Resetting unknown values to default material.', this.material_characteristics, this);
            this.material_characteristics = PhysicsBehaviour.propertyDefaults.material_characteristics;
            Object.keys(PhysicsMaterialProperties[PhysicsBehaviour.propertyDefaults.material_characteristics]).forEach(k => this[k] = PhysicsMaterialProperties.plastic[k]);
            return true;
        }

        // Fix invalid values:
        Object.keys(PhysicsMaterialProperties[this.material_characteristics]).forEach(k => {
            // @TODO: Allow values to differ from default material properties? (e.g. remove the second condition)
            if (isNaN(this[k]) || this[k] != PhysicsMaterialProperties[this.material_characteristics][k])
            {
                console.info('PhysicsBehaviour->cleanUpData(): Resetting invalid material property.', k, this[k], this);
                this[k] = PhysicsMaterialProperties[this.material_characteristics][k];
                hasChanged = true;
            }
        });

        return hasChanged;
    }
}

export class TeleportableBehaviour extends Behaviour
{
    static get Type() { return 'teleportable'; }
}
