import {trans} from "@/Utility/Helpers/trans";

export enum AIKnowledgeType {
    Permanent = "permanent",
    Temporary = "temporary",
}

export namespace AIKnowledgeTypeHelpers {
    export function all(): AIKnowledgeType[] {
        return Object.values(AIKnowledgeType);
    }

    export function caption(knowledgeType: AIKnowledgeType): string {
        return trans('commands.ai.knowledge.knowledge_type.' + knowledgeType);
    }

    export function icon(knowledgeType: AIKnowledgeType): string {
        switch (knowledgeType) {
            case AIKnowledgeType.Permanent:
                return 'icon_lock_outline';
            case AIKnowledgeType.Temporary:
                return 'icon_temp';
        }
    }
}
