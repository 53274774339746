<template>
    <div class="launch-page-stage-row launch-page-row">
        <div class="launch-page-container launch-page-stage">
            <div class="launch-page-stage-teaser">
                <h1>{{ teaserHeadline }}</h1>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h2 v-html="teaserCopy" />
                <div class="launch-page-stage-teaser-buttons">
                    <ButtonPrimary
                        v-if="teaserButtonWebappCaption !== null && unitIsLaunchable"
                        :caption="teaserButtonWebappCaption"
                        class="btn-white"
                        icon="icon_play"
                        @trigger="onButtonWebappClicked"
                    />
                    <ButtonPrimary
                        v-if="teaserButtonHeadsetCaption !== null && unitIsLaunchable"
                        :caption="teaserButtonHeadsetCaption"
                        class="btn-white"
                        icon="icon_passthrough-off"
                        @trigger="onButtonHeadsetClicked"
                    />
                    <ButtonPrimary
                        v-if="teaserButtonGetAppCaption !== null && !unitIsLaunchable"
                        :caption="teaserButtonGetAppCaption"
                        class="btn-white"
                        @trigger="onButtonGetAppClicked"
                    />
                </div>

            </div>
            <LaunchUnitCard
                :description="cardText"
                :headline="cardHeadline"
                :image-url="cardImageUrl"
                :unit-is-launchable="unitIsLaunchable"
                @click-launch="$emit('onButtonWebappClicked')"
            />
        </div>
    </div>
</template>

<script>
import LaunchUnitCard from '@/Vue/Lms/xapi/LaunchUnitCard.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

export default {
    name: 'PageLmsXApiLaunchStage',

    components: {
        ButtonPrimary,
        LaunchUnitCard,
    },

    props: {
        cardImageUrl: {
            type: String,
            required: true,
        },
        cardHeadline: {
            type: String,
            default: null,
        },
        cardText: {
            type: String,
            default: null,
        },
        teaserHeadline: {
            type: String,
            required: true,
        },
        teaserCopy: {
            type: String,
            required: true,
        },
        teaserButtonHeadsetCaption: {
            type: String,
            default: null,
        },
        teaserButtonWebappCaption: {
            type: String,
            default: null,
        },
        teaserButtonGetAppCaption: {
            type: String,
            default: null,
        },
        unitIsLaunchable: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        'onButtonHeadsetClicked',
        'onButtonWebappClicked',
        'onButtonGetAppClicked',
    ],

    methods: {

        onButtonHeadsetClicked(e) {
            e.target.blur();
            this.$emit('onButtonHeadsetClicked', e);
        },

        onButtonWebappClicked(e) {
            e.target.blur();
            this.$emit('onButtonWebappClicked', e);
        },

        onButtonGetAppClicked(e) {
            e.target.blur();
            this.$emit('onButtonGetAppClicked', e);
        },

    },
};
</script>

<style lang="scss" scoped>

$xapi-launch-page-margin: 24px;
$xapi-launch-page-max-width: 1368px;
$xapi-launch-min-column-width: 350px;
$xapi-launch-column-gap: 24px 48px;

.launch-page-container {
    width: 100%;
    max-width: $xapi-launch-page-max-width;
    display: flex;
    margin: 0 auto;
    padding: 0 $xapi-launch-page-margin 0 $xapi-launch-page-margin;
}

.launch-page-stage-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 0;

    &::after {
        background: var(--color-primary) url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' height=\'48\' width=\'1920\'%3E%3Cpath d=\'M0 .3v48h1920v-7.2s-530.7 10.2-1010.7 0S0 .3 0 .3z\' fill=\'%23fff\'/%3E%3C/svg%3E') no-repeat bottom;
        background-size: 100%;
        content: '';
        top: 0;
        bottom: 25px;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        transform: scaleX(-1);
        z-index: -1;
    }
}

.launch-page-stage {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 $xapi-launch-page-margin;
    gap: $xapi-launch-column-gap;

    .launch-page-stage-teaser {
        flex-basis: $xapi-launch-min-column-width;
        flex-grow: 1;
        color: var(--color-white);

        h1 {
            margin-bottom: 24px;
        }

        h2 {
            max-width: 480px;
            font-family: var(--font-family-condensed);
        }

        .launch-page-stage-teaser-buttons {
            display: flex;
            gap: 12px 24px;
            flex-wrap: wrap;
            margin-top: 24px;
        }
    }
}

@media screen and (max-width: 992px) {
    .launch-page-stage {
        .launch-page-stage-teaser {
            h1 {
                font-size: var(--font-size-large);
                line-height: var(--line-height-large);
                margin-bottom: 18px;
            }

            h2 {
                font-size: var(--font-size-medium);
                line-height: var(--line-height-medium);
            }

            .launch-page-stage-teaser-buttons {
                margin-top: 18px;
            }
        }
    }
}

</style>
