import ServiceIsBusyError from "@/Errors/ServiceIsBusyError";
import {route} from "@/Utility/Helpers";

export default class ManageService {

    public isLoading: boolean = false;
    
    async runCommand(commandName: string): Promise<string[]> {

        if (this.isLoading) {
            throw new ServiceIsBusyError();
        }

        this.isLoading = true;

        try {

            const url = route('api.manage.commands.run', {command: commandName});
            const response = await window.axios.post(url);
            return response.data.logs;

        } finally {
            this.isLoading = false;
        }
    }
}
