<template>
    <Modal
        :animated="true"
        event-type="MODAL_ABOUT"
    >

        <h2>{{ title }}</h2>
        <p>{{ versionText }}</p>

        <h3>{{ trans('labels.support') }}</h3>
        <ul>
            <li><a class="link-external" href="mailto:support@3spin.com" rel="noopener noreferrer" target="_blank">support@3spin.com</a></li>
            <li>+49-6151-870101-87</li>
        </ul>

        <h3>{{ trans('modals.about.headline_imprint_and_licenses') }}</h3>
        <ul>
            <li>
                <a class="link-external" :href="trans('modals.about.link_target_open_source')" rel="noopener noreferrer" target="_blank">
                    {{ trans('modals.about.link_text_open_source') }}
                </a>
            </li>
            <li>
                <a class="link-external" :href="trans('modals.about.link_target_imprint')" rel="noopener noreferrer" target="_blank">
                    {{ trans('modals.about.link_text_imprint') }}
                </a>
            </li>
            <li>
                <a class="link-external" :href="trans('modals.about.link_target_tos')" rel="noopener noreferrer" target="_blank">
                    {{ trans('modals.about.link_text_tos') }}
                </a>
            </li>
        </ul>

    </Modal>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Icon from '@/Vue/Common/Icon.vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers/trans';

export default defineComponent({
    methods: {
        trans
    },

    components: {
        Modal,
        Icon
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        version: {
            type: String,
            required: true,
        },
        release: {
            type: String,
            required: true,
        },
        build: {
            type: String as PropType<String | null>,
            default: null,
        },
    },

    computed: {

        versionText() {
            // E.g. "Version 2021.1 (2.0.0-1234)", "Version 2021.1 (2.0.0)""
            return trans('labels.version') + ' ' + this.release + ' (' + this.version + this.buildString + ')';
        },

        buildString() {
            return this.build !== null && this.build.length > 0 ? `-${this.build}` : '';
        },

    },
});
</script>

<style scoped lang="scss">

ul {
    list-style-type: none;

    li {
        margin-bottom: 6px;
    }
}

</style>
