import Variable from '@/Models/UnitData/Variables/Variables/Variable';

export default class NumberVariable extends Variable<number> {

    static get Type() {
        return 'number';
    }

    get defaultValue() {
        return 0;
    }

    static get MinValue() {
        return -1_000_000_000;
    }

    static get MaxValue() {
        return 1_000_000_000;
    }

    get isValid() {
        return !isNaN(this.value)
            && this.value >= NumberVariable.MinValue
            && this.value <= NumberVariable.MaxValue
            && this.value % 1 === 0
            && super.isValid;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        if (isNaN(this.value)) {
            console.info('NumberVariable->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = this.defaultValue;
            hasChanged = true;
        } else if (this.value !== parseInt(this.value, 10)) {
            console.info('NumberVariable->cleanUpData(): Changing float or string value to integer.', this.value, this);
            this.value = parseInt(this.value, 10);
            hasChanged = true;
        }

        if (this.value > NumberVariable.MaxValue) {
            console.info('NumberVariable->cleanUpData(): Resetting out-of-range value to maximum.', this.value, this);
            this.value = NumberVariable.MaxValue;
            hasChanged = true;
        } else if (this.value < NumberVariable.MinValue) {
            console.info('NumberVariable->cleanUpData(): Resetting out-of-range value to minimum.', this.value, this);
            this.value = NumberVariable.MinValue;
            hasChanged = true;
        }

        return hasChanged;
    }
}
