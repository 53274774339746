<template>

    <aside :class="{ 'open': isVisible }" class="layout-sidepanel">
        <div
            v-if="isVisible"
            id="sidepanel-devices"
            v-shortcuts
            class="sidepanel-content"
        >
            <header>
                <Icon class="icon-close" name="icon_doublearrow_right" @click="onClickCloseSidepanel" />
                {{ trans(headlineLabel) }}
                <LoadingIndicator v-if="isLoading" />
            </header>

            <ListFiltersBar
                :categories="filterCategories"
                :filter-words="filterWords"
                class="sidepanel-filters"
                @cancel="onClickCloseSidepanel"
                @change="applyFilters"
            />

            <ul v-if="devices.length > 0" v-not-focusable class="selectable-list">
                <li
                    v-for="device in filteredList"
                    :key="device.uid"
                    v-focusable
                    @click.capture.stop="selectDevice(device)"
                    @keyup.space="selectDevice(device)"
                >
                    <span class="preview">
                        <Icon :name="device.formFactorIcon" />
                    </span>
                    <section class="info">
                        <h4 class="name">
                            {{ device.name }}
                        </h4>
                        <p class="model">
                            {{ device.model }}
                        </p>
                    </section>

                    <Checkbox
                        :key="'deviceSelected'+device.uid+'_'+device.selected"
                        v-not-focusable
                        :events-disabled="true"
                        :model="device"
                        property="selected"
                    />
                </li>
            </ul>

            <NoItemsAvailable
                v-else
                :caption="trans('sessions.devices.no_items_caption')"
                :description="trans('sessions.devices.no_items_description')"
            />

            <footer class="buttons">
                <ButtonSecondary
                    v-if="countSelectedDevices !== filteredList.length"
                    caption="labels.select_all"
                    class="select-all"
                    @trigger="onClickSelectAll"
                />
                <ButtonSecondary
                    v-if="countSelectedDevices === filteredList.length"
                    caption="labels.deselect_all"
                    class="deselect-all"
                    @trigger="onClickDeselectAll"
                />
                <ButtonPrimary
                    :caption="assignmentButtonLabel"
                    :disabled="countSelectedDevices === 0"
                    class="assign-to-training"
                    icon="icon_add"
                    @trigger="onClickAddToSession"
                />
            </footer>
        </div>
    </aside>
</template>

<script lang="ts">

import ListFiltersBar from '@/Vue/Common/ListFiltersBar.vue';
import EventType from '@/Utility/EventType';
import Search from '@/Utility/Search';
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import Icon from '@/Vue/Common/Icon.vue';
import Checkbox from '@/Vue/Common/Checkbox.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import type Device from '@/Models/Devices/Device';
import type FilterCategory from '@/Filters/FilterCategory';
import DeviceFilters from '@/Filters/DeviceFilters';
import {trans} from '@/Utility/Helpers';
import LoadingIndicator from '@/Vue/Common/LoadingIndicator.vue';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';

export default defineComponent({
    components: {
        NoItemsAvailable,
        LoadingIndicator,
        ButtonPrimary,
        ButtonSecondary,
        Checkbox,
        Icon,
        ListFiltersBar
    },

    props: {
        headlineLabel: {
            type: String,
            default: 'labels.devices',
        },

        assignmentButtonLabel: {
            type: String,
            default: 'labels.add_to_session',
        },

        /**
         * If set to true, a small loading indicator will be displayed.
         */
        isLoading: {
            type: Boolean,
            default: false,
        },

        /**
         * The list of devices passed from the parent component
         */
        devices: {
            type: Array as PropType<Device[]>,
            default: () => {
                return [];
            },
        },
    },

    data() {
        return {
            isVisible: false,

            /**
             * DOM element that had focus before the dialog was shown
             */
            previousFocusElement: null as HTMLElement | null,

            /**
             * List of FilterCategory items
             */
            filterCategories: [
                DeviceFilters.All.setActive(true),
                DeviceFilters.InManagedMode.setActive(true),
            ],

            /**
             * Filtered list of devices
             */
            filteredList: [] as Device[],

            filterOptions: null,
            filterWords: null as string | null,

            events: new Map([
                [EventType.SIDEPANEL_DEVICES_FILTERS_SET, this.setFilters],
                [EventType.SIDEPANEL_DEVICES_SHOW, this.show],
                [EventType.SIDEPANEL_DEVICES_HIDE, this.hide],
                [EventType.SIDEPANEL_DEVICES_CANCEL, this.hide],
            ]),

            shortcuts: new Map([
                ['Escape', this.onClickCloseSidepanel],
                ['Space.prevent', null],
                ['Shift+Space.prevent', null]
            ])
        };
    },

    computed: {
        /**
         * Number of selected devices
         */
        countSelectedDevices(): number {
            return this.filteredList.filter((u) => u.selected).length;
        }
    },

    watch: {
        isVisible(isVisible: boolean) {
            if (isVisible) {
                if (document.activeElement instanceof HTMLElement) {
                    this.previousFocusElement = document.activeElement;
                }
            } else {
                this.previousFocusElement?.focus();
                this.previousFocusElement = null;
            }
        },

        devices(devices: Device[], oldDevices: Device[]) {
            // apply old selected status to new model instances
            oldDevices.forEach(oldDevice => {
                if (oldDevice.selected) {
                    const matchingNewDevice = devices.find(device => device.uid === oldDevice.uid);
                    if (matchingNewDevice) {
                        matchingNewDevice.selected = true;
                    }
                }
            });

            // Re-apply the filters:
            this.applyFilters();
        }
    },

    created() {
        // @NOTE: Only using one filter by default for now so it should be active
        this.filterCategories[0].setActive(true);
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        trans,

        show() {
            this.isVisible = true;
        },

        hide() {
            this.isVisible = false;
            this.onClickDeselectAll();
        },

        /**
         * Click handler for close button
         */
        onClickCloseSidepanel() {
            this.$globalEvents.emit(EventType.SIDEPANEL_DEVICES_CANCEL);
        },

        /**
         * Set and apply the filters to be used by the ListFiltersBar component
         */
        setFilters(filters: FilterCategory[], options: any = null) {
            this.filterCategories = filters;
            this.filterOptions = options;
            this.applyFilters();
            return this;
        },

        applyFilters(filterText: FilterCategory | string | null | undefined = undefined) {
            // Store filter text:
            if (filterText !== undefined && (filterText === null || typeof filterText === 'string') && filterText !== this.filterWords) {
                this.filterWords = filterText;
            }

            // Reset the list first:
            let filteredDevices = this.devices;

            // Apply filter categories:
            this.filterCategories
                .filter(fc => fc.isActive)
                .forEach(fc => {
                    filteredDevices = fc.callback(filteredDevices, this.filterOptions);
                }, this);

            // Apply word filter:
            if (this.filterWords !== null) {
                filteredDevices =
                    Search.filterObjects(filteredDevices, ['name', 'model'], this.filterWords) as Device[];
            }

            this.filteredList.length = 0;
            this.filteredList.push(...filteredDevices);

            return this;
        },

        /**
         * Select a specific device
         */
        selectDevice(device: Device) {
            device.selected = !device.selected;
        },

        /**
         * Click handler for select all button
         */
        onClickSelectAll() {
            this.filteredList.forEach((device) => device.selected = true);
        },

        /**
         * Click handler for select all button
         */
        onClickDeselectAll() {
            this.filteredList.forEach((device) => device.selected = false);
        },

        /**
         * Click handler for add-to-session button
         */
        onClickAddToSession() {
            if (this.countSelectedDevices < 1) {
                return;
            }

            this.$globalEvents.emit(
                EventType.SIDEPANEL_DEVICES_ASSIGN,
                this.filteredList.filter((device) => device.selected)
            );
        }
    }
});
</script>

<style lang="scss" scoped>

.layout-sidepanel {
    header {
        flex-direction: row;

        .loading-indicator {
            width: 14px;
            height: 14px;
            margin: 0 -28px 0 14px;
        }
    }

    ul.selectable-list > li {
        .preview {
            background-color: white;
            flex-basis: 36px;
            min-height: auto;
            aspect-ratio: 1;
            border-radius: 50%;
            padding: 8px;

            .icon {
                width: 100%;
            }
        }
        .info {
            .name {
                font-family: var(--font-family-mono-bold);
                margin-right: 16px;
            }

            .model {
                font-size: var(--font-size-small);
                line-height: var(--line-height-small);
                color: var(--color-anthracite40);
            }
        }

        &:hover,
        &:focus-visible,
        &:active {
            .model {
                color: var(--color-primary-hover);
            }
        }
    }

    .no-items-available {
        flex-grow: 1;
        align-self: center;
        margin-top: 10vh;
        max-width: 300px;
    }
}

</style>
