<template>
    <Modal
        id="dialog-ready-player-me"
        ref="modal"
        :animated="true"
        :show-close-button="true"
        :user-closable="true"
        event-type="MODAL_READY_PLAYER_ME"
        @hide="onHide"
        @show="onShow"
    >
        <iframe ref="frame"
                :src="iFrameSrc"
                loading="lazy"
                allow="camera *"
                class="frame">
        </iframe>
    </Modal>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import {defineComponent, PropType} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers';


// Type definitions:
type ReadyPlayerMeEvent = {
    source: string,
    eventName: 'v1.frame.ready' | 'v1.avatar.exported',
    data: {
        url: string,
    }
};

type AvatarType = 'user' | 'npc';


export default defineComponent({
    components: {
        Modal
    },

    props: {
        avatarType: {
            type: String as PropType<AvatarType>,
            required: true,
        },
        clearCache: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isVisible: false,
        }
    },

    mounted() {
        window.addEventListener('message', this.onMessageReceived);
    },

    beforeUnmount() {
        window.removeEventListener('message', this.onMessageReceived);
    },

    computed: {

        iFrameSrc() {
            let baseUrl = (this.avatarType === 'user')
                ? 'https://3spinlearning.readyplayer.me/avatar?frameApi'
                : 'https://3spinlearning-npc.readyplayer.me/avatar?frameApi';

            return this.clearCache
                ? baseUrl + '&clearCache'
                : baseUrl;
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        frame() {
            return this.$refs.frame as HTMLIFrameElement;
        }

    },

    methods: {
        trans,

        onShow() {
            this.isVisible = true;
        },

        onHide() {
            this.isVisible = false;
        },

        onMessageReceived(event: { data: string; }) {
            const json = this.parseMessage(event);
            if (json === null || json.source !== 'readyplayerme') {
                return;
            }

            switch (json.eventName) {
                case 'v1.frame.ready':
                    this.onFrameReady();
                    break;
                case 'v1.avatar.exported':
                    this.onAvatarExported(json);
                    break;
            }
        },

        onFrameReady() {
            // subscribe to all events sent from Ready Player Me
            this.frame.contentWindow?.postMessage(
                JSON.stringify({
                    target: 'readyplayerme',
                    type: 'subscribe',
                    eventName: 'v1.**'
                }),
                '*'
            );
        },

        onAvatarExported(event: ReadyPlayerMeEvent) {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_READY_PLAYER_ME_APPLY, event.data.url);
        },

        parseMessage(event: { data: string; }): ReadyPlayerMeEvent | null {
            try {
                return JSON.parse(event.data);
            } catch (error) {
                return null;
            }
        }
    }
});
</script>

<style lang="scss" scoped>

#dialog-ready-player-me {
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 32px;
    width: 660px;
    height: 724px;

    :deep(iframe) {
        display: block;
        border: none;
        width: 100%;
        height: 100%;
    }
}

</style>
