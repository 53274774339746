<template>

    <div :class="classes">
        <div
            v-if="isLoading"
            class="loader">
            <LoadingIndicator/>
        </div>

        <div v-if="hasError" class="error">
            <span>?</span>
        </div>

        <img
            v-if="imageSource"
            :alt="trans('labels.avatar_url')"
            :src="imageSource"
            class="preview-image"
            @error="onLoadError"
            @load="onLoadFinished"/>
    </div>

</template>

<script>
    import LoadingIndicator from "@/Vue/Common/LoadingIndicator";

    export default {
        name: 'ReadyPlayerMeAvatarPreview',

        components: {
            LoadingIndicator,
        },

        props: {
            avatar_glb_url: {
                type: String,
                default: null
            },
            fallback_avatar_url: {
                type: String,
                default: "/images/preview/avatar_default.png"
            },
            error_image_url: {
                type: String,
                default: "/svg/error-image-2.svg"
            },
            allowed_base_urls: {
                type: Array,
                default: () => [
                    'https://models.readyplayer.me/', // new
                    'https://api.readyplayer.me/v1/avatars/', // old
                    `${window.location.origin}/avatars/v1/`
                ]
            }
        },

        data() {
            return {
                /**
                 * @type boolean
                 */
                hasError: false,

                /**
                 * @type boolean
                 */
                isLoading: false,
            }
        },

        watch: {
            imageSource(newImageSource) {
                if (newImageSource !== null) {
                    // workaround for loadstart event on image tags not working in chromium
                    this.onLoadStarted();
                }

                if (newImageSource === this.fallback_avatar_url) {
                    this.onLoadFinished();
                }
            }
        },

        computed: {

            classes() {
                let classes = ['avatar-preview'];

                if (this.isLoading) {
                    classes.push('loading');
                }

                return classes.join(' ');
            },

            /**
             * @return {String} url of the avatar image to load
             */
            imageSource() {
                if (!this.avatar_glb_url) {
                    return this.fallback_avatar_url;
                }

                // do not make requests to arbitrary urls in our name
                if (this.allowed_base_urls.every(baseUrl => !this.avatar_glb_url.startsWith(baseUrl))) {
                    this.hasError = true;
                    return null;
                }

                return this.avatar_glb_url.replace(
                    '.glb',
                    '.png?blendShapes[mouthSmile]=0.3&blendShapes[browInnerUp]=0.2'
                )
            }
        },

        methods: {
            onLoadStarted() {
                this.isLoading = true;
            },

            onLoadFinished() {
                this.hasError = false;
                this.isLoading = false;
            },

            onLoadError() {
                this.hasError = true;
                this.isLoading = false;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .avatar-preview {
        aspect-ratio: 1;
        border: var(--forminput-border);
        border-radius: var(--forminput-border-radius);
        overflow: hidden;
        background-color: var(--background-color-light);

        img {
            height: 100%;
            width: 100%;
            display: inline-block;
        }

        .loader {
            height: 100%;
            width: 100%;
            text-align: center;
            padding-top: calc(50% - 20px);
        }

        .error {
            height: 100%;
            width: 100%;
            display: table;
            text-align: center;
            color: var(--color-anthracite40);
            font-size: var(--font-size-largest);
            font-family: var(--font-family-condensed);

            span {
                vertical-align: middle;
                display: table-cell;
            }
        }
    }

</style>
